import React from 'react';
import { SubscriptionOrderType } from '../views/user/SettingsView/subviews/subscription/types';
import { Link } from 'react-router-dom';
import { routeMainPaths } from '../utils/constants';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

type TRenewPaymentButton = {
  order: SubscriptionOrderType & { subscription_id?: number };
  className?: string;
  noButton?: boolean;
};

export const RenewPaymentButton: React.FC<TRenewPaymentButton> = ({
  order,
  className = '',
  noButton = false
}) => {
  const { t } = useTranslation();

  const isRecurring = order.subscription_price || order.subscription_id;

  const content = noButton ? (
    t('pay-for-order')
  ) : (
    <Button className="reorder-btn" type="primary" shape="round" style={{fontWeight: 'normal'}}>
      {t('pay-for-order')} →
    </Button>
  );

  return (
    <Link
      to={{
        pathname: `${routeMainPaths.REORDER}${order.id}/${
          isRecurring ? 'subscription' : 'onetime'
        }`,
        state: { back: window.location.pathname }
      }}
      className={className}
      style={{fontWeight: 'normal'}}
    >
      {content} →
    </Link>
  );
};
