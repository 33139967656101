import React from 'react';
import '../prescription-details-view.scss';
import { SubstancesTable } from '../../../../components/SubstancesTable/index';
import { prepareSubstancesData } from './prepareSubstancesData';
import { useSelector } from 'react-redux';

const PrescriptionDetailsSubstances = props => {
  const { prescription } = props;
  const { substances, units, substance_types, wasInitialized } = useSelector(
    state => state.panelMixerData
  );

  if (!prescription || !wasInitialized) return null;

  const data = prepareSubstancesData(
    prescription.json_body.substances,
    substances,
    units
  );

  let types = [];
  substance_types.forEach((val, key) => {
    const filtered = data.filter(item => item.type === key);
    if (filtered.length !== 0) {
      types.push({
        key,
        title: val.get('name'),
        titleIcon: val.get('icon'),
        data: filtered
      });
    }
  });

  return (
    <>
      {types.map(type => (
        <SubstancesTable
          key={type.key}
          data={type.data}
          title={type.title}
          titleIcon={type.titleIcon}
        />
      ))}
    </>
  );
};

export default PrescriptionDetailsSubstances;
