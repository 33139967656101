import React, { useCallback, useEffect } from 'react';
import { retrieveAffiliationCode } from '../../../api/affiliation';
import { useDispatch, useSelector } from 'react-redux';
import { HTTP_STATUS_CODES, affiliationStatus } from '../../../utils/constants';
import { setAffiliation } from './actions';
import { AffiliationBox } from '../../../components/AffiliationBox';

const { PENDING } = affiliationStatus;

const AffiliationDataProvider = ({ withLink }) => {
  const dispatch = useDispatch();

  const { affiliation, tokenData } = useSelector(
    state => state.userDataReducer
  );

  useEffect(() => {
    retrieveUserAffiliationCode();
  }, [tokenData]);

  const retrieveUserAffiliationCode = useCallback(async () => {
    const response = retrieveAffiliationCode(tokenData.get('user_id'));

    await response
      .then(({ state, affiliation_code }) => {
        dispatch(
          setAffiliation({
            status: state,
            code: affiliation_code
          })
        );
      })
      .catch(error => {
        if (error.response.status === HTTP_STATUS_CODES.NOT_FOUND) {
          dispatch(
            setAffiliation({
              status: PENDING,
              code: ''
            })
          );
        }
      });
  }, [dispatch, tokenData]);

  return (
    <div>
      {affiliation.status ? (
        <AffiliationBox
          code={affiliation.code}
          status={affiliation.status}
          withLink={withLink}
        />
      ) : null}
    </div>
  );
};

export default AffiliationDataProvider;
