import { getEnv } from '../../env';

export const authActionNames = {
  SET_LOGGED_IN: 'SET-LOGGED-IN',
  SET_TOKEN: 'SET-TOKEN'
};

export const dataLayerBundleLevels = {
  0: 'basic',
  1: 'optimum',
  2: 'complete'
};

export const roleNames = {
  CLIENT: 'CLIENT',
  DIETITIAN: 'DIETITIAN'
};

export const editStatus = {
  DRAFT: 'draft',
  COMPLETED: 'completed'
};

export const tokenKeys = {
  USER_ID: 'user_id',
  USERNAME: 'username',
  NAME: 'name',
  SURNAME: 'surname',
  EMAIL: 'email',
  AUD: 'aud',
  ROLES: 'roles'
};

export const actionNamesMixer = {
  LOAD_DATA: 'LOAD-DATA',
  SET_DISPLAY_UNITS: 'SET-DISPLAY-UNITS',
  SET_MIXER_INITIALIZED: 'SET-MIXER-INITIALIZED'
};

export const mixerDataNames = {
  SUBSTANCES: 'substances',
  UNITS: 'units',
  SUBSTANCE_TYPES: 'substance_types',
  EXCLUSIONS: 'exclusions',
  EXCHANGE_RATES: 'exchange_rates',
  BASELINE_PRICES: 'baseline_prices'
};

export const mixerDataToLoad = [
  mixerDataNames.UNITS,
  mixerDataNames.SUBSTANCES,
  mixerDataNames.SUBSTANCE_TYPES,
  mixerDataNames.EXCLUSIONS,
  mixerDataNames.EXCHANGE_RATES,
  mixerDataNames.BASELINE_PRICES
];

export const actionNamesUserData = {
  SET_USER_ORDERS: 'SET-USER-ORDERS',
  SET_SHOULD_FETCH_ORDERS: 'SET-SHOULD-FETCH-ORDERS',
  SET_USER_SETTINGS: 'SET-USER-SETTINGS',
  SET_USER_SUBSCRIPTION: 'SET-USER-SUBSCRIPTION',
  CANCEL_USER_SUBSCRIPTION: 'CANCEL-USER-SUBSCRIPTION',
  SET_TOKEN_DATA: 'SET-TOKEN-DATA',
  SET_PRESCRIPTIONS: 'SET-PRESCRIPTIONS',
  UPDATE_PRESCRIPTION_NAME: 'UPDATE-PRESCRIPTION-NAME',
  ADD_PRESCRIPTION: 'ADD-PRESCRIPTION',
  SET_USER: 'SET-USER',
  SET_SUNDOSE_DATA: 'SET-SUNDOSE-DATA',
  LOGOUT: 'LOGOUT'
};

export const actionNamesPanelAction = {
  SAVE_URL_PARAMS: 'SAVE-URL-PARAMS'
};

export const CALENDAR_FORMAT = 'YYYY-MM-DD';
export const CALENDAR_FORMAT_DISPLAY = 'DD.MM.YYYY';

export const actionNamesCalendar = {
  SET_DATES_TAKEN: 'SET-DATES-TAKEN',
  SET_CALENDAR_INVENTORY: 'SET-CALENDAR-INVENTORY',
  TOGGLE_DATE_TAKEN: 'TOGGLE-DATE-TAKEN'
};

export const CALENDAR_MAX_DOSES = 30;

export const exclusionsCategories = {
  HIDDEN: 'HIDDEN'
};

export const flavourKeys = {
  ACIDITY: 'acidity',
  SWEETNESS: 'sweetness'
};

export const surveyIds = {
  HOW_YOU_FEEL_SURVEY: getEnv(
    'REACT_APP_PUBLIC_SURVEY_CALENDAR_FEEL_SURVEY_ID'
  ),
  ADDITIONAL_QUESTION_SURVEY: getEnv(
    'REACT_APP_PUBLIC_SURVEY_CALENDAR_ADDITIONAL_QUESTION_SURVEY_ID'
  )
};

export const DEFAULT_COUNTRY_NORMS_CODE = 'pl';

export const notificationTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const routeKeys = {
  BASENAME: process.env.PUBLIC_URL,
  PANEL: 'panel',
  DASHBOARD: 'dashboard',
  COMPOSITION_PRESCRIPTION: 'composition-prescription',
  OFFER: 'offer',
  PAYMENTS: 'payments',
  SETTINGS: 'settings',
  THANK_YOU_PAGE: 'thank-you-page',
  REORDER: 'reorder'
};

export const settingsRouteKeys = {
  PROFILE: 'profile',
  DELIVERY: 'delivery',
  PRODUCT: 'product',
  PASSWORD_CHANGE: 'password-change',
  SUBSCRIPTION: 'subscription',
  COUPONS: 'coupons'
};

export const routeMainPaths = {
  PANEL: `/${routeKeys.PANEL}`,
  DASHBOARD: `/${routeKeys.PANEL}/${routeKeys.DASHBOARD}`,
  COMPOSITION_PRESCRIPTION: `/${routeKeys.PANEL}/${routeKeys.COMPOSITION_PRESCRIPTION}/`,
  OFFER: `/${routeKeys.PANEL}/${routeKeys.OFFER}`,
  PAYMENTS: `/${routeKeys.PANEL}/${routeKeys.PAYMENTS}`,
  SETTINGS: `/${routeKeys.PANEL}/${routeKeys.SETTINGS}/`,
  REORDER: `/${routeKeys.PANEL}/${routeKeys.REORDER}/`
};

export const affiliationActions = {
  SET_AFFILIATION: 'SET-AFFILIATION'
};

export const affiliationStatus = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE'
};

export const EMAIL_SUNDOSE_OFFICE = 'office@sundose.io';
export const EMAIL_SUNDOSE_CUSTOMERCARE = 'customercare@sundose.io';

export const DEFAULT_SHIPPING_COUNTRY = 'pl';

export const collapsePrescriptionExtraButtons = {
  CHANGE_NAME: 'CHANGE_NAME',
  EDIT: 'EDIT',
  SHARE: 'SHARE',
  NONE: ''
};

export const setDefaultPrescriptionName = (id: number): string =>
  `Sundose #${id}`;

export const HTTP_STATUS_CODES = {
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NO_CONTENT: 204
};

export const SBL_LOCALSTORAGE_KEY = 'sbl';

export const countryNormsCodes = {
  PL: 'pl',
  EN_GB: 'en-GB',
  DE_DE: 'de-DE',
  GA_IE: 'ga-IE',
  FR: 'fr',
  DE_AT: 'de-AT',
  SW: 'sw',
  IT_IT: 'it-IT',
  NL_NL: 'nl-NL'
};

export const currencies = {
  PLN: 'PLN',
  GBP: 'GBP',
  EUR: 'EUR'
};

export const countryCurrency = {
  [countryNormsCodes.PL]: currencies.PLN,
  [countryNormsCodes.EN_GB]: currencies.GBP,
  [countryNormsCodes.DE_DE]: currencies.EUR,
  [countryNormsCodes.GA_IE]: currencies.EUR,
  [countryNormsCodes.FR]: currencies.EUR,
  [countryNormsCodes.DE_AT]: currencies.EUR,
  [countryNormsCodes.SW]: currencies.EUR,
  [countryNormsCodes.IT_IT]: currencies.EUR,
  [countryNormsCodes.NL_NL]: currencies.EUR
};

export const payPalLocales = {
  [countryNormsCodes.PL]: 'pl_PL',
  [countryNormsCodes.EN_GB]: 'en_GB',
  [countryNormsCodes.DE_DE]: 'de_DE',
  [countryNormsCodes.IT_IT]: 'it_IT'
};
