import React from 'react';
import './prescription-preferences-box.scss';
import { PrescriptionPreferencesBox } from './index';
import { useSelector } from 'react-redux';
import { useIconsHiddenExclusions } from '../../utils/iconImports';

export const PrescriptionPreferencesBoxWrapper = ({
  exclusions,
  flavour,
  bottomButton,
  loading
}) => {
  const { exclusions: allExclusions, wasInitialized } = useSelector(
    state => state.panelMixerData
  );
  const hiddenExclusions = useIconsHiddenExclusions(allExclusions);

  if (!wasInitialized) return null;

  return (
    <PrescriptionPreferencesBox
      preferencesDefs={hiddenExclusions.toJS()}
      preferencesData={exclusions}
      flavour={flavour}
      bottomButton={bottomButton}
      loading={loading}
    />
  );
};
