import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';

import { getPrescriptions } from '../../../api/mixer';
import { savePrescriptions } from './actions';
import { useOptimize } from '../../../utils';
import { CollapsePrescription } from '../../../components/CollapsePrescription';
import { AddPrescriptionModalForm } from './AddPrescriptionModal';
import './saved-prescriptions-view.scss';
import InfoboxWithModal from '../../../components/InfoboxWithModal/InfoboxWithModal';

const SavedPrescriptionsView = () => {
  const { t } = useTranslation();
  useOptimize('activateSavedPrescriptions');

  const [expandAll, setExpandAll] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [
    attachPrescriptionModalVisible,
    setAttachPrescriptionModalVisible
  ] = useState(false);
  const { prescriptions } = useSelector(state => state.userDataReducer);
  const dispatch = useDispatch();

  const fetchPrescriptions = useCallback(async () => {
    const response = getPrescriptions();
    setLoadingData(true);
    await response
      .then(data => {
        dispatch(savePrescriptions(data));
        setLoadingData(false);
      })
      .catch(() => setLoadingData(false));
  }, [dispatch]);

  useEffect(() => {
    fetchPrescriptions();
  }, [fetchPrescriptions]);

  const toggleExpand = useCallback(() => {
    setExpandAll(!expandAll);
  }, [expandAll, setExpandAll]);

  const hasPrescriptions = prescriptions.toJS().length > 0;

  return (
    <>
      <AddPrescriptionModalForm
        visible={attachPrescriptionModalVisible}
        onClose={() => setAttachPrescriptionModalVisible(false)}
      />

      <div className="box-header-with-button">
        <div className="title">{t('Saved prescriptions')}</div>
        <Button type="link" onClick={toggleExpand}>
          {!expandAll && t('expand-lists')}
          {expandAll && t('hide-all')}
        </Button>
      </div>
      <div className="box-description">
        <div>{t('saved-prescriptions-description')}</div>
        <div onClick={() => setAttachPrescriptionModalVisible(true)}>
          <Trans i18nKey="dietitian-has-shared-prescription">
            <span
              className="dietitian-add-button"
            />
          </Trans>
        </div>
      </div>

      <Spin spinning={loadingData}>
        {hasPrescriptions ? (
          <CollapsePrescription
            listOfPrescriptions={prescriptions}
            expandAll={expandAll}
          />
        ) : loadingData ? null : (
          <InfoboxWithModal
            content={t('no-prescriptions-available')}
            modalContent={t('no-prescriptions-available-modal')}
          />
        )}
      </Spin>
    </>
  );
};

export default SavedPrescriptionsView;
