import { List } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { array, arrayOf, shape, string } from 'prop-types';

const MixerPreferences = ({ dataSource, preferencesData }) => {
  const { t } = useTranslation();

  return (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            avatar={<img alt="logo" src={item.icon} />}
            title={`${item.name}:`}
          />
          <h4 className="right-col">
            <strong>
              {preferencesData.includes(item.key) ? t('yes') : t('no')}
            </strong>
          </h4>
        </List.Item>
      )}
    />
  );
};

MixerPreferences.propTypes = {
  dataSource: arrayOf(
    shape({
      name: string,
      icon: string
    })
  ).isRequired,
  preferencesData: array.isRequired
};

export default MixerPreferences;
