import { Modal } from 'antd';
import '../components/Modal/small-confirm-modal.scss';

export const confirmUnsavedChanges = (pathname, history, t) => {
  Modal.confirm({
    title: t('unsaved-changes'),
    content: t('unsaved-changes-confirm-prompt-message'),
    icon: null,
    className: 'small-confirm-modal',
    okText: t('leave'),
    cancelText: t('cancel-btn'),
    cancelButtonProps: {
      type: 'primary',
      ghost: true
    },
    onOk() {
      history.block(null);
      history.push(pathname);
    },
    onCancel() {
      return undefined;
    }
  });
};
