import { axios } from './index';
import { getEnv } from '../../env';
import type {
  AddressType,
  CancellationFormValues,
  NextPaymentDateBody,
  UpdateCardBody
} from '../views/user/SettingsView/subviews/subscription/types';

const baseDjangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');
const userSubscriptionsBase = baseDjangoApi + '/user/subscriptions';

export const fetchUserSubscriptions = async () => {
  const response = await axios.get(`${userSubscriptionsBase}/`, {
    withCredentials: true
  });
  return response.data;
};

export const fetchSubscription = async (subscriptionId: number) => {
  const response = await axios.get(
    `${userSubscriptionsBase}/${subscriptionId}/`,
    {
      withCredentials: true
    }
  );
  return response.data;
};

export const updateSubscription = (
  id: number,
  body: UpdateCardBody | NextPaymentDateBody
) => axios.put(`${userSubscriptionsBase}/${id}`, body).then(res => res.data);

export const suspendSubscription = (
  id: number,
  body: { suspension_time: number }
) =>
  axios
    .post(`${userSubscriptionsBase}/${id}/suspension`, body)
    .then(res => res.data);

export const renewSubscription = (id: number) =>
  axios.post(`${userSubscriptionsBase}/${id}/renewal`).then(res => res.data);

export const cancelSubscription = (id: number) =>
  axios.delete(`${userSubscriptionsBase}/${id}`).then(res => res.data);

export const updateSubscriptionAddress = (
  id: number,
  billing: AddressType,
  shipping: AddressType | null
) =>
  axios
    .put(`${userSubscriptionsBase}/${id}`, { billing, shipping })
    .then(res => res.data);

export const reorderSubscription = (
  subscriptionId: number | undefined,
  orderId: string,
  token: string
) =>
  axios
    .post(`${userSubscriptionsBase}/${subscriptionId}/re-order/${orderId}`, {
      token
    })
    .then(res => res.data);

export const reorderOnetime = (
  orderId: string,
  payment_method: 'PayU' | 'PayPal'
) =>
  axios
    .post(`${baseDjangoApi}/re-order/${orderId}`, { payment_method })
    .then(res => res.data);

export const fetchCancellationReasons = () =>
  axios
    .get(`${baseDjangoApi}/auth/subscription/cancellation-survey`)
    .then(res => res.data);

export const saveCancellationReason = (data: CancellationFormValues[]) =>
  axios
    .post(
      `${baseDjangoApi}/auth/subscription/cancellation-survey/answers`,
      data
    )
    .then(res => res.data);

export const getCreditCardMask = (userId: number) =>
  axios.get(`${baseDjangoApi}/user/settings/${userId}/get_credit_card_mask/`);
