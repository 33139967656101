import React, { useState } from 'react';
import { useEffect } from 'react';
import { getVoucher } from '../../dataService';

const Voucher = props => {
  const [, setVoucherInfo] = useState(null);

  useEffect(() => {
    fetchData(props.match.params.voucherId);
  }, [props.match.params.voucherId]);

  const fetchData = async id => {
    const response = await getVoucher(id);
    console.log(response);
    setVoucherInfo(response);
    window.location = response.voucher_file;
  };

  return <div>Wczytywanie Twojego vouchera</div>;
};

export default Voucher;
