import { Map } from 'immutable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mixerDataNames, currencies } from './constants';

const { PLN, GBP, EUR } = currencies;

const useCurrency = () => {
  const exchangeRates = useSelector(
    state => state.panelMixerData[mixerDataNames.EXCHANGE_RATES]
  );
  const { t } = useTranslation();

  return Map({
    [PLN]: t('pln-symbol'),
    [EUR]: t('eur-symbol'),
    [GBP]: t('gbp-symbol')
  }).map((value, key) =>
    Map({
      code: key,
      text: value,
      exchangeRate: exchangeRates.get(key)
    })
  );
};

export const priceForCurrency = (price, currentCurrencyData) => {
  return price * currentCurrencyData.get('exchangeRate');
};

export default useCurrency;
