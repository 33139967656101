import React from 'react';
import { Button, Modal } from 'antd';
import { bool, element, func, oneOfType, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../assets/reactIcons';
import './action-modal.scss';

const ActionModal = ({
  okText,
  cancelText,
  onAcceptClick,
  onCancelClick,
  children,
  className,
  confirmLoading,
  ...rest
}) => {
  const { t } = useTranslation();

  const okTextFooter = okText === null ? t('ok') : okText;
  const cancelTextFooter = cancelText === null ? t('cancel-btn') : cancelText;

  return (
    <Modal
      maskStyle={{ backgroundColor: 'transparent' }}
      {...rest}
      maskClosable={false}
      className={`${className} action-modal`}
      wrapClassName="action-modal-wrap"
      onCancel={onCancelClick}
      centered={true}
      footer={
        <Footer
          cancelText={cancelTextFooter}
          okText={okTextFooter}
          onAcceptClick={onAcceptClick}
          onCancelClick={onCancelClick}
          confirmLoading={confirmLoading}
        />
      }
      closeIcon={<CloseIcon />}
    >
      {children}
    </Modal>
  );
};

ActionModal.propTypes = {
  onAcceptClick: func.isRequired,
  onCancelClick: func.isRequired,
  cancelText: oneOfType([string, element]),
  okText: oneOfType([string, element]),
  className: string,
  confirmLoading: bool
};

ActionModal.defaultProps = {
  cancelText: null,
  okText: null,
  className: '',
  confirmLoading: false
};

export default ActionModal;

const Footer = ({
  cancelText,
  okText,
  onAcceptClick,
  onCancelClick,
  confirmLoading
}) => {
  return (
    <div className="action-modal-footer">
      <Button type="secondary" ghost shape="round" onClick={onCancelClick}>
        {cancelText}
      </Button>
      <Button
        type="primary"
        shape="round"
        loading={confirmLoading}
        onClick={onAcceptClick}
      >
        {okText}
      </Button>
    </div>
  );
};

Footer.propTypes = {
  onAcceptClick: func.isRequired,
  onCancelClick: func.isRequired,
  cancelText: oneOfType([string, element]).isRequired,
  okText: oneOfType([string, element]).isRequired,
  confirmLoading: bool.isRequired
};
