import React from 'react';
import AriaModal from 'react-aria-modal';
import { bool, func, node, object, element } from 'prop-types';
import CloseButton from './CloseButton';
import '../../scss/modal.scss';

/**
 * @TODO could we delete this modal? Is it used anywhere?
 */

const underlayStyle = {
  paddingTop: '2em',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const propTypes = {
  isOpen: bool.isRequired,
  children: node.isRequired,
  onCloseClick: func,
  onExit: func,
  underlayStyle: object,
  header: element
};

const defaultProps = {
  onCloseClick: () => undefined,
  underlayStyle: underlayStyle
};

const getApplicationNode = () => document.getElementById('root');

const Modal = ({
  isOpen,
  children,
  onCloseClick,
  onExit,
  underlayStyle,
  header
}) =>
  isOpen ? (
    <div className="modal-container">
      <AriaModal
        titleText="demo one"
        onExit={onExit}
        initialFocus="#close-modal"
        getApplicationNode={getApplicationNode}
        underlayStyle={underlayStyle}
      >
        <>
          {header}
          <div className="modal-backdrop" />
          <CloseButton onClick={onCloseClick} />
          <div className="modal-content">{children}</div>
        </>
      </AriaModal>
    </div>
  ) : null;

Modal.propTypes = propTypes;

Modal.defaultProps = defaultProps;

export default Modal;
