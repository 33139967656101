import React from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { SendIcon } from '../../../../../assets/reactIcons';

const ResetPasswordRequestSuccess = () => {
  const [t] = useTranslation();
  const { search } = useLocation();
  return (
    <Row className="reset-password-request-success-container">
      <Col span={24}>
        <SendIcon />
        <h1 className="reset-password-request-success-container--title">
          {t('Success!')}
        </h1>
        <h4 className="reset-password-request-success-container--subtitle">
          {t(
            "If this is a registered account, we'll send a link to reset your password."
          )}
        </h4>
        <Link to={`/panel/auth/login${search}`}>
          <Button type="primary">{t('Log in')}</Button>
        </Link>
      </Col>
    </Row>
  );
};

export default ResetPasswordRequestSuccess;
