import AuthorizationForm from './AuthorizationForm';
import { FORM_NAME } from './AuthorizationForm';
import PasswordInput from './PasswordInput';
import EmailInput from './EmailInput/EmailInput';
import PasswordFormItemWithValidation, {
  RepeatPasswordFormItemWithValidation
} from './PasswordFormItemWithValidation';
import './authorization-form.scss';

export default AuthorizationForm;

export {
  PasswordInput,
  EmailInput,
  PasswordFormItemWithValidation,
  RepeatPasswordFormItemWithValidation,
  FORM_NAME
};
