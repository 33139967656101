import React, { useCallback, useEffect, useState } from 'react';
import { Map } from 'immutable';
import { AwaitData } from '../../../../components/AwaitData';
import { notification } from 'antd';
import { notificationTypes, tokenKeys } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import './settings-components.scss';
import { getBillingData, updateBillingData } from '../../../../api/legacy';
import { deserializeToken } from '../../../../utils/deserializers';
import { getLSItem } from '../../../../utils';

const { SUCCESS, ERROR } = notificationTypes;

export const SettingsWrapper = ({ component: Component, ...rest }) => {
  const [token] = useState(deserializeToken(getLSItem('flutter.access_token')));
  const [userSettings, setUserSettings] = useState(Map());
  const [dataLoading, setDataLoading] = useState(true);
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    const response = await getBillingData(token.get(tokenKeys.USER_ID));
    setUserSettings(Map(response));
    setDataLoading(false);
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = async values => {
    setDataLoading(true);

    const data = { ...userSettings.toJS(), ...values };
    const response = await updateBillingData(
      token.get(tokenKeys.USER_ID),
      data
    );

    if (response && response.status === 200) {
      setUserSettings(Map(data));
      notification[SUCCESS]({
        message: t('changes-saved-successfully'),
        className: 'notification-style-success'
      });
    } else
      notification[ERROR]({
        message: t('something-went-wrong'),
        className: 'notification-style-error'
      });

    setDataLoading(false);
  };

  if (userSettings.isEmpty()) return <AwaitData loading={true} />;

  return (
    <div className="settings-wrapper">
      <AwaitData loading={dataLoading}>
        <Component
          updateData={updateData}
          serverValues={userSettings}
          dataLoading={dataLoading}
          {...rest}
        />
      </AwaitData>
    </div>
  );
};
