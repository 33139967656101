import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import {
  PayPalButtons,
  PayPalScriptProvider,
  FUNDING,
  usePayPalScriptReducer
} from '@paypal/react-paypal-js';

import { Button, Col, notification, Radio, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import {
  SecureForm,
  SecureFormBody
} from '../../../components/SecureForm/SecureForm';

import {
  fetchUserSubscriptions,
  reorderOnetime,
  reorderSubscription,
} from '../../../api/subscription';
import { getOrder } from '../../../api/legacy';
import { ORDER_PROXY_STATUSES, WC_ORDER_STATUSES } from '../../../../utils';
import { pushToDataLayer } from '../../../utils/dataLayer';
import { payPalLocales } from '../../../utils/constants';
import { setUserSubscription } from '../../../actions';
import { UserSubscriptionType } from '../SettingsView/subviews/subscription/types';
import { getEnv } from '../../../../env';

import payuLogo from './../../../assets/img/offer_icons/payU.svg';
import paypalLogo from './../../../assets/img/offer_icons/paypal.svg';
import './renew-payment.scss';

type TRenewPaymentParams = {
  orderId: string;
  paymentType: 'subscription' | 'onetime';
};

const PayPalLoader: React.FC = () => (
  <ContentLoader
    speed={2}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="paypal-loader"
  >
    <rect x="0" y="0" rx="22" ry="22" width="100%" height="100%" />
  </ContentLoader>
);

type CustomPayPalButtonsType = {
  orderId: string;
};

const PayPalCustomButtons: React.FC<CustomPayPalButtonsType> = ({
  orderId
}) => {
  const [{ isPending }] = usePayPalScriptReducer();

  const paypalRedirectCallback = (_: any, actions: any) => {
    if (actions.order) {
      return actions.order.get().then((details: any) => {
        const { reference_id } = details.purchase_units[0];
        window.location.href =
          getEnv('REACT_APP_PUBLIC_APP') +
          `panel/thank-you-page/${reference_id}/PayPal$`;
      });
    }
  };

  const getErrorMessage = (error: any): string => {
    const data = error.message || error.response.data || {};

    if (data.detail) {
      return data.detail;
    }

    if (Array.isArray(data) && data[0]) {
      return data[0];
    }

    return data;
  };

  const errorNotification = (message: string) => {
    notification['error']({
      message,
      className: 'notification-style-error'
    });
  };

  if (isPending) return <PayPalLoader />;

  return (
    <PayPalButtons
      style={{
        layout: 'vertical',
        shape: 'pill',
        label: 'pay'
      }}
      createOrder={async () => {
        const res = await reorderOnetime(orderId, 'PayPal');

        if (!res.payment_order_id) {
          window.location.href =
            getEnv('REACT_APP_PUBLIC_APP') + `panel/thank-you-page/${orderId}/`;
        }

        return res.payment_order_id;
      }}
      onClick={(_, actions) => {
        pushToDataLayer('re_payment_intent');
        return actions.resolve();
      }}
      onError={(error?: any) => errorNotification(getErrorMessage(error))}
      onApprove={paypalRedirectCallback}
      onCancel={paypalRedirectCallback}
      fundingSource={FUNDING.PAYPAL}
    />
  );
};

// URL: /app/panel/reorder/:orderId/:paymentType
export const RenewPaymentView: React.FC<
  RouteComponentProps<TRenewPaymentParams>
> = ({ match, history }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { orderId, paymentType } = match.params;

  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<'PayU' | 'PayPal'>();

  const [order, setOrder] = useState<Record<string, string> | undefined>(
    undefined
  );

  const [subscription, setSubscription] = useState<
    UserSubscriptionType | undefined
  >(undefined);

  const isSubscription = paymentType === 'subscription';

  // No proper error handling for now, so let's just display error message, however appearance of such is highly unlikely.
  const errorHandler = (e: AxiosError): void => {
    notification.error({
      message: e.message,
      className: 'notification-style-error'
    });
  };

  // This should run only once, on mount, when no order object is present.
  useEffect(() => {
    if (!order) {
      switch (paymentType) {
        case 'subscription':
          fetchUserSubscriptions()
            .then(response => {
              setOrder(response[0].last_order);
              setSubscription(response[0]);
            })
            .catch(errorHandler);
          break;
        case 'onetime':
          getOrder(orderId).then(setOrder).catch(errorHandler);
          break;
        default:
          return;
      }
    }
  }, [order, paymentType]);

  // Prevent displaying anything when URL is corrupted or order failed to fetch/be found.
  if (!orderId || !order) return null;

  // Just in case - if someone enters corrupted URL but has some subscriptions on his account, do not allow to pay wrong amount for wrong order.
  if (Number(order.id) !== Number(orderId)) {
    history.push('/panel');
  }

  const goBack = () => {
    if (history.location.state?.back) {
      history.push(history.location.state.back.replace('/app', ''));
    } else {
      history.push('/panel/composition-prescription/');
    }
  };

  // This one is important - due to ticket description (SDEV-2921) payment renewal is possible only for those orders
  // that have failed and therefore are marked this way by PayU, so only status = "payment_failed" is handled by this component,
  // however we also handle "payment_accepted" status (as in situation that this payment has been processed already) by redirecting user to Thank You Page.
  switch (order.status) {
    case ORDER_PROXY_STATUSES.PAYMENT_ACCEPTED:
    case WC_ORDER_STATUSES.WC_COMPLETED:
      history.push('/panel/thank-you-page/' + orderId);
      break;
    case ORDER_PROXY_STATUSES.PAYMENT_FAILED:
    case WC_ORDER_STATUSES.WC_FAILED:
      break;
    default:
      goBack();
      break;
  }

  // Send request for payment renewal and redirect to Thank You Page if successful.
  const onSecureFormSubmitted = (body: SecureFormBody) => {
    setIsLoading(true);

    reorderSubscription(subscription?.id, orderId, body.subscription_token)
      .then(response => {
        dispatch(setUserSubscription(undefined));

        if (response.payment_url) {
          window.location.href = response.payment_url;
        } else {
          history.push('/panel/thank-you-page/' + orderId);
        }
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  };

  const onOnetimePaymentFormSubmitted = () => {
    setIsLoading(true);
    pushToDataLayer('re_payment_intent');
    reorderOnetime(orderId, 'PayU')
      .then(data => {
        if (data.payment_url) {
          window.location.assign(data.payment_url);
        } else {
          window.location.href =
            getEnv('REACT_APP_PUBLIC_APP') +
            `panel/thank-you-page/${orderId}/PayU`;
        }
      })
      .catch(errorHandler)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className={'go-back'}>
        <Button type="link" className="go-back-btn" onClick={goBack}>
          <LeftOutlined />
          {t('go-back-to-panel')}
        </Button>
      </div>
      <div className={'secure-form-wrapper'}>
        <h1>{t('pay-for-order-no', { orderId })}</h1>
        <p className="order-price">
          {t('total-to-pay')}{' '}
          <strong>
            {Number(
              order[isSubscription ? 'subscription_price' : 'price']
            ).toFixed(2)}{' '}
            {order?.currency}
          </strong>
        </p>
        {isSubscription ? (
          <SecureForm isLoading={isLoading} onSubmit={onSecureFormSubmitted} />
        ) : (
          <div>
            <h2>{t('payment-type')}</h2>
            <Row>
              <Col
                xs={24}
                className="payment-method__radio-button"
                onClick={() => setPaymentMethod('PayU')}
              >
                <Radio
                  className="custom-radio-button"
                  checked={paymentMethod === 'PayU'}
                >
                  <strong>PayU</strong>
                  <span>{t('payu-payment-methods')}</span>
                </Radio>
                <img src={payuLogo} alt="PayU Logo" />
              </Col>
              <Col
                xs={24}
                className="payment-method__radio-button payment-method__paypal"
                onClick={() => setPaymentMethod('PayPal')}
              >
                <Radio
                  className="custom-radio-button"
                  checked={paymentMethod === 'PayPal'}
                >
                  <strong>PayPal</strong>
                  <span>{t('paypal-payment-methods')}</span>
                </Radio>
                <img src={paypalLogo} alt="PayPal Logo" />
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ marginTop: '20px' }}>
                {paymentMethod === 'PayPal' ? (
                  <div className="paypal-buttons-container">
                    <PayPalScriptProvider
                      options={{
                        'client-id': getEnv(
                          'REACT_APP_PUBLIC_PAYPAL_CLIENT_ID'
                        ),
                        'merchant-id': getEnv(
                          'REACT_APP_PUBLIC_PAYPAL_MERCHANT_ID'
                        ),
                        currency: order.currency,
                        locale: payPalLocales[i18n.language],
                        vault: true // disables another "smart" payment methods, leaving only PayPal and Credit Card
                      }}
                    >
                      <PayPalCustomButtons orderId={orderId} />
                    </PayPalScriptProvider>
                  </div>
                ) : paymentMethod === 'PayU' ? (
                  <div className="payu-button-container">
                    <Button
                      loading={isLoading}
                      type="primary"
                      shape="round"
                      block
                      size="large"
                      onClick={onOnetimePaymentFormSubmitted}
                    >
                      {t('pay-for-order')}
                    </Button>
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};
