import React from 'react';
import { Button } from 'antd';
import { OrderedMap } from 'immutable';
import { useTranslation } from 'react-i18next';
import { settingsRouteKeys } from '../../../../utils/constants';
import { Link } from 'react-router-dom';
import { SettingsUserForm } from './SettingsUserForm';

const { PASSWORD_CHANGE } = settingsRouteKeys;

const fieldKeys = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  PHONE: 'phone',
  HIDDEN_PASSWORD: 'hiddenPassword'
};
const { FIRST_NAME, LAST_NAME, PHONE, HIDDEN_PASSWORD } = fieldKeys;

const ProfileSettings = ({ title, dataLoading, serverValues, updateData }) => {
  const profileItems = useProfileItems();

  return (
    <SettingsUserForm
      title={title}
      dataLoading={dataLoading}
      serverValues={serverValues}
      updateData={updateData}
      initialValues={serverValues.set(HIDDEN_PASSWORD, 'some dots').toJS()}
      itemsData={profileItems}
      fieldKeys={fieldKeys}
    />
  );
};

export default ProfileSettings;

const useProfileItems = () => {
  const { t } = useTranslation();

  return OrderedMap({
    [FIRST_NAME]: {
      required: true,
      label: t('first-name'),
      inputProps: {
        placeholder: t('first-name')
      }
    },
    [LAST_NAME]: {
      label: t('last-name'),
      inputProps: {
        placeholder: t('last-name')
      }
    },
    [PHONE]: {
      label: t('phone'),
      inputProps: {
        placeholder: t('phone')
      }
    },
    [HIDDEN_PASSWORD]: {
      label: t('password'),
      inputProps: {
        type: 'password',
        placeholder: t('password'),
        disabled: true,
        suffix: (
          <Link to={`${PASSWORD_CHANGE}/`}>
            <Button type="link">{t('change')}</Button>
          </Link>
        )
      }
    }
  });
};
