import { Button, Spin } from 'antd';
import React, { MouseEventHandler } from 'react';
import warningImage from '../../assets/img/warning.svg';

import './styles.scss';

type Props = {
  loading: boolean;
  message: string;
  primaryButtonText?: string;
  onPrimaryButtonClicked?: MouseEventHandler<HTMLElement>;
  secondaryButtonText?: string;
  onSecondaryButtonClicked?: MouseEventHandler<HTMLElement>;
};

const PersonaliseBanner: React.FC<Props> = ({
  loading,
  message,
  primaryButtonText,
  onPrimaryButtonClicked,
  secondaryButtonText,
  onSecondaryButtonClicked
}) => {
  return (
    <div className="personalise-banner-container">
      <img src={warningImage} alt="warning"/>
      <span className="personalise-banner-message">{message}</span>
      {loading ? (
        <Spin />
      ) : (
        <div className="personalise-banner-buttons">
          {primaryButtonText && onPrimaryButtonClicked && (
            <Button
              type="primary"
              shape="round"
              onClick={onPrimaryButtonClicked}
              className="personalise-banner-button personalise-banner-primary-button"
            >
              {primaryButtonText}&nbsp;→
            </Button>
          )}
          {secondaryButtonText && onSecondaryButtonClicked && (
            <Button
              type="primary"
              shape="round"
              onClick={onSecondaryButtonClicked}
              className="personalise-banner-button personalise-banner-secondary-button"
            >
              {secondaryButtonText}&nbsp;→
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonaliseBanner;
