import { constants, deserializers } from '../utils';
import { List, Map } from 'immutable';
import moment from 'moment';

const { actionNamesCalendar } = constants;

const {
  SET_DATES_TAKEN,
  SET_CALENDAR_INVENTORY,
  TOGGLE_DATE_TAKEN
} = actionNamesCalendar;

const { deserializeCalendarInventory } = deserializers;

const initialState = {
  inventory: Map({
    dose_count: 0,
    datetime_updated_at: moment()
  }),
  datesTaken: List()
};

const calendarDataReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case SET_CALENDAR_INVENTORY: {
      return {
        ...state,
        inventory: deserializeCalendarInventory(data)
      };
    }
    case SET_DATES_TAKEN: {
      return {
        ...state,
        datesTaken: data
      };
    }
    case TOGGLE_DATE_TAKEN: {
      const { date, hasTaken } = data;
      let datesTaken = state.datesTaken;
      if (hasTaken) datesTaken = datesTaken.push(date);
      else {
        const takenIndex = datesTaken.findIndex(d => d.isSame(date, 'day'));
        if (takenIndex !== -1) datesTaken = datesTaken.delete(takenIndex);
      }
      return {
        ...state,
        datesTaken,
        inventory: state.inventory.updateIn(['dose_count'], value =>
          hasTaken ? value - 1 : value + 1
        )
      };
    }
    default:
      return state;
  }
};

export default calendarDataReducer;
