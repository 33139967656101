import React from 'react';

const MoreIcon = () => (
  <i className="icon icon-more">
    <svg width="4px" height="16px" viewBox="0 0 4 16" version="1.1">
      <title>4A7AB1AB-9D35-4F00-9F8C-72286E800013@3x</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Panel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Składniki-main-dietetyk-1"
          transform="translate(-642.000000, -1006.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M644,1010.02339 C644.527132,1010.02339 644.992248,1009.82066 645.395349,1009.4152 C645.79845,1009.00975 646,1008.54191 646,1008.0117 C646,1007.48148 645.79845,1007.01365 645.395349,1006.60819 C644.992248,1006.20273 644.527132,1006 644,1006 C643.472868,1006 643.007752,1006.20273 642.604651,1006.60819 C642.20155,1007.01365 642,1007.48148 642,1008.0117 C642,1008.54191 642.20155,1009.00975 642.604651,1009.4152 C643.007752,1009.82066 643.472868,1010.02339 644,1010.02339 Z M644,1016.0117 C644.527132,1016.0117 644.992248,1015.80897 645.395349,1015.40351 C645.79845,1014.99805 646,1014.53021 646,1014 C646,1013.46979 645.79845,1013.00195 645.395349,1012.59649 C644.992248,1012.19103 644.527132,1011.9883 644,1011.9883 C643.472868,1011.9883 643.007752,1012.19103 642.604651,1012.59649 C642.20155,1013.00195 642,1013.46979 642,1014 C642,1014.53021 642.20155,1014.99805 642.604651,1015.40351 C643.007752,1015.80897 643.472868,1016.0117 644,1016.0117 Z M644,1022 C644.527132,1022 644.992248,1021.79727 645.395349,1021.39181 C645.79845,1020.98635 646,1020.51852 646,1019.9883 C646,1019.45809 645.79845,1018.99025 645.395349,1018.5848 C644.992248,1018.17934 644.527132,1017.97661 644,1017.97661 C643.472868,1017.97661 643.007752,1018.17934 642.604651,1018.5848 C642.20155,1018.99025 642,1019.45809 642,1019.9883 C642,1020.51852 642.20155,1020.98635 642.604651,1021.39181 C643.007752,1021.79727 643.472868,1022 644,1022 Z"
            id="-copy"
          ></path>
        </g>
      </g>
    </svg>
  </i>
);

export default MoreIcon;
