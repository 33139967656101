import { notification } from 'antd';
import { notificationTypes } from './constants';
import { List } from 'immutable';

const { ERROR } = notificationTypes;

export const showErrorNotification = (error, defaultMessage = 'Error') => {
  let message = defaultMessage;

  const data = error.response.data;
  if (data) {
    if (typeof data === 'string') message = data;
    else if (data.detail && typeof data.detail === 'string')
      message = data.detail;
    else if (Array.isArray(data) && data.length > 0)
      message = List(data).join('. ');
  }

  notification[ERROR]({ message, className: 'notification-style-error' });
};
