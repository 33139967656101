import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Radio, Switch } from 'antd';
import { useIconsHiddenExclusions } from '../../../../utils/iconImports';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import {
  postMixerSettings,
  retrieveMixerSettings,
  updateMixerSettings
} from '../../../../api/mixerSettings';
import { fromJS } from 'immutable';
import {
  DEFAULT_COUNTRY_NORMS_CODE,
  flavourKeys
} from '../../../../utils/constants';
import { AwaitData } from '../../../../components/AwaitData';
import { SettingsContentHeader } from '../components/SettingsContentHeader';
import './settings-subviews.scss';
import { getLSItem } from '../../../../utils';

const { SWEETNESS, ACIDITY } = flavourKeys;

export const ProductSettings = ({ title }) => {
  const { t } = useTranslation();
  const { exclusions: allExclusions, wasInitialized } = useSelector(
    state => state.panelMixerData
  );
  const hiddenExclusionsDefs = useIconsHiddenExclusions(allExclusions);
  const [userSettings, setUserSettings] = useState(null);
  const [noneFlavour, setNoneFlavour] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (wasInitialized && userSettings === null) {
      const { user_id } = jwt_decode(getLSItem('flutter.access_token'));
      fetchData(user_id);
    }
  });

  const fetchData = async id => {
    let response = await retrieveMixerSettings(id);
    if (response === null) {
      response = await postMixerSettings({
        country_norms_code: DEFAULT_COUNTRY_NORMS_CODE,
        flavour: {
          [flavourKeys.ACIDITY]: 0,
          [flavourKeys.SWEETNESS]: 0
        }
      });
    }
    handleResponse(response);
  };

  const handleResponse = response => {
    if (response && response.json_body) {
      setUserSettings(fromJS(response));
      setNoneFlavour(response.json_body.flavour[SWEETNESS] === 0);
    }
  };

  const updateData = async (key, value) => {
    setDataLoading(true);
    const jsonBody = {
      ...userSettings.get('json_body').toJS(),
      [key]: value.toJS()
    };
    const response = await updateMixerSettings(
      userSettings.get('user'),
      jsonBody
    );
    handleResponse(response);
    setDataLoading(false);
  };

  const onExclusionsChange = (checked, event) => {
    const exclusions = userSettings.get('json_body').get('exclusions');
    const newExclusions = checked
      ? exclusions.push(event.target.name)
      : exclusions.delete(exclusions.indexOf(event.target.name));
    updateData('exclusions', newExclusions);
  };

  const onFlavourChoiceChange = event => {
    const none = event.target.value;
    const flavour = userSettings.get('json_body').get('flavour');
    const newFlavour = none ? flavour.map(() => 0) : flavour.map(() => 2);
    setNoneFlavour(none);
    updateData('flavour', newFlavour);
  };

  const onFlavourChange = event => {
    const { name, value } = event.target;
    const flavour = userSettings.get('json_body').get('flavour');
    updateData('flavour', flavour.set(name, value));
  };

  if (!userSettings) return null;

  const data = userSettings.get('json_body');
  const { exclusions, flavour } = data.toJS();

  return (
    <div className="product-settings">
      <AwaitData loading={dataLoading}>
        <SettingsContentHeader title={title} />

        <div className="product-settings-exclusions">
          <ul>
            {hiddenExclusionsDefs.map(item => (
              <li key={item.key}>
                <Avatar src={item.icon} />
                <div className="product-exclusions-text">{item.name}</div>
                <Switch
                  name={item.key}
                  checked={exclusions.includes(item.key)}
                  onChange={onExclusionsChange}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="product-settings-flavour">
          <div className="product-flavour-header">{t('choice-of-flavour')}</div>
          <Radio.Group onChange={onFlavourChoiceChange} value={noneFlavour}>
            <Radio value={true}>
              <div className="product-flavour-text">{t('none')}</div>
            </Radio>
            <Radio value={false}>
              <div className="product-flavour-text">{t('orange')}</div>
            </Radio>
          </Radio.Group>

          {!noneFlavour && (
            <>
              <div className="product-flavour-header">
                {t('choice-of-sweetness')}
              </div>
              <Radio.Group
                value={flavour[SWEETNESS]}
                name={SWEETNESS}
                onChange={onFlavourChange}
              >
                <Radio value={1}>
                  <div className="product-flavour-text">
                    {t('not-very-sweet')}
                  </div>
                </Radio>
                <Radio value={2}>
                  <div className="product-flavour-text">
                    {t('medium-sweet')}
                  </div>
                </Radio>
                <Radio value={3}>
                  <div className="product-flavour-text">{t('very-sweet')}</div>
                </Radio>
              </Radio.Group>

              <div className="product-flavour-header">
                {t('choice-of-acidity')}
              </div>
              <Radio.Group
                value={flavour[ACIDITY]}
                name={ACIDITY}
                onChange={onFlavourChange}
              >
                <Radio value={1}>
                  <div className="product-flavour-text">
                    {t('not-very-sour')}
                  </div>
                </Radio>
                <Radio value={2}>
                  <div className="product-flavour-text">{t('medium-sour')}</div>
                </Radio>
                <Radio value={3}>
                  <div className="product-flavour-text">{t('very-sour')}</div>
                </Radio>
              </Radio.Group>
            </>
          )}
        </div>
      </AwaitData>
    </div>
  );
};
