import React, { useCallback } from 'react';
import { InfoBoxWithList } from './index';
import mixerIcon from '../../../assets/img/iconmostr.svg';
import { useTranslation } from 'react-i18next';
import { getEnv } from '../../../env';
import { Button, Row } from 'antd';
import { Link } from 'react-router-dom';

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');
const surveyBaseUrl = getEnv('REACT_APP_PUBLIC_SURVEY_ID');

export const InfoBoxMixer = () => {
  const { t } = useTranslation();

  const goToMixer = useCallback(
    () => (window.location.href = mixerBaseUrl),
    []
  );

  const description = (
    <>
      {t('mixer-description')}
      <Row type="flex" justify="end" className="buttons-row">
        <Button type="primary" ghost>
          <Link to={`/survey/id/${surveyBaseUrl}`}>{t('interview')+' →'}</Link>
        </Button>
        <Button type="primary" ghost onClick={goToMixer}>
          Sundose Mixer →
        </Button>
      </Row>
    </>
  );

  const listData = [{ icon: mixerIcon, title: 'Sundose Mixer', description }];

  return <InfoBoxWithList listData={listData} className="info-box-mixer" />;
};
