import React, { useCallback, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, notification } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { ActionModal } from '../ActionModal';
import { func, instanceOf } from 'prop-types';
import { Map } from 'immutable';
import { notificationTypes } from '../../utils/constants';
import { patchPrescription } from '../../api/mixer';
import { useDispatch } from 'react-redux';
import { updatePrescriptionName } from './actions';

const { SUCCESS, ERROR } = notificationTypes;

const NAME = 'name';

const ChangePrescriptionName = ({
  prescription,
  form,
  onCloseModal,
  ...props
}) => {
  const { getFieldDecorator } = form;
  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(false);
  const dispatch = useDispatch();

  const id = prescription.get('id');
  const name = prescription.get('name');

  const label = (
    <Trans i18nKey="change-name-prescription">
      <strong>{{ name }}</strong> {{ id }}
    </Trans>
  );

  const onAcceptClick = useCallback(async () => {
    setDataLoading(true);
    const response = await patchPrescription(id, form.getFieldsValue());

    if (response && response.errorResponse)
      notification[ERROR]({
        message: t('something-went-wrong'),
        className: 'notification-style-error'
      });
    else {
      dispatch(updatePrescriptionName(id, response.name));
      notification[SUCCESS]({
        message: t('name-successfully-changed'),
        className: 'notification-style-success'
      });
      onCloseModal();
    }

    setDataLoading(false);
  }, [dispatch, id, form, t, onCloseModal]);

  return (
    <ActionModal
      onAcceptClick={onAcceptClick}
      onCancelClick={onCloseModal}
      okText={t('save')}
      {...props}
      title={t('change-name')}
      confirmLoading={dataLoading}
      className="change-name"
    >
      <Form layout="vertical">
        <Form.Item label={label}>
          {getFieldDecorator(NAME, { initialValue: name })(<Input />)}
        </Form.Item>
      </Form>
    </ActionModal>
  );
};

ChangePrescriptionName.propTypes = {
  prescription: instanceOf(Map).isRequired,
  onCloseModal: func.isRequired
};

export default Form.create()(ChangePrescriptionName);
