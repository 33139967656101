import React from 'react';
import { func } from 'prop-types';
import CustomIcon from '../CustomIcon';

const propTypes = {
  onClick: func.isRequired
};

const defaultProps = {};

const CloseButton = ({ onClick }) => (
  <button
    id="close-modal"
    aria-label="Close"
    className="modal-close-button"
    onClick={onClick}
  >
    <CustomIcon name="close" onClick={onClick} />
  </button>
);

CloseButton.propTypes = propTypes;

CloseButton.defaultProps = defaultProps;

export default CloseButton;
