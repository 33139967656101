import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import './settings-view.scss';
import { SettingsRouter } from './SettingsRouter';
import { Col } from 'antd';

export const SettingsView = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('settings')} - Sundose</title>
      </Helmet>
      <div className="settings-view">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 22, offset: 1 }}
          xl={{ span: 24, offset: 0 }}
        >
          <SettingsRouter />
        </Col>
      </div>

      <div style={{ width: '100%', fontSize: 6, color: 'lightgrey' }}>
        {process.env.REACT_APP_BUILD_META}
      </div>
    </>
  );
};
