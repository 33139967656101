import React from 'react';
import { func, string } from 'prop-types';

const AuthorizationViewBackgroundDesktop = ({ onLogoClick, className }) => {
  return (
    <></>
  );
};

AuthorizationViewBackgroundDesktop.propTypes = {
  onLogoClick: func.isRequired,
  className: string
};

AuthorizationViewBackgroundDesktop.defaultProps = {
  className: ''
};

export default AuthorizationViewBackgroundDesktop;
