import React, { useCallback, useEffect, useState } from 'react';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import Login from './Login';
import { CheckIcon } from '../../assets/reactIcons';
import { signOut } from './actions';
import { loginUser, replaceURLForFlutterApp } from './utlis';
import { useRedirect } from '../../utils/redirect';
import ResetPasswordRouter from './ResetPassword';
import ChangePassword from './ChangePassword';
import {
  buyPrescriptionAsGift,
  verifyMail as verifyMailService
} from '../../api/auth';
import { HTTP_STATUS_CODES, SBL_LOCALSTORAGE_KEY } from '../../utils/constants';
import { getLSItem, setLSItem, useQuery, validateTokenTime } from '../../utils';
import UserAuthorizationRoute from './UserAuthorizationRoute';
import { QuickRegister } from './QuickRegister/QuickRegister';
import { TemporaryUserRegister } from './TemporaryUserRegister';
import { BuyPrescriptionAsGift } from './BuyPrescriptionAsGift';
import RegisterWithRecaptcha from './Register';
import { getEnv } from '../../../env';

const UserAuthorizationRouter = () => {
  const [t] = useTranslation();
  const { url } = useRouteMatch();
  const [redirect, redirectTo] = useRedirect();
  const [validatingUserLocally, setValidatingUserLocally] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, token, sbl } = useQuery();

  const redirectUser = redirectToFlutter => {
    if (redirectToFlutter && redirect.target) {
      window.location.href = replaceURLForFlutterApp(redirect.target);
    } else {
      let path;
      if (!redirect.shouldRedirect) path = '/panel/dashboard';
      redirectTo(path);
    }
  };

  const validateUserLocally = async () => {
    try {
      const auth = JSON.parse(getLSItem('flutter.sundose_auth'));
      const token = auth?.token;

      if (auth.temporary) {
        setValidatingUserLocally(false);
      } else {
        validateTokenTime();
        const settings = await loginUser(token, auth, dispatch);

        if (location.pathname.includes('temporary-register')) {
          const surveyFromUrl = location.search.includes('surveyId')
            ? location.search.replace('?surveyId=', '')
            : null;
          const surveyId =
            surveyFromUrl || getEnv('REACT_APP_PUBLIC_SURVEY_ID');
          redirectTo(`/survey/id/${surveyId}`);
        } else if (location.pathname.includes('buy-as-gift')) {
          const pathname = location.pathname.split('/');
          const prescriptionId = pathname[pathname.length - 1];

          buyPrescriptionAsGift(prescriptionId)
            .then(r => {
              window.location.href = `/mixer-app/${r.prescription_id}/gift-checkout`;
            })
            .catch(redirectUser);
        } else {
          redirectUser(settings.flutter_web_exp);
        }
      }
    } catch (e) {
      dispatch(signOut());
      setValidatingUserLocally(false);
    }
  };

  const validateMail = useCallback(async () => {
    await verifyMailService(user, token)
      .then(response => {
        if (response) {
          if (response.status === HTTP_STATUS_CODES.NO_CONTENT) {
            message.success({
              className: 'n-message n-message-success',
              content: t('Email verified'),
              icon: <CheckIcon />
            });
          }
        }
      })
      .catch(() => undefined);
  }, [t, token, user]);

  useEffect(() => {
    if (typeof sbl !== 'undefined') {
      setLSItem(SBL_LOCALSTORAGE_KEY, sbl);
    }

    if (matchPath(`${url}/mail-verification`, location.pathname)) {
      validateMail();
    }

    validateUserLocally();

    // eslint-disable-next-line
  }, [validateUserLocally]);

  if (validatingUserLocally) return null;

  return (
    <Switch>
      <UserAuthorizationRoute path={`${url}/login`} exact component={Login} />
      <UserAuthorizationRoute
        path={`${url}/register`}
        exact
        component={RegisterWithRecaptcha}
      />
      <UserAuthorizationRoute
        path={`${url}/reset-password`}
        component={ResetPasswordRouter}
      />
      <UserAuthorizationRoute
        path={`${url}/change-password`}
        exact
        component={ChangePassword}
      />
      <Route
        path={`${url}/temporary-register`}
        exact
        component={TemporaryUserRegister}
      />
      <Route
        path={`${url}/buy-as-gift/:prescriptionId`}
        exact
        component={BuyPrescriptionAsGift}
      />
      <Route path={`${url}/quick-register`} exact component={QuickRegister} />
      <Route
        path={[`${url}/quick-register/v2`, `${url}/quick-register/v3`]}
        exact
      >
        <Redirect to={`${url}/quick-register`} />
      </Route>
      <Route>
        <Redirect to={`${url}/login`} />
      </Route>
    </Switch>
  );
};

export default UserAuthorizationRouter;
