import React from 'react';
import { Avatar, Table } from 'antd';
import { FormatData } from './FormatData';
import './substances-table.scss';

const SubstancesTable = ({ title: tableTitle, titleIcon, data }) => {
  const columns = [
    {
      title: tableTitle ? tableTitle.toUpperCase() : '',
      dataIndex: 'name',
      width: '50%',
      className: 'column-name',
      colSpan: 2,
    },
    {
      title: '',
      dataIndex: 'amount',
      width: '50%',
      className: 'column-amount',
      colSpan: 0,
    }
  ];

  const formattedData = FormatData(data);

  return (
    <Table
      className="substances-table"
      columns={columns}
      dataSource={formattedData}
      pagination={false}
    />
  );
};

export default SubstancesTable;
