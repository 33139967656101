import { notification } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  allowEcommMerging,
  resendEmailVerification
} from '../../api/userSettings';
import { setShouldFetchOrders } from '../../views/user/OrdersView/actions';
import PersonaliseBanner from './PersonaliseBanner';

const PersonaliseBannerProvider: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [emailConfirmationBanner, setEmailConfirmationBanner] = useState(true);
  const [changesConfirmationBanner, setChangesConfirmationBanner] = useState(
    true
  );
  const [ecommOrdersMerge, setEcommOrdersMerge] = useState(true);
  const { settings } = useSelector((state: any) => state.userDataReducer);
  const { has_ecomm_order, is_email_confirmed, allowed_ecomm_merging } =
    settings || {};

  const resendEmail = () => {
    setLoading(true);
    resendEmailVerification()
      .then(() => {
        notification.success({
          message: t('verification-email-resent'),
          className: 'notification-style-success'
        });
        setEmailConfirmationBanner(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const mergeEcommOrders = () => {
    setLoading(true);

    allowEcommMerging()
      .then(() => {
        notification.success({
          message: t('ecomm-orders-merged'),
          className: 'notification-style-success'
        });
        setEcommOrdersMerge(false);
        dispatch(setShouldFetchOrders(true));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!settings) return null;

  if (!is_email_confirmed && emailConfirmationBanner) {
    return (
      <PersonaliseBanner
        loading={loading}
        message={t('email-not-verified')}
        onSecondaryButtonClicked={resendEmail}
        secondaryButtonText={t('resend-verification-email')}
      />
    );
  }

  if (
    is_email_confirmed &&
    has_ecomm_order &&
    !allowed_ecomm_merging &&
    ecommOrdersMerge
  ) {
    return (
      <PersonaliseBanner
        loading={loading}
        message={t('have-ecomm-orders')}
        secondaryButtonText={t('allow-ecomm-merge')}
        onSecondaryButtonClicked={mergeEcommOrders}
      />
    );
  }

  if (changesConfirmationBanner) {
    return (
      <PersonaliseBanner
        loading={loading}
        message={t('orders-and-prescriptions-might-have-been-deleted')}
        onSecondaryButtonClicked={() => setChangesConfirmationBanner(false)}
        secondaryButtonText={'OK'}
      />
    );
  }

  return null;
};

export default PersonaliseBannerProvider;
