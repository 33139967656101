import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import SubscriptionModal from './SubscriptionModal';
import minerals from '../../../../../../../assets/img/substance_types/minerals.svg';
import InfoIcon from '../../../../../../assets/img/info-icon.svg';

type OrderCardProps = {
  header: string;
  prescriptionId: number;
  prescriptionCountryNormsCode: string;
  price: number;
  currencyValue: string;
  discount: number;
  activeCoupon?: string;
};

const OrderCard: React.FC<OrderCardProps> = ({
  header,
  children,
  prescriptionId,
  prescriptionCountryNormsCode,
  price,
  currencyValue,
  discount,
  activeCoupon
}) => {
  const { t } = useTranslation();
  const [infoModal, setInfoModal] = useState(false);
  return (
    <section>
      <h2>{header}</h2>
      <div>
        {children}
        <p className="subscription-discount">
          {t('discount')} {Number(discount * 100).toFixed()}%
          <img
            src={InfoIcon}
            alt="info icon"
            onClick={() => setInfoModal(true)}
          />
        </p>
        <div className="subscription-card-prescription">
          <p>
            <img src={minerals} />{' '}
            <Trans i18nKey="order-subtitle">{{ prescriptionId }}</Trans>
          </p>
          <p>
            <Trans i18nKey="order-price-panel">
              <strong>
                {{ price: price.toFixed(2) }} {{ currencyValue }}
              </strong>
            </Trans>
          </p>

          {activeCoupon ? (
            <p className="active-coupon">
              {t('subscription-active-coupon')}
              <span> &quot;{activeCoupon}&quot;</span>
            </p>
          ) : null}

          <Link
            to={`/panel/composition-prescription/${prescriptionId}/details`}
          >
            <Button type="link">{t('see-prescription-details')}</Button>
          </Link>
        </div>
      </div>
      <SubscriptionModal
        isOpen={infoModal}
        onCancel={() => setInfoModal(false)}
        country={prescriptionCountryNormsCode}
      />
    </section>
  );
};

export default OrderCard;
