import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OrderedMap } from 'immutable';
import { GlobalOutlined } from '@ant-design/icons';
import ControlledPicker from '../ControlledPicker';
import i18n from '../../../../i18n';
import { patchUserSettings } from '../../../api/userSettings';
import { tokenKeys } from '../../../utils/constants';
import { LANGUAGES } from '../../../../utils';
import pl_flag from '../../../../assets/img/flags/flag_pl.svg';
import en_flag from '../../../../assets/img/flags/flag_en.svg';
import de_flag from '../../../../assets/img/flags/flag_de.svg';
import it_flag from '../../../../assets/img/flags/flag_it.svg';
import './language-picker.scss';
import { setLSItem } from '../../../utils';

const { PL, EN, DE, IT } = LANGUAGES;

const useLanguageSymbols = () =>
  OrderedMap({
    [PL]: {
      flag: pl_flag,
      short: 'POL',
      long: 'Polski'
    },
    [EN]: {
      flag: en_flag,
      short: 'ENG',
      long: 'English'
    },
    [DE]: {
      flag: de_flag,
      short: 'GER',
      long: 'Deutsch'
    },
    [IT]: {
      flag: it_flag,
      short: 'ITA',
      long: 'Italiano'
    }
  });

const LanguagePicker = ({ className }) => {
  const languageSymbols = useLanguageSymbols();
  const { tokenData } = useSelector(state => state.userDataReducer);

  const currentLanguage = i18n.language;

  const changeLanguage = useCallback(
    lang => {
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang);
        setLSItem('language', lang);
        patchUserSettings(tokenData.get(tokenKeys.USER_ID), {
          ui_language: lang
        });
      }
    },
    [tokenData]
  );

  const options = languageSymbols.map((val, key) => ({
    prefix: <img key={key} src={val.flag} alt={`flag_${key}`} />,
    text: `${val.long} (${val.short})`,
    title: (
      <>
        <GlobalOutlined />
        <p>{val.short}</p>
      </>
    )
  }));

  return (
    <ControlledPicker
      onOptionChange={changeLanguage}
      options={options}
      value={currentLanguage}
      className={`language-picker ${className}`}
    />
  );
};

LanguagePicker.defaultProps = {
  className: ''
};

export default LanguagePicker;
