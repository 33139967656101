import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

import UserPanelRoute from './UserPanelRoute';

import NoMatch from '../UtilityViews/NoMatch';

import {
  CompositionView,
  Dashboard,
  OfferView,
  PrescriptionDetailsView,
  SettingsView,
  Voucher
} from './views/user';

import { routeKeys, routeMainPaths } from './utils/constants';
import UserAuthorizationRouter from './views/auth';
import { SecureForm } from './components/SecureForm/SecureForm';
import { RenewPaymentView } from './views/user/OrdersView/RenewPaymentView';
import NewThankYouPage from './views/user/ThankYouPage/ThankYouPage';
import { ThankYouPageSubscription } from './views/user/ThankYouPage';

const { THANK_YOU_PAGE } = routeKeys;
const {
  DASHBOARD,
  SETTINGS,
  OFFER,
  COMPOSITION_PRESCRIPTION,
  REORDER
} = routeMainPaths;

const PanelRouter = () => {
  let { url } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${url}/auth/`} component={UserAuthorizationRouter} />

        <UserPanelRoute path={`${url}/`} exact component={Dashboard} />
        <UserPanelRoute path={DASHBOARD} exact component={Dashboard} />
        <UserPanelRoute path={OFFER} exact component={OfferView} />
        <UserPanelRoute path={SETTINGS} component={SettingsView} />
        <UserPanelRoute
          path={COMPOSITION_PRESCRIPTION}
          exact
          component={CompositionView}
        />

        <UserPanelRoute
          panelStyle={false}
          path={`${COMPOSITION_PRESCRIPTION}:prescriptionId/details`}
          exact
          component={PrescriptionDetailsView}
        />

        <UserPanelRoute
          path={`${url}/voucher/:voucherId`}
          exact
          component={Voucher}
        />

        <UserPanelRoute
          panelStyle={false}
          path={`${REORDER}:orderId/:paymentType`}
          exact
          component={RenewPaymentView}
        />

        <Route
          path={`${url}/${THANK_YOU_PAGE}/subscription`}
          component={ThankYouPageSubscription}
          exact
        />

        <Route
          path={[
            `${url}/${THANK_YOU_PAGE}/:id`,
            `${url}/${THANK_YOU_PAGE}/:id/:paymentMethod`,
            `${url}/${THANK_YOU_PAGE}/:id/:paymentMethod/:coupon`
          ]}
          component={NewThankYouPage}
          exact
        />

        {/* <Route
          path={[
            `${url}/${THANK_YOU_PAGE}/:id`,
            `${url}/${THANK_YOU_PAGE}/:id/:paymentMethod`,
            `${url}/${THANK_YOU_PAGE}/:id/:paymentMethod/:coupon`
          ]}
          component={OldThankYouPage}
          exact
        /> */}

        <Route path={`${url}/secure-form/:view/:language/:routeUserId?`} exact>
          <SecureForm standalone />
        </Route>

        <Route component={NoMatch} />
      </Switch>
    </>
  );
};
export default PanelRouter;
