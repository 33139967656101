import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { bool, instanceOf } from 'prop-types';
import { Button, Collapse } from 'antd';
import { List, Map } from 'immutable';
import moment from 'moment';

import CollapseOrderHeader from './CollapseOrderHeader';
import { useOrderStatus } from '../../utils';
import { getEnv } from '../../../env';

import '../CollapsePrescription/collapse-prescription.scss';
import '../Collapse/collapse.scss';
import './collapse-orders.scss';
import '../../scss/order-status.scss';
import { LANGUAGES, ORDER_PROXY_STATUSES } from '../../../utils';
import { RenewPaymentButton } from '../RenewPaymentButton';
import {
  getItemsFromPrescription,
  pushToDataLayer
} from '../../utils/dataLayer';
import { getPrescription } from '../../api/mixer';
import { useSelector } from 'react-redux';

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');

const { Panel } = Collapse;

const CollapseOrder = ({ listOfOrders, expandAll, expandFirstOnInit }) => {
  const [activeKeys, setActiveKeys] = useState([]);

  const onActiveChange = useCallback(
    listOfKeys => {
      setActiveKeys(listOfKeys);
    },
    [setActiveKeys]
  );

  useEffect(() => {
    let expanded = [];
    if (expandAll) {
      expanded = listOfOrders
        .map(prescription => prescription.get('id'))
        .toArray();
    }

    setActiveKeys(expanded);
  }, [expandAll, listOfOrders]);

  useEffect(() => {
    let expanded = activeKeys;
    if (expandFirstOnInit) {
      let firstOrder = listOfOrders.first(null);
      if (firstOrder !== null) expanded.push(firstOrder.get('id'));
    }
    setActiveKeys(expanded);
  }, [activeKeys, expandFirstOnInit, listOfOrders]);

  return (
    <div>
      <Collapse
        className="collapse-order sundose-collapse"
        activeKey={activeKeys}
        bordered={false}
        onChange={onActiveChange}
      >
        {listOfOrders.map((order, idx) => (
          <Panel
            showArrow={false}
            header={<CollapseOrderHeader order={order} />}
            key={order.get('id', idx)}
          >
            <CollapseOrderBody order={order} />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

CollapseOrder.propTypes = {
  listOfOrders: instanceOf(List).isRequired,
  expandAll: bool,
  expandFirstOnInit: bool
};

CollapseOrder.defaultProps = {
  expandAll: false,
  expandFirstOnInit: false
};

export default CollapseOrder;

const CollapseOrderBody = ({ order }) => {
  const {
    settings: { subscriber: isSubscriber },
    subscription: {
      last_order: lastSubscriptionOrder,
      next_order: nextSubscriptionOrder
    } = {}
  } = useSelector(state => state.userDataReducer);

  const { baseline_prices, exchange_rates } = useSelector(
    state => state.panelMixerData
  );

  const { paid_transactions_count } = useSelector(
    state => state.userDataReducer.settings
  );

  const triggerDataLayerPush = async prescriptionId => {
    const prescription = await getPrescription(prescriptionId);

    pushToDataLayer('add_to_cart', {
      button_label: t('order_again', { lng: LANGUAGES.EN }),
      paid_transactions_count,
      process_type: paid_transactions_count > 0 ? 'retention' : 'acquisition',
      formulation_category: prescription.form,
      items: getItemsFromPrescription(
        prescription,
        baseline_prices,
        exchange_rates
      )
    });

    const { campaign_name, campaign_valid_to, is_campaign } = prescription;
    const campaignValid = moment().isBefore(moment(campaign_valid_to));

    const checkoutToRedirectTo =
      is_campaign && campaignValid && campaign_name === 'CHRISTMAS'
        ? 'gift-checkout'
        : 'checkout';

    window.location.href = `${mixerBaseUrl}/${prescriptionId}/${checkoutToRedirectTo}`;
  };

  const { t } = useTranslation();
  const {
    id: orderNumber,
    created_at,
    user_name: author,
    currency,
    shipping_type: shippingType,
    status,
    price: basePrice,
    subscription_id: isSubscription,
    is_ecomm: isEcommOrder,
    order_items: items
  } = order.toJS();

  const price = Number(basePrice).toFixed(2);
  const currencyValue = currency;

  // L = local numeric date like: 28.02.2021 or 02/28/2021
  const dateOrdered = moment(created_at).format('L');
  const orderStatuses = useOrderStatus(shippingType === 'InPost');

  const orderStatus = orderStatuses.get(status, null);
  const prescriptionId = items[0].prescription_id;

  const isOrderFailed = status === ORDER_PROXY_STATUSES.PAYMENT_FAILED;

  return (
    <div className="sundose-collapse-body order-collapse-body">
      <div className="order-price order-descriptions">
        <Trans i18nKey="order-price-panel">
          <span className="font-weight-black">
            {{ price }} {{ currencyValue }}
          </span>
        </Trans>
      </div>
      <div className="date-ordered order-descriptions">
        <Trans i18nKey="date-ordered-panel">
          <span className="font-weight-black">{{ dateOrdered }}</span>
        </Trans>
      </div>
      <div className="order-number order-descriptions">
        <Trans i18nKey="order-number-panel">
          <span className="font-weight-black">{{ orderNumber }}</span>
        </Trans>
      </div>
      <div className="order-author order-descriptions">
        <Trans i18nKey="order-author-panel">
          <span className="font-weight-black">{{ author }}</span>
        </Trans>
      </div>
      <div className="order-author order-descriptions">
        {t('payment-method')}
        <span className="font-weight-black">
          {isSubscription ? t('subscription') : t('one-time-payment')}
        </span>
      </div>
      {orderStatus !== null && (
        <div className="order-delivery-status">
          <div className={orderStatus.className + ' status-badge'}>
            {orderStatus.description}
          </div>
        </div>
      )}

      {isEcommOrder ? null : (
        <div className="order-button-container">
          {isOrderFailed ? (
            <RenewPaymentButton
              noButton
              className={'ant-btn ant-btn-primary'}
              order={order.toJS()}
            />
          ) : (
            <Button
              type="primary"
              ghost
              style={{fontWeight: 'normal'}}
              className={'rounded-button'}
              href={'#'}
              onClick={() => triggerDataLayerPush(prescriptionId)}
            >
              {t('order-again')} →
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

CollapseOrderBody.propTypes = {
  order: instanceOf(Map).isRequired
};
