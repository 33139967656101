import React, { useCallback, useState } from 'react';
import moment from 'moment';
import Legend from './Legend';
import Modal from '../Modal';
import ConfirmDateTaken from './ConfirmDateTaken';
import { func, instanceOf, object } from 'prop-types';

import { getDateStatusClasses } from '../../utils/calendar';
import MonthCalendar from './MonthCalendar/MonthCalendar';
import { List, Map } from 'immutable';
import './calendar.scss';

const propTypes = {
  sundoseData: object.isRequired,
  datesTaken: instanceOf(List).isRequired,
  inventory: instanceOf(Map).isRequired,
  toggleTakingSundose: func.isRequired
};

const defaultProps = {};

const Calendar = props => {
  const { datesTaken, toggleTakingSundose, hasSundose } = props;
  const [clickedDate, setClickedDate] = useState(null);
  const onClickDay = val => setClickedDate(val);
  const onModalClose = () => setClickedDate(null);

  const isSundoseTaken = () =>
    clickedDate !== null &&
    datesTaken.findIndex(d => d.isSame(clickedDate, 'day')) !== -1;

  const onConfirmDateTaken = (date, hasTaken) => {
    toggleTakingSundose(date, hasTaken);
    onModalClose();
  };

  const today = moment();

  const getTileClassname = date => {
    const classNames = [];
    const prevToday = today.clone().add(-1, 'day');
    if (date.isSame(prevToday, 'day')) classNames.push('day-before-today');
    const statusesFromSelectedDate = getDateStatusClasses(date, datesTaken);

    return classNames.concat(statusesFromSelectedDate).join(' ');
  };

  const getTileDisabled = date => {
    const today = moment();
    return date.isAfter(today, 'day');
  };

  const isModalOpen = !!clickedDate;

  return (
    <div>
      <MonthCalendar
        footer={<Legend />}
        onDateClick={onClickDay}
        currentDate={today}
        additionalTileClassName={getTileClassname}
        disableTile={getTileDisabled}
      />
      <Modal isOpen={isModalOpen} onCloseClick={onModalClose}>
        {isModalOpen && (
          <ConfirmDateTaken
            date={clickedDate}
            onConfirm={onConfirmDateTaken}
            isTaken={isSundoseTaken()}
          />
        )}
      </Modal>
    </div>
  );
};

Calendar.propTypes = propTypes;

Calendar.defaultProps = defaultProps;

export default Calendar;
