import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form, Input } from 'antd';

import './email-input.scss';

const EmailInput = ({ inputName, validateStatus }) => {
  const [t] = useTranslation();

  return (
    <Form.Item noStyle shouldUpdate={true}>
      {form => {
        const hasErrors = form.getFieldError(inputName).length > 0;
        const validateTriggers = hasErrors
          ? ['onBlur', 'onChange']
          : ['onBlur'];

        return (
          <Form.Item
            name={inputName}
            label={t('Email')}
            rules={[
              { required: true, message: t('Email is required') },
              { type: 'email', message: t('Wrong format') }
            ]}
            normalize={value => value.trim()}
            validateTrigger={validateTriggers}
            validateStatus={validateStatus}
          >
            <Input tabIndex={1} name="email" className="email-input" />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

EmailInput.propTypes = {
  inputName: string.isRequired,
  validateStatus: string
};

EmailInput.defaultProps = {
  inputName: 'email'
};

export default EmailInput;
