import React from 'react';

const EditIcon = () => (
  <i className="icon icon-edit">
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
      <title>Edytuj@3x@3x</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Panel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Składniki-main-dietetyk"
          transform="translate(-523.000000, -1260.000000)"
          fill="currentColor"
        >
          <g id="Group-11" transform="translate(509.000000, 1183.000000)">
            <g id="Group-2">
              <g id="Edytuj@3x" transform="translate(14.000000, 77.000000)">
                <path
                  d="M11.05961,0.467 C11.3574721,0.764386387 11.5249963,1.16791915 11.5253288,1.58882342 C11.5256602,2.00972768 11.3587727,2.41352417 11.06138,2.71138 L10.20311,3.57029 L7.95168,1.31887 L8.80032,0.46887 C9.38259234,-0.114298687 10.3128808,-0.158942138 10.94832,0.36579 L11.0559,0.46334 L11.05961,0.467 Z M8.94661,4.82823 L2.5191,11.26712 L0,11.5269 L0.258,9.02462 L6.69515,2.57681 L8.94661,4.82823 Z"
                  id="Edytuj"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </i>
);

export default EditIcon;
