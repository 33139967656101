import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from 'antd';
import { getContactLink } from '../../../../utils';
import { getEnv } from '../../../../env';
import { string } from 'prop-types';

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');

export const Omega3InfoBox = ({ prescriptionId }) => {
  return (
    <div id="omega-3-info-box" className="omega-3-info-box">
      <Trans i18nKey="omega-3-info-box">
        <h3>...</h3>
        <Button
          type="primary"
          href={`${mixerBaseUrl}/${prescriptionId}/summary`}
        >
          ...
        </Button>
        <p>
          ...<b>...</b>
        </p>
        <p>
          ...<a href={getContactLink()}>...</a>...
        </p>
      </Trans>
    </div>
  );
};

Omega3InfoBox.propTypes = {
  prescriptionId: string.isRequired
};
