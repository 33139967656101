import { authActionNames, actionNamesUserData } from '../../utils/constants';
import { removeLoginData } from '../../utils';

const { SET_TOKEN } = authActionNames;

const { SET_USER, LOGOUT, SET_SUNDOSE_DATA } = actionNamesUserData;

export const setToken = token => {
  return {
    type: SET_TOKEN,
    data: token
  };
};

export const setUser = userData => {
  return {
    type: SET_USER,
    data: userData
  };
};

export const setSundoseData = data => ({
  type: SET_SUNDOSE_DATA,
  data
});

export const signOut = () => dispatch => {
  removeLoginData();

  dispatch(setToken(null));
  dispatch({ type: LOGOUT });
};
