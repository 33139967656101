import React, { useCallback, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import AuthorizationForm, {
  FORM_NAME,
  EmailInput
} from '../../../../components/AuthorizationForm';
import { resetPasswordRequest } from '../../../../api/auth';
import { HTTP_STATUS_CODES } from '../../../../utils/constants';

const { BAD_REQUEST } = HTTP_STATUS_CODES;

const formNames = {
  email: 'email'
};

const initValidateStatuses = {
  [formNames.email]: ''
};

const ResetPasswordRequest = () => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const sendResetPasswordLink = useCallback(
    async (values, forms) => {
      setLoading(true);
      await resetPasswordRequest(values[formNames.email])
        .then(() => {
          history.push(
            `/panel/auth/reset-password/request/success${history.location.search}`
          );
        })
        .catch(err => {
          const { response } = err;
          if (response) {
            if (response.status === BAD_REQUEST) {
              forms[FORM_NAME].setFields([
                {
                  name: formNames.email,
                  errors: [t('Wrong format')]
                }
              ]);
            }
          }
          setLoading(false);
        });
    },
    [history, t]
  );

  const onFormFinish = useCallback(
    async (name, { values, forms }) => {
      if (name === FORM_NAME) await sendResetPasswordLink(values, forms);
    },
    [sendResetPasswordLink]
  );

  return (
    <Row className="reset-password-request-container">
      <Col span={24}>
        <h1 className="reset-password-request-container--title">
          {t('Reset password')}
        </h1>
        <h4 className="reset-password-request-container--subtitle">
          {t('Enter your email address to reset password.')}
        </h4>
        <Row>
          <Col span={24}>
            <Form.Provider onFormFinish={onFormFinish}>
              <AuthorizationForm
                requiredMark={false}
                submitButtonText={t('Reset password')}
                pathsToValidate={Object.keys(initValidateStatuses)}
                submitting={loading}
                additionalButton={
                  <Link to={`/panel/auth/login${history.location.search}`}>
                    <Button
                      type="link"
                      className="reset-password-request-login-button"
                    >
                      {t('Back to Log in')}
                    </Button>
                  </Link>
                }
              >
                <EmailInput inputName={formNames.email} />
              </AuthorizationForm>
            </Form.Provider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ResetPasswordRequest;
