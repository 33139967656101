import React, { useCallback, useState } from 'react';
import { Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { SettingsContentHeader } from '../components';
import {
  HTTP_STATUS_CODES,
  notificationTypes
} from '../../../../utils/constants';
import { AwaitData } from '../../../../components/AwaitData';
import AuthorizationForm, {
  PasswordFormItemWithValidation,
  PasswordInput,
  RepeatPasswordFormItemWithValidation
} from '../../../../components/AuthorizationForm';
import { changePassword } from '../../../../api/auth';
import './settings-subviews.scss';

const { SUCCESS, ERROR } = notificationTypes;
const { useForm } = Form;

const fieldKeys = {
  OLD_PASSWORD: 'old_password',
  PASSWORD: 'new_password',
  CONFIRM_PASSWORD: 'new_password_confirm'
};
const { PASSWORD, CONFIRM_PASSWORD, OLD_PASSWORD } = fieldKeys;

const PasswordChangeSettings = ({ title }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [dataLoading, setDataLoading] = useState(false);

  const submitChangePassword = useCallback(
    async values => {
      setDataLoading(true);
      await changePassword({
        new_password: values[PASSWORD],
        old_password: values[OLD_PASSWORD]
      })
        .then(() => {
          notification[SUCCESS]({
            message: t('changes-saved-successfully'),
            className: 'notification-style-success'
          });
        })
        .catch(err => {
          const { response } = err;
          if (response) {
            if (response.status === HTTP_STATUS_CODES.BAD_REQUEST) {
              const fields = Object.entries(response.data).map(
                ([key, value]) => ({
                  name: key,
                  errors: [value]
                })
              );
              form.setFields(fields);
            } else {
              notification[ERROR]({
                message: t('something-went-wrong'),
                className: 'notification-style-error'
              });
            }
          }
        })
        .finally(() => {
          setDataLoading(false);
        });
    },
    [form, t]
  );

  return (
    <div className="settings-wrapper">
      <AwaitData loading={dataLoading}>
        <div className="settings-form">
          <SettingsContentHeader title={title} />
          <AuthorizationForm
            submitButtonText={t('Change password')}
            pathsToValidate={Object.values(fieldKeys)}
            onFinish={submitChangePassword}
            form={form}
          >
            <Form.Item
              name={OLD_PASSWORD}
              label={t('Old password')}
              rules={[
                {
                  required: true,
                  message: t('Password is required')
                }
              ]}
            >
              <PasswordInput />
            </Form.Item>
            <PasswordFormItemWithValidation name={PASSWORD} />
            <RepeatPasswordFormItemWithValidation
              basePasswordName={PASSWORD}
              name={CONFIRM_PASSWORD}
            />
          </AuthorizationForm>
        </div>
      </AwaitData>
    </div>
  );
};

export default PasswordChangeSettings;
