import { useTranslation } from 'react-i18next';
import { Map } from 'immutable';

import { countryNormsCodes } from './constants';

const {
  PL,
  EN_GB,
  DE_DE,
  GA_IE,
  FR,
  DE_AT,
  SW,
  IT_IT,
  NL_NL
} = countryNormsCodes;

export const useCountryDescriptions = () => {
  const { t } = useTranslation();

  return Map({
    [PL]: {
      displayName: t('country-name-poland'),
      description: t('country-description-poland'),
      isoKey: 'PL'
    },
    [EN_GB]: {
      displayName: t('country-name-uk'),
      description: t('country-description-uk'),
      isoKey: 'GB'
    },
    [DE_DE]: {
      description: t('country-description-germany'),
      displayName: t('country-name-germany'),
      isoKey: 'DE'
    },
    [GA_IE]: {
      displayName: t('country-name-ireland'),
      description: t('country-description-ireland'),
      isoKey: 'IE'
    },
    [FR]: {
      displayName: t('country-name-france'),
      description: t('country-description-france'),
      isoKey: 'FR'
    },
    [DE_AT]: {
      displayName: t('country-name-austria'),
      description: t('country-description-austria'),
      isoKey: 'AT'
    },
    [SW]: {
      displayName: t('country-name-switzerland'),
      description: t('country-description-switzerland'),
      isoKey: 'CH'
    },
    [IT_IT]: {
      displayName: t('country-name-italy'),
      description: t('country-description-italy'),
      isoKey: 'IT'
    },
    [NL_NL]: {
      displayName: t('country-name-netherlands'),
      description: t('country-description-netherlands'),
      isoKey: 'NL'
    }
  });
};
