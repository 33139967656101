import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

import SundoseLogo from '../../../assets/img/new-logo.svg';
import OrderPendingIcon from '../../../assets/img/order_status_icons/order-pending.svg';

import DHLLogo from '../../../assets/img/delivery/dhl@3x.png';
import InPostLogo from '../../../assets/img/delivery/inpost.svg';

import './typ.scss';
import {Button, Col, Row, Spin} from 'antd';
import {getLastOrder} from '../../../api/legacy';
import {AddressType} from '../SettingsView/subviews/subscription/types';
import {getLSItem, setLSItem, useOptimize} from '../../../utils';
import {useParams} from 'react-router';
import {getUserType} from '../../auth/utlis';
import {retrieveUserSettings} from '../../../api/userSettings';
import {getUserId, pushToDataLayer, pushUserDataToDataLayer} from '../../../utils/dataLayer';
import {dataLayerPushThankYouPage} from './dataLayer';
import {useSelector} from 'react-redux';
import {getPrescription} from '../../../api/mixer';
import {useCountryDescriptions} from '../../../utils/useCountryDescription';

interface Prescription {
  owner: number;
}

interface UserSettings {
  ui_language: string;
  paid_transactions_count: number;
  days_from_last_purchase: number;
  subscriber: boolean;
}

interface Order {
  order_items: any;
  analytics_coupon: string;
  billing: AddressType;
  shipping: AddressType;
  shipping_type: 'DHL' | 'InPost';
  id: number;
  // change_status_date: null;
  coupon: string | null;
  // created_at: '2022-03-09T15:56:17.529864+01:00';
  // currency: 'PLN';
  // date_created_at: '2022-03-09T14:56:17.529864Z';
  // delivered_date: null;
  // id: 1100745;
  is_mobile: boolean;
  // is_priority: false;
  // payment_method: 'PayU';
  // predicted_date_of_production: null;
  prescription_id: number;
  price: number;
  // product: 2;
  // regulation_id: 4;
  // shipment_id: null;
  // shipping_type: 'DHL';
  // status: 'order_placed';
  subscription_id: number | null;
  user_id: number;
  // user_name: 'qwe1232 qwe1233';
}

interface ThankYouPageParams {
  id: string;
  paymentMethod: string;
  coupon: string;
}

const NewThankYouPage: React.FC = () => {
  const [t] = useTranslation();
  const countries = useCountryDescriptions();

  useOptimize('activateThankYouPage');
  const {id, paymentMethod, coupon} = useParams<ThankYouPageParams>();

  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState<boolean>(true);

  const {billing, shipping} = order || {};

  const billingCountry = billing?.country
    ? countries.get(billing.country.toLowerCase())?.displayName
    : billing?.country;
  const shippingCountry = shipping?.country
    ? countries.get(shipping.country.toLowerCase())?.displayName
    : shipping?.country;

  const {exchange_rates, baseline_prices} = useSelector(
    // @ts-ignore
    state => state.panelMixerData
  );

  const userId = getUserId();

  const initializeSurvicateScript = (
    s: UserSettings,
    o: Order,
    b: AddressType
  ) => {
    const survicateScriptId = 'survicate-script-id';
    const survicateInitialized = document.getElementById(survicateScriptId);

    if (!survicateInitialized) {
      const script = document.createElement('script');

      script.innerHTML = `
      (function(opts) {
            opts.traits = {
                "email": "${billing?.email}",
                "language": "${s.ui_language}",
                "device": "${o.is_mobile ? 'mobile' : 'web'}",
                "user_type": "${getUserType(s.paid_transactions_count)}",
                "user_id": "${o.user_id}",
                "coupon": "${coupon}",
                "days_from_last_purchase": "${s.days_from_last_purchase}",
                "payment_type": "${paymentMethod}",
                "purchase_type": "${
        o.subscription_id ? 'subscription' : 'one-off'
      }",
                "order_id": "${id}",
                "paid_transactions_count": "${s.paid_transactions_count}",
                "purchase_value": "${o.price}",
                "active_subscriber": "${s.subscriber.toString()}",
                "first_name": "${b?.first_name}",
                "last_name": "${b?.last_name}"
             };
        })(window._sva = window._sva || {});
        (function(w) {
          var s = document.createElement('script');
          s.src = 'https://survey.survicate.com/workspaces/7fd3df625fd8a5eda12357a38a5112f6/web_surveys.js';
          s.async = true;
          var e = document.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(s, e);
        })(window);
      `;

      script.setAttribute('type', 'text/javascript');
      script.setAttribute('id', survicateScriptId);

      document.head.appendChild(script);
    }
  };

  useEffect(() => {
    retrieveUserSettings(userId).then((s: UserSettings) => {
      getLastOrder().then((data: Order[]) => {
        const o = data[0];
        setOrder(o);
        const prescriptionId = o.order_items[0].prescription_id;
        getPrescription(prescriptionId).then((p: Prescription) => {
          pushUserDataToDataLayer(p?.owner, s.ui_language, 'Thank You Page');

          initializeSurvicateScript(s, o, o.billing);

          if (getLSItem('TYP_orderId') !== order?.id.toString()) {
            setLSItem('TYP_orderId', order?.id);

            dataLayerPushThankYouPage(
              o,
              p,
              exchange_rates,
              baseline_prices,
              paymentMethod,
              s,
              coupon
            );
          }

          if (o.subscription_id) {
            pushToDataLayer('subscription_start', {
              subscription_id: o.subscription_id,
              transaction_id: o.id,
              value: o.price.toFixed(2)
            });
          }

          setLoading(false);
        });
      });
    });
  }, []);

  if (loading) {
    return <Spin/>;
  }

  return (
    <div className="typ-wrapper">
      <Helmet>
        <title>Sundose - {t('thank-you-for-buying-sundose')}</title>
      </Helmet>
      <div className="typ-container">
        <img src={SundoseLogo} alt={'Logo'}/>
        <h1>{`${t('thank-you-for-ordering')}, ${billing?.first_name}!`}</h1>
        <div className="card order-status">
          <div>
            <span>
              {t('order')} {order?.id}
            </span>
            <span className="badge">{t('order-pending')}</span>
          </div>
          <img src={OrderPendingIcon} alt="Order pending icon"/>
        </div>
        <h2>{t('typ-message')}</h2>
        <div className="card big-card">
          <div className="order-details">
            <div className="order-details-left">
              <h3>{t('order-no')}</h3>
            </div>
            <div className="order-details-right">
              <h3>{order?.id}</h3>
            </div>
          </div>
          <div className="customer-data details-upper-border">
            <h3>{t('customer-data')}</h3>
            <div className="addresses">
              <div className="address">
                <span><b>{t('billing-address')}</b></span>
                <span>
                  {billing?.first_name} {billing?.last_name}, {billingCountry}
                </span>
                <span>{billing?.address_1}</span>
                <span>
                  {billing?.postcode}, {billing?.city}
                </span>
                <span>{billing?.phone}</span>
                <span>{billing?.email}</span>
              </div>
              <div className="address">
                <span><b>{t('shipping-address')}</b></span>
                <span>
                  {shipping?.first_name} {shipping?.last_name},{' '}
                  {shippingCountry}
                </span>
                <span>{shipping?.address_1}</span>
                <span>
                  {shipping?.postcode}, {shipping?.city}
                </span>
              </div>
            </div>
          </div>
          <div className="order-details details-upper-border">
            <div className="order-details-left">
              <h3>{t('delivery-method')}</h3>

            </div>
            <div className="order-details-right">
              <Row gutter={[5, 0]}>
                <Col>
                  {order?.shipping_type === 'DHL' ? (
                    <img
                      src={DHLLogo}
                      className="dhl"
                      alt={'DHL Logo'}
                      style={{margin: '0'}}
                    />
                  ) : (
                    <img
                      src={InPostLogo}
                      className="inpost"
                      alt={'Inpost Logo'}
                      style={{margin: '0'}}
                    />
                  )}
                </Col>
                <Col>
                  {order?.shipping_type === 'DHL' ? (
                    <>
                      <b>DHL</b>
                    </>
                  ) : ''}
                  {order?.shipping_type === 'InPost' ? (
                    <>
                      <b>InPost</b>
                      <div>
                        <span>{t('inpost-point')} {shipping?.target_point}</span>
                      </div>
                    </>
                  ) : ''}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <div className="typ-footer">
        <Button
          className="go-to-dashboard-btn"
          type="link"
          onClick={() => {
            // not using react router on purpose to force app to reload data
            window.location.href = '/app/panel/dashboard';
          }}
        >
          {t('go-to-dashboard')}
        </Button>
      </div>
    </div>
  );
};

export default NewThankYouPage;
