import { useSelector } from 'react-redux';

/**
 * @TODO move this type to reducer and add types to whole store
 */
export interface subscriptionSettingsType {
  change_payment_date_limit: number;
  discounts: { start: number; step: number; stop: number };
  start: number;
  step: number;
  stop: number;
  id: number;
  type: string;
}

export const useSubscriptionDiscount = (country_norms_code: string) => {
  const {
    subscription_settings
  }: { subscription_settings: subscriptionSettingsType[] } = useSelector(
    (state: any) => state.userDataReducer.settings
  );
  const defaultDiscounts = { start: 0.15, stop: 0.25, step: 0.01 };
  let countryDiscounts;
  if (Array.isArray(subscription_settings)) {
    countryDiscounts = subscription_settings.find(
      ({ type }) => type === country_norms_code
    );

    if (!countryDiscounts) {
      countryDiscounts = subscription_settings.find(
        ({ type }) => type === 'other'
      );
    }
  }
  return (countryDiscounts && countryDiscounts.discounts) || defaultDiscounts;
};
