import moment from 'moment';
import { useEffect } from 'react';
import i18n from './i18n';
import 'moment/locale/pl';
import 'moment/locale/de';
import 'moment/locale/it';
import { getLSItem } from './panel/utils';

moment.updateLocale('pl', {
  months: [
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwiecień',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień'
  ],
  weekdays: [
    'Niedziela',
    'Poniedziałek',
    'Wtorek',
    'Środa',
    'Czwartek',
    'Piątek',
    'Sobota',
    'Niedziela'
  ],
  weekdaysShort: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
  weekdaysMin: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S']
});

const useMomentLocalization = () => {
  i18n.on('languageChanged', lang => {
    moment.locale(lang);
  });

  useEffect(() => {
    const lang = getLSItem('language');
    if (lang !== null) {
      moment.locale(lang);
    }
  }, []);
};

export default useMomentLocalization;
