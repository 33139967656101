import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NotFound from './NotFound';
import NotAuthorized from './NotAuthorized';
import Survey from './Survey';

const Stage = () => {
  let { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/id/:surveyId`} exact component={Survey} />
      <Route path={`${url}/id/:surveyId/:dietitian`} exact component={Survey} />
      <Route path={`${url}/not-authorized`} exact component={NotAuthorized} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Stage;
