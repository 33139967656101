import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../../../utils';
import ResetPassword from './ResetPassword';

const ResetPasswordDataProvider = () => {
  const [t] = useTranslation();
  const { user, token } = useQuery();

  return (
    <ResetPassword
      user={user}
      token={token}
      title={t('Reset your password')}
      subtitle={t('Please use this page to reset your password.')}
      submitButtonText={t('Reset password')}
      redirectOnError={true}
    />
  );
};

export default ResetPasswordDataProvider;
