import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';

import { retrieveMixerSettings } from '../../../api/mixerSettings';
import { userSignOut } from '../../../dataService';
import {
  routeMainPaths,
  settingsRouteKeys,
  tokenKeys
} from '../../../utils/constants';
import { useOptimize } from '../../../utils';

import { OrdersView } from '../OrdersView';
import SavedPrescriptionsView from '../SavedPrescriptionsView/SavedPrescriptionsView';
import { InfoBoxMixer } from '../../../components/InfoBox';
import './composition-view.scss';
import { signOut } from '../../auth/actions';
import { DietitianContainer } from '../../../components/OwnDietitian/OwnDietitian';
import PersonaliseBannerProvider from '../../../components/PersonaliseBanner/PersonaliseBannerProvider';

export const CompositionView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useOptimize('activateCompositionPage');
  const [userSettings, setUserSettings] = useState(null);
  const [fetchingUserSettings, setFetchingUserSettings] = useState(false);
  const history = useHistory();
  const { tokenData } = useSelector(state => state.userDataReducer);

  const logout = () =>
    userSignOut().then(() => {
      dispatch(signOut());
    });

  useEffect(() => {
    if (tokenData !== null) {
      fetchData(tokenData.get(tokenKeys.USER_ID));
    } else {
      logout();
    }
  }, [tokenData]);

  const fetchData = async id => {
    setFetchingUserSettings(true);
    const response = await retrieveMixerSettings(id);
    setUserSettings(response);
    setFetchingUserSettings(false);
  };

  const propsPreferencesBox = {
    bottomButton: {
      text: t('edit-preferences'),
      onClick: () =>
        history.push(`${routeMainPaths.SETTINGS}${settingsRouteKeys.PRODUCT}/`)
    },
    flavour: userSettings ? userSettings.json_body.flavour : undefined,
    exclusions: userSettings ? userSettings.json_body.exclusions : undefined,
    loading: fetchingUserSettings
  };

  return (
    <div className="composition-view-style">
      <Helmet>
        <title>{t('components')} - Sundose</title>
      </Helmet>
      <Col xs={{ span: 22, offset: 1 }} xl={{ span: 24, offset: 0 }}>
        <Row>
          <PersonaliseBannerProvider />
        </Row>
      </Col>

      <Col xs={{ span: 22, offset: 1 }} xl={{ span: 24, offset: 0 }}>
        <Row type="flex" justify="space-between" className="flex-dir-column">
          <Col xs={24} lg={11}>
            <InfoBoxMixer />
          </Col>
          <Col xs={24} lg={{ span: 12, offset: 1 }}>
            {/*<PrescriptionPreferencesBoxWrapper {...propsPreferencesBox} />*/}
            <DietitianContainer customClassName={'dietitian-infobox'} />
          </Col>
        </Row>
        <br />
        {/*<ComparePrescriptionsBox/>*/}
        {/*<br/>*/}

        <Row type="flex" justify="space-between" className="flex-dir-column">
          <Col xs={24} lg={11} className="margin-top-mobile">
            <SavedPrescriptionsView />
          </Col>
          <Col
            xs={24}
            lg={{ span: 12, offset: 1 }}
            className="margin-top-mobile"
          >
            <OrdersView />
          </Col>
        </Row>
      </Col>
    </div>
  );
};
