import React, { Component } from 'react';
import Velocity from 'velocity-animate';
import SurveyContext from '../SurveyContext';
import loadingIcon from '../assets/images/gif.gif';
import { withTranslation } from 'react-i18next';
import { getUserId, pushToDataLayer } from '../../panel/utils/dataLayer';
import { getUserSettings } from '../DataService';
import { getUserType } from '../../panel/views/auth/utlis';

class Intro extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);
    this.state = {};

    this.stageBox = React.createRef();
  }

  componentDidMount() {
    const stageBox = this.stageBox.current;
    const { updateStage, surveyId: survey_id } = this.context;

    Velocity(stageBox, { opacity: 1 }, { duration: 100 }).then(() => {
      setTimeout(() => {
        updateStage('phase-2');
      }, 500);
    });


    const userId = getUserId();

    getUserSettings(userId).then(response => {
      const { paid_transactions_count } = response;

      pushToDataLayer('survey_start', {
        survey_id,
        user_id: getUserId(),
        user_type: getUserType(paid_transactions_count),
        process_type: paid_transactions_count > 0 ? 'retention' : 'acquisition'
      });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div id="intro_stage" ref={this.stageBox}>
        <div className="wrap">
          <div className="left_column">
            <h2 className="loading">{t('health-survey-sundose')}</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Intro);
