import React from 'react';
import { arrayOf, string, oneOf, oneOfType } from 'prop-types';

import { Alert } from 'antd';
import './alert-message.scss';

const AlertMessage = ({ message, type, ...props }) => {
  const messageString = Array.isArray(message) ? (
    <span dangerouslySetInnerHTML={{ __html: message.join('<br />\n') }} />
  ) : (
    message
  );

  return <Alert message={messageString} type={type} {...props} />;
};

AlertMessage.propTypes = {
  message: oneOfType([arrayOf(string), string]).isRequired,
  type: oneOf(['success', 'info', 'warning', 'error']).isRequired
};

export default AlertMessage;
