/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import Header from './components/Header';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';

const { Content } = Layout;

const UserPanelRoute = ({ component: Component, panelStyle, ...rest }) => {
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

  let { url } = useRouteMatch();

  if (url.charAt(url.length - 1) === '/') {
    url = url.slice(0, -1);
  }
  const location = useLocation();
  const currentLocation = location.pathname + location.search;

  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoggedIn)
          return (
            <Redirect to={`${url}/auth/login?redirect=${currentLocation}`} />
          );

        return panelStyle ? (
          <div className={`user-panel ${props.location.pathname.substring(1)}`}>
            <Header />
            <Content style={styleSheet.panelContainer}>
              <Component {...props} />
            </Content>
          </div>
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

UserPanelRoute.defaultProps = {
  panelStyle: true
};

export default UserPanelRoute;

const styleSheet = {
  panelContainer: {
    width: '100%',
    maxWidth: '1170px',
    margin: '0 auto'
  }
};
