import { useTranslation } from 'react-i18next';

import pl_flag from '../../../../assets/img/flags/flag_pl.svg';
import en_flag from '../../../../assets/img/flags/flag_en.svg';
import de_flag from '../../../../assets/img/flags/flag_de.svg';
import ie_flag from '../../../../assets/img/country_flags/irl.svg';
// import fr_flag from '../../assets/img/country_flags/fr.svg';
import aus_flag from '../../../../assets/img/country_flags/aus.svg';
// import sw_flag from '../../assets/img/country_flags/sw.svg';
import it_flag from '../../../../assets/img/country_flags/it.svg';
import nl_flag from '../../../../assets/img/country_flags/nl.svg';

export const countryNormsCodes = {
  PL: 'pl',
  EN_GB: 'en-GB',
  DE_DE: 'de-DE',
  GA_IE: 'ga-IE',
  FR: 'fr',
  DE_AT: 'de-AT',
  SW: 'sw',
  IT_IT: 'it-IT',
  NL_NL: 'nl-NL'
};

type PhoneCodeInfo = {
  name: string;
  code: string;
  flag: any;
};

export const usePhoneCodes = (): Record<string, PhoneCodeInfo> => {
  const [t] = useTranslation();

  return {
    [countryNormsCodes.PL]: {
      name: t('country-name-poland'),
      code: '48',
      flag: pl_flag
    },
    [countryNormsCodes.EN_GB]: {
      name: t('country-name-uk'),
      code: '44',
      flag: en_flag
    },
    [countryNormsCodes.DE_DE]: {
      name: t('country-name-germany'),
      code: '49',
      flag: de_flag
    },
    [countryNormsCodes.GA_IE]: {
      name: t('country-name-ireland'),
      code: '353',
      flag: ie_flag
    },
    // [FR]: {
    //   name: t('country-name-france'),
    //   code: '33',
    //   flag: fr_flag
    // },
    [countryNormsCodes.DE_AT]: {
      name: t('country-name-austria'),
      code: '43',
      flag: aus_flag
    },
    // [SW]: {
    //   name: t('country-name-switzerland'),
    //   code: '41',
    //   flag: sw_flag
    // },
    [countryNormsCodes.IT_IT]: {
      name: t('country-name-italy'),
      code: '39',
      flag: it_flag
    },
    [countryNormsCodes.NL_NL]: {
      name: t('country-name-netherlands'),
      code: '31',
      flag: nl_flag
    }
  };
};
