import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { SettingsContentHeader } from '../components/SettingsContentHeader';
import { List, Map } from 'immutable';
import { useTranslation } from 'react-i18next';
import PreventLeavingWithChanges from '../../../../components/PreventLeavingPage';
import './settings-subviews.scss';

export const SettingsUserForm = ({
  title,
  dataLoading,
  serverValues,
  updateData,
  initialValues,
  itemsData,
  fieldKeys
}) => {
  const { t } = useTranslation();
  const [touchedFields, setTouchedFields] = useState(List());
  const [disabledSuffixes, setDisabledSuffixes] = useState(List());
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [form] = Form.useForm();

  const onSaveInput = useCallback(
    key => {
      const value = form.getFieldValue(key);
      updateData({
        [key]: key === 'phone' ? value.replaceAll(' ', '') : value
      });
      setDisabledSuffixes(disabledSuffixes.push(key));
    },
    [form, updateData, disabledSuffixes]
  );

  const checkForUnsavedChanges = useCallback(() => {
    const changes = Map(fieldKeys).some(
      key =>
        key !== 'hiddenPassword' &&
        form.getFieldValue(key) !== serverValues.get(key)
    );
    if (changes !== unsavedChanges) setUnsavedChanges(changes);
  }, [unsavedChanges, form, serverValues, fieldKeys]);

  useEffect(() => {
    checkForUnsavedChanges();
  }, [checkForUnsavedChanges]);

  const onValuesChange = useCallback(
    values => {
      let touched = touchedFields;
      let suffixes = disabledSuffixes;
      Object.keys(values).forEach(key => {
        if (!touched.contains(key)) touched = touched.push(key);

        if (values[key] === serverValues.get(key))
          suffixes = suffixes.push(key);
        else if (suffixes.contains(key))
          suffixes = suffixes.remove(suffixes.indexOf(key));
      });
      if (!touchedFields.equals(touched)) setTouchedFields(touched);
      if (!disabledSuffixes.equals(suffixes)) setDisabledSuffixes(suffixes);

      checkForUnsavedChanges();
    },
    [touchedFields, disabledSuffixes, serverValues, checkForUnsavedChanges]
  );

  return (
    <div className="settings-form">
      <PreventLeavingWithChanges prevent={unsavedChanges} />
      <SettingsContentHeader title={title} />

      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        hideRequiredMark
      >
        {itemsData
          .map((val, key) => {
            const suffix = !touchedFields.contains(key) ? (
              <></>
            ) : (
              <Button
                name={key}
                type="link"
                disabled={disabledSuffixes.contains(key)}
                onClick={() => onSaveInput(key)}
              >
                {t('save')}
              </Button>
            );

            return (
              <Form.Item
                name={key}
                key={key}
                label={val.label}
                rules={[
                  { required: true, message: t('required-field') },
                  { whitespace: true, message: t('required-field') }
                ]}
              >
                <Input
                  disabled={dataLoading}
                  suffix={suffix}
                  {...val.inputProps}
                />
              </Form.Item>
            );
          })
          .valueSeq()
          .toJS()}
      </Form>
    </div>
  );
};

SettingsUserForm.defaultProps = {
  serverValues: Map()
};
