import React, { useState } from 'react';
import { Alert, Button, Checkbox, Form } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { getEnv } from '../../../../../../env';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import AuthorizationForm, {
  EmailInput
} from '../../../../../components/AuthorizationForm';
import {
  getLSItem,
  saveLoginData,
  useLocaleLink,
  useOptimize
} from '../../../../../utils';
import { quickSignUp } from '../../../../../api/auth';
import { loginUser } from '../../../utlis';
import {
  HTTP_STATUS_CODES,
  SBL_LOCALSTORAGE_KEY
} from '../../../../../utils/constants';
import { Footer } from '../../../UserAuthorizationRoute';
import { QuickRegisterSunSVG } from '../../../../../components/svg/QuickRegisterSunSVG/QuickRegisterSunSVG';
import './quick-register-form.scss';
import { pushToDataLayer } from '../../../../../utils/dataLayer';
import { Link } from 'react-router-dom';

const { BAD_REQUEST } = HTTP_STATUS_CODES;
const { useForm } = Form;

const formNames = {
  email: 'email',
  agreement_1: 'agreement_1',
  agreement_2: 'agreement_2'
};

export const QuickRegisterForm = ({ setErrors }) => {
  const [, forceUpdate] = React.useState();
  const [form] = useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [t] = useTranslation();
  const privacyPolicyLink = useLocaleLink('privacyPolicy');
  const storeRulesLink = useLocaleLink('storeRules');
  const socialBusSlug = getLSItem(SBL_LOCALSTORAGE_KEY);

  React.useEffect(() => {
    forceUpdate({});
  }, []);

  useOptimize('activateQuickRegisterForm');

  const validateFields = [formNames.email, formNames.agreement_1];

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onFormSubmit = async values => {
    if (!submitting) {
      try {
        setSubmitting(true);
        setErrors([]);

        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
        }

        const registerRequestBody = {
          email: values.email,
          agreement_level:
            (values['agreement_1'] ? 1 : 0) + (values['agreement_2'] ? 1 : 0)
        };

        try {
          registerRequestBody.recaptcha = await executeRecaptcha('register');
        } catch {
          console.log('Quick Register - Recaptcha failed to execute.');
        }

        if (socialBusSlug) {
          registerRequestBody['social_bus_slug'] = socialBusSlug;
        }
        const registerResponse = await quickSignUp(registerRequestBody);

        pushToDataLayer('sign_up', {
          process_type: 'acquisition',
          user_type: 'visitor',
          method: 'email', // Sign up method chosen (eg. email, facebook, apple_id,)
          marketing_agreement: registerRequestBody.agreement_2, // Marketing agreement (eg. true,false)
          registered_user: true, // Registered user
          sign_up_page: 'quick sign up' // Sign up page (eg. standard sign up, health survey sign up)
        });

        saveLoginData(registerResponse);
        await loginUser(
          registerResponse.token,
          registerResponse,
          dispatch,
          'after quick register form'
        );
        localStorage.removeItem(SBL_LOCALSTORAGE_KEY);

        // SDEV-3160 Google Optimize Experiment with skipping survey:
        const [paramName, paramValue] = history.location.search
          .replace('?', '')
          .split('=');

        if (paramName === 'redirect') {
          if (history.location.search.includes('survey')) {
            history.push(paramValue);
          } else {
            window.location.href = paramValue.replace('&external', '');
          }
        } else {
          const surveyId = getEnv('REACT_APP_PUBLIC_SURVEY_ID');
          history.push(`/survey/id/${surveyId}`);
        }
      } catch (e) {
        const { response } = e;
        if (response) {
          if (response.status === BAD_REQUEST) {
            const errorMessages = Object.values(response.data).reduce(
              (acc, val) => [...acc, ...val],
              []
            );

            if (response.data.account_exists) {
              setAccountExists(true);
            } else {
              setErrors(errorMessages);
            }

            document.querySelector('input[name=email]').focus();
          }
        }
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="quick-register-form">
      <AuthorizationForm
        submitting={submitting}
        form={form}
        submitButtonText={t('quick-register-button-copy') + " →"}
        onFinish={onFormSubmit}
        pathsToValidate={validateFields}
      >
        {accountExists ? (
          <Alert
            message={
              <Trans
                i18nKey="account-exists"
                components={{
                  logIn: <Link to="/panel/auth/login" />,
                  resetPassword: (
                    <Link to="/panel/auth/reset-password/request" />
                  )
                }}
              />
            }
            type="error"
          />
        ) : null}

        <Form.Item className="quick-register-form__email-field">
          <EmailInput inputName={formNames.email} />
        </Form.Item>

        <Form.Item
          name={formNames.agreement_1}
          valuePropName="checked"
          className="checkbox-item"
          initialValue={false}
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(''),
              message: t('quick-register-agreement-required')
            }
          ]}
        >
          <Checkbox className="required">
            {t('register-privacy-checkbox')}
          </Checkbox>
        </Form.Item>

        <Form.Item
          initialValue={false}
          valuePropName="checked"
          name={formNames.agreement_2}
          className="checkbox-item"
          rules={[]}
        >
          <Checkbox>{t('register-marketing-checkbox')}</Checkbox>
        </Form.Item>
      </AuthorizationForm>
      <p className="quick-register-form__extra-agreements">
        <Trans
          i18nKey="register-regulations-and-privacy-policy"
          components={{
            linkRules: <Button type="link" href={storeRulesLink} />,
            linkPrivacy: <Button type="link" href={privacyPolicyLink} />
          }}
        />
      </p>
      <Footer />
    </div>
  );
};

export default function QuickRegisterFormWithRecaptcha({ setErrors }) {
  const reCaptchaKey = getEnv('REACT_APP_PUBLIC_RECAPTCHA_V3_KEY');

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <QuickRegisterForm setErrors={setErrors} />
    </GoogleReCaptchaProvider>
  );
}
