import React, { useCallback, useState } from 'react';
import { Button, Popover } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { func, instanceOf, string } from 'prop-types';
import './custom-picker.scss';
import { Map } from 'immutable';

const ControlledPicker = ({
  className,
  onOptionChange,
  options,
  value,
  optionClassName
}) => {
  const [showOptions, setShowOptions] = useState(false);

  const onOptionClick = useCallback(
    key => {
      onOptionChange(key);
      setShowOptions(false);
    },
    [onOptionChange]
  );

  const onTitleClick = useCallback(() => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  const content = (
    <>
      {options
        .map((val, key) => (
          <div
            key={key}
            className={`custom-picker-option ${optionClassName}`}
            onClick={() => onOptionClick(key)}
          >
            {val.prefix && val.prefix} {val.text}
          </div>
        ))
        .valueSeq()
        .toJS()}
    </>
  );

  const currentOption = options.get(value, { title: '' });

  return (
    <span className={`custom-picker ${className}`}>
      <Popover
        content={content}
        placement="bottom"
        trigger="click"
        visible={showOptions}
        onVisibleChange={setShowOptions}
      >
        <Button type="link" onClick={onTitleClick}>
          <span className="custom-picker-title">
            {currentOption.title}
            {showOptions ? <UpOutlined /> : <DownOutlined />}
          </span>
        </Button>
      </Popover>
    </span>
  );
};

ControlledPicker.propTypes = {
  onOptionChange: func.isRequired,
  options: instanceOf(Map).isRequired,
  value: string.isRequired,
  className: string,
  optionClassName: string
};

ControlledPicker.defaultProps = {
  className: '',
  optionClassName: ''
};

export default ControlledPicker;
