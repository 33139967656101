// Internal helpers

import moment from 'moment';
import { List } from 'immutable';

const isDateInArrayOfDates = (dates = List(), date) =>
  !!dates.find(d => d.isSame(date, 'day'));

const classesNames = {
  TODAY: 'is-today',
  YESTERDAY: 'is-yesterday',
  DISABLED: 'is-disabled',
  ACTIVE: 'is-active',
  START_DATE: 'is-start-date',
  END_DATE: 'is-end-date',
  LAST_DATE: 'is-last-date'
};

//exported

export const getDateStatusClasses = (date, datesTaken) => {
  const today = moment();
  let classes = [];
  if (date.isAfter(today, 'day')) {
    classes.push(classesNames.DISABLED);
    return classes;
  }
  if (date.isSame(today, 'day')) classes.push(classesNames.TODAY);
  if (date.isSame(today.clone().add(-1, 'd'), 'day'))
    classes.push(classesNames.YESTERDAY);
  if (isDateInArrayOfDates(datesTaken, date)) classes.push(classesNames.ACTIVE);
  if (!isDateInArrayOfDates(datesTaken, date.clone().add(-1, 'day')))
    classes.push(classesNames.START_DATE);
  if (!isDateInArrayOfDates(datesTaken, date.clone().add(1, 'day')))
    classes.push(classesNames.END_DATE);
  return classes;
};
