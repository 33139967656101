import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Modal } from 'antd';

import CloseIcon from '../../../../../../assets/img/close.svg';

interface PreCancelSubscriptionModalProps {
  isOpen: boolean;
  onCancel: () => void;
  openChangeDatesModal: () => void;
  openSuspendModal: () => void;
  openCancelModal: () => void;
  currentDiscount: number;
  paymentDateLimit: number;
  suspended: boolean;
}

const PreCancelSubscriptionModal: React.FC<PreCancelSubscriptionModalProps> = ({
  isOpen,
  onCancel,
  openChangeDatesModal,
  openSuspendModal,
  openCancelModal,
  currentDiscount,
  paymentDateLimit,
  suspended
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  return (
    <Modal
      width={600}
      visible={isOpen}
      wrapClassName="subscription-modal"
      bodyStyle={{
        padding: 30,
        boxShadow: '0 2px 7px 0 #00000020'
      }}
      maskClosable
      centered
      footer={null}
      onCancel={onCancel}
      closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
    >
      <h2>{t('subscription-precancel-header')}</h2>

      <Row>
        <Col xs={24}>
          <p style={{ marginTop: 15 }}>
            {t('subscription-precancel-desc', {
              discount: `${currentDiscount * 100}%`
            })}
          </p>
        </Col>
      </Row>

      <Form
        form={form}
        className="ant-form ant-form-vertical ant-form-hide-required-mark padding-10"
      >
        <Form.Item>
          {suspended ? null : (
            <>
              <Row>
                <Col xs={24}>
                  <p style={{ marginTop: 35, marginBottom: 15 }}>
                    {t('subscription-precancel-info')}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={24} className="col-padding">
                  <Button
                    type="primary"
                    shape="round"
                    block
                    size="large"
                    onClick={openSuspendModal}
                  >
                    {t('suspend-subscription')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={24}>
              <p style={{ marginTop: suspended ? 15 : 35, marginBottom: 15 }}>
                {t('subscription-precancel-postpone', {
                  maxPaymentDate: paymentDateLimit
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="col-padding">
              <Button
                type="primary"
                shape="round"
                block
                size="large"
                onClick={openChangeDatesModal}
              >
                {t('move-payment-date')}
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: '50px' }}>
            <Col xs={24} className="col-padding">
              <Button
                className={'btn-padding'}
                type="default"
                shape="round"
                block
                size="large"
                onClick={openCancelModal}
              >
                {t('still-want-to-cancel')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PreCancelSubscriptionModal;
