import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { settingsRouteKeys } from '../../../utils/constants';
import { useSettingsRoutes } from '../../../utils/useSettings';
import { SettingsMainNavigation } from './components';
import {
  ClientDataSettings,
  CouponSettings,
  DeliverySettings,
  PasswordChangeSettings,
  SubscriptionSettings
} from './subviews';

const {
  DELIVERY,
  PROFILE,
  PASSWORD_CHANGE,
  SUBSCRIPTION,
  COUPONS
} = settingsRouteKeys;

export const SettingsRouter: React.FC = () => {
  const settingsRoutes = useSettingsRoutes();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={SettingsMainNavigation} />
      <Route exact path={`${path}${PROFILE}/`}>
        {/*<SettingsWrapper*/}
        {/*  component={ProfileSettings}*/}
        {/*  title={settingsRoutes.get(PROFILE)}*/}
        {/*/>*/}
        <ClientDataSettings />
      </Route>
      <Route exact path={`${path}${PROFILE}/${PASSWORD_CHANGE}/`}>
        <PasswordChangeSettings title={settingsRoutes.get(PASSWORD_CHANGE)} />
      </Route>
      <Route exact path={`${path}${SUBSCRIPTION}/`}>
        <SubscriptionSettings />
      </Route>
      <Route exact path={`${path}${DELIVERY}/`}>
        <DeliverySettings />
      </Route>
      <Route exact path={`${path}${COUPONS}/`}>
        <CouponSettings />
      </Route>
    </Switch>
  );
};
