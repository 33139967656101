import React from 'react';
import { Collapse } from 'antd';
import * as PropTypes from 'prop-types';
import { OrderedMap } from 'immutable';
import './settings-navigation-accordion.scss';
import { LanguagePicker } from '../Pickers';

const { Panel } = Collapse;

export const SettingsNavigationAccordion = ({
  title,
  dataSource,
  panelProps,
  ...rest
}) => {
  const renderedItems = dataSource
    .map((value, key) => <Panel key={key} header={value} {...panelProps} />)
    .valueSeq()
    .toJS();

  return (
    <div className="settings-navigation-accordion">
      <div className="settings-navigation-title">
        {title}
        <LanguagePicker />
      </div>
      <Collapse
        accordion
        activeKey={[]}
        bordered={false}
        expandIconPosition="right"
        {...rest}
      >
        {renderedItems}
      </Collapse>
    </div>
  );
};

SettingsNavigationAccordion.propTypes = {
  title: PropTypes.string,
  dataSource: PropTypes.instanceOf(OrderedMap),
  panelProps: PropTypes.instanceOf(Object)
};

SettingsNavigationAccordion.defaultProps = {
  dataSource: OrderedMap()
};
