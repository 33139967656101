import React, { useEffect, useState } from 'react';
import { Form, notification, Spin } from 'antd';
import { SettingsContentHeader } from '../components';

import { useTranslation } from 'react-i18next';
import { CourierForm } from './delivery/courier-form';
import {
  fetchUserSubscriptions,
  updateSubscriptionAddress
} from '../../../../api/subscription';
import { setUserSubscription } from '../../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingData, updateBillingData } from '../../../../api/legacy';
import { addressSplitter } from './delivery';
import { pushToDataLayer } from '../../../../utils/dataLayer';
import { usePhoneCodes } from '../utils';

const ClientDataSettings: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState('PL'); // to ensure that country is always set

  const {
    user,
    subscription,
    settings: { subscriber }
    // @ts-ignore
  } = useSelector(state => state.userDataReducer);

  const [form] = Form.useForm();

  const phoneCodes = usePhoneCodes();

  const submitForm = () => {
    setIsPending(true);
    const values = form.getFieldsValue();

    // const phoneCode = phoneCodes[values['phone_code']]?.code;
    const street = `${values['street']} ${values['building_number']}`;
    const flat = values['flat_number'];

    const body = {
      ...values,
      address_1: flat ? `${street}/${flat}` : street,
      country: selectedCountry,
      phone: `${values['phone_code']}${values['phone_number']}`
    };

    const updateFunction = () => {
      return subscriber
        ? updateSubscriptionAddress(
            subscription.id,
            body,
            subscription.address.shipping
          )
        : updateBillingData(user.get('user'), body);
    };

    updateFunction()
      .then(() => {
        let eventBody = {};

        if (subscriber) {
          eventBody = { ...eventBody, subscription_id: subscription?.id };
        }

        pushToDataLayer('client_data_edit', eventBody);

        notification.success({
          message: t('client-data-updated'),
          className: 'notification-style-success'
        });
      })
      .catch(() => {
        notification.error({
          message: t('unexpected-error'),
          className: 'notification-style-error'
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  useEffect(() => {
    const onFinally = (payload: any) => {
      form.setFieldsValue({
        ...payload,
        phone_code: payload.phone.slice(0, 2),
        phone_number: payload.phone.slice(2, payload.phone.length),
        ...addressSplitter(payload.address_1)
      });

      setSelectedCountry(payload.country);
      setIsLoading(false);
    };

    if (subscriber) {
      fetchUserSubscriptions()
        .then(res => {
          const sub = res[0];
          dispatch(setUserSubscription(sub));

          return sub.address.billing;
        })
        .then(onFinally);
    } else {
      getBillingData(user.get('user')).then(onFinally);
    }
  }, []);

  return (
    <>
      <SettingsContentHeader
        addSaveButton={null}
        btnText={null}
        title={t('profile')}
        isSubscriptionActive={false}
        isSusbcriptionSuspended={false}
        isSubscriptionPage={false}
      />
      <div className="delivery-settings">
        {isLoading ? (
          <Spin />
        ) : (
          <CourierForm
            isPending={isPending}
            form={form}
            submitForm={submitForm}
            subscriber={false}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            phoneVisible
            emailVisible
            phoneCodes={phoneCodes}
          />
        )}
      </div>
    </>
  );
};

export default ClientDataSettings;
