import React from 'react';
import { useTranslation } from 'react-i18next';
import '../scss/await-contact.scss';

function AwaitContact() {
  const { t } = useTranslation();
  return (
    <div className="await-contact">
      <div className="await-contact-header">
        <b>{t('answers-sent-to-dietitian-message')}</b>
        <p>{t('dietitian-got-answers-will-contact')}</p>
      </div>
    </div>
  );
}

export default AwaitContact;
