import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Collapse, notification, Tag } from 'antd';
import { bool, instanceOf } from 'prop-types';
import { List, Map } from 'immutable';
import CollapsePrescriptionHeader from './CollapsePrescriptionHeader';
import PrescriptionCollapseExtraButtons from './CollapsePrescriptionExtra';
import useCurrency, { priceForCurrency } from '../../utils/useCurrency';
import { getDietitianData } from '../../api/legacy';
import {
  countryCurrency,
  editStatus,
  notificationTypes,
  roleNames,
  tokenKeys
} from '../../utils/constants';
import { ChevronIcon, UnorderedListIcon } from '../../assets/reactIcons';
import './collapse-prescription.scss';
import '../Collapse/collapse.scss';

const { NAME, SURNAME, ROLES } = tokenKeys;
const { ERROR } = notificationTypes;

const { Panel } = Collapse;

const CollapsePrescription = ({ listOfPrescriptions, expandAll }) => {
  const { tokenData } = useSelector(state => state.userDataReducer);
  const [activeKeys, setActiveKeys] = useState([]);

  const onActiveChange = useCallback(
    listOfKeys => {
      setActiveKeys(listOfKeys);
    },
    [setActiveKeys]
  );

  useEffect(() => {
    let expanded = [];
    if (expandAll) {
      expanded = listOfPrescriptions
        .map(prescription => prescription.get('id'))
        .toArray();
    }
    setActiveKeys(expanded);
  }, [expandAll, listOfPrescriptions]);

  return (
    <div>
      <Collapse
        className="collapse-prescription sundose-collapse"
        activeKey={activeKeys}
        bordered={false}
        onChange={onActiveChange}
        expandIcon={({ isActive }) => <ChevronIcon rotate={isActive} />}
      >
        {listOfPrescriptions
          .filter(
            prescription => prescription.get('edit_status') !== editStatus.DRAFT
          )
          .map((prescription, idx) => {
            const isAuthorDietitian =
              prescription.get('owner') !== prescription.get('author');
            const isCurrentUserDietitian = tokenData
              .get(ROLES)
              .includes(roleNames.DIETITIAN);
            return (
              <Panel
                className={
                  !isCurrentUserDietitian && isAuthorDietitian
                    ? 'collapse-prescription-dietitian'
                    : ''
                }
                header={
                  <CollapsePrescriptionHeader prescription={prescription} />
                }
                key={prescription.get('id', idx)}
                extra={
                  <PrescriptionCollapseExtraButtons
                    prescription={prescription}
                  />
                }
              >
                <CollapsePrescriptionBody
                  prescription={prescription}
                  isAuthorDietitian={isAuthorDietitian}
                />
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

CollapsePrescription.propTypes = {
  listOfPrescriptions: instanceOf(List).isRequired,
  expandAll: bool
};

CollapsePrescription.defaultProps = {
  expandAll: false
};

export default CollapsePrescription;

const CollapsePrescriptionBody = ({ prescription, isAuthorDietitian }) => {
  const { tokenData } = useSelector(state => state.userDataReducer);
  const [author, setAuthor] = useState('');
  const currencies = useCurrency();
  const { t } = useTranslation();
  const prescriptionJsonBody = prescription.get('json_body');
  const prescriptionCurrency = currencies.get(
    countryCurrency[prescriptionJsonBody.get('country_norms_code')]
  );
  const price = priceForCurrency(
    prescription.get('price'),
    prescriptionCurrency
  ).toFixed(2);
  const currencyText = prescriptionCurrency.get('text');

  const fetchData = useCallback(
    async diet_id => {
      const response = await getDietitianData(diet_id);
      if (response) {
        const name = response.first_name ? response.first_name : '';
        const surname = response.last_name ? response.last_name : '';
        setAuthor(`${name} ${surname}`);
      } else
        notification[ERROR]({
          message: t('could-not-fetch-dietitian'),
          className: 'notification-style-error'
        });
    },
    [t]
  );

  useEffect(() => {
    const authorId = prescription.get('author');
    if (authorId === tokenData.get(tokenKeys.USER_ID)) {
      const name = tokenData.get(NAME) ? tokenData.get(NAME) : '';
      const surname = tokenData.get(SURNAME) ? tokenData.get(SURNAME) : '';
      setAuthor(`${name} ${surname}`);
    } else if (isAuthorDietitian) {
      fetchData(authorId);
    }
  }, [fetchData, prescription, isAuthorDietitian, tokenData]);

  const isCompleted = prescription.get('edit_status') === editStatus.COMPLETED;

  return (
    <div className="sundose-collapse-body prescription-collapse-body">
      <div className="prescription-price">
        <span className="font-weight-black">
          {price} {currencyText}
        </span>
      </div>
      <div className="prescription-author">
        {t('author')}: <span className="font-weight-black">{author}</span>
        {isAuthorDietitian && (
          <Tag color="#B4DDF4" className="custom-tag-style">
            {t('dietitian').toUpperCase()}
          </Tag>
        )}
      </div>
      <div className="prescription-action-buttons">
        {isCompleted && (
          <Link to={`${prescription.get('id')}/details`}>
            <Button type="link" className="no-padding grey-button">
              {t('list of ingredients')}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

CollapsePrescriptionBody.propTypes = {
  prescription: instanceOf(Map).isRequired
};
