import React, {useEffect, useState} from 'react';
import {SettingsContentHeader} from '../../components';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Form, Modal, notification, Radio, Row, Spin} from 'antd';
import {pick} from 'lodash';
import {fetchUserSubscriptions, updateSubscriptionAddress} from '../../../../../api/subscription';
import {getAllShippingData, updateInPostShippingData, updateShippingData} from '../../../../../api/legacy';

import './delivery-settings.scss';
import type {AddressesType, AddressType, UserSubscriptionType} from '../subscription/types';
import {CourierForm} from './courier-form';
import {pushToDataLayer} from '../../../../../utils/dataLayer';
import {setUserSubscription} from '../../../../../actions';
import {useCountryDescriptions} from "../../../../../utils/useCountryDescription";

import DhlLogo from '../../../../../assets/img/delivery/dhl@3x.png';
import InPostLogo from '../../../../../assets/img/delivery/inpost.svg';

// export const pickDefaultAddress = (data: AddressesType) => {
//   const a = [];
//
//   if (data.courier?.is_picked) {
//     a.push({ ...data.courier, shipping_kind: 'COURIER' });
//   }
//
//   if (data.inpost?.is_picked) {
//     a.push({ ...data.inpost, shipping_kind: 'INPOST' });
//   }
//
//   if (a.length === 2) {
//     a.sort(
//       (a, b) =>
//         new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
//     );
//   }
//
//   return a[0];
// };
//
// export const addressSplitter = (address: string) => {
//   const splitted = address.split(' ');
//   const numbers = splitted.pop() ?? '';
//   const street = splitted.join(' ');
//   const [building_number, flat_number] = numbers.split('/');
//
//   return { street, building_number, flat_number };
// };
//
// export const DeliverySettings: React.FC = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//
//   const [shouldFetchData, setShouldFetchData] = useState(true);
//   const [isLoading, setLoading] = useState(true);
//   const [isPending, setPending] = useState(false);
//
//   const [selectedCountry, setSelectedCountry] = useState<string>('PL'); // to ensure that country is always set
//
//   const {
//     settings: { subscriber },
//     user,
//     subscription
//   } = useSelector((state: any) => state.userDataReducer);
//
//   const [form] = Form.useForm();
//
//   const populateCourierForm = (billing: AddressType, shipping: AddressType) => {
//     form.setFieldsValue({
//       ...pick(billing, ['first_name', 'last_name', 'country', 'phone', 'email'])
//     });
//
//     if (shipping) {
//       form.setFieldsValue({
//         ...shipping,
//         ...addressSplitter(shipping.address_1)
//       });
//     }
//
//     setSelectedCountry(shipping?.country || billing?.country);
//   };
//
//   useEffect(() => {
//     if (shouldFetchData) {
//       setLoading(true);
//
//       const onFinally = ({
//         billing,
//         shipping
//       }: {
//         billing: AddressType;
//         shipping: AddressType;
//       }) => {
//         populateCourierForm(billing, shipping);
//         setLoading(false);
//         setShouldFetchData(false);
//       };
//
//       if (subscriber) {
//         fetchUserSubscriptions()
//           .then(res => {
//             const sub = res[0];
//             dispatch(setUserSubscription(sub));
//
//             return {
//               billing: sub.address.billing,
//               shipping: sub.address.shipping
//             };
//           })
//           .then(onFinally);
//       } else {
//         getAllShippingData(user.get('user'))
//           .then(data => ({ billing: data.billing, shipping: data.courier }))
//           .then(onFinally);
//       }
//
//       window.scrollTo({ top: 0, behavior: 'smooth' });
//     }
//   }, [shouldFetchData]);
//
//   const submitCourierForm = () => {
//     setPending(true);
//     const values = {
//       ...form.getFieldsValue(),
//       country: selectedCountry
//     };
//     values.address_1 = `${values.street} ${values.building_number}${
//       values.flat_number ? '/' + values.flat_number : ''
//     }`;
//
//     const body = {
//       ...values,
//       shipping_kind: 'COURIER',
//       is_picked: true
//     };
//
//     const updateFunction = () => {
//       return subscriber
//         ? updateSubscriptionAddress(
//             subscription.id,
//             subscription.address.billing,
//             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//             // @ts-ignore
//             body
//           )
//         : updateShippingData(user.get('user'), body);
//     };
//
//     updateFunction()
//       .then(() => {
//         let eventBody = {};
//
//         if (subscriber) {
//           eventBody = { ...eventBody, subscription_id: subscription?.id };
//         }
//
//         pushToDataLayer('delivery_address_edit', eventBody);
//
//         notification.success({
//           message: t('address-updated'),
//           className: 'notification-style-success'
//         });
//
//         if (subscriber) {
//           updateShippingData(user.get('user'), body);
//         }
//
//         // setShouldFetchData(true);
//       })
//       .catch(() => {
//         notification.error({
//           message: t('unexpected-error'),
//           className: 'notification-style-error'
//         });
//       })
//       .finally(() => {
//         setPending(false);
//       });
//   };
//
//   return (
//     <>
//       <SettingsContentHeader
//         addSaveButton={null}
//         btnText={null}
//         title={t('delivery-address')}
//         isSubscriptionActive={false}
//         isSusbcriptionSuspended={false}
//         isSubscriptionPage={false}
//       />
//       <div className="delivery-settings">
//         {isLoading ? (
//           <Spin />
//         ) : (
//           <CourierForm
//             form={form}
//             isPending={isPending}
//             submitForm={submitCourierForm}
//             subscriber={subscriber}
//             selectedCountry={selectedCountry}
//             setSelectedCountry={setSelectedCountry}
//           />
//         )}
//       </div>
//     </>
//   );
// };

export const pickDefaultAddress = (data: AddressesType) => {
  const a = [];

  if (data.courier?.is_picked) {
    a.push({...data.courier, shipping_kind: 'COURIER'});
  }

  if (data.inpost?.is_picked) {
    a.push({...data.inpost, shipping_kind: 'INPOST'});
  }

  if (a.length === 2) {
    a.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );
  }

  return a[0];
};

export const addressSplitter = (address: string) => {
  const splitted = address.split(' ');
  const numbers = splitted.pop() ?? '';
  const street = splitted.join(' ');
  const [building_number, flat_number] = numbers.split('/');

  return {street, building_number, flat_number};
};

const loadInPostSDK = (callback: Function, errorCallback: Function) => {
  const scriptId = 'settings-inpost-sdk';
  const stylesheetId = 'settings-inpost-sdk-stylesheet';

  const scriptAlreadyExisting = document.getElementById(scriptId);
  const stylesheetAlreadyExisting = document.getElementById(stylesheetId);

  if (!scriptAlreadyExisting) {
    const script = document.createElement('script');

    script.setAttribute(
      'src',
      'https://geowidget.easypack24.net/js/sdk-for-javascript.js'
    );
    // script.setAttribute('src', getEnv('REACT_APP_PUBLIC_PAYU_SCRIPT_URL'));
    script.setAttribute('async', 'true');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', scriptId);

    document.head.appendChild(script);

    script.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.easyPack.init({});

      if (callback) callback();
    };

    script.onerror = error => {
      if (errorCallback) errorCallback(error);
    };
  }

  callback(true);

  if (!stylesheetAlreadyExisting) {
    const stylesheet = document.createElement('link');
    stylesheet.setAttribute('rel', 'stylesheet');
    stylesheet.setAttribute(
      'href',
      'https://geowidget.easypack24.net/css/easypack.css'
    );

    document.head.appendChild(stylesheet);
  }
};

const getAddressesFromSubscription = (sub: UserSubscriptionType) => {
  if (sub.address.shipping && sub.address.shipping.shipping_kind === 'INPOST') {
    return {
      courier: null,
      inpost: {...sub.address.shipping, is_picked: true}
    };
  }
  return {
    courier: {...sub.address.shipping, is_picked: true} || null,
    inpost: null
  };
};

export const DeliverySettings: React.FC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [shouldInpostBeVisible, setShouldInpostBeVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [selectedDeliveryForm, setSelectedDeliveryForm] = useState<
    'COURIER' | 'INPOST' | undefined
  >();

  const [billing, setBilling] = useState<AddressType | undefined>();

  const [sdkLoaded, setSdkLoaded] = React.useState(false);
  const [modal, setModal] = React.useState(false);

  const [selectedCountry, setSelectedCountry] = useState('PL'); // to ensure that country is always set
  const [isAddressFormVisible, setIsAddressFormVisible] = useState(false);

  const [addresses, setAddresses] = useState<AddressesType>({
    courier: null,
    inpost: null
  });

  const [subscriptionAddress, setSubscriptionAddress] = useState<AddressesType>(
    {
      courier: null,
      inpost: null
    }
  );

  const {
    settings: {subscriber},
    user,
    subscription
  } = useSelector((state: any) => state.userDataReducer);

  const courier = selectedDeliveryForm === 'COURIER';
  const inpost = selectedDeliveryForm === 'INPOST';

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!sdkLoaded) {
      loadInPostSDK(setSdkLoaded, console.warn);
    }
  }, []);

  const setDefaultAddress = (el: AddressType | undefined) => {
    if (el) {
      setSelectedDeliveryForm(el.shipping_kind);
    }
  };

  useEffect(() => {
    if (shouldFetchData) {
      setLoading(true);

      const onFinally = () => {
        setLoading(false);
        setShouldFetchData(false);
      };

      getAllShippingData(user.get('user'))
        .then(data => {
          const a = pick(data, ['courier', 'inpost']);
          setBilling(data.billing);
          setAddresses(a);

          if (!subscriber) {
            setShouldInpostBeVisible(true);
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setDefaultAddress(pickDefaultAddress(data));
        })
        .finally(onFinally);

      if (subscriber) {
        fetchUserSubscriptions()
          .then(res => {
            const sub = res[0];
            dispatch(setUserSubscription(sub));
            const data = getAddressesFromSubscription(sub);

            setShouldInpostBeVisible(
              sub.last_order.prescription_country_norms_code === 'pl'
            );

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setSubscriptionAddress(data);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setDefaultAddress(pickDefaultAddress(data));
          })
          .finally(onFinally);
      }

      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [shouldFetchData]);

  const populateCourierForm = () => {
    if (subscriber) {
      form.setFieldsValue({
        ...pick(subscription.address.billing, [
          'first_name',
          'last_name',
          'country',
          'phone',
          'email'
        ]),
        ...pick(subscription.address.shipping, [
          'first_name',
          'last_name',
          'country',
          'phone',
          'email'
        ])
      });
    } else {
      form.setFieldsValue({
        ...pick(billing, [
          'first_name',
          'last_name',
          'country',
          'phone',
          'email'
        ])
      });
    }

    const courierAddress =
      subscriber && subscriptionAddress.courier?.address_1
        ? subscriptionAddress.courier
        : addresses.courier;

    if (courierAddress) {
      form.setFieldsValue({
        ...courierAddress,
        ...addressSplitter(courierAddress.address_1)
      });
    }

    const fallbackCountry = subscriber
      ? subscription.address.shipping.country ||
      subscription.address.billing.country
      : billing?.country;

    setSelectedCountry(courierAddress?.country || fallbackCountry);

    setIsAddressFormVisible(true);
  };

  const handlePointChange = (point: any) => {
    let requestData: AddressType = {
      email: '',
      phone: '',
      city: '',
      country: '',
      first_name: '',
      last_name: '',
      postcode: '',
      address_1: '',
      updated_at: ''
    };

    if (subscriber) {
      requestData = {
        ...subscription.address.billing,
        ...subscription.address.shipping
      };
    }

    requestData = {
      ...requestData,
      target_point: point.name,
      address_1: point.address.line1,
      postcode: point.address.line2.split(' ')[0],
      city: point.address.line2.split(' ')[1]
    };

    const body = {
      ...requestData,
      shipping_kind: 'INPOST',
      is_picked: true,
      country: 'PL'
    };

    const updateFunction = () => {
      return subscriber
        ? updateSubscriptionAddress(
          subscription.id,
          subscription.address.billing,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          body
        )
        : updateInPostShippingData(user.get('user'), body);
    };

    updateFunction()
      .then(() => {
        let eventBody = {};

        if (subscriber) {
          eventBody = {...eventBody, subscription_id: subscription?.id};
        }

        pushToDataLayer('delivery_address_edit', eventBody);

        notification.success({
          message: t('address-updated'),
          className: 'notification-style-success'
        });
      })
      .catch(() => {
        notification.error({
          message: t('unexpected-error'),
          className: 'notification-style-error'
        });
      })
      .finally(() => {
        if (subscriber) {
          updateInPostShippingData(user.get('user'), body);
        }
        setShouldFetchData(true);

        setIsAddressFormVisible(false);
      });
    closeInPostMap();
  };

  const closeInPostMap = () => {
    setModal(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById('inpost-map-inner').innerHTML = '';
  };

  const openInPostMap = () => {
    setModal(true);
    loadInpostMap();
  };

  const loadInpostMap = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.easyPack.mapWidget('inpost-map-inner', handlePointChange);
  };

  const getModalWidth = (): number => {
    const iw = window.innerWidth;

    if (iw <= 600) return iw;
    if (iw > 600 && iw < 1000) return iw * 0.75;
    return 1000;
  };

  const checkHasFilledAddress = (address: AddressType | null) => {
    if (!address) {
      return false;
    }
    const requiredFields = ['address_1', 'city', 'country', 'postcode'];
    for (const [key, value] of Object.entries(address)) {
      if (requiredFields.includes(key) && !value) {
        return false;
      }
    }
    return true;
  };

  const submitCourierForm = () => {
    const values = {
      ...form.getFieldsValue(),
      country: selectedCountry
    };
    values.address_1 = `${values.street} ${values.building_number}${
      values.flat_number ? '/' + values.flat_number : ''
    }`;

    setAddresses({
      courier: values,
      inpost: addresses.inpost
    });

    const body = {
      ...values,
      shipping_kind: 'COURIER',
      is_picked: true
    };

    const updateFunction = () => {
      return subscriber
        ? updateSubscriptionAddress(
          subscription.id,
          subscription.address.billing,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          body
        )
        : updateShippingData(user.get('user'), body);
    };

    updateFunction()
      .then(() => {
        let eventBody = {};

        if (subscriber) {
          eventBody = {...eventBody, subscription_id: subscription?.id};
        }

        pushToDataLayer('delivery_address_edit', eventBody);

        notification.success({
          message: t('address-updated'),
          className: 'notification-style-success'
        });

        if (subscriber) {
          updateShippingData(user.get('user'), body);
        }
        setShouldFetchData(true);
        setIsAddressFormVisible(false);
      })
      .catch(() => {
        notification.error({
          message: t('unexpected-error'),
          className: 'notification-style-error'
        });
      });
  };

  const updateDefaultShippingMethod = (method: 'COURIER' | 'INPOST') => {
    setLoading(true);
    setSelectedDeliveryForm(method);
    updateShippingAddress(method);
  };

  const updateShippingAddress = (
    method: 'COURIER' | 'INPOST' | null = null
  ) => {
    const deliveryForm = method ?? selectedDeliveryForm;

    let updateFunction;

    const courierShippingAddress =
      subscriber && courier ? subscriptionAddress.courier : addresses.courier;
    const inpostShippingAddress =
      subscriber && inpost ? subscriptionAddress.inpost : addresses.inpost;

    if (deliveryForm === 'INPOST') {
      updateFunction = () => {
        const body = {
          ...inpostShippingAddress,
          shipping_kind: 'INPOST',
          is_picked: true,
          country: 'PL'
        };

        return subscriber
          ? updateSubscriptionAddress(
            subscription.id,
            subscription.address.billing,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            body
          )
          : updateInPostShippingData(user.get('user'), body);
      };
    } else {
      updateFunction = () => {
        const body = {
          ...courierShippingAddress,
          shipping_kind: 'COURIER',
          is_picked: true
        };

        return subscriber
          ? updateSubscriptionAddress(
            subscription.id,
            subscription.address.billing,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            body
          )
          : updateShippingData(user.get('user'), body);
      };
    }

    updateFunction()
      .then(() => {
        let eventBody = {};

        if (subscriber) {
          eventBody = {...eventBody, subscription_id: subscription?.id};
        }

        pushToDataLayer('delivery_address_edit', eventBody);

        notification.success({
          message: t('delivery-method-updated'),
          className: 'notification-style-success'
        });
      })
      .catch(() => {
        notification.error({
          message: t('unexpected-error'),
          className: 'notification-style-error'
        });
      })
      .finally(() => {
        setShouldFetchData(true);
        setLoading(false);
      });
  };

  const addressCourier =
    subscriber && courier ? subscriptionAddress.courier : addresses.courier;

  const addressInpost =
    subscriber && inpost ? subscriptionAddress.inpost : addresses.inpost;

  const countries = useCountryDescriptions();

  const getCountryName = (isoKey: string): string =>
    Object.values(countries.toJS()).find(c => c.isoKey === isoKey)
      ?.displayName || isoKey;

  return (
    <>
      <SettingsContentHeader
        addSaveButton={null}
        btnText={null}
        title={t('delivery-address')}
        isSubscriptionActive={false}
        isSusbcriptionSuspended={false}
        isSubscriptionPage={false}
        goBackFunction={
          isAddressFormVisible
            ? () => setIsAddressFormVisible(false)
            : undefined
        }
      />
      <div className="delivery-settings">
        {isLoading ? (
          <Spin/>
        ) : (
          <>
            {isAddressFormVisible ? (
              <CourierForm
                form={form}
                submitForm={submitCourierForm}
                subscriber={subscriber}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                isPending={isLoading}/>
            ) : (
              <>
                {subscriber ? (
                  <div className="personalise-banner-container">
                    {t('you-have-active-subscription')}
                  </div>
                ) : null}

                <Row>
                  <Col
                    xs={24}
                    className={`delivery-container ${
                      courier ? 'box-active' : ''
                    }`}
                  >
                    <div style={{display: 'flex'}}>
                      {checkHasFilledAddress(addressCourier) ? (
                        <div>
                          <Radio
                            checked={courier}
                            disabled={!checkHasFilledAddress(addressCourier)}
                            onClick={() =>
                              updateDefaultShippingMethod('COURIER')
                            }
                          />
                        </div>
                      ) : null}
                      <div className="delivery-info">
                        <div
                          style={{
                            cursor: !checkHasFilledAddress(addressCourier)
                              ? 'not-allowed'
                              : 'pointer'
                          }}
                          onClick={() =>
                            !checkHasFilledAddress(addressCourier)
                              ? undefined
                              : updateDefaultShippingMethod('COURIER')
                          }
                        >
                          <h2>{t('dhl-description')}</h2>
                          {/*<span>{t('delivery-time')}</span>*/}
                          {/*<span>*/}
                          {/*  {t('cost')}: {t('delivery-price')}*/}
                          {/*</span>*/}
                        </div>

                        {addressCourier &&
                        checkHasFilledAddress(addressCourier) ? (
                          <>
                            <h3>{t('delivery-address')}</h3>
                            <p>
                              {addressCourier.first_name}{' '}
                              {addressCourier.last_name}
                            </p>
                            <p>{addressCourier.address_1}</p>
                            <p>
                              {addressCourier.postcode} {addressCourier.city}
                            </p>
                            <p>{getCountryName(addressCourier.country)}</p>
                          </>
                        ) : checkHasFilledAddress(addressInpost) ? null : (
                          <p
                            style={{
                              color: '#EC2A18',
                              display: checkHasFilledAddress(addressCourier)
                                ? 'none'
                                : 'block'
                            }}
                          >
                            {t('need-to-fill-address-first')}
                          </p>
                        )}

                        <Button
                          type="primary"
                          shape="round"
                          // loading={true}
                          onClick={populateCourierForm}
                        >
                          {t(
                            checkHasFilledAddress(addressCourier)
                              ? 'change'
                              : 'add-delivery-address'
                          )}
                        </Button>
                      </div>
                    </div>
                    <div>
                      <img src={DhlLogo} className="dhl-logo" alt="DHL Logo"/>
                    </div>
                  </Col>
                  {shouldInpostBeVisible ? (
                    <Col
                      xs={24}
                      className={`delivery-container ${
                        inpost ? 'box-active' : ''
                      }`}
                    >
                      <div style={{display: 'flex'}}>
                        {addressInpost?.target_point ? (
                          <div>
                            <Radio
                              checked={inpost}
                              disabled={!addressInpost?.target_point}
                              onClick={() =>
                                !addressInpost?.target_point
                                  ? undefined
                                  : updateDefaultShippingMethod('INPOST')
                              }
                            />
                          </div>
                        ) : null}
                        <div
                          className={`delivery-info ${
                            !addressInpost?.target_point ? 'disabled' : ''
                          }`}
                        >
                          <div
                            style={{
                              cursor: !addressInpost?.target_point
                                ? 'not-allowed'
                                : 'pointer'
                            }}
                            onClick={() =>
                              addressInpost?.target_point
                                ? updateDefaultShippingMethod('INPOST')
                                : undefined
                            }
                          >
                            <h2>{t('inpost-description')}</h2>
                            {/*<span>{t('delivery-time')}</span><br/>*/}
                            {/*<span>*/}
                            {/*  {t('cost')}: {t('delivery-price')}*/}
                            {/*</span>*/}
                          </div>
                          {addressInpost?.target_point ? (
                            <>
                              <h3>{t('selected-inpost-point')}</h3>
                              <p>
                                {t('inpost-point')} {addressInpost.target_point}
                              </p>
                              <p>
                                {addressInpost.address_1},{' '}
                                {addressInpost.postcode} {addressInpost.city}
                              </p>
                              <p>{addressInpost.address_2}</p>
                            </>
                          ) : checkHasFilledAddress(addressCourier) ? null : (
                            <p
                              style={{
                                color: '#EC2A18',
                                display: addressInpost?.target_point
                                  ? 'none'
                                  : 'block'
                              }}
                            >
                              {t('need-to-select-target-point-first')}
                            </p>
                          )}

                          <Button
                            type="primary"
                            shape="round"
                            onClick={openInPostMap}
                          >
                            {t(
                              addressInpost?.target_point
                                ? 'change'
                                : 'select-target-point'
                            )}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <img
                          src={InPostLogo}
                          className="inpost-logo"
                          alt={'InPost Logo'}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>

                <Modal
                  width={getModalWidth()}
                  visible={modal}
                  wrapClassName="delivery-modal"
                  bodyStyle={{
                    padding: 20,
                    height: 650,
                    boxShadow: '0 2px 7px 0 #00000020'
                  }}
                  maskClosable
                  centered
                  footer={null}
                  onCancel={closeInPostMap}
                >
                  <div id="inpost-map" style={{padding: '17px'}}><div id="inpost-map-inner"></div></div>
                </Modal>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
