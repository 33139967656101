import React from 'react';
import { useTranslation } from 'react-i18next';
import './certificates-inline.scss';

export const CertificatesInline = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={`certificates-inline ${className}`}>
      <div className="certificates-inline-text">
        {t('certificates-inline-warranty')}
      </div>
      <img
        src="/app/quality-certificates@2x.png"
        alt="Quality certificates logos"
        title="Vegan, IFS, BRCGS quality certificates. Healthy Brand of the Year"
      />
    </div>
  );
};

CertificatesInline.defaultProps = {
  className: ''
};
