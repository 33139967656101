import { CollapseHeader } from '../Collapse';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import EyeIcon from '../../assets/reactIcons/eyeIcon';

const CollapseOrderHeader = ({ order }) => {
  const { t } = useTranslation();
  const prescriptionId = order.toJS().order_items[0].prescription_id;

  let extraButton = null;

  const showExtraButton =
    order.get('prescription_id', null) !== null &&
    !order.get('is_ecomm', false);

  if (showExtraButton) {
    extraButton = (
      <Link to={`${prescriptionId}/details`}>
        <Button type="primary">
          <Icon component={EyeIcon} />
          {t('info')}
        </Button>
      </Link>
    );
  }

  return (
    <CollapseHeader
      subtitle={<Trans i18nKey="order-subtitle">{{ prescriptionId }}</Trans>}
      extraButton={extraButton}
    />
  );
};

export default CollapseOrderHeader;
