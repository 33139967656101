import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMixerData } from './actions';

const LoadMixerDataOnInit = ({ children }) => {
  const wasInitialized = useSelector(
    state => state.panelMixerData.wasInitialized
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!wasInitialized) {
      dispatch(loadMixerData());
    }
  }, [dispatch, wasInitialized]);

  return <>{children}</>;
};

export default LoadMixerDataOnInit;
