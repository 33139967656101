import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Modal, Radio } from 'antd';

import CloseIcon from '../../../../../../assets/img/close.svg';
import moment from 'moment';

interface SuspendSubscriptionModalProps {
  isOpen: boolean;
  nextOrderDate: any;
  usedSuspensions: number;
  nextSuspendDate?: string;
  onSubmit: (formValues: number) => void;
  onCancel: () => void;
  openChangeDatesModal: () => void;
  currentDiscount: number;
}

const SuspendSubscriptionModal: React.FC<SuspendSubscriptionModalProps> = ({
  isOpen,
  nextOrderDate,
  usedSuspensions,
  nextSuspendDate,
  onSubmit,
  onCancel,
  openChangeDatesModal,
  currentDiscount
}) => {
  const [checkedDuration, setCheckedDuration] = useState(0);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleChangeDate = () => {
    onCancel();
    openChangeDatesModal();
  };

  useEffect(() => {
    if (isOpen) {
      // fetchCancellationReasons().then(setReasons);
    }
  }, [isOpen]);

  const onFormSubmit = () => {
    onSubmit(checkedDuration);
  };

  return (
    <Modal
      width={600}
      visible={isOpen}
      wrapClassName="subscription-modal"
      bodyStyle={{
        padding: 30,
        boxShadow: '0 2px 7px 0 #00000020'
      }}
      maskClosable
      centered
      footer={null}
      onCancel={onCancel}
      closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
    >
      <h2>{t('suspend-subscription')}</h2>
      <p style={{ marginBottom: 25 }}>
        <Trans
          i18nKey="subscription-suspend-description"
          values={{
            currentDiscount: currentDiscount * 100
          }}
          components={{
            changeDateModal: (
              <span className="text-link" onClick={handleChangeDate} />
            )
          }}
        />
      </p>

      {usedSuspensions > 0 ? (
        <div className="alert">
          <p>
            {t(`subscription-suspend-used-${usedSuspensions}`, {
              nextSuspendDate: moment(nextSuspendDate).format('DD.MM.YYYY')
            })}
          </p>
        </div>
      ) : null}

      <strong style={{ marginTop: 10, marginBottom: 10 }}>
        {t('subscription-suspend-for-label')}
      </strong>

      <Form
        form={form}
        className="ant-form ant-form-vertical ant-form-hide-required-mark padding-10"
        onFinish={onFormSubmit}
      >
        <Form.Item valuePropName="checked" className="checkbox-item">
          <div className="suspend-times">
            <Radio
              className="custom-radio-button"
              checked={checkedDuration === 1}
              onClick={() => setCheckedDuration(1)}
              disabled={usedSuspensions >= 3}
            >
              {t('subscription-suspend-for-1', {
                nextPaymentDate: nextOrderDate
                  .clone()
                  .add('1', 'month')
                  .format('DD.MM.YYYY')
              })}
            </Radio>
            <Radio
              className="custom-radio-button"
              checked={checkedDuration === 2}
              onClick={() => setCheckedDuration(2)}
              disabled={usedSuspensions >= 2}
            >
              {t('subscription-suspend-for-2', {
                nextPaymentDate: nextOrderDate
                  .clone()
                  .add('2', 'month')
                  .format('DD.MM.YYYY')
              })}
            </Radio>
            <Radio
              className="custom-radio-button"
              checked={checkedDuration === 3}
              onClick={() => setCheckedDuration(3)}
              disabled={usedSuspensions >= 1}
            >
              {t('subscription-suspend-for-3', {
                nextPaymentDate: nextOrderDate
                  .clone()
                  .add('3', 'month')
                  .format('DD.MM.YYYY')
              })}
            </Radio>
          </div>
        </Form.Item>

        <Form.Item>
          <Row style={{ marginTop: '25px' }}>
            <Col xs={24} className="col-padding">
              <Button
                htmlType="submit"
                disabled={checkedDuration === 0}
                type="primary"
                shape="round"
                block
                size="large"
              >
                {t('suspend-subscription-button')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SuspendSubscriptionModal;
