import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';

import CloseIcon from '../../../../../../assets/img/close.svg';
import moment from 'moment';

interface RenewSubscriptionModalProps {
  isOpen: boolean;
  nextOrderDate: any;
  usedSuspensions: number;
  onSubmit: () => void;
  onCancel: () => void;
}

const RenewSubscriptionModal: React.FC<RenewSubscriptionModalProps> = ({
  isOpen,
  nextOrderDate,
  usedSuspensions,
  onSubmit,
  onCancel
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={600}
      visible={isOpen}
      wrapClassName="subscription-modal"
      bodyStyle={{
        padding: 30,
        boxShadow: '0 2px 7px 0 #00000020'
      }}
      maskClosable
      centered
      footer={null}
      onCancel={onCancel}
      closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
    >
      <h2>{t('subscription-renew')}</h2>
      <p>
        {t(`subscription-renew-description-${usedSuspensions}`, {
          nextPaymentDate: nextOrderDate
            .clone()
            .startOf('day')
            .format('DD.MM.YYYY'),
          daysUntilPayment: nextOrderDate
            .startOf('day')
            .diff(moment().startOf('day'), 'days')
        })}
      </p>
      <div className="alert info-alert">
        <p>
          {t(`subscription-renew-infobox-1`, {
            tomorrowDate: moment().add(1, 'day').format('DD.MM.YYYY')
          })}
        </p>
        <p className={'p-last'}>{t(`subscription-renew-infobox-2`)}</p>
      </div>
      <Button
        type="primary"
        shape="round"
        block
        size="large"
        onClick={onSubmit}
      >
        {t('subscription-renew-button')}
      </Button>
    </Modal>
  );
};

export default RenewSubscriptionModal;
