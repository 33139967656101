import { actionNamesPanelAction } from '../utils/constants';
import { Map } from 'immutable';

const { SAVE_URL_PARAMS } = actionNamesPanelAction;

const initialState = {
  urlParams: Map({
    redirect: undefined,
    external: undefined
  })
};

const panelActionReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case SAVE_URL_PARAMS: {
      return {
        ...state,
        urlParams: Map(data)
      };
    }

    default:
      return state;
  }
};

export default panelActionReducer;
