import { useTranslation } from 'react-i18next';
import { Map } from 'immutable';
import { ORDER_PROXY_STATUSES } from '../../utils';

export type OrderProxyStatusDefinition = {
  description: string;
  className: string;
  timelineOrder: number;
  hideIcons: boolean;
};

export type OrderStatuses = Map<string, OrderProxyStatusDefinition>;

const useOrderStatus = (isInpost = false): OrderStatuses => {
  const { t } = useTranslation();
  const statusMap = {
    [ORDER_PROXY_STATUSES.ORDER_PLACED]: {
      description: t('order-pending'),
      className: 'order-status order-pending',
      timelineOrder: 1,
      hideIcons: false
    },
    [ORDER_PROXY_STATUSES.PAYMENT_ACCEPTED]: {
      description: t('order-processing'),
      className: 'order-status order-processing',
      timelineOrder: 2,
      hideIcons: false
    },
    [ORDER_PROXY_STATUSES.PRESCRIPTION_VERIFIED]: {
      description: t('order-production'), // Step selected on Popover by description
      className: 'order-status order-production',
      timelineOrder: 0, // 0 = not listed on Steps Popover
      hideIcons: false
    },
    [ORDER_PROXY_STATUSES.IN_MANUFACTURING]: {
      description: t('order-production'),
      className: 'order-status order-production',
      timelineOrder: 3,
      hideIcons: false
    },
    [ORDER_PROXY_STATUSES.WAITING_FOR_COURIER]: {
      description: t('order-waiting-for-courier'),
      className: 'order-status order-waiting-for-courier',
      timelineOrder: 4,
      hideIcons: false
    },
    [ORDER_PROXY_STATUSES.IN_DELIVERY]: {
      description: t('order-completed'),
      className: 'order-status order-completed',
      timelineOrder: 5,
      hideIcons: false
    },
    [ORDER_PROXY_STATUSES.DELIVERED]: {
      description: t('order-delivered'),
      className: 'order-status order-delivered',
      timelineOrder: 6,
      hideIcons: false
    },

    [ORDER_PROXY_STATUSES.RETURNED_TO_SENDER]: {
      description: t('order-returned-to-sender'),
      className: 'order-status order-returned-to-sender',
      timelineOrder: 0,
      hideIcons: true
    },
    [ORDER_PROXY_STATUSES.ORDER_CANCELLED]: {
      description: t('order-cancelled'),
      className: 'order-status order-cancelled',
      timelineOrder: 0,
      hideIcons: true
    },
    [ORDER_PROXY_STATUSES.ORDER_REFUNDED]: {
      description: t('order-refunded'),
      className: 'order-status order-refunded',
      timelineOrder: 0,
      hideIcons: true
    },
    [ORDER_PROXY_STATUSES.PAYMENT_FAILED]: {
      description: t('order-failed'),
      className: 'order-status order-failed',
      timelineOrder: 0,
      hideIcons: true
    }
  };

  if (isInpost) {
    statusMap[ORDER_PROXY_STATUSES.READY_TO_PICKUP] = {
      description: t('order-ready-to-pickup'),
      className: 'order-status order-ready-to-pickup',
      timelineOrder: 6,
      hideIcons: false
    };
    statusMap[ORDER_PROXY_STATUSES.DELIVERED].timelineOrder = 7;
  }

  return Map(statusMap);
};

export default useOrderStatus;
