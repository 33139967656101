import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import logoIcon from '../assets/img/new-logo.svg';
import { useTranslation } from 'react-i18next';
import { routeMainPaths } from '../utils/constants';
import { signOut as signOutAction } from '../views/auth/actions';
import { useDispatch } from 'react-redux';

const { DASHBOARD, COMPOSITION_PRESCRIPTION, OFFER, SETTINGS } = routeMainPaths;

const Header = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const signOut = useCallback(() => {
    dispatch(signOutAction());
  }, [dispatch]);

  return (
    <div className="wrapper">
      <div className="header">
        <div className="branding">
          <img className="logo" src={logoIcon} alt="logo" />
          <div className="header-certificates">
            
          </div>
        </div>
        <div className="nav">
          <ul>
            <li className="menu-item">
              <NavLink to={DASHBOARD} activeClassName="active">
                <span className="icon icon-dashboard" />
                <b className="menu-item-name">{t('dashboard')}</b>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink to={COMPOSITION_PRESCRIPTION} activeClassName="active">
                <span className="icon icon-composition" />
                <b className="menu-item-name">{t('components')}</b>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink to={OFFER} activeClassName="active">
                <span className="icon icon-affilate" />
                <b className="menu-item-name">{t('offer')}</b>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink to={SETTINGS} activeClassName="active">
                <span className="icon icon-account" />
                <b className="menu-item-name">{t('settings')}</b>
              </NavLink>
            </li>
            <li className="menu-item">
              <button type="button" onClick={signOut}>
                <span className="icon icon-logout" />
                <b className="menu-item-name">{t('sign-out')}</b>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
