import React from 'react';
import './info-box.scss';
import { Button, Row, Spin } from 'antd';
import * as PropTypes from 'prop-types';

export const InfoBox = ({ children, bottomButton, loading }) => {
  return (
    <div className="info-box">
      <Spin spinning={loading}>
        <Row type="flex" className="content-wrapper">
          {children}
        </Row>
        {bottomButton && (
          <Row type="flex" justify="end">
            <Button
              type="primary"
              ghost
              className="right-button"
              {...bottomButton}
            >
              {bottomButton.text}
            </Button>
          </Row>
        )}
      </Spin>
    </div>
  );
};

InfoBox.propTypes = {
  bottomButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func
  }),
  loading: PropTypes.bool
};

InfoBox.defaultProps = {
  loading: false
};
