import React, { Component } from 'react';
import SurveyContext from '../../SurveyContext';
import { withTranslation } from 'react-i18next';
import { List } from 'immutable';
import selectedIcon from './../../assets/img/seleted.svg'
import notSelectedIcon from './../../assets/img/no-selected.svg'

class FormMulti extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);
    this.state = {
      questionOptionAnswers: []
    };
  }

  componentDidMount() {
    const {
      questionIndex,
      questionIds,
      questions,
      answeredQuestions
    } = this.context;

    const questionId = questionIds[questionIndex];
    const question = questions[questionId];
    const {
      questionOption: { questionOptionAnswers }
    } = question;

    const currentQuestionAnswers = answeredQuestions.get(questionId, null);
    const data = questionOptionAnswers.reduce((acc, answer, index) => {
      let answeredQuestionOption = currentQuestionAnswers
        ? currentQuestionAnswers.questionOptionAnswers.find(
            n => n.id === answer.id
          )
        : undefined;
      acc[index] = {
        ...answer,
        selected: !!answeredQuestionOption
      };

      return acc;
    }, []);

    this.setState({
      questionOptionAnswers: data
    });
  }

  isOptionSelected = (answer, index, optionAnswerIndex) => {
    if (index === optionAnswerIndex) {
      return !answer.selected;
    }

    return answer.selected;
  };

  isNoneAnyOfAboveOption = answer => {
    return !!answer.localizedValue.find(element => {
      const { language, value } = element;
      const plTranslation = ['pl', 'pl_PL'].includes(language.toLowerCase());
      const isNoneOption = [
        'żadne z powyższych',
        'żadne z wymienionych'
      ].includes(value.toLowerCase());

      return plTranslation && isNoneOption;
    });
  };

  toggleOption = optionAnswerIndex => {
    const { questionOptionAnswers } = this.state;

    let noneOfAboveIndex = null;
    let shouldDeselect = null;
    const data = questionOptionAnswers.reduce((acc, answer, index) => {
      if (this.isNoneAnyOfAboveOption(answer)) {
        noneOfAboveIndex = index;
        if (index === optionAnswerIndex) {
          shouldDeselect = true;
        }
      }
      const selected = this.isOptionSelected(answer, index, optionAnswerIndex);

      acc[index] = {
        ...answer,
        selected
      };

      return acc;
    }, []);

    if (shouldDeselect) {
      const noneOfAboveAnswer = data[noneOfAboveIndex];
      if (noneOfAboveAnswer.selected === true) {
        data.forEach((element, index) => {
          if (index !== noneOfAboveIndex) {
            element.selected = false;
          }
        });
      }
    }

    if (!shouldDeselect && noneOfAboveIndex) {
      data[noneOfAboveIndex].selected = false;
    }

    this.setState({
      questionOptionAnswers: data
    });
  };

  filterSelectedOptions = questionOptionAnswers =>
    questionOptionAnswers.filter(answer => answer.selected);

  getQuestionOptionAnswersIds = questionOptionAnswers =>
    questionOptionAnswers.reduce((acc, answer, index) => {
      acc[index] = {
        id: answer.id
      };

      return acc;
    }, []);

  submitAnswer = () => {
    const { questionOptionAnswers } = this.state;
    const { setOptionAnswers } = this.context;

    const filteredOptionAnswers = this.filterSelectedOptions(
      questionOptionAnswers
    );
    const questionOptionAnswersIds = this.getQuestionOptionAnswersIds(
      filteredOptionAnswers
    );

    if (questionOptionAnswersIds.length === 0) return;

    setOptionAnswers(questionOptionAnswersIds);
  };

  render() {
    const { questionOptionAnswers } = this.state;
    const { t } = this.props;

    const sortedOptions = List(questionOptionAnswers).sortBy(
      val => val.position
    );
    const answersElements = sortedOptions.map((answer, index) => (
      <div className="form-answer" key={answer.id}>
        <button
          onClick={this.toggleOption.bind(this, index)}
          type="button"
          value={answer.value}
          className={`new-btn-full ${
            answer.selected ? 'checked' : 'not-checked'
          }`}
        >
          <div className="multi-wrap">
            <div className="multi-check">
              {answer.selected
                ? <img style={{width: '20px', height: '20px'}} src={selectedIcon} alt="Selected ..." />
                : <img style={{width: '20px', height: '20px'}} src={notSelectedIcon} alt="Not Selected ..." />
              }
            </div>
            <div className="multi-text">
              <span className="text">{answer.value}</span>
            </div>
          </div>
        </button>
      </div>
    ));

    return (
      <div>
        <div className="wrapper">
          {answersElements}
        </div>
        <div className="multi-btn">
          <button
            type="button"
            className="btn btn-confirm new-send-answers"
            onClick={this.submitAnswer}
          >
            {t('send-answers')} →
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormMulti);
