import React, { useMemo, useState } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { fromJS, Map } from 'immutable';
import {
  FORM_VALIDATION_STATES,
  sortValidationRules,
  ValidationHelp
} from './PasswordFormItemWithValidation';
import { PasswordInput } from '../index';

const initialValidation = {
  samePassword: {
    state: FORM_VALIDATION_STATES.NOT_TOUCHED
  }
};

const RepeatPasswordFormItemWithValidation = ({
  name,
  label,
  basePasswordName
}) => {
  const [t] = useTranslation();
  const [validation, setValidation] = useState(fromJS(initialValidation));

  const updateValidationState = newState => {
    setValidation(prev =>
      prev.updateIn(['samePassword', 'state'], () => newState)
    );
  };

  const formLabel = useMemo(() => {
    if (typeof label === 'undefined') {
      return t('Password');
    }
    return label;
  }, [label, t]);

  const texts = useMemo(() => {
    return Map({
      samePassword: t('be same as New Password')
    });
  }, [t]);

  return (
    <Form.Item
      name={name}
      validateTrigger="onChange"
      label={formLabel}
      dependencies={[basePasswordName]}
      rules={[
        {
          required: true
        },
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (
              typeof value !== 'undefined' &&
              getFieldValue(basePasswordName) === value
            ) {
              updateValidationState(FORM_VALIDATION_STATES.SUCCESS);
              return Promise.resolve();
            }
            updateValidationState(FORM_VALIDATION_STATES.ERROR);
            return Promise.reject('');
          }
        })
      ]}
      help={<ValidationHelp validation={validation} texts={texts} />}
      validateStatus={sortValidationRules(validation).first().get('state')}
    >
      <PasswordInput />
    </Form.Item>
  );
};

RepeatPasswordFormItemWithValidation.propTypes = {
  name: string.isRequired,
  basePasswordName: string.isRequired,
  label: string
};

export default RepeatPasswordFormItemWithValidation;
