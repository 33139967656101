import React from 'react';
import { InfoBox } from '../InfoBox';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import './prescription-preferences-box.scss';
import { settingsRouteKeys } from '../../utils/constants';
import warningIcon from '../../../assets/img/warning.svg';
import { useHistory, useRouteMatch } from 'react-router';
import * as PropTypes from 'prop-types';
import MixerPreferences from './MixerPreferences';

const { PRODUCT } = settingsRouteKeys;

export const PrescriptionPreferencesBox = ({
  preferencesDefs,
  preferencesData,
  flavour,
  bottomButton,
  loading
}) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const baseUrl = url.substring(0, url.indexOf('/panel'));

  if (loading) {
    return (
      <div className="prescription-preferences-box">
        <InfoBox loading={loading} />
      </div>
    );
  }

  if (!preferencesData || !flavour) {
    return (
      <div className="prescription-preferences-box prescription-preferences-box-empty-state">
        <InfoBox>
          <Row type="flex" justify="space-around" align="middle">
            <div className="icon-row">
              <img alt="alert" src={warningIcon} />
            </div>
            <h1>{t('prescription-preferences-box-empty-title')}</h1>
          </Row>
        </InfoBox>
      </div>
    );
  }

  return (
    <div className="prescription-preferences-box">
      <InfoBox bottomButton={bottomButton}>
        <Row type="flex" className="prescription-preferences-box-content">
          <Col xs={24} sm={24}>
            <h1>{t('prescription-preferences-title')}</h1>
            <MixerPreferences
              dataSource={preferencesDefs}
              preferencesData={preferencesData}
            />
          </Col>

          <Col xs={24} sm={{ span: 24 }} className="flavour-column" style={{paddingTop: '30px'}}>
            <h1>{t('flavour')}</h1>
            <h4 className="flavour-header">{flavour.name_translation}</h4>
          </Col>
        </Row>
      </InfoBox>
    </div>
  );
};

PrescriptionPreferencesBox.propTypes = {
  preferencesDefs: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  preferencesData: PropTypes.arrayOf(PropTypes.string),
  flavour: PropTypes.instanceOf(Object),
  bottomButton: PropTypes.instanceOf(Object),
  loading: PropTypes.bool
};
