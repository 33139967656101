import React, { createContext, useCallback, useState } from 'react';
import * as dataService from './dataService';

export const CalendarContext = createContext(null);

const normalizeCalendarData = data => {
  return {
    ...data,
    sachetsAmount:
      data.sachetsAmount !== null ? Number(data.sachetsAmount) : null
  };
};

export const CalendarProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [calendarData, setCalendarData] = useState({
    firstDate: null,
    sachetsAmount: null,
    datesTaken: []
  });
  const [isCalendarEmpty, setIsCalendarEmpty] = useState(false);

  const createCalendar = useCallback(
    async (sachetsAmount, firstDate) => {
      setIsLoading(true);

      await dataService.createCalendar(sachetsAmount, firstDate);

      const { datesTaken } = calendarData;
      const a = !!datesTaken.find(d => d === firstDate);
      const isDateTaken = a || (await dataService.saveCalendarDate(firstDate));

      if (isDateTaken) {
        const data = await dataService.getCalendarDates();
        setCalendarData(normalizeCalendarData(data));
        setIsCalendarEmpty(!data.sachetsAmount);
      }

      setIsLoading(false);
    },
    [isLoading]
  );

  const saveCalendarDate = useCallback(async date => {
    setIsLoading(true);
    await dataService.saveCalendarDate(date);

    const data = await dataService.getCalendarDates();
    setCalendarData(normalizeCalendarData(data));
  }, []);

  const getCalendarDates = useCallback(async () => {
    const data = await dataService.getCalendarDates();

    setCalendarData(normalizeCalendarData(data));

    setIsCalendarEmpty(!data.sachetsAmount);
  }, []);

  return (
    <CalendarContext.Provider
      value={{
        // Data
        isLoading,
        calendarData,
        isCalendarEmpty,

        // Actions
        createCalendar,
        getCalendarDates,
        setIsCalendarEmpty,
        saveCalendarDate
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};
