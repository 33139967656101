import { Map } from 'immutable';
import { actionNamesMixer } from '../utils/constants';

const { SET_MIXER_INITIALIZED, LOAD_DATA } = actionNamesMixer;

const initialState = {
  substances: Map(),
  units: Map(),
  substance_types: Map(),
  wasInitialized: false,
  exchange_rates: Map(),
  baseline_prices: Map(),
  shipping_price: Map(),
};

const panelMixerDataReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case SET_MIXER_INITIALIZED: {
      return {
        ...state,
        wasInitialized: data
      };
    }
    case LOAD_DATA: {
      return {
        ...state,
        [action.dataName]: data
      };
    }
    default:
      return state;
  }
};

export default panelMixerDataReducer;
