import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import { Col, Form, Row } from 'antd';
import { string, any, bool } from 'prop-types';
import { Set } from 'immutable';
import AuthorizationForm, {
  PasswordFormItemWithValidation,
  RepeatPasswordFormItemWithValidation
} from '../../../../components/AuthorizationForm';
import { resetPassword } from '../../../../api/auth';
import AlertMessage from '../../../../components/AlertMessage/AlertMessage';
import { HTTP_STATUS_CODES } from '../../../../utils/constants';

const { BAD_REQUEST } = HTTP_STATUS_CODES;

const formNames = {
  password: 'password',
  passwordConfirm: 'passwordConfirm'
};

const initValidateStatuses = {
  [formNames.passwordConfirm]: '',
  [formNames.passwordConfirm]: ''
};

const validationFields = {
  password: 'password',
  resetToken: 'reset_token',
  user: 'user'
};

const ResetPassword = ({
  user,
  token,
  title,
  subtitle,
  submitButtonText,
  additionalButton,
  redirectOnError
}) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [responseErrors, setResponseErrors] = useState([]);
  const history = useHistory();

  const onFormChange = useCallback(() => {
    setResponseErrors([]);
  }, []);

  const redirectToLinkExpired = useCallback(() => {
    history.push('/panel/auth/reset-password/link-expired');
  }, [history]);

  const onFormFinish = useCallback(
    async (formName, { values }) => {
      setLoading(true);
      await resetPassword({
        password: values[formNames.passwordConfirm],
        reset_token: token,
        user: user
      })
        .then(() => {
          history.push('/panel/auth/login');
        })
        .catch(err => {
          const { response } = err;
          if (response) {
            if (response.status === BAD_REQUEST) {
              if (redirectOnError) {
                if (
                  response.data.hasOwnProperty(validationFields.resetToken) ||
                  response.data.hasOwnProperty(validationFields.user)
                )
                  redirectToLinkExpired();
              }

              const errorsToDisplay = Set(
                Object.values(response.data).reduce(
                  (acc, val) => [...acc, ...val],
                  []
                )
              );
              setResponseErrors(errorsToDisplay.toArray());
              setLoading(false);
            }
          } else {
            redirectToLinkExpired();
          }
        });
    },
    [history, redirectOnError, redirectToLinkExpired, token, user]
  );

  if (
    typeof user === 'undefined' ||
    typeof token === 'undefined' ||
    user.length === 0 ||
    token.length === 0
  ) {
    return <Redirect to="/panel/auth/reset-password/request" />;
  }

  return (
    <Row className="reset-password-container">
      <Col span={24}>
        <h1 className="reset-password-container--title">{title}</h1>
        <h4 className="reset-password-container--subtitle">{subtitle}</h4>
        {responseErrors.length !== 0 ? (
          <AlertMessage message={responseErrors} type="error" />
        ) : null}
        <Row>
          <Col span={24}>
            <Form.Provider
              onFormFinish={onFormFinish}
              onFormChange={onFormChange}
            >
              <AuthorizationForm
                requiredMark={false}
                submitButtonText={submitButtonText}
                pathsToValidate={Object.keys(initValidateStatuses)}
                submitting={loading}
                additionalButton={additionalButton}
              >
                <PasswordFormItemWithValidation
                  label={t('New Password')}
                  name={formNames.password}
                />
                <RepeatPasswordFormItemWithValidation
                  basePasswordName={formNames.password}
                  name={formNames.passwordConfirm}
                  label={t('Repeat Password')}
                />
              </AuthorizationForm>
            </Form.Provider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ResetPassword.propTypes = {
  user: string.isRequired,
  token: string.isRequired,
  title: string,
  subtitle: string,
  submitButtonText: string,
  additionalButton: any,
  redirectOnError: bool
};

ResetPassword.defaultProps = {
  redirectOnError: false
};

export default ResetPassword;
