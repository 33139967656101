import minerals from '../../../assets/img/substance_types/minerals.svg';
import adaptogens from '../../../assets/img/substance_types/adaptogens.svg';
import omega3 from '../../../assets/img/substance_types/omega3.svg';
import vitamins from '../../../assets/img/substance_types/vitamins.svg';
import bioactive from '../../../assets/img/substance_types/bioactive.svg';
import probiotics from '../../../assets/img/substance_types/probiotics.svg';
import antioxidants from '../../../assets/img/substance_types/antioxidants.svg';
import { Map } from 'immutable';

const iconsSubstanceTypes = Map({
  MINERALS: minerals,
  OMEGA3: omega3,
  ADAPTOGENS: adaptogens,
  VITAMINS: vitamins,
  ANTIOXIDANTS: antioxidants,
  PROBIOTICS: probiotics,
  BIOACTIVE: bioactive
});

export default iconsSubstanceTypes;
