import {
  applyMiddleware,
  compose,
  combineReducers,
  createStore as createStoreRedux
} from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import {
  calendarData,
  panelMixerData,
  userDataReducer,
  panelActionReducer,
  authReducer
} from './reducers';

export const createStore = () => {
  let appReducers = combineReducers({
    panelMixerData,
    calendarData,
    userDataReducer,
    panelActionReducer,
    authReducer
  });

  let composeEnhancers = compose;

  let middlewares = [thunk];
  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
      collapsed: true
    });

    composeEnhancers =
      typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25
          })
        : compose;

    middlewares = [...middlewares, logger];
  }

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  return createStoreRedux(appReducers, enhancer);
};
