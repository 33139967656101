import React from 'react';
import * as Sentry from '@sentry/browser';
import PageError404 from '../panel/components/PageError404';

const NoMatch = () => {
  try {
    throw new PageError404();
  } catch (err) {
    Sentry.captureException(err);
  }

  return <div>404</div>;
};

export default NoMatch;
