import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import logoSVG from '../../../assets/img/logo.svg';
import FacebookIcon from '../../../assets/img/facebook.svg';
import GoogleIcon from '../../../assets/img/google.svg';
import AppleIcon from '../../../assets/img/apple.svg';
import LineIcon from '../../../assets/img/line.svg';
import QuickRegisterFormWithRecaptcha from './components/QuickRegisterForm/QuickRegisterForm';
import AlertMessage from '../../../components/AlertMessage/AlertMessage';
import {getLSItem, hideHeader, useOptimize} from '../../../utils';
import './quick-register.scss';
import CloseIcon from '../../../assets/img/modal-close.svg';
import { Button, Modal } from 'antd';
import { pushToDataLayer } from '../../../utils/dataLayer';
import { useHistory } from 'react-router';

const socialMediaButtons = [
  { text: 'Kontynuuj z Facebook', className: 'facebook', icon: FacebookIcon },
  { text: 'Kontynuuj z Google', className: 'google', icon: GoogleIcon },
  { text: 'Kontynuuj z Apple', className: 'apple', icon: AppleIcon }
];

export const QuickRegister = () => {
  const [t] = useTranslation();
  const [errors, setErrors] = useState([]);

  const {
    location: { search }
  } = useHistory();

  const [isTemporaryUser, setIsTemporaryUser] = useState(false);

  useOptimize('activateQuickRegisterPage');

  const authData = getLSItem('flutter.sundose_auth');

  if (authData && !isTemporaryUser) {
    const { temporary } = JSON.parse(authData) ?? {};
    setIsTemporaryUser(temporary);
  }

  let headerText = t('quick-register-subtitle');

  if (isTemporaryUser) {
    headerText = t('quick-register-subtitle-for-temp-user');
  }

  const isUnderOptimizeExperiment = search.includes('go=sm');

  return (
    <section className="quick-register-page-v3">
      <Helmet>
        <title>{t('Register with Sundose')}</title>
      </Helmet>
      <header className="quick-register-page-v3__header">
      </header>
      <main className="quick-register-page-v3__content">
        {isUnderOptimizeExperiment ? (
          <h2 className="title go-exp">
            Załóż darmowe konto i zachowaj swój skład
          </h2>
        ) : (
          <h2 className="title">{headerText}</h2>
        )}

        <ExperimentWithSocialMediaRegistration />

        {errors.length > 0 ? (
          <AlertMessage type="error" message={errors} />
        ) : null}

        <QuickRegisterFormWithRecaptcha setErrors={setErrors} />
      </main>
    </section>
  );
};

const ExperimentWithSocialMediaRegistration = () => {
  const {
    location: { search }
  } = useHistory();

  const [showSocialMediaButtons, setSocialMediaButtons] = useState(
    search.includes('go=sm')
  );
  const [showSocialMediaModal, setSocialMediaModal] = useState(false);

  const socialMediaButtonOnClick = className => {
    pushToDataLayer(`${className}_registration_intent`);
    setSocialMediaModal(true);
  };

  const onSocialMediaModalClose = () => {
    setSocialMediaModal(false);
    setSocialMediaButtons(false);

    document.querySelector('input[name=email]').focus();
  };

  if (!showSocialMediaButtons) return null;

  return (
    <>
      <div className="social-media-buttons">
        {socialMediaButtons.map(btn => (
          <Button
            key={btn.text}
            onClick={() => socialMediaButtonOnClick(btn.className)}
            block
            size="large"
            className={btn.className}
          >
            <img src={btn.icon} /> {btn.text}
          </Button>
        ))}

        <div className="with-line">
          <img src={LineIcon} />
          <span>LUB</span>
          <img src={LineIcon} />
        </div>
      </div>

      <Modal
        width={400}
        visible={showSocialMediaModal}
        wrapClassName="subscription-modal"
        bodyStyle={{
          padding: 30,
          boxShadow: '0 2px 7px 0 #00000020'
        }}
        maskClosable
        centered
        footer={null}
        onCancel={onSocialMediaModalClose}
        closeIcon={
          <img src={CloseIcon} className="modal-close-icon" alt="close icon" />
        }
      >
        <div className="text-left">
          <h3
            style={{
              fontSize: 21,
              marginBottom: 20,
              lineHeight: '25px'
            }}
          >
            Zakładanie konta przez Facebook, Google i Apple nie jest jeszcze
            dostępne
          </h3>
          <p>
            Na razie sprawdzamy Twoje zainteresowanie tą opcją. Weźmiemy pod
            uwagę Twój wybór. Dziękujemy!
          </p>
          <Button
            type="primary"
            shape="round"
            block
            size="large"
            style={{ fontWeight: 600, marginTop: 20 }}
            onClick={onSocialMediaModalClose}
          >
            Załóż konto używając e-mail
          </Button>
        </div>
      </Modal>
    </>
  );
};
