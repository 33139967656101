import React, { Component } from 'react';
import SurveyContext from '../../SurveyContext';
import { List } from 'immutable';

class FormOption extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  submitAnswer = answerId => {
    const { setOptionAnswers } = this.context;

    setOptionAnswers([{ id: answerId }]);
  };

  render() {
    const {
      questionIndex,
      questionIds,
      questions,
      answeredQuestions
    } = this.context;

    const questionId = questionIds[questionIndex];
    const question = questions[questionId];
    const {
      questionOption: { questionOptionAnswers }
    } = question;
    const currentQuestionAnswer = answeredQuestions.get(questionId, null);
    const sortedOptions = List(questionOptionAnswers).sortBy(
      val => val.position
    );
    const answersElements = sortedOptions.map(answer => {
      let selectedClassName = '';
      let answeredQuestionOption = currentQuestionAnswer
        ? currentQuestionAnswer.questionOptionAnswers.find(
            n => n.id === answer.id
          )
        : undefined;
      if (typeof answeredQuestionOption !== 'undefined')
        selectedClassName = 'btn-full';
      else selectedClassName = 'btn-ghost';
      return (
        <div className="form-answer" key={answer.id}>
          <button
            onClick={this.submitAnswer.bind(this, answer.id)}
            type="button"
            value={answer.value}
            className={`btn ${selectedClassName}`}
          >
            {answer.value}
          </button>
        </div>
      );
    });

    return <div className="wrapper">{answersElements}</div>;
  }
}

export default FormOption;
