import { axios } from './index';
import { getEnv } from '../../env';

const baseDjangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');

const baseUrl = baseDjangoApi + '/auth';

export const signIn = values =>
  axios
    .post(`${baseUrl}/login/`, {
      ...values
    })
    .then(response => response.data);

export const signUp = values =>
  axios.post(`${baseUrl}/register/`, values).then(response => response.data);

export const signUpTemporaryUser = () =>
  axios.post(`${baseUrl}/temporary_register/`).then(response => response.data);

export const buyPrescriptionAsGift = prescriptionId =>
  axios
    .post(`${baseDjangoApi}/buy-composition/${prescriptionId}`)
    .then(response => response.data);

export const resetPasswordRequest = email =>
  axios
    .post(`${baseUrl}/password_reset_request/`, {
      email: email
    })
    .then(response => response.data);

export const resetPassword = data =>
  axios
    .post(`${baseUrl}/password_reset/`, data)
    .then(response => response.data);

export const changePassword = data =>
  axios
    .post(`${baseUrl}/password_change/`, data)
    .then(response => response.data);

export const verifyMail = (user, token) =>
  axios.get(`${baseUrl}/mail_verification/`, {
    params: { token: token, user: user }
  });

export const quickSignUp = data =>
  axios
    .post(`${baseUrl}/quick_register/`, data)
    .then(response => response.data);
