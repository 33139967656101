import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSubscriptionDiscount } from '../../../utils/useSubscriptionDiscount';

type OfferPriceProps = {
  country: string;
  currencyText: string;
  summaryPrice: number;
  packagePrice: number;
};

const OfferPrice: React.FC<OfferPriceProps> = ({
  country,
  currencyText,
  summaryPrice,
  packagePrice,
}) => {
  const { t } = useTranslation();
  const { start: subscriptionDiscount } = useSubscriptionDiscount(country);
  const subBasePrice = summaryPrice - packagePrice;
  const discountedPrice = subBasePrice - (subBasePrice * subscriptionDiscount);
  return (
    <div className="offer-price-summary-price-row">
      {`${t('subscription')} -${(subscriptionDiscount * 100).toFixed()}% `}
      <strong>{`${discountedPrice.toFixed(2)} ${currencyText}`}</strong>
      <div>{`${t('one-time-payment')} ${summaryPrice.toFixed(
        2
      )} ${currencyText}`}</div>
    </div>
  );
};

export default OfferPrice;
