import { affiliationActions } from '../../../utils/constants';

const { SET_AFFILIATION } = affiliationActions;

export const setAffiliation = affiliation => {
  return {
    type: SET_AFFILIATION,
    data: affiliation
  };
};
