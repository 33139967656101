import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { fromJS, Map } from 'immutable';
import { PasswordInput } from '../index';

export const FORM_VALIDATION_STATES = {
  NOT_TOUCHED: 'not-touched',
  ERROR: 'error',
  SUCCESS: 'success'
};

const initialValidation = {
  length: {
    state: FORM_VALIDATION_STATES.NOT_TOUCHED
  },
  upperCase: {
    state: FORM_VALIDATION_STATES.NOT_TOUCHED
  },
  digit: {
    state: FORM_VALIDATION_STATES.NOT_TOUCHED
  }
};

export const ValidationHelp = ({ validation, texts }) => {
  const [t] = useTranslation();
  return (
    <div className="validation-rules">
      <div className="validation-rules-title">{t('The password should:')}</div>
      <div className="validation-rules-wrapper">
        {validation
          .map((value, key) => (
            <div
              key={key}
              className={`validation-rules-item validation--${value.get(
                'state'
              )}`}
            >
              <span
                className={`validation-mark validation-mark--${value.get(
                  'state'
                )}`}
              />
              <div>{texts.get(key)}</div>
            </div>
          ))
          .toIndexedSeq()}
      </div>
    </div>
  );
};

export const sortValidationRules = validation => {
  return validation.sort((a, b) => {
    if (a.get('state') === FORM_VALIDATION_STATES.ERROR) return -1;
    if (b.get('state') === FORM_VALIDATION_STATES.ERROR) return 1;
    if (
      a.get('state') === FORM_VALIDATION_STATES.NOT_TOUCHED &&
      b.get('state') === FORM_VALIDATION_STATES.SUCCESS
    )
      return -1;
    if (
      a.get('state') === FORM_VALIDATION_STATES.SUCCESS &&
      b.get('state') === FORM_VALIDATION_STATES.NOT_TOUCHED
    )
      return 1;
    return 0;
  });
};

const PasswordFormItemWithValidation = ({ name, label }) => {
  const [t] = useTranslation();
  const [validation, setValidation] = useState(fromJS(initialValidation));

  const updateValidationState = (rule, newState) => {
    setValidation(prev => prev.updateIn([rule, 'state'], () => newState));
  };

  const passwordLengthValidator = useCallback((rule, value) => {
    if (typeof value === 'undefined' || value.length < rule.min) {
      updateValidationState('length', FORM_VALIDATION_STATES.ERROR);
      return Promise.reject();
    }
    updateValidationState('length', FORM_VALIDATION_STATES.SUCCESS);
    return Promise.resolve();
  }, []);

  const passwordRegexValidator = (rule, value, ruleName) => {
    if (!rule.pattern.test(value)) {
      updateValidationState(ruleName, FORM_VALIDATION_STATES.ERROR);
      return Promise.reject();
    }

    updateValidationState(ruleName, FORM_VALIDATION_STATES.SUCCESS);
    return Promise.resolve();
  };

  const formLabel = useMemo(() => {
    if (typeof label === 'undefined') {
      return t('Password');
    }
    return label;
  }, [label, t]);

  const texts = useMemo(() => {
    return Map({
      length: t('be a minimum of eight (8) characters in length'),
      upperCase: t('contain at least one Uppercase letter (A-Z)'),
      digit: t('contain at least one Digit (0-9)')
    });
  }, [t]);

  return (
    <Form.Item
      name={name}
      validateTrigger="onChange"
      label={formLabel}
      rules={[
        {
          required: true,
          message: t('Password is required')
        },
        {
          min: 8,
          validator: passwordLengthValidator
        },
        {
          pattern: /[A-Z]+/,
          validator: (rule, value) =>
            passwordRegexValidator(rule, value, 'upperCase')
        },
        {
          pattern: /\d+/,
          validator: (rule, value) =>
            passwordRegexValidator(rule, value, 'digit')
        }
      ]}
      help={<ValidationHelp validation={validation} texts={texts} />}
      validateStatus={sortValidationRules(validation).first().get('state')}
    >
      <PasswordInput />
    </Form.Item>
  );
};

PasswordFormItemWithValidation.propTypes = {
  name: string.isRequired,
  label: string
};

export default PasswordFormItemWithValidation;
