import { dataLayerBundleLevels } from '../../../utils/constants';
import { dataLayerPushLoginStatus } from '../../../utils/dataLayer/dataLayerPushLoginStatus';
import { pushToDataLayer } from '../../../utils/dataLayer';
import moment from 'moment';
import { getUserType } from '../../auth/utlis';

const getThankYouPageProducts = (
  prescription,
  currencyCode,
  exchangeRates,
  baselinePrices
) => {
  const json_body = prescription.json_body;
  const id = json_body.selected_bundle.id;
  const variant = dataLayerBundleLevels[json_body.selected_bundle.level];

  const packagePrice = baselinePrices.get(json_body.country_norms_code);
  const totalPrice = prescription.price + packagePrice;
  const price = (totalPrice * exchangeRates.get(currencyCode)).toFixed(2);

  return [
    {
      id,
      name: id,
      category: id,
      variant,
      variantId: `${id}_${variant}`,
      price,
      currency: currencyCode,
      dimension2: !json_body.are_substances_pristine,
      quantity: 1
    }
  ];
};

export const dataLayerPushThankYouPage = async (
  order,
  prescription,
  exchangeRates,
  baselinePrices,
  paymentMethod,
  userSettings,
  coupon
) => {
  const orderTotal = order.price;
  const currency = order.currency;

  const {
    subscriber,
    date_of_registration,
    date_of_the_last_purchase,
    days_from_last_purchase,
    paid_transactions_count: settingsPTC,
    last_paid_order_id
  } = userSettings;

  const products = getThankYouPageProducts(
    prescription,
    currency,
    exchangeRates,
    baselinePrices
  );

  const norms = prescription.json_body.country_norms_code;
  const shipping = (
    baselinePrices.get(norms) * exchangeRates.get(currency)
  ).toFixed(2);

  // OLD Universal Analytics Event:
  await dataLayerPushLoginStatus();

  window.dataLayer = window.dataLayer || [];

  // OLD Universal Analytics Event:
  window.dataLayer.push({
    OrderTotal: orderTotal,
    event: 'eec.Purchase',
    ecommerce: {
      currencyCode: order.currency,
      purchase: {
        actionField: {
          id: order.id,
          affiliation: 'Sundose Web Panel', //Sundose Web Panel or Sundose App
          revenue: orderTotal * 0.92,
          tax: orderTotal * 0.08,
          shipping,
          option: paymentMethod ? paymentMethod : 'PayU'
          // coupon: 'testowanie99'
        },
        products
      }
    }
  });

  /**
   * @todo
   * - coupon - pass it in URL
   * - user settings from API
   */

  // Google Analytics 4 Event:
  const product = products[0];

  const now = moment();

  const dateOfRegistration = moment(date_of_registration, 'X');
  const dateOfLastPurchase = moment(date_of_the_last_purchase, 'X');

  let paid_transactions_count =
    order.id === last_paid_order_id ? settingsPTC - 1 : settingsPTC;

  pushToDataLayer('purchase', {
    process_type: paid_transactions_count > 0 ? 'retention' : 'acquisition',
    user_type: getUserType(paid_transactions_count),
    purchase_type: order.subscriber ? 'subscription_start' : 'one_off', // Purchase type (eg. one_off, subscription_start, subscription_renewal)
    active_subscriber: subscriber, // active subscriber (eg. true, false)

    date_of_the_last_purchase: dateOfLastPurchase.format('YYYY-MM-DD'), // timestamp of last purchase
    date_of_registration: dateOfRegistration.format('YYYY-MM-DD'), // timestamp of registration
    paid_transactions_count, //number of orders paid

    hours_from_register_to_order: now.diff(dateOfRegistration, 'hours'),
    days_from_last_purchase,

    payment_type: paymentMethod ? paymentMethod : 'PayU', // payment processor (eg. PayU, PayPal, Stripe)
    shipping_type: order.shipping_type, // shipping type (eg. DHL, inPost)
    ecommerce: {
      purchase: {
        transaction_id: order.id, // Unique order ID
        affiliation: 'sundose.io', // affiliate page url
        value: orderTotal, // Total item price (eg. 33.75)
        // tax: '4.90', // Total tax  (eg. 4.90)
        // shipping: '5.99', // Format cost (included in value)
        currency, // Order currency
        ...(coupon && { coupon }), // Used coupon name (eg. 'odpornosc') - only if validated
        items: [
          {
            item_id: prescription.id, // Formulation ID (eg. '279691')
            item_name: 'Sundose Personalized Supplement', // Item name (eg. 'Sundose Personalized Supplement','Sundose Shaker','100 PLN Voucher' etc.)
            item_brand: 'Sundose', // Always Sundose
            item_category: prescription.form, // Sundose form name (eg. 'Powder','Powder + Capsules','Capsules''Smoothie','Shake')
            item_category2: product.category, // Sundose formulation category (eg. 'Beauty', 'Hashimoto', 'Preventive treatment', Vege etc.)
            item_variant: product.variant, // Sundose bundle name (eg. 'Essential','Optimal','Complete')
            price: orderTotal, // Total item price (eg. 33.75)
            currency, // Order currency
            quantity: 1 // Quantity per order (eg. 1)
          }
        ]
      }
    }
  });
};
