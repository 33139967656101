import { useEffect } from 'react';

/**
 * Hook to activate Google Optimize by adding custom event when Component has rendered it's content.
 * Google Optimize should change DOM **after** React. Otherwise React could revert changes made by GO.
 * In most cases it should work fine. If Component for some reason re-render you should try useOptimizeAfterLastRender hook.
 * @param customEventName name of custom event used in Google Optimize to trigger an experiment. Should start with "activate..."
 */
const useOptimize = (customEventName: string): void => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: `optimize.${customEventName}` });
    // console.log(`OPTIMIZE ${customEventName} !!!!!!!!!!!!!!!!!!!`);
  }, [customEventName]);
};

export default useOptimize;
