import { actionNamesUserData } from './utils/constants';
import { deserializeToken } from './utils/deserializers';
import * as Sentry from '@sentry/browser';
import { getLSItem } from './utils';

const {
  SET_TOKEN_DATA,
  SET_USER_SETTINGS,
  SET_USER_SUBSCRIPTION,
  CANCEL_USER_SUBSCRIPTION
} = actionNamesUserData;

export const setParseTokenData = token => {
  return dispatch => {
    if (typeof token === 'undefined') token = getLSItem('flutter.access_token');
    const data = deserializeToken(token);

    Sentry.setUser({
      id: data.get('user_id'),
      username: data.get('username'),
      email: data.get('email')
    });

    dispatch({ type: SET_TOKEN_DATA, data });
  };
};

export const setUserSettings = data => {
  return { type: SET_USER_SETTINGS, data };
};

export const setUserSubscription = data => {
  return { type: SET_USER_SUBSCRIPTION, data };
};

export const cancelUserSubscription = data => {
  return { type: CANCEL_USER_SUBSCRIPTION, data };
};
