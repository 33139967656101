import { useCallback, useEffect, useState } from 'react';
import engGB from 'antd/es/locale/en_GB';
import plPL from 'antd/es/locale/pl_PL';
import deDE from 'antd/es/locale/de_DE';
import itIt from 'antd/es/locale/it_IT';
import i18n from './i18n';
import { LANGUAGES } from './utils';
import { getLSItem } from './panel/utils';

const { PL, EN, DE, IT } = LANGUAGES;

const useAntDesignLocale = () => {
  const [language, setLanguage] = useState(plPL);

  const retrieveLanguageConfig = useCallback(language => {
    switch (language) {
      case EN:
        return engGB;
      case DE:
        return deDE;
      case IT:
        return itIt;
      case PL:
      default:
        return plPL;
    }
  }, []);

  i18n.on('languageChanged', lang => {
    setLanguage(retrieveLanguageConfig(lang));
  });

  useEffect(() => {
    const lang = getLSItem('language');
    setLanguage(retrieveLanguageConfig(lang !== null ? lang : i18n.language));
  }, [retrieveLanguageConfig]);

  return language;
};

export default useAntDesignLocale;
