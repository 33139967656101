import React from 'react';

const ChangeNameIcon = () => (
  <i className="icon icon-change-name">
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
      <title>52F256AC-AB4A-4953-B3D2-3F110D2AF2B9@3x</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Panel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Składniki-main-dietetyk"
          transform="translate(-530.000000, -1206.000000)"
          fill="currentColor"
        >
          <g id="Group-11" transform="translate(509.000000, 1183.000000)">
            <g id="Group-2">
              <g id="Group" transform="translate(21.000000, 23.000000)">
                <polygon
                  id="Path"
                  fillRule="nonzero"
                  points="2 0 2 1.8 5.14285714 1.8 5.14285714 8 6.85714286 8 6.85714286 1.8 10 1.8 10 0"
                ></polygon>
                <rect id="Rectangle" x="0" y="8" width="1.5" height="4"></rect>
                <rect
                  id="Rectangle-Copy-16"
                  x="10.5"
                  y="8"
                  width="1.5"
                  height="4"
                ></rect>
                <rect
                  id="Rectangle-Copy-17"
                  transform="translate(6.000000, 11.250000) rotate(-270.000000) translate(-6.000000, -11.250000) "
                  x="5.25"
                  y="5.25"
                  width="1.5"
                  height="12"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </i>
);

export default ChangeNameIcon;
