import React from 'react';
import logo from './assets/img/logo.svg';
import sunImg from './assets/img/sun.svg';
import maintenanceIcon from './assets/img/maintenance-icon.svg';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'antd';
import './scss/maintenance-page.scss';
import { getContactLink, isMaintenanceOn } from '../utils';
import { useHistory } from 'react-router';

const MaintenancePage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  if (!isMaintenanceOn()) history.push('/panel');

  return (
    <div className="maintenance-page">
      <Row type="flex" justify="space-between">
        <a href="/" className="btn-logo">
          <img src={logo} alt="logo" />
        </a>
        <img src={sunImg} alt="sun" className="sun-img" />
      </Row>
      <div className="maintenance-page-info">
        <img src={maintenanceIcon} alt="maintenance" />
        <h1>{t('maintenance-in-progress')}</h1>
        <p>{t('maintenance-in-progress-description')}</p>
        <Button type="primary">
          <a href={getContactLink()}>{t('contact-customer-support')}</a>
        </Button>
      </div>
    </div>
  );
};

export default MaintenancePage;
