import React from 'react';
import { Spin } from 'antd';
import './await-data.scss';
import { useTranslation } from 'react-i18next';

const AwaitData = ({ children, loading }) => {
  const { t } = useTranslation();

  return (
    <Spin
      delay={100}
      spinning={loading}
      className="custom-spinner"
      size="large"
      tip={t('loading')}
      wrapperClassName="custom-spinner-children"
    >
      {children}
    </Spin>
  );
};

AwaitData.defaultProps = {
  children: <></>
};

export default AwaitData;
