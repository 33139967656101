import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useSubscriptionDiscount } from '../../../../../../utils/useSubscriptionDiscount';

const InactiveCard: React.FC = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const subscriptionDiscount = useSubscriptionDiscount(language);
  const maxDiscount = (subscriptionDiscount.stop * 100).toFixed();

  return (
    <section>
      <h2>{t('inactive-subscription-header')}</h2>
      <div>
        <p>{t('inactive-subscription-description')}</p>
        <ul className="check-mark-list">
          <li>
            <Trans i18nKey="inactive-subscription-point-1">
              {{ maxDiscount }}
            </Trans>
          </li>
          <li>{t('inactive-subscription-point-2')}</li>
          <li>{t('inactive-subscription-point-3')}</li>
        </ul>
        <Link to={'/panel/offer'}>
          <Button loading={false} type="primary" shape="round" size="large">
            {t('buy-sundose-button')}
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default InactiveCard;
