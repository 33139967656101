import React, { Component, useEffect } from 'react';
import SurveyContext from '../SurveyContext';
import ProgressBar from './ProgressBar';
import arrowRight from '../assets/img/arrow-right.svg';
import skipIcon from '../assets/img/skip.svg';
import { withTranslation } from 'react-i18next';
import { getUserId, pushToDataLayer } from '../../panel/utils/dataLayer';
import { useHistory } from 'react-router';
import { markUserSkippedSurvey, markUserTookPartInExp } from '../DataService';

const SkipSurvey = () => {
  const {
    location: { search }
  } = useHistory();

  const userId = getUserId();

  useEffect(() => {
    if (search === '?ab')
      (async () => {
        await markUserTookPartInExp(userId);
      })();
  }, []);

  if (!search || search !== '?ab') return null;

  const onClick = () => {
    markUserSkippedSurvey(userId).then(() => {
      pushToDataLayer('survey_skipped');
      window.location.href = '/mixer-app';
    });
  };

  return (
    <div className="skip-survey">
      <img src={skipIcon} alt="Skip survey icon" />
      <span onClick={onClick}>Pomiń wywiad</span>
    </div>
  );
};

class Header extends Component {
  static contextType = SurveyContext;

  render() {
    const { t } = this.props;
    let {
      questions,
      questionIds,
      answeredQuestions,
      questionIndex,
      goToPreviousQuestion,
      nextQuestionNavigation,
      animationInProgress
    } = this.context;
    let count = 0;
    let currentQuestionId = questionIds[questionIndex];
    let previousQuestions = answeredQuestions.filter(a =>
      a.position === 1 ? questionIndex !== 0 : a.position !== questionIndex - 1
    );

    previousQuestions.forEach(answeredQuestion => {
      if (answeredQuestion.finished) {
        count += 1;
      }
    });

    let lastAnsweredQuestion = answeredQuestions.last(null);
    let displayNextQuestionButton = false;

    if (
      lastAnsweredQuestion !== null &&
      lastAnsweredQuestion.questionId === currentQuestionId
    ) {
      displayNextQuestionButton = true;
    }

    const current = questions[currentQuestionId] ?? { position: 0 };
    const progress = (current.position / Object.keys(questions).length) * 100

    return (
      <div id="header">
        <div id="header-title-wrapper">
          <h1>{t('my-health-survey')}</h1>

          <SkipSurvey />
        </div>
        <div className="wrapper">
          <div className="progress-wrapper">
            <button
              onClick={() => goToPreviousQuestion(questionIndex)}
              type="button"
              className="btn-link right-margin"
              disabled={previousQuestions.size === 0 || animationInProgress}
            >
              <span><span className="arrow">←</span><span className="hide-mobile">{t('previous-question')}</span></span>
            </button>
            <ProgressBar progress={progress} />
            <button
              onClick={nextQuestionNavigation}
              type="button"
              className="btn-link left-margin"
              disabled={!displayNextQuestionButton || animationInProgress}
            >
              <span><span className="hide-mobile">{t('next-question')}</span>{' '}<span className="arrow">→</span></span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Header);
