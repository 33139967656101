import React from 'react';
import { string } from 'prop-types';

const EyeWithEyelashes = ({ color }) => (
  <svg
    width="28"
    height="18"
    viewBox="0 0 28 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.84841 13.0887C2.91532 12.2553 2.74141 10.8607 3.44128 9.82375C4.84802 7.76435 7.87693 4.33781 13.2773 4.38217C18.5621 4.42653 21.8318 8.00831 22.9407 10.1944C23.3968 11.095 23.2641 12.1817 22.6048 12.9461C21.2441 14.5144 18.071 16.9271 13.1126 16.8099C8.42821 16.6974 5.51495 14.5921 3.84841 13.0887Z"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1077 14.8677C15.4656 14.8677 17.377 12.9563 17.377 10.5984C17.377 8.24054 15.4656 6.3291 13.1077 6.3291C10.7498 6.3291 8.83838 8.24054 8.83838 10.5984C8.83838 12.9563 10.7498 14.8677 13.1077 14.8677Z"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6004 2.73307V1"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.92861 3.2305L8.44702 1.56555"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.13143 5.31683L4.12866 3.90376"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.42099 8.24277L1 7.25108"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.272 3.2305L18.7551 1.56555"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0693 5.31683L23.0737 3.90376"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7798 8.24277L26.2008 7.25108"
      stroke={color}
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

EyeWithEyelashes.propTypes = {
  color: string
};

EyeWithEyelashes.defaultProps = {
  color: 'black'
};

export default EyeWithEyelashes;
