import React from 'react';

export const ShopCartIcon = () => {
  return (
    <svg width="19px" height="13px" viewBox="0 0 19 13" version="1.1">
      <title>78F6CF70-F522-435F-A472-06BF7D8ED278@3x</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Panel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Składniki-lista-kupiony"
          transform="translate(-744.000000, -42.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="Group-8-Copy-2" transform="translate(720.000000, 30.000000)">
            <g
              id="iconmonstr-shopping-cart-2"
              transform="translate(33.500000, 18.500000) scale(-1, 1) translate(-33.500000, -18.500000) translate(24.000000, 12.000000)"
            >
              <path
                d="M19,0 L18.4117917,1.44444444 L16.8846667,1.44444444 L14.1344167,10.1111111 L3.65354167,10.1111111 L0,2.16666667 L13.3095,2.16666667 L12.863,3.61111111 L2.38133333,3.61111111 L4.70645833,8.66666667 L12.9619583,8.66666667 L15.6789583,0 L19,0 Z M6.72916667,10.8333333 C6.07366667,10.8333333 5.54166667,11.3186667 5.54166667,11.9166667 C5.54166667,12.5153889 6.07366667,13 6.72916667,13 C7.38466667,13 7.91666667,12.5153889 7.91666667,11.9166667 C7.91666667,11.3186667 7.38466667,10.8333333 6.72916667,10.8333333 Z M9.5,11.9166667 C9.5,12.5153889 10.032,13 10.6875,13 C11.343,13 11.875,12.5153889 11.875,11.9166667 C11.875,11.3186667 11.343,10.8333333 10.6875,10.8333333 C10.032,10.8333333 9.5,11.3179444 9.5,11.9166667 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
