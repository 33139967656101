import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Button, Checkbox, Col, Form, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import AuthorizationForm, {
  EmailInput,
  FORM_NAME,
  PasswordFormItemWithValidation
} from '../../../components/AuthorizationForm';
import { signUp as signUpService } from '../../../api/auth';
import { loginUser } from '../utlis';
import { useRedirect } from '../../../utils/redirect';
import AlertMessage from '../../../components/AlertMessage/AlertMessage';
import {
  HTTP_STATUS_CODES,
  SBL_LOCALSTORAGE_KEY
} from '../../../utils/constants';
import {
  getLSItem,
  saveLoginData,
  useLocaleLink,
  useOptimize
} from '../../../utils';

import { pushCompleteRegistrationEvent } from '../../../utils/analyticEventsHelpers';
import { pushToDataLayer } from '../../../utils/dataLayer';
import { getEnv } from '../../../../env';

const { BAD_REQUEST } = HTTP_STATUS_CODES;

const formNames = {
  email: 'email',
  password: 'password',
  agreement_1: 'agreement_1',
  agreement_2: 'agreement_2'
};

const initValidateStatuses = {
  [formNames.email]: '',
  [formNames.password]: '',
  [formNames.agreement_1]: ''
};

const Register = () => {
  const [t] = useTranslation();
  useOptimize('activateRegisterPage');

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [loading, setLoading] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [validateStatuses, setValidateStatuses] = useState(
    initValidateStatuses
  );
  const [responseErrors, setResponseErrors] = useState([]);
  const [redirect, redirectTo] = useRedirect();
  const privacyPolicyLink = useLocaleLink('privacyPolicy');
  const storeRulesLink = useLocaleLink('storeRules');
  const socialBusSlug = getLSItem(SBL_LOCALSTORAGE_KEY);
  const dispatch = useDispatch();

  const redirectUser = useCallback(() => {
    let path;
    if (!redirect.shouldRedirect) path = '/panel/dashboard';

    redirectTo(path);
  }, [redirect.shouldRedirect, redirectTo]);

  const signUp = useCallback(
    async values => {
      setLoading(true);

      const registerData = {
        ...values,
        agreement_level:
          values[formNames.agreement_1] + values[formNames.agreement_2]
      };

      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
      }

      try {
        registerData.recaptcha = await executeRecaptcha('register');
      } catch {
        console.log('Register - Recaptcha failed to execute.');
      }

      if (socialBusSlug) registerData['social_bus_slug'] = socialBusSlug;
      await signUpService(registerData)
        .then(async data => {
          try {
            pushToDataLayer('sign_up', {
              process_type: 'acquisition',
              user_type: 'visitor',
              method: 'email', // Sign up method chosen (eg. email, facebook, apple_id,)
              marketing_agreement: registerData.agreement_2, // Marketing agreement (eg. true,false)
              registered_user: true, // Registered user
              sign_up_page: 'standard sign up' // Sign up page (eg. standard sign up, health survey sign up)
            });

            saveLoginData(data);
            pushCompleteRegistrationEvent(data.user);
            await loginUser(data.token, data, dispatch, undefined);
            localStorage.removeItem(SBL_LOCALSTORAGE_KEY);
            redirectUser();
          } catch (e) {
            setLoading(false);
          }
        })
        .catch(err => {
          const { response } = err;
          if (response) {
            if (response.status === BAD_REQUEST) {
              const errorMessages = Object.values(response.data).reduce(
                (acc, val) => [...acc, ...val],
                []
              );

              if (response.data.account_exists) {
                setAccountExists(true);
              } else {
                setResponseErrors(errorMessages);
              }

              document.querySelector('input[name=email]').focus();

              setValidateStatuses({
                [formNames.email]: 'error',
                [formNames.password]: 'error',
                [formNames.agreement_1]: ''
              });
            }
          }
          setLoading(false);
        });
    },
    [dispatch, redirectUser, socialBusSlug, executeRecaptcha]
  );

  /**
   * @TODO remove Form.Provider from Register and other Auth Forms.
   * Provider is required when we use many forms inside one provider.
   * Krzysiek Paczuski made this component, but requirements has changed and we use one form here.
   * Refactor onFormFinish to onFinish and onFormChange to onValueChange ???
   * Custom validation with validateStatuses is probably not needed any more (to check)
   */
  const onFormFinish = useCallback(
    async (name, { values, forms }) => {
      if (name === FORM_NAME) await signUp(values, forms);
    },
    [signUp]
  );

  const onFormChange = useCallback(() => {
    if (
      validateStatuses[formNames.email].length !== 0 ||
      validateStatuses[formNames.password].length !== 0
    ) {
      setValidateStatuses(initValidateStatuses);
      setResponseErrors([]);
    }
  }, [validateStatuses]);

  return (
    <Row className="register-container">
      <Helmet>
        <title>{t('Register with Sundose')}</title>
      </Helmet>
      <Col span={24}>
        <h1 className="register-container--title">
          {t('Register with Sundose')}
        </h1>
        <h4 className="register-container--subtitle">
          {t(
            'We care about the security of your data - after creating an account you will have access to your Sundose history.'
          )}
        </h4>
        {responseErrors.length !== 0 ? (
          <AlertMessage message={responseErrors} type="error" />
        ) : null}

        {accountExists ? (
          <Alert
            message={
              <Trans
                i18nKey="account-exists"
                components={{
                  logIn: <Link to="/panel/auth/login" />,
                  resetPassword: (
                    <Link to="/panel/auth/reset-password/request" />
                  )
                }}
              />
            }
            type="error"
          />
        ) : null}

        <Row>
          <Col span={24}>
            <Form.Provider
              onFormFinish={onFormFinish}
              onFormChange={onFormChange}
            >
              <AuthorizationForm
                requiredMark={false}
                submitButtonText={t('Register')}
                pathsToValidate={Object.keys(initValidateStatuses)}
                submitting={loading}
                page={'register'}
              >
                <EmailInput
                  inputName={formNames.email}
                  validateStatus={
                    validateStatuses[formNames.email]
                      ? validateStatuses[formNames.email]
                      : undefined
                  }
                />
                <PasswordFormItemWithValidation name={formNames.password} />
                <Form.Item
                  name={formNames.agreement_1}
                  valuePropName="checked"
                  className="checkbox-item"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject('')
                    }
                  ]}
                  initialValue={false}
                >
                  <Checkbox tabIndex={3} className="required">
                    {t('register-privacy-checkbox')}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name={formNames.agreement_2}
                  initialValue={false}
                >
                  <Checkbox tabIndex={4}>
                    {t('register-marketing-checkbox')}
                  </Checkbox>
                </Form.Item>
              </AuthorizationForm>
              <p className="register-container--form-extra">
                <Trans
                  i18nKey="register-regulations-and-privacy-policy"
                  components={{
                    linkRules: <Button type="link" href={storeRulesLink} />,
                    linkPrivacy: <Button type="link" href={privacyPolicyLink} />
                  }}
                />
              </p>
            </Form.Provider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default function RegisterWithRecaptcha() {
  const reCaptchaKey = getEnv('REACT_APP_PUBLIC_RECAPTCHA_V3_KEY');

  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <Register />
    </GoogleReCaptchaProvider>
  );
}

// export default Register;
