import React, { Component } from 'react';
import SurveyContext from './SurveyContext';
import {
  addNewQuestionAnswer,
  deleteQuestionAnswer,
  editQuestionAnswer,
  getData
} from './DataService';
import { List, OrderedMap, Stack } from 'immutable';
import AnimationActions from './utils/AnimationActions';
import axios from 'axios';
import { withRouter } from 'react-router';
import { getEnv } from '../env';
import Cookies from 'universal-cookie';
import { getOrders } from '../panel/api/legacy';
import { HAS_SUNDOSE_ORDER_STATUS_SET } from '../utils';
import { deserializeToken } from '../panel/utils/deserializers';
import { getLSItem, setLSItem } from '../panel/utils';

axios.defaults.baseURL = getEnv('REACT_APP_PUBLIC_SURVEY_API_BASE_URL');
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
  const cookies = new Cookies();
  if (cookies.get('token')) {
    config.headers.Authorization = `Bearer ${cookies.get('token')}`;
  }
  return config;
});

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL') + '/';

class GlobalState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: undefined,
      animate: true,
      showMenu: false,
      surveyId: undefined,
      surveyAnswerId: undefined,
      terminalAction: AnimationActions.idle,
      animationInProgress: false,
      stage: 'phase-1',
      answeredQuestions: OrderedMap(),
      deletedAnswersForward: Stack()
    };
  }

  validateUserAndSetId = async () => {
    const token = getLSItem('flutter.access_token');
    let validated = 0;
    try {
      const deserializedToken = deserializeToken(token);
      validated = deserializedToken.get('user_id');
    } catch (e) {}
    let hasSundose = false;
    if (validated) {
      const ordersResponse = getOrders();
      await ordersResponse.then(orders => {
        hasSundose =
          List(orders).filter(order =>
            HAS_SUNDOSE_ORDER_STATUS_SET.has(order.wc_status)
          ).size !== 0;
      });
    }
    this.setState({
      userId: String(validated),
      hasSundose
    });
    return validated;
  };

  setSurvey = async (surveyId, survey) => {
    const { surveyName, questions, questionIds } = getData(survey);

    let surveyAnswerId = await this.createSurveyAnswerId(surveyId);

    if (surveyAnswerId) {
      const surveyAnswers = await this.getAnswersForSurvey(surveyId);
      const currentSurvey = surveyAnswers.find(
        surveyAnswer => surveyAnswer.id === surveyAnswerId
      );
      const currentSurveyAnswers = currentSurvey.questionAnswers.filter(
        answer => answer.surveyAnswerId === surveyAnswerId
      );

      let answeredQuestions = {};

      currentSurveyAnswers.forEach(answer => {
        answeredQuestions = {
          ...answeredQuestions,
          [answer.questionId]: {
            ...answer,
            value: answer.questionOptionAnswers.length
              ? answer.questionOptionAnswers[0].value
              : answer.answer,
            finished: true,
            questionName: questions[answer.questionId].name,
            position: questions[answer.questionId].position
          }
        };

        questions[answer.questionId] = {
          ...questions[answer.questionId],
          value: answer.answer
        };
      });

      answeredQuestions = OrderedMap(answeredQuestions);

      this.setState({
        surveyId,
        surveyName,
        questions,
        questionIds,
        questionIndex: this.getLastQuestionIndex(
          currentSurveyAnswers,
          questionIds,
          questions,
          answeredQuestions
        ),
        surveyAnswerId,
        answeredQuestions
      });
    }
  };

  getAnswersForSurvey = surveyId =>
    axios(`/survey/${surveyId}/answer`, {
      method: 'get',
      withCredentials: true
    }).then(response => response.data);

  createSurveyAnswerId = surveyId => {
    const { userId } = this.state;

    return axios
      .post(`/survey/${surveyId}/answer`, {
        surveyId,
        userId
      })
      .then(response => {
        return response.data.id;
      });
  };

  updateStage = stage => {
    this.setState({
      stage
    });
  };

  animationStarted = () => {
    this.setState({
      animationInProgress: true
    });
  };

  animationFinished = () => {
    this.setState({
      animationInProgress: false,
      terminalAction: ''
    });
  };

  testIfShouldSkip = (
    operator,
    parentQuestion,
    parentAnswer,
    conditionAnswer
  ) => {
    let shouldSkipQuestion = false;
    try {
      switch (operator) {
        case 'EQ':
          if (parentQuestion.answerType === 'multi_option') {
            shouldSkipQuestion = !parentAnswer.includes(conditionAnswer);
          } else {
            shouldSkipQuestion = conditionAnswer !== parentAnswer;
          }
          break;
        case 'LT':
          shouldSkipQuestion = conditionAnswer > parentAnswer;
          break;
        case 'GT':
          shouldSkipQuestion = conditionAnswer < parentAnswer;
          break;
        case 'NOT':
          if (parentQuestion.answerType === 'multi_option') {
            shouldSkipQuestion = parentAnswer.includes(conditionAnswer);
          } else {
            shouldSkipQuestion = conditionAnswer === parentAnswer;
          }
          break;
      }
    } catch (e) {
      console.log(e);
    }

    return shouldSkipQuestion;
  };

  getParentAnswer = (nextQuestion, parentQuestion, answeredQuestions) => {
    const parentAnsweredQuestion = answeredQuestions.get(
      nextQuestion.condition.questionId
    );
    if (parentAnsweredQuestion === undefined) {
      return undefined;
    }
    let parentValue = parentAnsweredQuestion.value;
    if (parentQuestion.answerType === 'option') {
      parentValue = parentAnsweredQuestion.questionOptionAnswers[0].id;
    } else if (parentQuestion.answerType === 'multi_option') {
      parentValue = parentAnsweredQuestion.questionOptionAnswers.map(
        el => el.id
      );
    }
    return parentQuestion.answerType === 'bool'
      ? JSON.parse(parentValue)
      : parentValue;
  };

  prepareNextQuestion = () => {
    const {
      questionIndex,
      questionIds,
      questions,
      answeredQuestions
    } = this.state;
    const nextQuestion = questions[questionIds[questionIndex + 1]];
    if (nextQuestion) {
      if (nextQuestion.condition) {
        let parentQuestion = questions[nextQuestion.condition.questionId];
        let parentAnswer = this.getParentAnswer(
          nextQuestion,
          parentQuestion,
          answeredQuestions
        );
        const conditionAnswer = nextQuestion.condition.answer;

        let shouldSkipQuestion = this.testIfShouldSkip(
          nextQuestion?.condition?.operator,
          parentQuestion,
          parentAnswer,
          conditionAnswer
        );
        if (shouldSkipQuestion) {
          let skipped = questionIndex + 1;
          let startingIndex = questionIndex + 1;
          let shouldSkip = true;

          while (shouldSkip) {
            const next = questions[questionIds[startingIndex]];

            if (next) {
              if (next.condition) {
                parentQuestion = questions[next.condition.questionId];
                parentAnswer = this.getParentAnswer(
                  next,
                  parentQuestion,
                  answeredQuestions
                );
                let skip = false;
                if (parentAnswer === undefined) {
                  skip = true;
                } else {
                  skip = this.testIfShouldSkip(
                    next.condition.operator,
                    parentQuestion,
                    parentAnswer,
                    next.condition.answer
                  );
                }
                if (skip === true) {
                  startingIndex += 1;
                  skipped += 1;
                } else {
                  shouldSkip = false;
                }
              } else {
                shouldSkip = false;
                this.moveToQuestion(skipped);
              }
              this.moveToQuestion(next.position - 1);
            } else {
              /* There is no more questions */
              shouldSkip = false;
              this.updateStage('phase-3');
            }
          }
        } else {
          this.changeQuestion();
        }
      } else {
        this.changeQuestion();
      }
    } else {
      this.updateStage('phase-3');
    }
  };

  changeQuestion = () => {
    const { questionIndex } = this.state;

    this.setState({
      terminalAction: AnimationActions.nextQuestion,
      questionIndex: questionIndex + 1
    });
  };

  moveToQuestion = newQuestionIndex => {
    const termAction =
      newQuestionIndex < this.state.questionIndex
        ? AnimationActions.prevQuestion
        : AnimationActions.nextQuestion;
    this.setState({
      terminalAction: termAction,
      questionIndex: newQuestionIndex
    });
  };

  updateTerminalAction = terminalAction => {
    this.setState({
      terminalAction
    });
  };

  goToNextQuestionPreviouslyAnswered = () => {
    const {
      deletedAnswersForward,
      userId,
      surveyAnswerId,
      answeredQuestions
    } = this.state;
    let self = this;
    if (deletedAnswersForward.size === 0) {
      this.setState({
        terminalAction: {
          animationName: AnimationActions.answered,
          animationComplete: self.prepareNextQuestion
        }
      });
    } else {
      let nextAnswer = deletedAnswersForward.first();
      addNewQuestionAnswer(surveyAnswerId, nextAnswer.questionId, {
        userId,
        surveyAnswerId,
        answer: nextAnswer.value,
        questionId: nextAnswer.questionId,
        questionOptionAnswers: nextAnswer.questionOptionAnswers
      }).then(answer => {
        const { questionOptionAnswers, id } = answer;
        self.setState({
          answeredQuestions: answeredQuestions.set(nextAnswer.questionId, {
            value: nextAnswer.value,
            finished: true,
            position: nextAnswer.position,
            id: id,
            questionName: nextAnswer.questionName,
            questionId: nextAnswer.questionId,
            questionOptionAnswers:
              typeof questionOptionAnswers !== 'undefined'
                ? questionOptionAnswers
                : null
          }),
          deletedAnswersForward: deletedAnswersForward.pop(),
          terminalAction: {
            animationName: AnimationActions.answered,
            animationComplete: self.prepareNextQuestion
          }
        });
      });
    }
  };

  goToPreviousQuestion = currentQuestionIdx => {
    let {
      questionIds,
      questions,
      answeredQuestions,
      deletedAnswersForward,
      surveyAnswerId
    } = this.state;

    let currentQuestion = questions[questionIds[currentQuestionIdx]];
    let currentQuestionAnswer = answeredQuestions.get(currentQuestion.id, null);
    let filteredQuestionAnswers = answeredQuestions.filter(
      answer => answer.position !== currentQuestion.position
    );
    let prevAnswer = filteredQuestionAnswers.last(null);

    if (prevAnswer === null) return;

    const prevPosition = prevAnswer.position - 1; // position na index

    if (currentQuestionAnswer !== null) {
      let self = this;
      deleteQuestionAnswer(
        surveyAnswerId,
        currentQuestionAnswer.questionId,
        currentQuestionAnswer.id
      ).then(answer =>
        self.setState({
          terminalAction: {
            animationName: AnimationActions.slideQuestionToLeft,
            animationComplete: () =>
              self.setState({
                deletedAnswersForward: deletedAnswersForward.push(
                  currentQuestionAnswer
                ),
                answeredQuestions: answeredQuestions.delete(answer.questionId),
                terminalAction: AnimationActions.prevQuestion,
                questionIndex: prevPosition
              })
          }
        })
      );
    } else {
      let self = this;
      this.setState({
        terminalAction: {
          animationName: AnimationActions.slideQuestionToLeft,
          animationComplete: () => self.moveToQuestion(prevPosition)
        }
      });
    }
  };

  getLastQuestionIndex = (
    questionAnswers,
    questionIds,
    questions,
    answeredQuestions
  ) => {
    if (questionAnswers.length === 0) {
      return 0;
    }

    const lastQuestion = questionAnswers.reduce((prev, current) =>
      prev.creationTime > current.creationTime ? prev : current
    );
    const lastIndex = questionIds.indexOf(lastQuestion.questionId);
    const questionToDispaly = questions[questionIds[lastIndex]];

    if (questionIds[lastIndex] === undefined) {
      return this.finishSurvey();
    }

    if (questionToDispaly.condition) {
      const parentQuestion = questions[questionToDispaly.condition.questionId];
      const parentValue = answeredQuestions.get(
        questionToDispaly.condition.questionId
      ).value;
      const parentAnswer =
        parentQuestion.answerType === 'bool'
          ? JSON.parse(parentValue)
          : parentValue;
      const conditionAnswer = questionToDispaly.condition.answer;

      const questionLastIndex = lastIndex;

      if (parentAnswer !== conditionAnswer) {
        let skipped = questionLastIndex + 1;
        let startingIndex = questionLastIndex + 1;
        let shouldSkip = true;

        while (shouldSkip) {
          const next = questions[questionIds[startingIndex]];

          if (next) {
            if (next.condition) {
              if (next.condition.questionId === parentQuestion.id) {
                startingIndex += 1;
                skipped += 1;
              } else {
                shouldSkip = false;
              }
            } else {
              shouldSkip = false;
              /* set skipped index */
              return skipped;
            }
          } else {
            /* There is no more questions */
            shouldSkip = false;
            this.updateStage('phase-3');
          }
        }
      } else {
        return lastIndex;
      }
    }

    return lastIndex;
  };

  setAnsweredQuestion = answeredQuestionNetworkResponse => {
    const { questions, answeredQuestions } = this.state;
    const { questionId } = answeredQuestionNetworkResponse;
    return answeredQuestions.set(questionId, {
      ...answeredQuestionNetworkResponse,
      value: answeredQuestionNetworkResponse.answer,
      finished: true,
      position: questions[questionId].position,
      questionName: questions[questionId].name
    });
  };

  setOptionAnswers = answersIds => {
    const {
      questionIds,
      questionIndex,
      surveyAnswerId,
      userId,
      answeredQuestions,
      deletedAnswersForward
    } = this.state;

    const questionId = questionIds[questionIndex];

    let self = this;
    let previousAnswer = answeredQuestions.get(questionId, null);

    let answerBody = {
      questionOptionAnswers: answersIds,
      surveyAnswerId,
      userId
    };

    let request = null;
    if (previousAnswer !== null) {
      request = editQuestionAnswer(
        surveyAnswerId,
        questionId,
        previousAnswer.id,
        {
          ...previousAnswer,
          ...answerBody
        }
      ).then(answer => {
        return { answer, deletedAnswersForward: Stack() };
      });
    } else {
      request = addNewQuestionAnswer(
        surveyAnswerId,
        questionId,
        answerBody
      ).then(answer => {
        return { answer, deletedAnswersForward };
      });
    }

    request.then(response => {
      const { answer, deletedAnswersForward } = response;
      this.setState({
        terminalAction: {
          animationName: AnimationActions.answered,
          animationComplete: () => {
            self.setState(
              {
                answeredQuestions: self.setAnsweredQuestion(answer),
                deletedAnswersForward,
                terminalAction: ''
              },
              self.prepareNextQuestion
            );
          }
        }
      });
    });
  };

  setStandardAnswer = value => {
    const {
      questionIds,
      questionIndex,
      surveyAnswerId,
      answeredQuestions,
      userId,
      deletedAnswersForward
    } = this.state;

    const questionId = questionIds[questionIndex];

    let self = this;
    let previousAnswer = answeredQuestions.get(questionId, null);
    let request = null;
    let answerBody = {
      answer: value,
      surveyAnswerId,
      userId,
      questionOptionAnswers: null
    };
    if (previousAnswer !== null) {
      request = editQuestionAnswer(
        surveyAnswerId,
        questionId,
        previousAnswer.id,
        {
          ...previousAnswer,
          ...answerBody
        }
      ).then(answer => {
        return {
          answer,
          deletedAnswersForward: Stack()
        };
      });
    } else {
      request = addNewQuestionAnswer(
        surveyAnswerId,
        questionId,
        answerBody
      ).then(answer => {
        return {
          answer,
          deletedAnswersForward
        };
      });
    }

    request.then(response => {
      let { answer, deletedAnswersForward } = response;
      this.setState({
        terminalAction: {
          animationName: AnimationActions.answered,
          animationComplete: () => {
            self.setState(
              {
                answeredQuestions: self.setAnsweredQuestion(answer),
                deletedAnswersForward,
                terminalAction: ''
              },
              self.prepareNextQuestion
            );
          }
        }
      });
    });
  };

  openMenu = () => {
    this.setState({
      showMenu: true,
      animate: false
    });
  };

  closeMenu = () => {
    this.setState({
      showMenu: false
    });
  };

  goToQuestion = questionId => {
    const { questions } = this.state;

    this.updateTerminalAction(AnimationActions.goToQuestion);

    this.setState({
      showMenu: false,
      animate: true,
      questionIndex: questions[questionId].position
    });
  };

  finishSurvey = async () => {
    if (getLSItem('NEW_OFFER_FLOW_EXPERIMENT_USER')) {
      setLSItem('NEW_OFFER_FLOW_EXPERIMENT', 'yes');
      localStorage.removeItem('NEW_OFFER_FLOW_EXPERIMENT_USER');
    }

    let redirectUrl = mixerBaseUrl;

    const flutterOriginFlag = this.props.location.search.includes(
      'origin=flutter'
    );

    if (flutterOriginFlag || getLSItem('REDIRECT_TO_FLUTTER_WEB')) {
      redirectUrl = '/profile/formulation/country';
    }

    window.location.href = redirectUrl;
  };

  render() {
    const {
      surveyId,
      surveyAnswerId,
      surveyName,
      questions,
      questionIds,
      questionIndex,
      terminalAction,
      stage,
      showMenu,
      animate,
      answeredQuestions,
      animationInProgress
    } = this.state;

    const { children } = this.props;

    return (
      <SurveyContext.Provider
        value={{
          showMenu,
          stage,
          surveyId,
          surveyAnswerId,
          surveyName,
          questions,
          questionIds,
          questionIndex,
          terminalAction,
          animate,
          animationInProgress,
          validateUserAndSetId: this.validateUserAndSetId,
          updateTerminalAction: this.updateTerminalAction,
          prepareNextQuestion: this.prepareNextQuestion,
          updateStage: this.updateStage,
          setOptionAnswers: this.setOptionAnswers,
          setStandardAnswer: this.setStandardAnswer,
          openMenu: this.openMenu,
          closeMenu: this.closeMenu,
          goToQuestion: this.goToQuestion,
          finishSurvey: this.finishSurvey,
          answeredQuestions,
          setSurvey: this.setSurvey,
          goToPreviousQuestion: this.goToPreviousQuestion,
          nextQuestionNavigation: this.goToNextQuestionPreviouslyAnswered,
          animationStarted: this.animationStarted,
          animationFinished: this.animationFinished
        }}
      >
        {children}
      </SurveyContext.Provider>
    );
  }
}

export default withRouter(GlobalState);
