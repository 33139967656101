import React from 'react';
import classNames from 'classnames';
import { node, string } from 'prop-types';

const propTypes = {
  children: node.isRequired,
  className: string
};

const defaultProps = {
  className: ''
};

const ShadowBox = ({ children, className: customClassName }) => {
  const className = classNames('shadow-box', {
    [customClassName]: !!customClassName
  });

  return <div className={className}>{children}</div>;
};

ShadowBox.propTypes = propTypes;
ShadowBox.defaultProps = defaultProps;

export default ShadowBox;
