import { fromJS } from 'immutable';

export const capitalizeNameFirstLetters = data => {
  return data.map(value => {
    if (!value.get) return value;
    const name = value.get('name');
    if (!name) return value;
    const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
    return value.set('name', capitalized);
  });
};

export const addDisplayUnitToGoals = (goals, substances, units) => {
  return goals.map(goal => {
    const levels = goal.get('levels');
    if (!levels) return goal;

    const updatedLevels = levels.map(levelData => {
      const updatedSubstances = levelData
        .get('substances')
        .map((amount, key) => {
          const unitKey = substances.get(key).get('display_unit');
          const displayUnit = units.get(unitKey);
          const result = {
            id: key,
            amount,
            displayAmount:
              amount * displayUnit.get('unit_conversion_multiplier'),
            displayUnitText: displayUnit.get('text')
          };
          return fromJS(result);
        });
      return levelData.set('substances', updatedSubstances.valueSeq());
    });

    return goal.set('levels', updatedLevels);
  });
};

export const addDisplayUnit = (substances, substancesData, units) => {
  return substances.map(value =>
    addDisplayUnitToSubstance(value, substancesData, units)
  );
};

export const addDisplayUnitToSubstance = (value, substances, units) => {
  const unitKey = substances.get(value.get('id')).get('display_unit');
  const unit = units.get(unitKey);
  return value
    .set(
      'displayAmount',
      value.get('amount') * unit.get('unit_conversion_multiplier')
    )
    .set('displayUnitText', unit.get('text'));
};
