import React, { useCallback, useEffect, useState } from 'react';
import Icon, { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getEnv } from '../../../../../env';
import { Link } from 'react-router-dom';
import { routeMainPaths, tokenKeys } from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { getOrdersForPrescription } from '../../../../api/legacy';
import { ShopCartIcon } from '../../../../assets/reactIcons';
import {
  getItemsFromPrescription,
  pushToDataLayer
} from '../../../../utils/dataLayer';
import { LANGUAGES } from '../../../../../utils';

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');

const PrescriptionDetailsHeader = ({ prescription }) => {
  const { exchange_rates, baseline_prices } = useSelector(
    state => state.panelMixerData
  );

  const { t } = useTranslation();
  const [hasOrders, setHasOrders] = useState(true);
  const { tokenData, settings } = useSelector(state => state.userDataReducer);
  const { paid_transactions_count } = settings;
  const prescriptionId = prescription.id;

  const fetchPrescriptions = useCallback(async () => {
    const response = getOrdersForPrescription(prescriptionId);
    await response.then(data => setHasOrders(data && data.length > 0));
  }, [prescriptionId]);

  useEffect(() => {
    fetchPrescriptions();
  }, [fetchPrescriptions]);

  const disableEdit =
    hasOrders || tokenData.get(tokenKeys.USER_ID) !== prescription.author;

  return (
    <div className="prescription-details-header">
      <Row type="flex">
        <Col xs={{ span: 23 }} lg={{ span: 20, offset: 1 }}>
          <Link to={routeMainPaths.COMPOSITION_PRESCRIPTION}>
            <Button type="link" className="go-back-btn">
              <LeftOutlined />
              {t('go-back-to-composition')}
            </Button>
          </Link>

          <div className="prescription-details-header-buttons">
            {!disableEdit && (
              <Button
                className="btn-edit"
                type="primary"
                ghost
                onClick={() => {
                  window.location.href =
                    mixerBaseUrl + `/prescription/${prescriptionId}/`;
                }}
              >
                {t('edit')}
              </Button>
            )}
            <Button
              className="btn-buy"
              type="primary"
              onClick={() => {
                pushToDataLayer('add_to_cart', {
                  button_label: t('buy-composition', { lng: LANGUAGES.EN }),
                  paid_transactions_count,
                  process_type:
                    paid_transactions_count > 0 ? 'retention' : 'acquisition',
                  formulation_category: prescription.form,
                  items: getItemsFromPrescription(
                    prescription,
                    baseline_prices,
                    exchange_rates
                  )
                });
                window.location.href = `${mixerBaseUrl}/${prescriptionId}/checkout`;
              }}
            >
              <Icon component={ShopCartIcon} /> {t('buy-composition')}
            </Button>
          </div>
        </Col>
        <Divider />
      </Row>
    </div>
  );
};

export default PrescriptionDetailsHeader;
