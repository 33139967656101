import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Select, Modal } from 'antd';
import moment from 'moment';

import CloseIcon from '../../../../../../assets/img/close.svg';
import { NextPaymentDateBody, UserSubscriptionType } from '../types';

interface ChangePaymentDateModalProps {
  isOpen: boolean;
  onSubmit: (res: NextPaymentDateBody) => void;
  onCancel: () => void;
  subscription: UserSubscriptionType | undefined;
  suspended: boolean;
}

const defaultDateFormat = 'DD.MM.YYYY';

function getRangeInDays(start: string, end: string): string[] {
  const from = moment(start, defaultDateFormat);
  const to = moment(end, defaultDateFormat);
  const today = moment();
  const diff = to.diff(from, 'days');
  const range: string[] = [];
  for (let i = 0; i < diff; i++) {
    const newDate = from.clone().add(i, 'days');
    if (newDate.isAfter(today, 'day')) {
      range.push(newDate.format(defaultDateFormat));
    }
  }
  return range;
}

const ChangePaymentDateModal: React.FC<ChangePaymentDateModalProps> = ({
  isOpen,
  onSubmit,
  onCancel,
  subscription,
  suspended
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const currentSubscription = subscription ?? ({} as UserSubscriptionType);
  const { last_order: lastOrder, next_order: nextOrder } = currentSubscription;

  const today = moment();
  const nextPaymentDateAsMoment = moment(nextOrder.payment_date);
  const nextPaymentCountDays = nextPaymentDateAsMoment
    .startOf('day')
    .diff(today.startOf('day'), 'days');
  const nextPaymentDate = nextPaymentDateAsMoment.format('DD.MM');

  const defaultNextPaymentDate = moment(
    suspended ? currentSubscription.renew_date : lastOrder.payment_date
  ).add(23, 'days');

  const maxDaysFromNextPaymentDate = moment(
    suspended ? currentSubscription.renew_date : lastOrder.payment_date
  ).add(currentSubscription.change_payment_date_limit * 2, 'days');

  return (
    <Modal
      width={600}
      visible={isOpen}
      wrapClassName="subscription-modal"
      bodyStyle={{
        padding: 30,
        boxShadow: '0 2px 7px 0 #00000020'
      }}
      maskClosable
      centered
      footer={null}
      onCancel={onCancel}
      closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
    >
      <h2>{t('change-payment-date')}</h2>

      <p>
        {t('next-payment-planned-for', {
          paymentDate: nextPaymentDate
        })}{' '}
        {/* @todo count with 'in-several-days' worked only for PL */}
        {t('in-several-days_2', { count: nextPaymentCountDays })}
      </p>
      <p style={{ marginBottom: 25 }}>
        {/* @todo count with 'payment-date-can-be-postponed' worked only for PL */}
        {t('payment-date-can-be-postponed_2', {
          count: currentSubscription.change_payment_date_limit
        })}
      </p>

      <Form
        form={form}
        layout="vertical"
        name={'payment_date_form'}
        hideRequiredMark
        onFinish={onSubmit}
      >
        <Form.Item
          label={t('next-payment-date')}
          name={'payment_date'}
          rules={[{ required: true, message: t('required-field') }]}
          style={{ marginBottom: 30 }}
        >
          <Select allowClear getPopupContainer={node => node.parentNode}>
            {getRangeInDays(
              defaultNextPaymentDate.format(defaultDateFormat),
              maxDaysFromNextPaymentDate.format(defaultDateFormat)
            ).map((val, key) => (
              <Select.Option
                key={key}
                value={moment(val, defaultDateFormat).format('YYYY-MM-DD')}
              >
                {val}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            loading={false}
            type="primary"
            shape="round"
            block
            size="large"
          >
            {t('save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePaymentDateModal;
