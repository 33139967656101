import { Set } from 'immutable';
import { getEnv } from './env';
import { getLSItem } from './panel/utils';

export const getCookie = cname => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const authorizationInterceptor = config => {
  const token = getLSItem('flutter.access_token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    console.log('no token in localStorage');
    delete config.headers['Authorization'];
  }
  return config;
};

export const authorizationInterceptorError = error => {
  return Promise.reject(error);
};

export const maintenancePath = getEnv('REACT_APP_PUBLIC_APP') + 'maintenance';
export const isMaintenanceOn = () =>
  getEnv('REACT_APP_PUBLIC_MAINTENANCE_MODE') === 'true';

export const maintenanceCheckInterceptor = config => {
  if (isMaintenanceOn()) {
    window.location.href = maintenancePath;
    return {
      headers: {},
      method: config.method,
      url: ''
    };
  }

  return config;
};

export const LANGUAGES = {
  PL: 'pl',
  EN: 'en-GB',
  DE: 'de-DE',
  IT: 'it-IT'
};

export const getDefaultLanguage = () => {
  const browserLanguage =
    LANGUAGES[navigator.language.substring(0, 2).toUpperCase()];
  return getLSItem('language') || browserLanguage || LANGUAGES.PL;
};

const contactLinks = {
  [LANGUAGES.PL]: 'https://sundose.zendesk.com/hc/pl',
  [LANGUAGES.EN]: 'https://sundose.zendesk.com/hc/en-gb',
  [LANGUAGES.DE]: 'https://sundose.zendesk.com/hc/de',
  [LANGUAGES.IT]: 'https://sundose.zendesk.com/hc/it'
};

export const getContactLink = () => contactLinks[getDefaultLanguage()];

export const WC_ORDER_STATUSES = {
  WC_PENDING: 'wc-pending',
  WC_PROCESSING: 'wc-processing',
  WC_ON_HOLD: 'wc-on-hold',
  WC_COMPLETED: 'wc-completed',
  WC_CANCELLED: 'wc-cancelled',
  WC_REFUNDED: 'wc-refunded',
  WC_FAILED: 'wc-failed',
  WC_DELIVERED: 'wc-delivered',
  WC_PRODUCTION: 'wc-production'
};

export const HAS_SUNDOSE_ORDER_STATUS_SET = Set([
  WC_ORDER_STATUSES.WC_PROCESSING,
  WC_ORDER_STATUSES.WC_PRODUCTION,
  WC_ORDER_STATUSES.WC_COMPLETED,
  WC_ORDER_STATUSES.WC_DELIVERED,
  WC_ORDER_STATUSES.WC_FAILED
]);

export const ORDER_PROXY_STATUSES = {
  ORDER_PLACED: 'order_placed',
  PAYMENT_ACCEPTED: 'payment_accepted',
  PRESCRIPTION_VERIFIED: 'prescription_verified',
  IN_MANUFACTURING: 'in_manufacturing',
  WAITING_FOR_COURIER: 'waiting_for_courier',
  IN_DELIVERY: 'in_delivery',
  READY_TO_PICKUP: 'ready_to_pickup',
  DELIVERED: 'delivered',
  RETURNED_TO_SENDER: 'returned_to_sender',
  ORDER_CANCELLED: 'order_cancelled',
  ORDER_REFUNDED: 'order_refunded',
  PAYMENT_FAILED: 'payment_failed'
};
