import { axios } from './index';
import { getEnv } from '../../env';

const baseDjangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');
const mixerSettingsBase = baseDjangoApi + '/mixer/settings';

export const retrieveMixerSettings = async userId =>
  axios
    .get(`${mixerSettingsBase}/${userId}/`, { withCredentials: true })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status === 404) return null;
    });

export const updateMixerSettings = async (userId, json_body) =>
  axios
    .put(`${mixerSettingsBase}/${userId}/`, {
      withCredentials: true,
      json_body
    })
    .then(response => response.data)
    .catch(error => {
      console.log(JSON.stringify(error));
    });

export const postMixerSettings = async json_body =>
  axios
    .post(`${mixerSettingsBase}/`, { withCredentials: true, json_body })
    .then(response => response.data)
    .catch(error => {
      console.log(JSON.stringify(error));
    });
