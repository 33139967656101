import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { setRedirectParams } from './actions';

export const SaveUrlParamsOnInit = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  dispatch(setRedirectParams(search));

  return null;
};
