import { axios } from './index';
import { getEnv } from '../../env';

const baseDjangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');
const userSettingsBase = baseDjangoApi + '/user/settings';

export const retrieveUserSettings = async userId =>
  axios
    .get(`${userSettingsBase}/${userId}/`, { withCredentials: true })
    .then(response => response.data);

export const postUserSettings = async data =>
  axios
    .post(`${userSettingsBase}/`, { withCredentials: true, ...data })
    .then(response => response.data);

export const patchUserSettings = async (userId, data) =>
  axios
    .patch(`${userSettingsBase}/${userId}/`, { withCredentials: true, ...data })
    .then(response => response.data)
    .catch(error => console.log(error));

export const postExperimentData = body =>
  axios
    .post(`${baseDjangoApi}/analytics/experiment/`, body)
    .then(res => res.data);

export const VWOInterceptor = data => {
  const dictionary = window._vwo_exp;

  if (!data || !dictionary) {
    return;
  }

  const [_, experimentId, variationId] = data;

  const { name: experiment_name, comb_n } = dictionary[experimentId];
  const variant_name = comb_n[variationId];

  postExperimentData({ variant_name, experiment_name }).then(() => {});
};

export const resendEmailVerification = () =>
  axios.post(`${baseDjangoApi}/auth/mail_verification/`, {}).then(r => r.data);

export const allowEcommMerging = () =>
  axios
    .post(`${baseDjangoApi}/auth/confirm_ecomm_merging`, {
      allowed_ecomm_merging: true
    })
    .then(r => r.data);
