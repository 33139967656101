import { fromJS, List } from 'immutable';
import {
  actionNamesUserData,
  affiliationActions,
  affiliationStatus
} from '../utils/constants';
import { deserializePrescriptions } from '../utils/deserializers';

const {
  SET_USER_ORDERS,
  SET_SHOULD_FETCH_ORDERS,
  SET_USER_SETTINGS,
  SET_USER_SUBSCRIPTION,
  CANCEL_USER_SUBSCRIPTION,
  SET_TOKEN_DATA,
  SET_PRESCRIPTIONS,
  UPDATE_PRESCRIPTION_NAME,
  ADD_PRESCRIPTION,
  SET_USER,
  SET_SUNDOSE_DATA,
  LOGOUT
} = actionNamesUserData;

const { SET_AFFILIATION } = affiliationActions;

const initialState = {
  orders: List(),
  tokenData: null,
  prescriptions: List(),
  user: null,
  settings: null,
  affiliation: {
    status: affiliationStatus.NONE,
    code: ''
  },
  sundoseData: {
    userType: 'visitor',
    hasSundose: false,
    hasComposition: false,
    awaitingDietitian: false,
    shouldFetchOrders: false,
    sinceDelivered: null
  },
  subscription: {}
};

const userDataReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case LOGOUT: {
      return initialState;
    }
    case SET_USER_ORDERS: {
      return {
        ...state,
        orders: fromJS(data)
      };
    }
    case SET_SHOULD_FETCH_ORDERS: {
      return {
        ...state,
        shouldFetchOrders: data
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: data
      };
    }
    case SET_USER_SETTINGS: {
      return {
        ...state,
        settings: data
      };
    }
    case SET_USER_SUBSCRIPTION: {
      return {
        ...state,
        subscription: data
      };
    }
    case CANCEL_USER_SUBSCRIPTION: {
      return {
        ...state,
        subscription: data || null,
        settings: {
          ...state.settings,
          subscriber: false
        }
      };
    }
    case SET_TOKEN_DATA: {
      return {
        ...state,
        tokenData: data
      };
    }
    case SET_PRESCRIPTIONS: {
      return {
        ...state,
        prescriptions: deserializePrescriptions(data)
      };
    }
    case UPDATE_PRESCRIPTION_NAME: {
      const index = state.prescriptions.findIndex(p => p.get('id') === data.id);
      return {
        ...state,
        prescriptions: state.prescriptions.setIn([index, 'name'], data.name)
      };
    }
    case ADD_PRESCRIPTION: {
      return {
        ...state,
        prescriptions: state.prescriptions.push(fromJS(data))
      };
    }
    case SET_AFFILIATION: {
      return {
        ...state,
        affiliation: data
      };
    }
    case SET_SUNDOSE_DATA: {
      return {
        ...state,
        sundoseData: data
      };
    }
    default:
      return state;
  }
};

export default userDataReducer;
