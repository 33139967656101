import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useOptimize } from '../../../utils';
import './typ.scss';
import TYPSubscriptionIcon from '../../../assets/img/typ-subscription.svg';

export const ThankYouPageSubscription = () => {
  const { t } = useTranslation();
  useOptimize('activateThankYouPage');

  return (
    <div className="typ-wrapper">
      <Helmet>
        <title>Sundose - {t('thank-you-for-buying-sundose')}</title>
      </Helmet>
      <div className="typ-container typ-subscription-container">
        <img src={TYPSubscriptionIcon} alt={'Logo'} />
        <h1>{t('thank-you-for-changing-prescription-in-subscription')}</h1>

        <Button
          className="go-to-dashboard-btn"
          type="link"
          onClick={() => {
            // not using react router on purpose to force app to reload data
            window.location.href = '/app/panel/dashboard';
          }}
        >
          {t('go-to-dashboard')}
        </Button>
      </div>
    </div>
  );
};
