import React from 'react';
import { Link } from 'react-router-dom';
import './affiliation-box.scss';
import { string } from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { affiliationStatus } from '../../utils/constants';

const { PENDING, COMPLETE } = affiliationStatus;

export const AffiliationBox = ({ status, code, withLink }) => {
  const { t } = useTranslation();

  let content;

  switch (status) {
    case COMPLETE:
      content = (
        <>
          <div className="affiliation-box-title">
            {t('affiliation-status-has-coupon-title')}
          </div>
          <div className="affiliation-box-coupon">
            <span>{code}</span>
          </div>
          <div className="affiliation-box-description">
            <Trans
              i18nKey="affiliation-status-has-coupon-description"
              components={{
                str: <strong />
              }}
            />
          </div>
          <div className="affiliation-box-italic">
            {t('affiliation-status-has-coupon-description-more')}
          </div>
          {withLink ? (
            <div className="affiliation-box-link">
              <Link to={`/panel/settings/coupons`}>{t('my-coupons')}</Link>
            </div>
          ) : null}
        </>
      );
      break;

    case PENDING:
      content = (
        <>
          <div className="affiliation-box-title">
            {t('affiliation-status-pending-title')}
          </div>
          <div className="small-width affiliation-box-description">
            {t('affiliation-status-pending-description')}
          </div>
        </>
      );
      break;
    default:
      break;
  }

  return <div className="affiliation-box">{content}</div>;
};

AffiliationBox.propTypes = {
  status: string.isRequired,
  code: string.isRequired
};
