import { addIconsJS } from '../../../../utils/addIcons';
import { iconsSubstances } from '../../../../utils/iconImports';

export const prepareSubstancesData = (data, substances, units) => {
  data = addIconsJS(data, iconsSubstances);
  return data.map(item => {
    const substanceData = substances.get(item.id);
    const unitData = units.get(substanceData.get('display_unit'));
    return {
      ...item,
      key: item.id,
      type: substanceData.get('type'),
      name: substanceData.get('name'),
      amount: item.amount * unitData.get('unit_conversion_multiplier'),
      unit: unitData.get('text'),
      nrv: item.nrv
    };
  });
};
