import React from 'react';
import CloseIcon from '../../assets/img/close.svg';
import infoIcon from '../../assets/img/info-icon.svg';

import { Modal } from 'antd';

import './InfoboxWithModal.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  content: string | React.ReactNode;
};
const InfoboxWithModal: React.FC<Props> = ({ content }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const onCancel = () => setIsOpen(false);

  return (
    <div className="infobox-with-modal">
      {content}

      <img
        onClick={() => setIsOpen(true)}
        className="info-icon"
        src={infoIcon}
        alt="info icon"
      />

      <Modal
        className="infobox-modal"
        width={600}
        visible={isOpen}
        bodyStyle={{
          padding: 30,
          boxShadow: '0 2px 7px 0 #00000020'
        }}
        maskClosable
        centered
        footer={null}
        onCancel={onCancel}
        closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
      >
        <strong>{t('infobox-modal-header-1')}</strong>
        <span>{t('infobox-modal-body-1')}</span>
        <strong>{t('infobox-modal-header-2')}</strong>
        <span>{t('infobox-modal-body-2')}</span>
        <strong>{t('infobox-modal-header-3')}</strong>
        <span>{t('infobox-modal-body-3')}</span>
        {i18n.language === 'pl' && <strong>Do dzieła! :)</strong>}
      </Modal>
    </div>
  );
};

export default InfoboxWithModal;
