import { constants } from '../../utils';
import { retrieveMixerData } from '../../api/mixer';
import { fromJS } from 'immutable';
import { addIcons } from '../../utils/addIcons';
import { capitalizeNameFirstLetters } from '../../utils/modifyDataFunctions';
import { iconsSubstances, iconsSubstanceTypes } from '../../utils/iconImports';

const { mixerDataToLoad, mixerDataNames, actionNamesMixer } = constants;

const {
  LOAD_DATA,
  SET_DISPLAY_UNITS,
  SET_MIXER_INITIALIZED
} = actionNamesMixer;

export const loadMixerData = () => {
  return async dispatch => {
    await Promise.all(
      mixerDataToLoad.map(dataName => retrieveMixerData(dataName))
    )
      .then(responses => {
        responses.forEach((data, i) => {
          const dataName = mixerDataToLoad[i];
          let immutableData = fromJS(data);
          if (dataName === mixerDataNames.SUBSTANCES)
            immutableData = addIcons(immutableData, iconsSubstances);
          if (dataName === mixerDataNames.SUBSTANCE_TYPES)
            immutableData = addIcons(immutableData, iconsSubstanceTypes);
          immutableData = capitalizeNameFirstLetters(immutableData);
          dispatch({ type: LOAD_DATA, dataName, data: immutableData });
        });
        dispatch({ type: SET_DISPLAY_UNITS });
        dispatch({ type: SET_MIXER_INITIALIZED, data: true });
      })
      .catch(() => {
        console.log('error while loading mixer data');
      });
  };
};
