import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, TimePicker } from 'antd';
import { bool, func, instanceOf } from 'prop-types';
import moment from 'moment';
import './time-picker-with-save.scss';

const TimePickerWithSave = ({
  onSaveClick,
  value,
  onChange,
  disabledAll,
  activeEditing,
  ...rest
}) => {
  const { t } = useTranslation();
  const [timePickerValue, setTimePickerValue] = useState(value);
  const [activeEditingState, setActiveEditing] = useState(activeEditing);
  const [openTimePicker, setOpenTimePicker] = useState(false);

  const onSaveButtonClick = useCallback(
    e => {
      onSaveClick(timePickerValue);
      setActiveEditing(false);
      setOpenTimePicker(false);
      e.stopPropagation();
    },
    [timePickerValue, onSaveClick]
  );

  const onTimePickerChange = useCallback(
    time => {
      if (typeof onChange !== 'undefined') onChange(time);
      else setTimePickerValue(time);
    },
    [setTimePickerValue, onChange]
  );

  const onChangeButtonClick = useCallback(() => {
    setActiveEditing(true);
  }, []);

  const onOpenChange = useCallback(
    open => {
      setOpenTimePicker(open);
    },
    [setOpenTimePicker]
  );

  useEffect(() => {
    setTimePickerValue(value);
  }, [value]);

  useEffect(() => {
    setActiveEditing(activeEditing);
  }, [activeEditing]);

  return (
    <TimePicker
      disabled={!activeEditingState || disabledAll}
      {...rest}
      value={timePickerValue}
      onChange={onTimePickerChange}
      allowClear={false}
      format="HH:mm"
      onOpenChange={onOpenChange}
      open={openTimePicker}
      className="time-picker-with-save"
      suffixIcon={
        disabledAll ? (
          <></>
        ) : (
          <>
            {!activeEditingState && (
              <Button
                key="change-button"
                type="link"
                onClick={onChangeButtonClick}
              >
                {t('change')}
              </Button>
            )}
            {activeEditingState && (
              <Button type="link" key="save-button" onClick={onSaveButtonClick}>
                {t('save')}
              </Button>
            )}
          </>
        )
      }
    />
  );
};

TimePickerWithSave.propTypes = {
  onSaveClick: func.isRequired,
  value: instanceOf(moment).isRequired,
  activeEditing: bool,
  disabledAll: bool
};

TimePickerWithSave.defaultProps = {
  activeEditing: false,
  disabledAll: false
};

export default TimePickerWithSave;
