import ashwagandha from '../../../assets/img/substances/ashwagandha.png';
import b1_thiamine from '../../../assets/img/substances/b1_thiamine.png';
import b2_riboflavin from '../../../assets/img/substances/b2_riboflavin.png';
import b3_niacin from '../../../assets/img/substances/b3_niacin.png';
import b5_panthotenic_acid from '../../../assets/img/substances/b5_panthotenic_acid.png';
import b6_pyridoxine from '../../../assets/img/substances/b6_pyridoxine.png';
import b7_biotin from '../../../assets/img/substances/b7_biotin.png';
import b9_folic_acid from '../../../assets/img/substances/b9_folic_acid.png';
import b9_folic_acid_met from '../../../assets/img/substances/b9_folic_acid_met.png';
import b12_cobalamin from '../../../assets/img/substances/b12_cobalamin.png';
import b12_cobalamin_met from '../../../assets/img/substances/b12_cobalamin_met.png';
import bacopa_monnieri from '../../../assets/img/substances/bacopa_monnieri.png';
import bifidobacterium_breve from '../../../assets/img/substances/bifidobacterium_breve.png';
import calcium from '../../../assets/img/substances/calcium.png';
import choline from '../../../assets/img/substances/choline.png';
import chromium from '../../../assets/img/substances/chromium.png';
import copper from '../../../assets/img/substances/copper.png';
import dha from '../../../assets/img/substances/dha.png';
import epa from '../../../assets/img/substances/epa.png';
import ginseng from '../../../assets/img/substances/ginseng.png';
import inositol from '../../../assets/img/substances/inositol.png';
import iodine from '../../../assets/img/substances/iodine.png';
import iron from '../../../assets/img/substances/iron.png';
import l_ornithine from '../../../assets/img/substances/l_ornithine.png';
import l_theanine from '../../../assets/img/substances/l_theanine.png';
import lactobacillus_plantarum from '../../../assets/img/substances/lactobacillus_plantarum.png';
import lactobacillus_rhamnosus from '../../../assets/img/substances/lactobacillus_rhamnosus.png';
import lutein from '../../../assets/img/substances/lutein.png';
import lycopene from '../../../assets/img/substances/lycopene.png';
import magnesium from '../../../assets/img/substances/magnesium.png';
import manganese from '../../../assets/img/substances/manganese.png';
import molybdenum from '../../../assets/img/substances/molybdenum.png';
import potassium from '../../../assets/img/substances/potassium.png';
import q10 from '../../../assets/img/substances/q10.png';
import saccharomyces_boulardii from '../../../assets/img/substances/saccharomyces_boulardii.png';
import selenium from '../../../assets/img/substances/selenium.png';
import silymarin from '../../../assets/img/substances/silymarin.png';
import vitamin_c from '../../../assets/img/substances/vitamin_c.png';
import vitamin_d from '../../../assets/img/substances/vitamin_d.png';
import vitamin_e from '../../../assets/img/substances/vitamin_e.png';
import zinc from '../../../assets/img/substances/zinc.png';

import { Map } from 'immutable';

const iconsSubstances = Map({
  ashwagandha: ashwagandha,
  b1_thiamine: b1_thiamine,
  b2_riboflavin: b2_riboflavin,
  b3_niacin: b3_niacin,
  b5_panthotenic_acid: b5_panthotenic_acid,
  b6_pyridoxine: b6_pyridoxine,
  b7_biotin: b7_biotin,
  b9_folic_acid: b9_folic_acid,
  b9_folic_acid_met: b9_folic_acid_met,
  b12_cobalamin: b12_cobalamin,
  b12_cobalamin_met: b12_cobalamin_met,
  bacopa_monnieri: bacopa_monnieri,
  bifidobacterium_breve: bifidobacterium_breve,
  calcium: calcium,
  choline: choline,
  chromium: chromium,
  copper: copper,
  dha: dha,
  epa: epa,
  ginseng: ginseng,
  inositol: inositol,
  iodine: iodine,
  iron: iron,
  l_ornithine: l_ornithine,
  l_theanine: l_theanine,
  lactobacillus_plantarum: lactobacillus_plantarum,
  lactobacillus_rhamnosus: lactobacillus_rhamnosus,
  lutein: lutein,
  lycopene: lycopene,
  magnesium: magnesium,
  manganese: manganese,
  molybdenum: molybdenum,
  potassium: potassium,
  q10: q10,
  saccharomyces_boulardii: saccharomyces_boulardii,
  selenium: selenium,
  silymarin: silymarin,
  vitamin_c: vitamin_c,
  vitamin_d: vitamin_d,
  vitamin_e: vitamin_e,
  zinc: zinc
});

export default iconsSubstances;
