import React from 'react';
import { any } from 'prop-types';

const CollapseHeader = ({ title, subtitle, extraButton }) => {
  return (
    <div className="sundose-collapse-header">
      <div className="collapse-header-container">
        <div className="header-title">{title}</div>
        <div className="header-subtitle">{subtitle}</div>
      </div>
      <div className="buttons-container collapse-header-buttons">
        {extraButton}
      </div>
    </div>
  );
};

CollapseHeader.propTypes = {
  title: any,
  subtitle: any,
  extraButton: any
};

export default CollapseHeader;
