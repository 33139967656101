import { Button, Form, Input, Select } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { EMAIL_SUNDOSE_CUSTOMERCARE } from '../../../../../utils/constants';
import React, { useEffect } from 'react';
import { useCountryDescriptions } from '../../../../../utils/useCountryDescription';
import { FormInstance } from 'antd/es/form';

type CourierFormProps = {
  form: FormInstance;
  isPending: boolean;
  submitForm: () => void;
  subscriber: boolean;
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
  emailVisible?: boolean | undefined;
  phoneVisible?: boolean | undefined;
  phoneCodes?: any;
};

export const CourierForm: React.FC<CourierFormProps> = ({
  form,
  isPending,
  submitForm,
  subscriber,
  selectedCountry,
  setSelectedCountry,
  emailVisible = false,
  phoneVisible = false,
  phoneCodes
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    handleCountryChange(selectedCountry);
  }, []);

  const countries = Object.values(useCountryDescriptions().toJS() ?? {})
    .filter(x => !['CH', 'FR'].includes(x.isoKey))
    .map(x => ({ value: x.isoKey, label: x.displayName }));

  const handleCountryChange = (value: any) => {
    setSelectedCountry(value);
    form.setFieldsValue({ country: value });
  };

  const defaultRules = [
    { required: true, message: t('required-field') },
    { whitespace: true, message: t('required-field') }
  ];

  return (
    <Form form={form} layout="vertical" hideRequiredMark onFinish={submitForm}>
      <Form.Item
        name={'first_name'}
        label={t('name')}
        rules={[
          { required: true, message: t('required-field') },
          { whitespace: true, message: t('required-field') }
        ]}
      >
        <Input disabled={isPending} />
      </Form.Item>
      <Form.Item name={'last_name'} label={t('surname')} rules={defaultRules}>
        <Input disabled={isPending} />
      </Form.Item>

      <Form.Item label={t('country')} name={'country'} rules={defaultRules}>
        <Select
          value={selectedCountry}
          disabled={subscriber || isPending}
          onChange={handleCountryChange}
        >
          {countries.map(v => (
            <Select.Option key={v.value} value={v.value}>
              {t(v.label)}
            </Select.Option>
          ))}
        </Select>

        {subscriber ? (
          <Trans
            i18nKey="cant-change-country-for-subscription"
            components={{
              linkToEmail: (
                <a
                  className="cc-email"
                  href={`mailto:${EMAIL_SUNDOSE_CUSTOMERCARE}`}
                />
              )
            }}
          />
        ) : null}
      </Form.Item>

      <Form.Item label={t('address')} name={'street'} rules={defaultRules}>
        <Input placeholder={t('street-name')} disabled={isPending} />
      </Form.Item>

      <Form.Item className="form-custom-inline checkbox-form-inline-address">
        <Form.Item name={'building_number'} rules={defaultRules}>
          <Input placeholder={t('building-number')} disabled={isPending} />
        </Form.Item>
        <Form.Item name={'flat_number'}>
          <Input placeholder={t('flat-number')} disabled={isPending} />
        </Form.Item>
      </Form.Item>

      <Form.Item name={'city'} label={t('city')} rules={defaultRules}>
        <Input />
      </Form.Item>
      <Form.Item
        name={'postcode'}
        label={t('postal-code')}
        rules={[
          { required: true, message: t('required-field') },
          { max: 10, message: t('wrong-format') }
        ]}
      >
        <Input placeholder="00000" disabled={isPending} />
      </Form.Item>

      {/*Below fields are hidden but needed to be caught by form object.*/}
      <Form.Item name="address_1" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="address_2" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      <Form.Item name="company" style={{ display: 'none' }}>
        <Input />
      </Form.Item>
      {emailVisible ? (
        <Form.Item
          name={'email'}
          label={t('email')}
          validateTrigger={['onBlur']}
          rules={[
            { required: true, message: t('required-field') },
            { type: 'email', message: t('wrong-format') }
          ]}
        >
          <Input disabled={isPending} />
        </Form.Item>
      ) : (
        <Form.Item name="email" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
      )}

      {phoneVisible ? (
        <Form.Item
          className="form-custom-inline checkbox-form-inline-phone required-field"
          label={t('phone')}
        >
          <Form.Item
            name={'phone_code'}
            rules={[
              { required: true, message: t('required-field') },
              { whitespace: true, message: t('required-field') }
            ]}
          >
            <Select
              className="phone-codes"
              dropdownClassName="phone-codes-dropdown"
              dropdownMatchSelectWidth={false}
              disabled={isPending}
            >
              {Object.keys(phoneCodes).map((key: string) => (
                <Select.Option
                  key={phoneCodes[key].code}
                  value={phoneCodes[key].code}
                  className="phone-codes-option"
                  label={phoneCodes[key].name}
                >
                  <img alt={`flag_${key}`} src={phoneCodes[key].flag} />
                  <span className="hide-in-selection">
                    {`${phoneCodes[key].name} (${phoneCodes[key].code})`}
                  </span>
                  <span className="hide-in-menu">{phoneCodes[key].code}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={'phone_number'}
            rules={[
              { required: true, message: t('required-field') },
              { pattern: /^[0-9]*$/, message: t('use-only-numbers') }
            ]}
          >
            <Input placeholder="000000000" disabled={isPending} />
          </Form.Item>
        </Form.Item>
      ) : (
        <Form.Item label={t('phone')} name="phone" style={{ display: 'none' }}>
          <Input />
        </Form.Item>
      )}

      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          shape="round"
          block
          size="large"
          disabled={isPending}
        >
          {t('save')}
        </Button>
      </Form.Item>
    </Form>
  );
};
