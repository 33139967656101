import React from 'react';
import classNames from 'classnames';
import { oneOf, string } from 'prop-types';

const propTypes = {
  name: oneOf(['arrow-left', 'arrow-right', 'close']).isRequired,
  className: string
};

const defaultProps = {
  className: ''
};

const CustomIcon = ({ name, className: customClassName }) => {
  const className = classNames(`icon-${name}`, {
    [customClassName]: !!customClassName
  });

  return <span className={className} />;
};

CustomIcon.propTypes = propTypes;

CustomIcon.defaultProps = defaultProps;

export default CustomIcon;
