import { useTranslation } from 'react-i18next';
import { OrderedMap, Map } from 'immutable';
import { settingsRouteKeys } from './constants';

const {
  DELIVERY,
  PROFILE,
  PASSWORD_CHANGE,
  SUBSCRIPTION,
  COUPONS
} = settingsRouteKeys;

export const useSettingsRoutes = () => {
  const { t } = useTranslation();

  return Map({
    [PROFILE]: t('profile'),
    [DELIVERY]: t('delivery-address'),
    [PASSWORD_CHANGE]: t('password-change'),
    [SUBSCRIPTION]: t('subscription'),
    [COUPONS]: t('coupons-tab')
  });
};

export const useSettingsMainNavigation = () => {
  const texts = useSettingsRoutes();

  return OrderedMap({
    [PROFILE]: texts.get(PROFILE),
    [DELIVERY]: texts.get(DELIVERY),
    [SUBSCRIPTION]: texts.get(SUBSCRIPTION),
    [COUPONS]: texts.get(COUPONS)
  });
};
