import React from 'react';
import { useTranslation } from 'react-i18next';

const propTypes = {};

const defaultProps = {};

const Legend = () => {
  const { t } = useTranslation();
  return (
    <div className="calendar-legend">
      <div className="legend-item">
        <span className="legend-icon filled" />
        <span className="legend-label">{t('sundose-taken')}</span>
      </div>
      <div className="legend-item">
        <span className="legend-icon disabled" />
        <span className="legend-label">{t('sundose-not-taken')}</span>
      </div>
    </div>
  );
};

Legend.propTypes = propTypes;

Legend.defaultProps = defaultProps;

export default Legend;
