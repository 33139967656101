class PageError404 extends Error {
  constructor() {
    super();

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PageError404);
    }

    this.name = 'PageError404';
    this.message = window.location.href;
  }
}

export default PageError404;
