import React, { useCallback, useEffect, useState } from 'react';
import { func, instanceOf, string } from 'prop-types';
import { List, Map } from 'immutable';
import { Button, Popover } from 'antd';
import {
  ChangeNameIcon,
  EditIcon,
  MoreIcon,
  ShareIcon
} from '../../assets/reactIcons';
import { useTranslation } from 'react-i18next';
import {
  collapsePrescriptionExtraButtons,
  roleNames,
  tokenKeys
} from '../../utils/constants';
import {
  ChangePrescriptionName,
  SharePrescriptionView
} from '../ActionModalsPrescription';
import { getEnv } from '../../../env';
import { useSelector } from 'react-redux';
import { getOrdersForPrescription } from '../../api/legacy';

const { CHANGE_NAME, EDIT, SHARE, NONE } = collapsePrescriptionExtraButtons;

const { ROLES } = tokenKeys;

const { DIETITIAN } = roleNames;

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');

const PrescriptionCollapseExtraButtons = ({ prescription }) => {
  let hiddenButtonsInit = List();

  const { tokenData } = useSelector(state => state.userDataReducer);

  const p = prescription.toJS();

  const hideShareButton = !tokenData.get(ROLES).includes(DIETITIAN);
  if (hideShareButton) hiddenButtonsInit = hiddenButtonsInit.push(SHARE);

  const onExternalDivClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const [activeAction, setActiveAction] = useState(NONE);

  const [hiddenButtons] = useState(hiddenButtonsInit);
  // const [hasOrders, setHasOrders] = useState(null);

  // const fetchData = useCallback(
  //   async id => {
  //     const response = getOrdersForPrescription(id);
  //     await response.then(data => setHasOrders(data && data.length > 0));
  //   },
  //   [setHasOrders]
  // );

  // useEffect(() => {
  //   fetchData(prescriptionId);
  // }, [fetchData, prescriptionId]);

  const onButtonClick = useCallback(
    action => {
      setPopoverVisible(false);
      setActiveAction(action);

      if (action === EDIT)
        window.location.href = mixerBaseUrl + `/${p.id}/summary`;
    },
    [setActiveAction, p.id]
  );

  const resetActiveAction = useCallback(() => {
    setActiveAction(NONE);
  }, []);

  return (
    <div onClick={onExternalDivClick}>
      <SharePrescriptionView
        prescription={prescription}
        onAcceptClick={resetActiveAction}
        onCancelClick={resetActiveAction}
        visible={activeAction === SHARE}
      />
      <ChangePrescriptionName
        prescription={prescription}
        onCloseModal={resetActiveAction}
        visible={activeAction === CHANGE_NAME}
      />
      <Popover
        trigger="click"
        placement="bottomRight"
        overlayClassName="collapse-prescription-extra-popover"
        className="prescription-extra-popover-button-wrapper"
        visible={popoverVisible}
        onVisibleChange={setPopoverVisible}
        content={
          <PrescriptionExtraButtonsMenu
            className="prescription-more-menu-buttons"
            onButtonClick={onButtonClick}
            editDisabled={
              !p.is_editable ||
              p.ready_made ||
              tokenData.get(tokenKeys.USER_ID) !== p.author
            }
            hiddenButtons={hiddenButtons}
          />
        }
      >
        <Button type="link" className="more-button">
          <MoreIcon />
        </Button>
      </Popover>
    </div>
  );
};

PrescriptionCollapseExtraButtons.propTypes = {
  prescription: instanceOf(Map).isRequired
};

export default PrescriptionCollapseExtraButtons;

export const PrescriptionExtraButtonsMenu = ({
  className,
  onButtonClick,
  editDisabled,
  hiddenButtons
}) => {
  const { t } = useTranslation();

  const onShareClick = useCallback(() => {
    onButtonClick(SHARE);
  }, [onButtonClick]);

  const onEditClick = useCallback(() => {
    onButtonClick(EDIT);
  }, [onButtonClick]);

  const onChangeNameClick = useCallback(() => {
    onButtonClick(CHANGE_NAME);
  }, [onButtonClick]);

  const menuButtons = [
    <Button key={CHANGE_NAME} type="link" onClick={onChangeNameClick}>
      <ChangeNameIcon />
      {t('change-name')}
    </Button>,
    <Button
      key={EDIT}
      type="link"
      onClick={onEditClick}
      disabled={editDisabled}
    >
      <EditIcon />
      {t('edit')}
    </Button>,
    <Button key={SHARE} type="link" onClick={onShareClick}>
      <ShareIcon />
      {t('share')}
    </Button>
  ];

  return (
    <div className={`${className} buttons-menu-wrapper`}>
      {menuButtons
        .filter(button => !hiddenButtons.includes(button.key))
        .map(button => (
          <Button key={button.key} {...button.props} />
        ))}
    </div>
  );
};

PrescriptionExtraButtonsMenu.propTypes = {
  className: string,
  onButtonClick: func.isRequired,
  disabledButtons: instanceOf(List)
};

PrescriptionExtraButtonsMenu.defaultProps = {
  className: '',
  disabledButtons: List()
};
