import * as calendarUtils from './calendar';
import * as constants from './constants';
import * as modifyDataFunctions from './modifyDataFunctions';
import * as deserializers from './deserializers';

export { calendarUtils, constants, modifyDataFunctions, deserializers };

export { default as useCurrency } from './useCurrency';
export { default as useOrderStatus } from './useOrderStatus';
export { default as useQuery } from './useQuery';
export { default as useOptimizeAfterLastRender } from './dataLayer/useOptimizeAfterLastRender';
export { default as useOptimize } from './dataLayer/useOptimize';
export { default as useLocaleLink } from './useLocaleLink';

export const saveLoginData = data => {
  setLSItem('flutter.user_id', data.user);
  setLSItem('flutter.authentication_date', new Date().getTime());
  setLSItem('flutter.access_token', data.token);
  setLSItem('flutter.is_temporary_user', Boolean(data.temporary));

  setLSItem('flutter.sundose_auth', JSON.stringify(data));
  setLSItem(
    'flutter.sundose_auth_time',
    new Date().setDate(new Date().getDate() + 30)
  );

  localStorage.removeItem('NEW_OFFER_FLOW_EXPERIMENT_KEY');
};

export const setLSItem = (key, value) => {
  let val = value;

  switch (typeof val) {
    case 'string':
      val = val.charAt(0) === '{' ? `${val}` : `"${val}"`;
      break;
    case 'number':
      val = `"${val}"`;
      break;
    default:
      val = `${val}`;
  }

  localStorage.setItem(key, val);
};

export const getLSItem = key => {
  let val = localStorage.getItem(key) ?? null;

  if (val && val.charAt(0) === `"`) {
    val = val.substring(1, val.length - 1);
  }

  if (val && val.charAt(val.length - 1) === `"`) {
    val = val.substring(0, val.length - 2);
  }

  return val;
};

export const validateTokenTime = () => {
  const time = Number(getLSItem('flutter.sundose_auth_time'));
  const now = new Date().getTime();

  if (time <= now) {
    throw new Error('Token is no longer valid.');
  }
};

export const removeLoginData = () => {
  // cleaning react app
  localStorage.removeItem('flutter.sundose_auth');
  localStorage.removeItem('flutter.sundose_auth_time');
  localStorage.removeItem('flutter.access_token');
  // cleaning flutter app
  localStorage.removeItem('flutter.user_id');
  localStorage.removeItem('flutter.authentication_date');
  localStorage.removeItem('flutter.access_token');
  localStorage.removeItem('flutter.is_temporary_user');
};

export const hideHeader = `
      .ant-page-header {
          display: none!important;
      }
`;
