import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../utils';

const { PL, DE, EN, IT } = LANGUAGES;

const localeLinks = {
  privacyPolicy: {
    [EN]: 'https://help.sundose.io/hc/en-gb/articles/360015278959',
    [PL]: 'https://help.sundose.io/hc/pl/articles/360015278959',
    [DE]: 'https://help.sundose.io/hc/de/articles/360015278959',
    [IT]: 'https://help.sundose.io/hc/en-gb/articles/360015278959' // @TODO temporary until we have translation
    // [IT]: 'https://help.sundose.io/hc/it/articles/360015278959'
  },
  storeRules: {
    [EN]: '/uk/terms/sundose-web-store-rules',
    [PL]: '/pl/terms/sundose-web-store-rules',
    [DE]: '/de/terms/sundose-web-store-rules',
    [IT]: '/it/terms/sundose-web-store-rules'
  }
};
/**
 * Function to get an URL for current language
 * @param {string} linkType - privacyPolicy | storeRules
 */
const useLocaleLink = linkType => {
  const [, i18n] = useTranslation();

  return localeLinks[linkType][i18n.language] || localeLinks[linkType][PL];
};
export default useLocaleLink;
