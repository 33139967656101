import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Button, Checkbox, Form, Spin } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import './secure-form.scss';
import { getEnv } from '../../../env';

import visaLogo from './../../assets/img/offer_icons/visa@3x.png';
import mastercardLogo from './../../assets/img/offer_icons/mastercard@3x.png';
import arrowRight from './../../assets/img/arrow-right.svg';
import { useParams } from 'react-router';
import { LANGUAGES } from '../../../utils';
import { useSelector } from 'react-redux';
import { getCreditCardMask } from '../../api/subscription';

export type SecureFormBody = {
  subscription: string;
  subscription_consent: string;
  subscription_token: string;
};

type SecureFormProps = {
  onSubmit?: (res: SecureFormBody) => void;
  isLoading?: boolean;
  standalone?: boolean;
  changingMode?: boolean;
};

enum AvailableViewModes {
  Checkout = 'checkout',
  ChangeCard = 'change-card',
  RenewPayment = 'renew-payment'
}

const formOptions = {
  style: {
    basic: {
      fontColor: '#000000a6',
      fontSize: '22px',
      fontWeight: '400',
      padding: '50px'
    },
    invalid: {
      fontColor: '#ff4d4f'
    },
    placeholder: {
      fontColor: '#00000040'
    }
  },
  placeholder: {
    number: '',
    date: 'MM / YY',
    cvv: ''
  },
  cardIcon: false,
  lang: 'pl'
};

const loadPayuSDK = (callback: () => void) => {
  const scriptId = 'secureform-payu-sdk';

  const scriptAlreadyExisting = document.getElementById(scriptId);

  if (scriptAlreadyExisting) {
    scriptAlreadyExisting.remove();
  }

  const script = document.createElement('script');

  /**
   * From Doc:
   * PROD: https://secure.payu.com/javascript/sdk
   * SANDBOX: https://secure.snd.payu.com/javascript/sdk
   *
   * From examples:
   * https://merch-prod.snd.payu.com/javascript/sdk ????
   * https://developers.payu.com/pl/card_tokenization.html#secureform
   */

  script.setAttribute('src', getEnv('REACT_APP_PUBLIC_PAYU_SCRIPT_URL'));
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('id', scriptId);

  document.body.appendChild(script);

  script.onload = () => {
    if (callback) callback();
  };
};

export const SecureForm: React.FC<SecureFormProps> = ({
  onSubmit,
  isLoading,
  standalone,
  changingMode
}) => {
  const [t, i18n] = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useSelector(state => state.userDataReducer);

  const [sdkLoaded, setSdkLoaded] = useState<boolean>(false);
  const [sdk, setSdk] = useState<any>(null);

  const [mask, setMask] = useState<string>('');

  const [form] = Form.useForm();

  const { view, language, routeUserId } = useParams<{
    view: string;
    language: string;
    routeUserId?: string;
  }>();

  const lang = (LANGUAGES as Record<string, string>)[language?.toUpperCase()];

  if (lang && i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }

  const maskFormatter = (str: string) => {
    let newMask = '';
    for (let i = 0; i < str.length; i++) {
      if (i % 4 === 0) {
        newMask += ` ${str[i]}`;
      } else {
        newMask += `${str[i]}`;
      }
    }

    return newMask;
  };

  const userId = routeUserId ? Number(routeUserId) : user?.get('user');

  const isInChangingMode =
    changingMode || view === AvailableViewModes.ChangeCard;

  useEffect(() => {
    if (isInChangingMode) {
      getCreditCardMask(userId).then(response => setMask(response.data));
    }
  }, []);

  useLayoutEffect(() => {
    if (sdkLoaded) {
      // @TODO: fix this ts/eslint hack
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const PayuSDK = PayU(getEnv('REACT_APP_PUBLIC_PAYU_POS_ID'));

      const PayuSecureForm = PayuSDK.secureForms();
      const cardNumber = PayuSecureForm.add('number', formOptions);
      const cardDate = PayuSecureForm.add('date', formOptions);
      const cardCvv = PayuSecureForm.add('cvv', formOptions);

      cardNumber.render('#payu-card-number');
      cardDate.render('#payu-card-date');
      cardCvv.render('#payu-card-cvv');

      // Remove PayU default styles which are not really !important ;)
      document.querySelectorAll('.payu-secure-form-iframe').forEach(frame => {
        frame.setAttribute('style', '');
      });

      setSdk(PayuSDK);
    } else {
      loadPayuSDK(() => setSdkLoaded(true));
    }
  }, [sdkLoaded]);

  const formErrorHandler = (errors: string[]) => {
    let message = t('payu-card-data-error');
    if (Array.isArray(errors)) {
      errors.forEach((error: string, index: number) => {
        message = message.concat(index === 0 ? ': ' : ', ', t(error));
        const field = document.querySelector(
          `#${error} .payu-secure-form-iframe`
        );
        field && field.setAttribute('style', 'border: 1px solid #ff4d4f');
      });
    }
  };

  const onButtonClick = () => {
    form
      .validateFields()
      .then(async () => {
        const { body, error, status } = await sdk.tokenize('MULTI');
        // Clear red border from Secure Form fields
        document.querySelectorAll('.payu-secure-form-iframe').forEach(frame => {
          frame.setAttribute('style', '');
        });

        if (status === 'ERROR' || (error && error.messages.length)) {
          const errors: string[] = [];
          error &&
            error.messages.map(
              ({ code }: { code: string; message: string }) => {
                if (code.indexOf('card') !== -1)
                  errors.push('payu-card-number');
                if (code.indexOf('expDate') !== -1)
                  errors.push('payu-card-date');
                if (code.indexOf('cvv') !== -1) errors.push('payu-card-cvv');
              }
            );
          return formErrorHandler(errors);
        }

        const requestData: SecureFormBody = {
          subscription: 'true',
          subscription_consent: t('subscription-agreement-label'),
          subscription_token: body.token
        };

        if (onSubmit) onSubmit(requestData);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.flutter_inappwebview) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.flutter_inappwebview.callHandler('tokenHandler', requestData);
        }
      })
      .catch(formErrorHandler);
  };

  let headerText = '';
  let btnText = t('save');

  switch (view) {
    case AvailableViewModes.Checkout:
    case AvailableViewModes.RenewPayment:
      headerText = t('pay-with-card');
      btnText = t('pay');
      break;
    case AvailableViewModes.ChangeCard:
      headerText = t('change-card');
      break;
    default:
      break;
  }

  return (
    <Form
      form={form}
      className={`ant-form ant-form-vertical ant-form-hide-required-mark padding-10 secure-form-container ${
        standalone && 'standalone-secure-form'
      }`}
    >
      <div className={standalone ? 'card-data-container' : ''}>
        {standalone ? (
          <div className={'card-data-header'}>
            <div className="card-data-header-content">{headerText}</div>
            <div>
              <img
                src={visaLogo}
                height="16"
                alt="Visa"
                style={{ marginRight: 10 }}
              />

              <img src={mastercardLogo} height="16" alt="Mastercard" />
            </div>
          </div>
        ) : null}

        {isInChangingMode ? (
          <>
            <h3 className="change-card-header">{t('current-card')}</h3>
            {mask ? (
              <span style={{ fontSize: 18 }}>{maskFormatter(mask)}</span>
            ) : (
              <Spin />
            )}
            <h3 className="change-card-header">{t('new-card')}</h3>
          </>
        ) : null}

        <div
          dangerouslySetInnerHTML={{
            __html: `
<div>
    <div class="ant-row ant-form-item">
      <div class="ant-col ant-form-item-label">
        <label
          for="payu-card-number"
          class="ant-form-item-required"
          title="${t('payu-card-number')}"
        >
          ${t('payu-card-number')}
        </label>
      </div>
      <div class="ant-col ant-form-item-control">
        <div class="ant-form-item-control-input">
          <div class="ant-form-item-control-input-content" id="payu-card-number">
          </div>
        </div>
      </div>
    </div>
    <div class="ant-row ant-row-space-between form-custom-inline">
      <div class="ant-col colp ant-col-xs-24 ant-col-sm-12 ant-form-item-control">
        <div class="ant-form-item-control-input">
          <div class="ant-form-item-control-input-content">
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-form-item-label">
                <label
                  for="payu-card-date"
                  class="ant-form-item-required"
                  title="${t('payu-card-date')}"
                >
                  ${t('payu-card-date')}
                </label>
              </div>
              <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                  <div class="ant-form-item-control-input-content" id="payu-card-date">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ant-col ant-col-xs-24 ant-col-sm-12 ant-form-item-control">
        <div class="ant-form-item-control-input">
          <div class="ant-form-item-control-input-content">

            <div class="ant-row ant-form-item">
              <div class="ant-col ant-form-item-label">
                <label
                  for="payu-card-cvv"
                  class="ant-form-item-required"
                  title="${t('payu-card-cvv')}"
                >
                  ${t('payu-card-cvv')}
                </label>
              </div>
              <div class="ant-col ant-form-item-control">
                <div class="ant-form-item-control-input">
                  <div class="ant-form-item-control-input-content" id="payu-card-cvv">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>`
          }}
        />

        <Form.Item
          name={'agreement-checkbox'}
          valuePropName="checked"
          className="checkbox-item"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(''),
              message: t('required-field')
            }
          ]}
        >
          <Checkbox className="required">
            <span className="checkbox-inner-content">
              {t('subscription-agreement-label')}
            </span>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            className={'secure-form-bnt-submit'}
            loading={isLoading}
            type="primary"
            shape="round"
            block
            size="large"
            onClick={onButtonClick}
          >
            {btnText} <img src={arrowRight} alt="arrow right" />
          </Button>
        </Form.Item>
      </div>

      <div className={standalone ? 'agreements-container' : ''}>
        <p className={'secure-form-info'}>
          <Trans
            i18nKey="payu-payment-regulations-agreement"
            components={{
              rulesLink: (
                <a href={t('payu-payment-regulations-agreement-url')} />
              )
            }}
          />
        </p>

        <p className="secure-form-info">
          {t('subscription-agreement-description-1')}
        </p>

        <p className="secure-form-info">
          {t('subscription-agreement-description-2')}
        </p>

        <p className={'secure-form-info'}>
          <Trans
            i18nKey="payu-privacy-policy-agreement"
            components={{
              rulesLink: (
                <a
                  href={t('payu-privacy-policy-url')}
                  style={{
                    overflowWrap: 'break-word'
                  }}
                />
              )
            }}
          />
        </p>
      </div>
    </Form>
  );
};
