import React from 'react';
import { InfoBox } from './';
import { Avatar, List } from 'antd';
import * as PropTypes from 'prop-types';

export const InfoBoxWithList = props => {
  return (
    <div className={`info-box-with-list ${props.className}`}>
      <InfoBox bottomButton={props.bottomButton}>
        <List
          itemLayout="horizontal"
          dataSource={props.listData}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<></>}
                title={<div className="info-box-title">{item.title}</div>}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </InfoBox>
    </div>
  );
};

InfoBoxWithList.propTypes = {
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Object)
      ])
    })
  ).isRequired,
  bottomButton: PropTypes.instanceOf(Object),
  className: PropTypes.string
};

InfoBoxWithList.defaultProps = {
  className: ''
};
