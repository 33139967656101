import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import './settings-components.scss';

export const SettingsContentHeader = ({
  title,
  addSaveButton,
  btnText,
  isSubscriptionActive,
  isSusbcriptionSuspended,
  isSubscriptionPage,
  ...rest
}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation();
  const path = match.path.substring(0, match.path.length - 1);
  const prevPath = path.substring(0, path.lastIndexOf('/') + 1);

  const { goBackFunction } = rest;

  return (
    <div className="settings-content-header">
      <Button
        type="link"
        className="go-back-btn"
        onClick={() => {
          if (goBackFunction) {
            goBackFunction();
          } else {
            history.push(prevPath);
          }
        }}
      >
        <LeftOutlined />
        {title}
      </Button>

      {addSaveButton && (
        <Button type="link" className="save-btn" {...rest}>
          {btnText}
        </Button>
      )}
      {isSubscriptionPage ? (
        isSusbcriptionSuspended ? (
          <div className="subscription-status suspended">{t('suspended')}</div>
        ) : isSubscriptionActive ? (
          <div className="subscription-status active">{t('active')}</div>
        ) : (
          <div className="subscription-status">{t('inactive')}</div>
        )
      ) : null}
    </div>
  );
};

SettingsContentHeader.defaultProps = {
  addSaveButton: false
};
