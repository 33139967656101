import React, { Component } from 'react';
import SurveyContext from '../SurveyContext';
import Terminal from './Terminal';
import Intro from './Intro';
import Outro from './Outro';
import Header from './Header';
import Menu from './Menu';
import { getSurvey } from '../DataService';

class Survey extends Component {
  static contextType = SurveyContext;

  async componentDidMount() {
    const { setSurvey, validateUserAndSetId } = this.context;
    const { history } = this.props;
    const {
      match: {
        params: { surveyId }
      }
    } = this.props;

    const validated = await validateUserAndSetId();
    if (!validated) {
      history.push('/panel/auth/quick-register');
      return;
    }
    const response = await getSurvey(surveyId);

    if (response.status === 401) {
      history.push(`/survey/not-authorized`);
    }

    if (response.status === 404) {
      history.push(`/survey/survey-not-found`);
    }

    if (response.status === 200) {
      setSurvey(surveyId, response.data);
    }
  }

  render() {
    const { surveyId, stage } = this.context;
    const {
      match: {
        params: { dietitian }
      }
    } = this.props;
    // phase-1 --> intro to the survey
    // phase-2 --> answering questions
    // phase-3 --> thank you for answering (outro)
    if (!surveyId) {
      return null;
    }


    if (stage === 'phase-1') {
      return <Intro />;
    }

    if (stage === 'phase-2') {
      return (
        <div className="survey-app">
          <Menu />
          <Header />
          <Terminal />
        </div>
      );
    }

    if (stage === 'phase-3') {
      return <Outro dietitian={dietitian} />;
    }

    return null;
  }
}

export default Survey;
