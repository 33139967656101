import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getCalendlyMeeting, getDietitianInfo} from '../../api/legacy';
import DietitianIcon from '../../assets/img/dietitian-icon.svg';
import moment from 'moment';
import {Button} from 'antd';
import {pushToDataLayer} from '../../utils/dataLayer';
import {useTranslation} from "react-i18next";

type OwnDietitian = { first_name: string; last_name: string; image: unknown };

export const DietitianContainer: React.FC<{
  customClassName?: string;
}> = ({customClassName}) => {
  const [t] = useTranslation();
  const [ownDietitian, setOwnDietitian] = useState<OwnDietitian | undefined>(
    undefined
  );
  const [calendlyMeeting, setCalendlyMeeting] = useState<Record<string, string> | undefined>(undefined);
  const [calendlyLink, setCalendlyLink] = useState(
    'https://calendly.com/dietetycy-sundose/konsultacja-z-dietetykiem-sundose'
  );

  const [title, setTitle] = useState<string>(
    t('dietitian-title')
  );
  const [content, setContent] = useState<string>(
    t('dietitian-content')
  );

  const {
    settings: {subscriber}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useSelector(state => state.userDataReducer);

  useEffect(() => {
    getCalendlyMeeting().then(meetings => {
      if (!meetings || !meetings.length) return;

      const today = +new Date();

      setCalendlyMeeting(
        meetings
          .filter((m: { active: boolean }) => m.active)
          .filter((m: { start_time: string }) => {
            const date = Date.parse(m.start_time);
            return date >= today;
          })
          .sort(
            (a: { start_time: string }, b: { start_time: string }) =>
              Date.parse(a.start_time) > Date.parse(b.start_time)
          )?.[0]
      );
    });

    if (subscriber) {
      getDietitianInfo()
        .then(response => {
          const {
            first_name,
            last_name,
            dashboard_info_title,
            dashboard_info_content,
            calendly_link,
            photo,
          } = response;

          if (dashboard_info_title && dashboard_info_title !== '') {
            setTitle(dashboard_info_title);
          }
          if (dashboard_info_content && dashboard_info_content !== '') {
            setContent(dashboard_info_content);
          }

          if (calendly_link === '') {
            setCalendlyLink('https://calendly.com/dietetycy-sundose/konsultacja-z-dietetykiem-sundose');
          } else {
            setCalendlyLink(calendly_link);
          }

          let image = <img alt="Your Dietitian" src={DietitianIcon}/>;
          if (photo !== '') {
            image = (
              <img
                alt="Your Dietitian"
                src={photo + '?auto=compress&auto=enhance&auto=format'}
                srcSet={photo + '?dpr=2'}
              />
            );
          }

          setOwnDietitian({
            first_name,
            last_name,
            image
          });
        })
        .catch(() => {
        });
    }
  }, []);

  return (
    <div className={`dietitian-container ${customClassName || ''}`}>
      <div className={'dietitian-info'}>
        <strong>{title}</strong>
        <p className="dietitian-p">{content}</p>

        {calendlyMeeting ? (
          <div className="dietitian-meeting">
            Umówiona konsultacja <br/>
            {moment(calendlyMeeting?.start_time).format('HH:mm, DD.MM.YYYY')}
          </div>
        ) : (
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              pushToDataLayer('calendly_meeting_schedule_intent');
              window.open(calendlyLink, '_blank');
            }}
          >
            Umów się →
          </Button>
        )}
        <p className="dietitian-p" style={{marginTop: '15px'}}>
          {t('dietitian-content-alt')} <a href={'mailto:' + 'dietetyk@sundose.io'}>dietetyk@sundose.io</a>
        </p>
      </div>
      <div className={'dietitian-data'}>
        {ownDietitian ? (
          <>
            {ownDietitian.image}
            <p className="dietitian-p">
              {ownDietitian.first_name} <br/> {ownDietitian.last_name}
            </p>
          </>
        ) : (
          <img alt="Your Dietitian" src={DietitianIcon}/>
        )}
      </div>
    </div>
  );
};
