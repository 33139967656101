import {
  authorizationInterceptor,
  authorizationInterceptorError,
  maintenanceCheckInterceptor
} from '../../utils';
import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  authorizationInterceptor,
  authorizationInterceptorError
);

axios.interceptors.request.use(maintenanceCheckInterceptor);

export default axios;
