import React, { useEffect } from 'react';
import Calendar from './index';
import { useDispatch, useSelector } from 'react-redux';
import {
  retrieveDatesTaken,
  retrieveCalendarInventory,
  deleteTakenDate,
  takeSundoseOnGivenDay
} from './actions';

export const CalendarDataProvider = () => {
  const dispatch = useDispatch();

  const { datesTaken, inventory } = useSelector(state => state.calendarData);

  const { hasSundose } = useSelector(
    state => state.userDataReducer.sundoseData
  );

  useEffect(() => {
    dispatch(retrieveDatesTaken());
    dispatch(retrieveCalendarInventory());
  }, []);

  const toggleTakingSundose = (date, hasTaken) => {
    if (hasTaken) {
      dispatch(takeSundoseOnGivenDay(date));
    } else {
      dispatch(deleteTakenDate(date));
    }
  };

  return (
    <Calendar
      hasSundose={hasSundose}
      inventory={inventory}
      datesTaken={datesTaken}
      toggleTakingSundose={toggleTakingSundose}
    />
  );
};
