import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'antd';

import { useSubscriptionDiscount } from '../../../../../../utils/useSubscriptionDiscount';
import CloseIcon from '../../../../../../assets/img/close.svg';

type SubscriptionModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  country: string;
};

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({
  isOpen,
  onCancel,
  country
}) => {
  const { t } = useTranslation();
  const subscriptionDiscount = useSubscriptionDiscount(country);
  const startDiscount = `${(subscriptionDiscount.start * 100).toFixed()}%`;
  const maxDiscount = `${(subscriptionDiscount.stop * 100).toFixed()}%`;
  const discountStep = `${subscriptionDiscount.step * 100}%`;
  const secondMonthDiscount = (
    (subscriptionDiscount.start + subscriptionDiscount.step) *
    100
  ).toFixed();

  return (
    <Modal
      width={400}
      visible={isOpen}
      wrapClassName="subscription-modal"
      bodyStyle={{
        padding: 30,
        boxShadow: '0 2px 7px 0 #00000020'
      }}
      maskClosable
      centered
      footer={null}
      onCancel={onCancel}
      closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
    >
      <div className="text-left">
        <h3>
          {t('subscription-info-modal-title')} {maxDiscount}
        </h3>
        <p>
          <Trans
            i18nKey="subscription-info-modal-description-1"
            values={{ maxDiscount, discountStep }}
          />
        </p>
        <div className="subscription-modal-discount-row">
          <span>
            {t('discount')} -{startDiscount}
          </span>
          {t('first-month')}
        </div>
        <div className="subscription-modal-discount-row">
          <span>
            {t('discount')} -{secondMonthDiscount}%
          </span>
          {t('second-month')}
        </div>
        <div className="subscription-modal-discount-row">
          <span>
            {t('discount')} -{maxDiscount}
          </span>
          {t('11th-month-and-next')}
        </div>
        <p>
          <Trans
            i18nKey="subscription-info-modal-description-2"
            values={{ startDiscount }}
          />
        </p>
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
