import React, { Component } from 'react';
import SurveyContext from './SurveyContext';
import GlobalState from './GlobalState';
import SurveyRoutes from './components/Stage';
import './index.scss';

class SurveyApp extends Component {
  static contextType = SurveyContext;

  render() {
    return (
      <GlobalState>
        <div className="survey-style">
          <SurveyRoutes />
        </div>
      </GlobalState>
    );
  }
}

export default SurveyApp;
