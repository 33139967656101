import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  Col,
  Row,
  Form,
  Checkbox,
  notification,
  Modal,
  Spin,
  Button
} from 'antd';

import './coupon-settings.scss';

import {
  getCoupons,
  getReferralTerms,
  setReferralConsent
} from '../../../../../api/affiliation';
import { SettingsContentHeader } from '../../components';
import { AffiliationView } from '../../../AffiliationView';
import InfoIcon from '../../../../../assets/img/info-icon.svg';
import CloseIcon from '../../../../../assets/img/close.svg';
import { setUserSettings } from '../../../../../actions';

type Coupon = {
  id: number;
  amount: number;
  code: string;
  created_at: string;
  currency: string;
  used: boolean;
};

interface Terms {
  content: string;
  is_valid_from: string;
  attachments: Record<string, string>[];
}

type TermsResponse = {
  last: Terms;
  new: Terms;
};

export const CouponSettings: React.FC = () => {
  const { t } = useTranslation();

  const {
    settings
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useSelector(state => state.userDataReducer);

  const { subscriber, subscription_consent_use_code } = settings;

  const dispatch = useDispatch();

  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [agreementModal, setAgreementModal] = useState(false);
  const [agreementRequestPending, setAgreementRequestPending] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [rulesModal, showRulesModal] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(
    subscription_consent_use_code !== null &&
      subscription_consent_use_code !== ''
  );

  useEffect(() => {
    getCoupons().then(setCoupons);
  }, []);

  const [termsObject, setTermsObject] = useState<Terms | null>(null);

  useEffect(() => {
    const getTerms = async () => {
      const terms: TermsResponse = await getReferralTerms();
      const newValidDate = moment(terms.new.is_valid_from);
      const today = moment();
      const isNewValid =
        !terms.last ||
        !terms.last.is_valid_from ||
        !newValidDate.isAfter(today);
      setTermsObject(isNewValid ? terms.new : terms.last);
    };

    rulesModal && !termsObject && getTerms();
  }, [rulesModal]);

  const agreementOnChange = () => {
    setAgreementRequestPending(true);
    const consent = agreementChecked ? null : t('coupon-agreement');

    setReferralConsent(consent)
      .then(() => {
        setAgreementChecked(!agreementChecked);
        notification['success']({
          message: t('success'),
          className: 'notification-style-success'
        });
      })
      .finally(() => {
        dispatch(
          setUserSettings({
            ...settings,
            subscription_consent_use_code: consent
          })
        );
        setAgreementModal(false);
        setAgreementRequestPending(false);
      });
  };

  return (
    <>
      <SettingsContentHeader
        addSaveButton={null}
        btnText={null}
        title={t('coupons-tab')}
        isSubscriptionActive={false}
        isSusbcriptionSuspended={false}
        isSubscriptionPage={false}
      />

      <div className="coupons-container">
        <Row>
          <Col xs={24} md={{ span: 12 }} className="inner-container column-coupon">
            <h3 className="title">
              {t(coupons.length ? 'your-coupons' : 'no-coupons')}
            </h3>
            {coupons.length ? (
              <>
                <div className="visible-on-desktop">
                  <table>
                    <thead>
                      <tr>
                        <th>{t('coupon-code')}</th>
                        <th className="th-amount">
                          {t('amount')}{' '}
                          <img
                            src={InfoIcon}
                            alt="info icon"
                            onClick={() => setInfoModal(true)}
                          />
                        </th>
                        <th>{t('created-date')}</th>
                        <th>{t('status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons.map(item => (
                        <tr key={item.id}>
                          <td
                            className={`coupon-code ${item.used ? 'used' : ''}`}
                          >
                            {item.code}
                          </td>
                          <td>
                            {item.amount} {item.currency}
                          </td>
                          <td>
                            {moment(item.created_at).format('DD.MM.YYYY')}
                          </td>
                          <td className={item.used ? '' : 'active'}>
                            {t(item.used ? 'status-used' : 'status-unused')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="visible-on-mobile">
                  {coupons.map(item => (
                    <div className="coupon-row" key={item.id}>
                      <Row>
                        <Col xs={12}>{t('coupon-code')}</Col>
                        <Col
                          xs={12}
                          className={`coupon-code ${item.used ? 'used' : ''}`}
                        >
                          {item.code}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>{t('amount')}</Col>
                        <Col xs={12}>
                          {item.amount} {item.currency}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>{t('created-date')}</Col>
                        <Col xs={12}>
                          {moment(item.created_at).format('DD.MM.YYYY')}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>{t('status')}</Col>
                        <Col xs={12} className={item.used ? '' : 'active'}>
                          {t(item.used ? 'status-used' : 'status-unused')}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>

                <Modal
                  width={400}
                  visible={agreementModal}
                  wrapClassName="subscription-modal"
                  bodyStyle={{
                    padding: 30,
                    boxShadow: '0 2px 7px 0 #00000020'
                  }}
                  maskClosable
                  centered
                  footer={null}
                  onCancel={() => setAgreementModal(false)}
                  closeIcon={
                    <img src={CloseIcon} width="16" alt="close icon" />
                  }
                >
                  <div className="coupon-agreement-modal">
                    <p>
                      {t(
                        agreementChecked
                          ? 'coupon-disagreement'
                          : 'coupon-agreement'
                      )}
                    </p>

                    <Button
                      shape="round"
                      className="ant-primary-button"
                      onClick={() => setAgreementModal(false)}
                    >
                      {t('cancel-btn')}
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      className="ant-primary-button"
                      onClick={agreementOnChange}
                      loading={agreementRequestPending}
                    >
                      {t('confirm')}
                    </Button>
                  </div>
                </Modal>

                <Modal
                  width={400}
                  visible={infoModal}
                  wrapClassName="subscription-modal"
                  bodyStyle={{
                    padding: 30,
                    boxShadow: '0 2px 7px 0 #00000020'
                  }}
                  maskClosable
                  centered
                  footer={null}
                  onCancel={() => setInfoModal(false)}
                  closeIcon={
                    <img src={CloseIcon} width="16" alt="close icon" />
                  }
                >
                  <h3>{t('referral-amount-info-title')}</h3>
                  <p>{t('referral-amount-info-description')}</p>
                </Modal>
              </>
            ) : (
              t('copy-code-and-send-to-your-friends')
            )}

            {coupons.length && subscriber ? (
              <Form layout="vertical">
                <Form.Item>
                  <Checkbox
                    checked={agreementChecked}
                    onChange={() => setAgreementModal(true)}
                  >
                    {t('coupon-agreement')}
                  </Checkbox>
                </Form.Item>
              </Form>
            ) : null}
          </Col>
          <Col xs={24} md={{ span: 11, offset: 1 }}>
            <AffiliationView withLink={false} />

            <div className="referral-rules">
              <span onClick={() => showRulesModal(true)}>
                {t('referral-rules-link')}
              </span>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        width={800}
        visible={rulesModal}
        wrapClassName="subscription-modal"
        bodyStyle={{
          padding: 30,
          boxShadow: '0 2px 7px 0 #00000020'
        }}
        maskClosable
        centered
        footer={null}
        onCancel={() => showRulesModal(false)}
        closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
      >
        {termsObject ? (
          <div
            dangerouslySetInnerHTML={{ __html: termsObject.content }}
            style={{
              maxHeight: '80vh',
              overflowY: 'scroll',
              marginTop: 20
            }}
          />
        ) : (
          <Spin />
        )}
      </Modal>
    </>
  );
};
