import { axios } from './index';
import { getEnv } from '../../env';

const baseDjangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');

const baseURL = baseDjangoApi + '/sharing';

export const retrieveAffiliationCode = async userId =>
  axios.get(`${baseURL}/code/${userId}/`).then(response => response.data);

export const getCoupons = () =>
  axios.get(`${baseURL}/coupons/`).then(r => r.data);

export const setReferralConsent = consent =>
  axios.post(`${baseURL}/consent/`, { consent });

export const getReferralTerms = () =>
  axios.get(`${baseDjangoApi}/terms/recommendation`).then(r => r.data);
