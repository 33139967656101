import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import * as Sentry from '@sentry/browser';
import { getEnv } from './env';

const buildMeta = process.env.REACT_APP_BUILD_META;
const appVersion = buildMeta ? buildMeta.split(' ').slice(-1)[0] : buildMeta;

if (getEnv('REACT_APP_PUBLIC_ENVIRONMENT') !== 'devlocal') {
  Sentry.init({
    dsn:
      'https://2157051eb51d4cd195b0b27526d89bef@o382211.ingest.sentry.io/5210744',
    environment: getEnv('REACT_APP_PUBLIC_ENVIRONMENT'),
    release: appVersion
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
