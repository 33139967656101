import React from 'react';

import FormBool from './Bool';
import FormOption from './Option';
import FormMulti from './Multi';
import FormString from './String';
import FormInteger from './Integer';
import FormNumber from './Number';

const Form = ({ answerType, question, currentQuestionAnswer }) => {
  switch (answerType) {
    case 'string':
      return (
        <FormString
          key={question.id}
          question={question}
          currentQuestionAnswer={currentQuestionAnswer}
        />
      );

    case 'number':
      return (
        <FormNumber
          key={question.id}
          question={question}
          currentQuestionAnswer={currentQuestionAnswer}
        />
      );

    case 'integer':
      return (
        <FormInteger
          key={question.id}
          question={question}
          currentQuestionAnswer={currentQuestionAnswer}
        />
      );

    case 'bool':
      return <FormBool key={question.id} />;

    case 'option':
      return <FormOption key={question.id} />;

    case 'multi_option':
      return <FormMulti key={question.id} />;

    default:
      return null;
  }
};

export default Form;
