import { exclusionsCategories } from '../constants';
import { useIconsPrescriptionLegend } from './index';
import { List } from 'immutable';

export const useIconsHiddenExclusions = allExclusions => {
  const iconsPrescriptionLegend = useIconsPrescriptionLegend();

  if (!allExclusions) return List();

  return allExclusions
    .filter(
      (val, key) =>
        val.get('category') === exclusionsCategories.HIDDEN &&
        iconsPrescriptionLegend.get(key) !== undefined &&
        key !== 'small_capsules'
    )
    .map((val, key) => {
      const legendData = iconsPrescriptionLegend.get(key);
      return {
        key,
        name: legendData.name,
        icon: legendData.icon
      };
    })
    .valueSeq();
};
