import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PanelApp from './panel/PanelApp';
import NoMatch from './UtilityViews/NoMatch';
import SurveyApp from './survey/SurveyApp';
import useMomentLocalization from './useMomentLocalization';
import { ConfigProvider } from 'antd';
import useAntDesignLocale from './useAntDesignLocale';
import MaintenancePage from './UtilityViews/MaintenancePage';
import { isMaintenanceOn, maintenancePath } from './utils';
import { dataLayerPushLoginStatus } from './panel/utils/dataLayer/dataLayerPushLoginStatus';
import i18n from './i18n';
import { VWOInterceptor } from './panel/api/userSettings';
import { getLSItem } from './panel/utils';

function App() {
  useMomentLocalization();
  const language = useAntDesignLocale();
  const token = getLSItem('flutter.access_token');

  useEffect(() => {
    if (!isMaintenanceOn() && !token) {
      // OLD Universal Analytics event:
      dataLayerPushLoginStatus(null);
    }

    if (window.VWO) {
      window.VWO.push(['onVariationApplied', VWOInterceptor]);
    }
  }, [token, window.VWO]);

  if (isMaintenanceOn() && window.location.href !== maintenancePath) {
    window.location.href = maintenancePath;
    return null;
  }

  return (
    <ConfigProvider locale={language}>
      <Helmet>
        <html lang={i18n.language} />
        <title>Sundose</title>
      </Helmet>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/maintenance">
            <MaintenancePage />
          </Route>
          <Route path="/panel">
            <PanelApp />
          </Route>
          <Route path="/survey">
            <SurveyApp />
          </Route>
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
