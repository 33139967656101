import React from 'react';
import { useTranslation } from 'react-i18next';

type OfferPriceSubscribersProps = {
  nextOrder: any;
  currencyText: string;
};

const OfferPriceSubscribers: React.FC<OfferPriceSubscribersProps> = ({
  nextOrder,
  currencyText,
}) => {
  const { t } = useTranslation();
  return (
    <div className="offer-price-summary-price-row">
      <strong>{`${nextOrder?.subscription_price?.toFixed(2)} ${currencyText}`}</strong>
      <div>
        {`${t('subscription')} -${(nextOrder?.discount * 100).toFixed()}% `}
        <s>{`${nextOrder?.prescription_price.toFixed(2)} ${currencyText}`}</s>
        {nextOrder?.coupon ? (
          <p className="offer-price-summary-price-row">
            {t('subscription-active-coupon')}
            <span> &quot;{nextOrder?.coupon}&quot;</span>
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default OfferPriceSubscribers;
