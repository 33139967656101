import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider } from 'antd';
import moment from 'moment';

import useCurrency, { priceForCurrency } from '../../../utils/useCurrency';
import { countryCurrency, routeMainPaths } from '../../../utils/constants';
import usePackagingPrice from '../../../utils/usePackagingPrice';
import { getEnv } from '../../../../env';

import { PrescriptionPreferencesBoxWrapper } from '../../../components/PrescriptionPreferencesBox';
import { Omega3InfoBox } from './Omega3InfoBox';
import OfferPriceSubscribers from './OfferPriceSubscribers';
import OfferPrice from './OfferPrice';
import {
  WarrantyQuality,
  WarrantyReimbursement
} from '../../../components/Warranty';

import mineralsLogo from '../../../../assets/img/substance_types/minerals.svg';
import eyeIcon from '../../../../assets/img/eye.svg';
import lockIcon from '../../../assets/img/offer_icons/lock.png';
import visaLogo from '../../../assets/img/offer_icons/visa.png';
import mastercardLogo from '../../../assets/img/offer_icons/mastercard.png';
import payULogo from '../../../assets/img/offer_icons/payU.svg';
import paypalLogo from '../../../assets/img/offer_icons/paypal.svg';
import blikIcon from '../../../assets/img/offer_icons/blik.png';
import {
  getItemsFromPrescription,
  pushToDataLayer
} from '../../../utils/dataLayer';
import { useSelector } from 'react-redux';
import { LANGUAGES } from '../../../../utils';

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');

export const OfferPrescription = ({
  prescription,
  isSubscriber,
  nextSubscriptionOrder,
  lastSubscriptionOrder,
  subscriptionPrice,
}) => {
  const { exchange_rates, baseline_prices } = useSelector(
    state => state.panelMixerData
  );

  const { paid_transactions_count } = useSelector(
    state => state.userDataReducer.settings
  );

  const { t } = useTranslation();
  const currencies = useCurrency();
  const { id, price, json_body, updated_at } = prescription;
  const { exclusions, country_norms_code } = json_body;
  const prescriptionCurrency = currencies.get(
    countryCurrency[country_norms_code]
  );
  const packagingPrice = usePackagingPrice(country_norms_code);

  const pricePrescription = priceForCurrency(price, prescriptionCurrency);
  const packagePrice = priceForCurrency(packagingPrice, prescriptionCurrency);
  const summaryPrice = pricePrescription + packagePrice;
  const currencyText = prescriptionCurrency.get('text');

  const smallCapsules = exclusions.includes('small_capsules');
  const dateBeforeOmegaFix = new Date(updated_at) < new Date(2020, 5, 12);
  const showOmega3InfoBox = smallCapsules && dateBeforeOmegaFix;

  /**
   * @TODO repeated in SubscriptionSettings: make generic function / component?
   */
  const nextOrderDate = moment(nextSubscriptionOrder?.payment_date);
  const nextOrderDateFormatted = nextOrderDate.format('dddd DD.MM');
  const today = moment();
  const nextOrderDaysLeft = nextOrderDate
    .startOf('day')
    .diff(today.startOf('day'), 'days');

  const { campaign_name, campaign_valid_to, is_campaign } = prescription;

  const campaignValid = moment().isBefore(moment(campaign_valid_to));

  const checkoutToRedirectTo =
    is_campaign && campaignValid && campaign_name === 'CHRISTMAS'
      ? 'gift-checkout'
      : 'checkout';

  return (
    <>
      <Divider />
      <div className="offer-price-parts">
        <div className="offer-price-row">
          {t('cost-ingredients-sachets')}
          <span>
            {isSubscriber ? nextSubscriptionOrder?.prescription_price.toFixed(2): pricePrescription} {currencyText}
          </span>
        </div>
      </div>
      <Divider />

      <div className="offer-action-price">
        <div className="offer-price-summary">
          <div className="offer-price-summary-description">
            {t('sundose-offer-price-summary')}
          </div>
          {isSubscriber ? (
            <OfferPriceSubscribers
              nextOrder={nextSubscriptionOrder}
              currencyText={currencyText}
            />
          ) : (
            <OfferPrice
              country={country_norms_code}
              summaryPrice={summaryPrice}
              currencyText={currencyText}
              packagePrice={packagePrice}
            />
          )}
        </div>
        {!showOmega3InfoBox && (
          <>
            <div className="offer-action-buttons">
              <Button type="primary" ghost href={mixerBaseUrl} shape="round">
                {t('create-new-composition')} →
              </Button>

              {isSubscriber ? (
                <div className="subscription-status-active">
                  ✓ {t('subscription-active')}
                </div>
              ) : (
                <Button
                  type="primary"
                  href={`${mixerBaseUrl}/${id}/${checkoutToRedirectTo}`}
                  shape="round"
                  onClick={() =>
                    pushToDataLayer('add_to_cart', {
                      button_label: t('buy-same-prescription', {
                        lng: LANGUAGES.EN
                      }),
                      paid_transactions_count,
                      process_type:
                        paid_transactions_count > 0
                          ? 'retention'
                          : 'acquisition',
                      formulation_category: prescription.form,
                      items: getItemsFromPrescription(
                        prescription,
                        baseline_prices,
                        exchange_rates
                      )
                    })
                  }
                >
                  {t('buy-same-prescription')} →
                </Button>
              )}
            </div>
            {isSubscriber ? (
              <div className="subscription-dates-and-settings">
                {t('planned-payment')}: {nextOrderDateFormatted}{' '}
                {/* @todo count with 'in-several-days' worked only for PL */}
                {t('in-several-days_2', { count: nextOrderDaysLeft })}
                <Link to={'/panel/settings/subscription/'}>
                  {t('subscription-settings')} →
                </Link>
              </div>
            ) : null}
            <div className="offer-payment-methods">
              <div className="offer-payment-methods-wrapper">
                <img src={lockIcon} alt="lock" />
                <img src={blikIcon} alt="Blik" />
                <img src={payULogo} alt="PayU" />
                <img src={paypalLogo} alt="PayPal" />
                <img src={visaLogo} alt="Visa" />
                <img src={mastercardLogo} alt="Mastercard" />
              </div>
            </div>
          </>
        )}
      </div>

      {showOmega3InfoBox && <Omega3InfoBox prescriptionId={prescription.id} />}

      <div className="offer-summary">
        <div className="offer-summary-header">
          {isSubscriber
            ? t('prescription-in-subscription')
            : t('summary-last-prescription')}
        </div>
        <div className="summary-box">
          <div className="summary-box-header">
            {t('components')}
            <Button type="link">
              <Link
                to={`${routeMainPaths.COMPOSITION_PRESCRIPTION}${id}/details`}
              >
                {t('see')} →
              </Link>
            </Button>
          </div>
          <div className="summary-box-body">
            <img alt="logo" src={mineralsLogo} />
            <span>
              {t('prescription')} #{id}
            </span>
          </div>
        </div>
        <PrescriptionPreferencesBoxWrapper
          exclusions={exclusions}
          flavour={prescription.flavour}
        />
      </div>

      {/*<div className="offer-warranties">*/}
      {/*  <Col xs={11}>*/}
      {/*    /!*<WarrantyQuality />*!/*/}
      {/*  </Col>*/}
      {/*</div>*/}
    </>
  );
};
