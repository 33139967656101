import { Map } from 'immutable';
import moment from 'moment';
import { getLastOrder } from '../../api/legacy';
import { setUserOrders } from '../user/OrdersView/actions';
import { setSundoseData, setToken, setUser } from './actions';
import { setParseTokenData } from '../../actions';
import { initSessionUserSettings } from '../../utils/initSessionUserSettings';
import { pushToDataLayer } from '../../utils/dataLayer';

import Cookies from 'universal-cookie';
import { getLSItem } from '../../utils';

const cookies = new Cookies();

/*
parameter values:
  visitor = don't have an account (default parameter!);
  lead = have an account but didn't buy anything;
  hot_visitor = have temporary user account and ddn't buy anything;
  trial = have an account and bought 1 Sundose;
  customer = have an account and bought 2 Sundoses;
  loyal = have an account and bought 3 Sundoses;
 */
export function getUserType(howManyOrders) {
  if (howManyOrders >= 3) return 'loyal';
  if (howManyOrders === 2) return 'customer';
  if (howManyOrders === 1) return 'trial';

  return 'lead';
}

export function replaceURLForFlutterApp(target) {
  const elements = target?.replace('/panel/', '').split('/') ?? [];

  switch (elements[0]) {
    case 'reorder':
      return `/profile/formulation/${elements[1]}/checkout`;
    default:
      return target;
  }
}

export const loginUser = async (token, auth, dispatch, loginPage) => {
  dispatch(setToken(token));
  dispatch(setParseTokenData());
  dispatch(setUser(Map(auth)));

  const settings = await initSessionUserSettings(token, dispatch);
  const paidOrders = settings.paid_transactions_count || 0;
  const orders = await getLastOrder();
  const order = orders[0]?.price ? orders[0] : null;

  const userType = getUserType(paidOrders);

  dispatch(setUserOrders(orders.filter(o => o.price)));

  dispatch(
    setSundoseData({
      userType,
      shouldFetchOrders: true,
      hasSundose: !!order,
      hasComposition: order && order.prescription_id !== null,
      awaitingDietitian: false,
      sinceDelivered: order
        ? moment().diff(moment(order.date_created_at).add(3, 'days'), 'days')
        : null
    })
  );

  // // OLD Universal Analytics Event:
  // dataLayerPushLoginStatus(orders);

  // NEW GA4 Events:
  pushToDataLayer(undefined, {
    user_id: auth.user,
    language_chosen: getLSItem('language')
  });

  if (loginPage) {
    pushToDataLayer('login', {
      user_type: userType,
      method: 'email', // Login method chosen (eg. email, facebook, apple_id,)
      registered_user: true, // Registered user
      paid_transactions_count: settings.paid_transactions_count, // User segmentation based on their purchase history (eg. visitor, lead, trial, customer, loyal)
      active_subscriber: settings.subscriber, // Active subscriber (eg. true, false)
      login_page: loginPage // Login page (eg. 'standard login, health survey login')
    });
  }

  cookies.set('process_type', paidOrders > 0 ? 'retention' : 'acquisition', {
    path: '/'
  });

  cookies.set('isLoggedIn', true, { path: '/' });

  return settings;
};
