import React, { Component, useCallback, useState } from 'react';
import { ActionModal } from '../../../components/ActionModal';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, notification } from 'antd';
import * as PropTypes from 'prop-types';
import './saved-prescriptions-view.scss';
import { receiveSharedPrescription } from '../../../api/mixer';
import { notificationTypes } from '../../../utils/constants';
import { useDispatch } from 'react-redux';
import { addPrescription } from './actions';

const { SUCCESS } = notificationTypes;

const fieldKeys = {
  PRESCRIPTION_ID: 'prescription_id',
  PIN_NUMBER: 'pin_number'
};

const AddPrescriptionModal = ({ visible, onClose, form }) => {
  const { t } = useTranslation();
  const [dataLoading, setDataLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  const onSubmit = useCallback(() => {
    const { PRESCRIPTION_ID, PIN_NUMBER } = fieldKeys;
    form.validateFields(async (err, values) => {
      if (!err) {
        setDataLoading(true);
        const response = await receiveSharedPrescription(
          values[PRESCRIPTION_ID].trim(),
          values[PIN_NUMBER].trim()
        );
        setDataLoading(false);

        if (response && response.errorResponse) {
          setErrorMessage(response.errorResponse.data.detail);
        } else {
          dispatch(addPrescription(response));
          onClose();
          notification[SUCCESS]({
            message: t('prescription-successfully-added'),
            className: 'notification-style-success'
          });
        }
      }
    });
  }, [dispatch, form, t, onClose]);

  return (
    <ActionModal
      visible={visible}
      title={t('add-composition')}
      okText={t('add') + ' →'}
      onCancelClick={onClose}
      onAcceptClick={onSubmit}
      confirmLoading={dataLoading}
      className="add-composition"
    >
      <div className="add-prescription-modal-body-inner">
        <AddPrescriptionForm form={form} t={t} />
        {errorMessage && (
          <div className="add-prescription-error-message">{errorMessage}</div>
        )}
      </div>
    </ActionModal>
  );
};

AddPrescriptionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export const AddPrescriptionModalForm = Form.create()(
  withTranslation()(AddPrescriptionModal)
);

class AddPrescriptionForm extends Component {
  render() {
    const { t, form } = this.props;
    const { getFieldDecorator, getFieldError, isFieldTouched } = form;
    const { PRESCRIPTION_ID, PIN_NUMBER } = fieldKeys;

    // Only show error after a field is touched.
    const errors = {
      [PRESCRIPTION_ID]:
        isFieldTouched(PRESCRIPTION_ID) && getFieldError(PRESCRIPTION_ID),
      [PIN_NUMBER]: isFieldTouched(PIN_NUMBER) && getFieldError(PIN_NUMBER)
    };
    return (
      <Form layout="vertical">
        <Form.Item
          validateStatus={errors[PRESCRIPTION_ID] ? 'error' : ''}
          help={errors[PRESCRIPTION_ID] || ''}
          label={t('number-id-prescription')}
          required={false}
        >
          {getFieldDecorator(PRESCRIPTION_ID, {
            rules: [{ required: true, message: t('input-required') }]
          })(<Input />)}
        </Form.Item>
        <Form.Item
          validateStatus={errors[PIN_NUMBER] ? 'error' : ''}
          help={errors[PIN_NUMBER] || ''}
          label={t('number-pin')}
          required={false}
        >
          {getFieldDecorator(PIN_NUMBER, {
            rules: [{ required: true, message: t('input-required') }]
          })(<Input />)}
        </Form.Item>
      </Form>
    );
  }
}
