import React from 'react';
import sundoseImage from '../assets/img/sundose-image.svg';
import sundoseLogo from '../assets/img/logo.svg';
import shapeImage from '../assets/img/shape-01.svg';
import shapeWave from '../assets/img/shape-02.svg';
import { getEnv } from '../../env';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="survey-error">
      <div className="wrapper">
        <div className="row">
          <div className="col-wide">
            <div className="survey-error-wrapper">
              <img className="shape shape-small" src={shapeImage} alt="shape" />
              <img className="shape shape-big" src={shapeImage} alt="shape" />
              <img className="shape shape-wave" src={shapeWave} alt="shape" />
              <a href="/" className="btn-logo">
                <img src={sundoseLogo} alt="logo" />
              </a>
              <div className="survey-error-content">
                <img
                  className="sundose-sun-image"
                  src={sundoseImage}
                  alt="sun"
                />
                <div className="top">
                  <div>
                    <span role="img" aria-label="hmm">
                      🤔
                    </span>
                    <b>Hmm,</b>
                  </div>
                  <p>{t('survey-not-found-info')}</p>
                </div>
                <div className="bottom">
                  <Link
                    className="button"
                    to={`/survey/id/${getEnv('REACT_APP_PUBLIC_SURVEY_ID')}`}
                  >
                    {t('go-to-survey')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
