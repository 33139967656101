import { authActionNames } from '../utils/constants';

const { SET_TOKEN } = authActionNames;

const initialState = {
  isLoggedIn: false,
  token: null
};

const authReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case SET_TOKEN: {
      return {
        ...state,
        token: data,
        isLoggedIn: data !== null
      };
    }
    default:
      return state;
  }
};

export default authReducer;
