import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { LinkExpiredIcon } from '../../../../assets/reactIcons';

const ResetPasswordLinkExpired = () => {
  const [t] = useTranslation();
  const { search } = useLocation();
  return (
    <Row className="reset-password-link-expired-container">
      <Col span={24}>
        <LinkExpiredIcon />
        <h1 className="reset-password-link-expired-container--title">
          {t('This link has expired.')}
        </h1>
        <h4 className="reset-password-link-expired-container--subtitle">
          {t('Send a link to reset your password again.')}
        </h4>
        <div className="reset-password-link-expired-container--buttons-container">
          <Link to={`/panel/auth/reset-password/request${search}`}>
            <Button type="primary">{t('Resend a link')}</Button>
          </Link>
          <Button type="link" href="/">
            {t('Go back to Home page')}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default ResetPasswordLinkExpired;
