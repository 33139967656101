import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, notification, Row } from 'antd';
import moment from 'moment';

import {
  cancelSubscription,
  fetchUserSubscriptions,
  renewSubscription,
  saveCancellationReason,
  suspendSubscription,
  updateSubscription
} from '../../../../../api/subscription';
import { useOrderStatus } from '../../../../../utils';
import {
  routeMainPaths,
  settingsRouteKeys
} from '../../../../../utils/constants';
import { ORDER_PROXY_STATUSES } from '../../../../../../utils';
import {
  cancelUserSubscription,
  setUserSubscription
} from '../../../../../actions';

import { AwaitData } from '../../../../..//components/AwaitData';
import { SettingsContentHeader } from '../../components';
import OrderCard from './components/OrderCard';
import InactiveCard from './components/InactiveCard';
import ChangeCardModal from './components/ChangeCardModal';
import CancelSubscriptionModal from './components/CancelSubscriptionModal';
import ChangePaymentDateModal from './components/ChangePaymentDateModal';
import {
  CancellationFormValues,
  CardChangedBody,
  NextPaymentDateBody,
  UserSubscriptionType
} from './types';

import './subscription-settings.scss';
import { pushToDataLayer } from '../../../../../utils/dataLayer';
import { RenewPaymentButton } from '../../../../../components/RenewPaymentButton';
import SuspendSubscriptionModal from './components/SuspendSubscriptionModal';
import PreCancelSubscriptionModal from './components/PreCancelSubscriptionModal';
import RenewSubscriptionModal from './components/RenewSubscriptionModal';

export const SubscriptionSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    subscription,
    settings: { subscription_renewal_count }
  } = useSelector((state: any) => state.userDataReducer);
  const userSubscription: UserSubscriptionType = subscription ?? null;

  const [isLoading, setLoading] = useState(true);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [isPreCancelModalOpen, setIsPreCancelModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);
  const [isPaymentDateModalOpen, setIsPaymentDateModalOpen] = useState(false);
  const [cancelSubmitButtonLoading, setCancelSubmitButtonLoading] = useState(
    false
  );

  const orderStatuses = useOrderStatus(true);

  const successNotification = (message: string) =>
    notification.success({
      message,
      className: 'notification-style-success'
    });

  useEffect(() => {
    const getUserSubscription = async () => {
      setLoading(true);
      const response = await fetchUserSubscriptions();
      // For now there will be always only one subscription object in the response array
      dispatch(setUserSubscription(response[0]));
      setLoading(false);
    };
    userSubscription && Object.keys(userSubscription).length > 0
      ? setLoading(false)
      : getUserSubscription();

    const [field, referrer] = window.location.search
      .replace('?', '')
      .split('=');

    if (userSubscription && field === 'referrer' && referrer === 'card-swap') {
      successNotification(t('card-data-changed'));
    }
  }, [userSubscription]);

  const {
    active: isActive,
    last_order: lastOrder,
    next_order: nextOrder,
    change_payment_date_limit,
    used_suspensions: usedSuspensions,
    suspension_available_date: nextSuspendDate,
    suspended
    // recurring
  } = userSubscription || {};

  const currentSubscriptionId = userSubscription?.id || 0;

  const today = moment();
  const lastOrderDate = moment(lastOrder?.payment_date);
  const lastOrderDateFormatted = lastOrderDate.format('DD.MM.YY');
  const lastOrderNextPaymentDate = lastOrderDate.format('DD.MM');
  const lastOrderNextPaymentDaysLeft = lastOrderDate.diff(today, 'days');

  const nextOrderDate = moment(nextOrder?.payment_date);
  const nextOrderDateFormatted = nextOrderDate.format('dddd DD.MM');
  const nextOrderDaysLeft = nextOrderDate
    .startOf('day')
    .diff(today.startOf('day'), 'days');

  const onSubscriptionCancelled = (data: CancellationFormValues[]) => {
    setCancelSubmitButtonLoading(true);

    saveCancellationReason(data)
      .then(() => {
        cancelSubscription(currentSubscriptionId)
          .then(() => {
            successNotification(t('subscription-cancelled'));
            dispatch(cancelUserSubscription());
            setIsCancelModalOpen(false);

            pushToDataLayer('subscription_cancel', {
              subscription_id: currentSubscriptionId, // Unique subscription ID
              subscription_renewal_count, // number of subscription renewal from CRM
              subscription_progressive_discount_value: `${
                lastOrder.discount * 100
              }%`, // discount from CRM
              subscription_cancel_reason: data
                .map(x =>
                  x.reason_key.replace('subscription_cancellation_', '')
                )
                .join(', ') // selected resign reason
            });
            setCancelSubmitButtonLoading(false);
          })
          .catch(err => {
            console.warn(err);
            setCancelSubmitButtonLoading(false);
          });
      })
      .catch(err => {
        console.warn(err);
        setCancelSubmitButtonLoading(false);
      });
  };

  const onSubscriptionSuspended = (suspension_time: number) => {
    suspendSubscription(currentSubscriptionId, { suspension_time })
      .then(() => {
        successNotification(t('subscription-suspended'));
        dispatch(setUserSubscription(undefined));

        pushToDataLayer('subscription_suspended', {
          subscription_id: currentSubscriptionId
        });

        setIsSuspendModalOpen(false);
      })
      .catch(console.warn);
  };

  const onSubscriptionRenewed = () => {
    renewSubscription(currentSubscriptionId)
      .then(() => {
        successNotification(t('subscription-renewed'));
        dispatch(setUserSubscription(undefined));

        pushToDataLayer('subscription_renewed', {
          subscription_id: currentSubscriptionId
        });

        setIsRenewModalOpen(false);
      })
      .catch(console.warn);
  };

  const openSubscriptionPreCancelModal = () => {
    setIsPreCancelModalOpen(true);

    // pushToDataLayer('subscription_cancel_intent', {
    //   subscription_id: currentSubscriptionId, // Unique subscription ID
    //   subscription_renewal_count, // number of subscription renewal from CRM
    //   subscription_progressive_discount_value: `${lastOrder.discount * 100}%` // discount from CRM
    // });
  };

  const openSubscriptionCancelModal = () => {
    setIsCancelModalOpen(true);

    pushToDataLayer('subscription_cancel_intent', {
      subscription_id: currentSubscriptionId, // Unique subscription ID
      subscription_renewal_count, // number of subscription renewal from CRM
      subscription_progressive_discount_value: `${lastOrder.discount * 100}%` // discount from CRM
    });
  };

  const openSuspendSubscriptionModal = () => {
    setIsSuspendModalOpen(true);

    pushToDataLayer('subscription_suspend_intent', {
      subscription_id: currentSubscriptionId // Unique subscription ID
      // subscription_renewal_count, // number of subscription renewal from CRM
      // subscription_progressive_discount_value: `${lastOrder.discount * 100}%` // discount from CRM
    });
  };

  const openRenewSubscriptionModal = () => {
    setIsRenewModalOpen(true);

    pushToDataLayer('subscription_renew_intent', {
      subscription_id: currentSubscriptionId // Unique subscription ID
      // subscription_renewal_count, // number of subscription renewal from CRM
      // subscription_progressive_discount_value: `${lastOrder.discount * 100}%` // discount from CRM
    });
  };

  const openPaymentDateChangeModal = () => {
    pushToDataLayer('subscription_payment_date_edit_intent', {
      subscription_id: currentSubscriptionId
    });
    setIsPaymentDateModalOpen(true);
  };

  const openCardModal = () => {
    pushToDataLayer('subscription_card_edit_intent', {
      subscription_id: currentSubscriptionId
    });
    setIsCardModalOpen(true);
  };

  const onDeliveryAddressChange = () => {
    pushToDataLayer('subscription_delivery_address_edit_intent', {
      subscription_id: currentSubscriptionId
    });
  };

  const onPaymentDateChanged = (body: NextPaymentDateBody) => {
    updateSubscription(currentSubscriptionId, body)
      .then(() => {
        successNotification(t('next-payment-date-changed'));
        dispatch(setUserSubscription(undefined));

        pushToDataLayer('subscription_payment_date_edit', {
          subscription_id: currentSubscriptionId
        });

        setIsPaymentDateModalOpen(false);
      })
      .catch(console.warn);
  };

  const onCardChanged = (body: CardChangedBody) => {
    setIsRequestPending(true);
    updateSubscription(currentSubscriptionId, {
      token: body.subscription_token
    })
      .then(response => {
        setIsRequestPending(false);

        pushToDataLayer('subscription_card_edit', {
          subscription_id: currentSubscriptionId
        });

        if (response.payment_url) {
          window.location.href = response.payment_url;
        } else {
          successNotification(t('card-data-changed'));
          setIsCardModalOpen(false);
        }
      })
      .catch(() => {
        setIsRequestPending(false);
      });
  };

  const orderStatus = lastOrder
    ? orderStatuses.get(lastOrder.status, null)
    : ''; // we get PROXY statuses from API and map them to WooCommerce statuses

  const isPaymentFailed =
    lastOrder?.status === ORDER_PROXY_STATUSES.PAYMENT_FAILED;

  const hasNextOrder = nextOrder && nextOrder.payment_date;

  return isLoading ? (
    <AwaitData loading={isLoading} />
  ) : (
    <>
      <SettingsContentHeader
        addSaveButton={null}
        btnText={null}
        title={t('subscription')}
        isSubscriptionActive={isActive}
        isSusbcriptionSuspended={suspended}
        isSubscriptionPage
      />

      <Row className="subscription-settings" gutter={[{ xs: 4, sm: 40 }, 16]}>
        <Col xs={22} md={12}>
          {lastOrder ? (
            <OrderCard
              header={t('last-order')}
              prescriptionId={lastOrder.prescription_id}
              prescriptionCountryNormsCode={
                lastOrder.prescription_country_norms_code
              }
              price={lastOrder.subscription_price}
              currencyValue={lastOrder.currency}
              discount={lastOrder.discount}
              activeCoupon={lastOrder.coupon}
            >
              {orderStatus ? (
                <div className="subscription-status-and-payment">
                  <div className={orderStatus.className + ' status-badge'}>
                    {orderStatus.description}
                  </div>
                  {!isActive && isPaymentFailed ? (
                    <RenewPaymentButton order={lastOrder} />
                  ) : null}
                </div>
              ) : null}
              <p>
                {lastOrder?.payment_date ? (
                  isPaymentFailed ? (
                    <span>
                      {t('next-payment-attempting', {
                        count: lastOrderNextPaymentDaysLeft,
                        date: lastOrderNextPaymentDate
                      })}
                    </span>
                  ) : (
                    <>
                      {t('paid-date')}: {lastOrderDateFormatted}
                    </>
                  )
                ) : null}
              </p>
              <p>
                {t('order-no')} {lastOrder.id}
              </p>
            </OrderCard>
          ) : (
            <InactiveCard />
          )}
        </Col>
        {isActive && hasNextOrder ? (
          <Col xs={22} md={12}>
            <OrderCard
              header={t('next-order')}
              prescriptionId={nextOrder.prescription_id}
              prescriptionCountryNormsCode={
                lastOrder.prescription_country_norms_code
              }
              price={nextOrder.subscription_price}
              currencyValue={nextOrder.currency ?? lastOrder?.currency}
              discount={nextOrder.discount}
              activeCoupon={nextOrder.coupon}
            >
              {suspended ? (
                <>
                  <p>
                    {t('subscription-suspended-until', {
                      suspendUntilDate: nextOrderDate.format('DD.MM.YYYY')
                    })}
                  </p>
                  <Button
                    type="primary"
                    shape="round"
                    block
                    size="large"
                    onClick={openRenewSubscriptionModal}
                  >
                    {t('renew-subscription-now')}
                  </Button>
                </>
              ) : null}
              <p className="next-payment-date">
                {t('planned-payment')}: {nextOrderDateFormatted}{' '}
                {/* @todo count with 'in-several-days' worked only for PL */}
                {t('in-several-days_2', { count: nextOrderDaysLeft })}
              </p>
              {isActive ? (
                <p>
                  <Button type="link" onClick={openPaymentDateChangeModal}>
                    {t('edit-payment-date')}
                  </Button>
                </p>
              ) : null}
            </OrderCard>
          </Col>
        ) : null}
      </Row>
      {isActive && hasNextOrder ? (
        <div className="subscription-footer">
          <Button type="link" onClick={openCardModal}>
            {t('change-payment-card')}
          </Button>
          <Link
            to={`${routeMainPaths.SETTINGS}${settingsRouteKeys.DELIVERY}`}
            onClick={onDeliveryAddressChange}
          >
            <Button type="link">{t('change-delivery-address')}</Button>
          </Link>
          {!suspended ? (
            <Button type="link" onClick={openSuspendSubscriptionModal}>
              {t('suspend-subscription')}
            </Button>
          ) : null}
          <Button type="link" onClick={openSubscriptionPreCancelModal}>
            {t('cancel-subscription')}
          </Button>

          <ChangeCardModal
            isOpen={isCardModalOpen}
            isLoading={isRequestPending}
            onSubmit={onCardChanged}
            onCancel={() => setIsCardModalOpen(false)}
          />

          <PreCancelSubscriptionModal
            isOpen={isPreCancelModalOpen}
            onCancel={() => setIsPreCancelModalOpen(false)}
            openChangeDatesModal={() => {
              setIsPreCancelModalOpen(false);
              openPaymentDateChangeModal();
            }}
            openSuspendModal={() => {
              setIsPreCancelModalOpen(false);
              openSuspendSubscriptionModal();
            }}
            openCancelModal={() => {
              setIsPreCancelModalOpen(false);
              openSubscriptionCancelModal();
            }}
            currentDiscount={nextOrder?.discount || lastOrder?.discount || 0.15}
            paymentDateLimit={change_payment_date_limit || 30}
            suspended={suspended}
          />

          <CancelSubscriptionModal
            isOpen={isCancelModalOpen}
            onSubmit={onSubscriptionCancelled}
            onCancel={() => setIsCancelModalOpen(false)}
            openChangeDatesModal={() => {
              setIsCancelModalOpen(false);
              openPaymentDateChangeModal();
            }}
            currentDiscount={nextOrder?.discount || lastOrder?.discount || 0.15}
            paymentDateLimit={change_payment_date_limit || 30}
            submitButtonLoading={cancelSubmitButtonLoading}
          />

          {isActive && !suspended ? (
            <SuspendSubscriptionModal
              nextOrderDate={nextOrderDate}
              usedSuspensions={usedSuspensions}
              nextSuspendDate={nextSuspendDate}
              isOpen={isSuspendModalOpen}
              onSubmit={onSubscriptionSuspended}
              onCancel={() => setIsSuspendModalOpen(false)}
              openChangeDatesModal={() => {
                setIsCancelModalOpen(false);
                openPaymentDateChangeModal();
              }}
              currentDiscount={
                nextOrder?.discount || lastOrder?.discount || 0.15
              }
            />
          ) : null}

          {suspended ? (
            <RenewSubscriptionModal
              usedSuspensions={usedSuspensions}
              nextOrderDate={nextOrderDate}
              isOpen={isRenewModalOpen}
              onSubmit={onSubscriptionRenewed}
              onCancel={() => setIsRenewModalOpen(false)}
            />
          ) : null}

          <ChangePaymentDateModal
            suspended={suspended}
            subscription={userSubscription}
            isOpen={isPaymentDateModalOpen}
            onSubmit={onPaymentDateChanged}
            onCancel={() => setIsPaymentDateModalOpen(false)}
          />
        </div>
      ) : null}
    </>
  );
};
