import React, { Component } from 'react';
import Velocity from 'velocity-animate';
import SurveyContext from '../SurveyContext';
import loadingIcon from '../assets/images/gif.gif';
import { withTranslation } from 'react-i18next';
import { getUserId, pushToDataLayer } from '../../panel/utils/dataLayer';
import { getUserSettings } from '../DataService';

class Intro extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);

    this.state = {};

    this.stageBox = React.createRef();
  }

  componentDidMount() {
    const { finishSurvey, surveyId: survey_id } = this.context;

    const stageBox = this.stageBox.current;

    Velocity(stageBox, { opacity: 1 }, { duration: 999 }).then(() => {
      finishSurvey();
    });

    const userId = getUserId();

    getUserSettings(userId).then(response => {
      const { paid_transactions_count } = response;

      pushToDataLayer('survey_complete', {
        survey_id,
        user_id: getUserId(),
        process_type: paid_transactions_count > 0 ? 'retention' : 'acquisition'
      });
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div id="outro_stage" ref={this.stageBox}>
        <div className="wrap">
          <div className="right_column">
              <h2 className="loading">{t('just-one-moment')}</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Intro);
