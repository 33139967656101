import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import { getOrders } from '../../../api/legacy';
import { setUserOrders } from './actions';
import { useOptimize } from '../../../utils';

import { CollapseOrder } from '../../../components/CollapseOrders';
import '../SavedPrescriptionsView/saved-prescriptions-view.scss';
import { setSundoseData } from '../../auth/actions';
import moment from 'moment';
import InfoboxWithModal from '../../../components/InfoboxWithModal/InfoboxWithModal';

const OrdersView = () => {
  const { t } = useTranslation();
  useOptimize('activateOrdersList');

  const [expandAll, setExpandAll] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();

  const { orders, sundoseData } = useSelector(state => state.userDataReducer);

  useEffect(() => {
    if (sundoseData.shouldFetchOrders) {
      setLoadingData(true);

      getOrders()
        .then(data => {
          dispatch(setUserOrders(data));
          setLoadingData(false);
        })
        .finally(() => {
          setLoadingData(false);

          dispatch(
            setSundoseData({
              ...sundoseData,
              shouldFetchOrders: false
            })
          );
        });
    }
  }, [sundoseData.onlyFirstOrderFetched]);

  const toggleExpand = useCallback(() => {
    setExpandAll(!expandAll);
  }, [expandAll, setExpandAll]);

  const hasOrders = orders.toJS().length > 0;

  return (
    <>
      <div className="box-header-with-button">
        <div className="title">{t('Orders in production')}</div>
        <Button type="link" onClick={toggleExpand}>
          {!expandAll && t('expand-lists')}
          {expandAll && t('hide-all')}
        </Button>
      </div>
      <div className="box-description">
        {t('orders-in-production-description')}
      </div>

      <Spin spinning={loadingData}>
        {hasOrders ? (
          <CollapseOrder
            listOfOrders={orders}
            expandAll={expandAll}
            expandFirstOnInit={true}
          />
        ) : loadingData ? null : (
          <InfoboxWithModal
            content={t('no-orders-available')}
            modalContent={t('no-orders-available-modal')}
          />
        )}
      </Spin>
    </>
  );
};

export default OrdersView;
