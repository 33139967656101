import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { signUpTemporaryUser } from '../../api/auth';
import { useHistory } from 'react-router';
import { pushCompleteRegistrationEvent } from '../../utils/analyticEventsHelpers';
import { loginUser } from './utlis';
import { useDispatch } from 'react-redux';
import { getEnv } from '../../../env';
import { saveLoginData } from '../../utils';

export const TemporaryUserRegister = () => {
  const { location, push } = useHistory();
  const dispatch = useDispatch();

  const surveyFromUrl = location.search.includes('surveyId')
    ? location.search.replace('?surveyId=', '')
    : null;
  const surveyId = surveyFromUrl || getEnv('REACT_APP_PUBLIC_SURVEY_ID');

  useEffect(() => {
    signUpTemporaryUser().then(data => {
      saveLoginData({ ...data, temporary: true });
      pushCompleteRegistrationEvent(data.user);

      loginUser(
        data.token,
        data,
        dispatch,
        'after temporary register form'
      ).then(() => {
        push(`/survey/id/${surveyId}`);
      });
    });
  }, []);

  return <Spin />;
};
