import React, {Component} from 'react';
import Velocity from 'velocity-animate';
import SurveyContext from '../SurveyContext';
import Form from './Form';
import AnimationActions from '../utils/AnimationActions';
import {getUserId, pushToDataLayer} from '../../panel/utils/dataLayer';

class Terminal extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);
    this.state = {};

    this.questionBox = React.createRef();
    this.answerBox = React.createRef();
    this.contextRef = React.createRef();
  }

  componentDidMount() {
    const {terminalAction, updateTerminalAction} = this.context;

    const questionBox = this.questionBox.current;

    this.contextRef = this.context;

    if (terminalAction === AnimationActions.idle) {
      Velocity(questionBox, {opacity: 1}, {duration: 50}).then(
        () => {
          updateTerminalAction(AnimationActions.awaitAnswer);
        }
      );
    }

    this.updateDataLayer();
  }

  getEngFullText(question) {
    return question.localizedFullText.find(t => t.language.includes('en'))
      .value;
  }

  updateDataLayer() {
    const survey_question_position = this.context.questionIndex;
    const survey_question_id = this.context.questionIds[
      survey_question_position
      ];
    const survey_question = this.getEngFullText(
      this.context.questions[survey_question_id]
    );

    if (
      !this.state.last_survey_question_position ||
      survey_question_position > this.state.last_survey_question_position
    ) {
      this.setState({
        last_survey_question_position: survey_question_position,
        last_survey_question_id: survey_question_id,
        last_survey_question: survey_question
      });
    }

    // last_question should always be the latest question (highest index) to which user had reached
    const last_question =
      this.context.questionIndex < this.state.last_survey_question_position ||
      this.context.questionIndex < this.contextRef.questionIndex
        ? {
          text: this.state.last_survey_question,
          id: this.state.last_survey_question_id,
          position: this.state.last_survey_question_position + 1
        }
        : {
          text: survey_question,
          id: survey_question_id,
          position: survey_question_position + 1
        };

    const sqp = survey_question_position + 1;

    pushToDataLayer('survey_question_shown', {
      user_id: getUserId(),
      survey_question,
      survey_question_id,
      survey_question_position: this.formatQuestionPosition(sqp),
      last_survey_question: last_question.text,
      last_survey_question_id: last_question.id,
      last_survey_question_position: this.formatQuestionPosition(
        last_question.position
      ),
      survey_id: this.context.surveyId
    });
  }

  formatQuestionPosition(questionPosition) {
    if (questionPosition < 10) {
      return `0${questionPosition}`;
    }

    return `${questionPosition}`;
  }

  componentDidUpdate() {
    const {
      terminalAction,
      updateTerminalAction,
      animate,
      animationInProgress,
      animationStarted,
      animationFinished
    } = this.context;
    const answerBox = this.answerBox.current;
    const questionBox = this.questionBox.current;

    if (this.context.questionIndex !== this.contextRef.questionIndex) {
      this.updateDataLayer();
      this.contextRef = this.context;
    }

    if (terminalAction === AnimationActions.awaitAnswer && animate) {
      Velocity(answerBox, {opacity: 1}, {duration: 50, delay: 0})
        .then(() => {
          if (animationInProgress) {
            animationFinished();
          }
        });
    }

    if (terminalAction === AnimationActions.goToQuestion) {
      updateTerminalAction(AnimationActions.nextQuestion);
    }

    if (terminalAction.animationName === AnimationActions.answered) {
      if (animationInProgress) {
        return;
      }
      animationStarted();
      Velocity(answerBox, {opacity: 0}, {duration: 50});

      Velocity(
        questionBox,
        {opacity: 0},
        {delay: 0, duration: 50}
      ).then(terminalAction.animationComplete);
    }

    if (terminalAction.animationName === AnimationActions.slideQuestionToLeft) {
      if (animationInProgress) {
        return;
      }
      animationStarted();
      Velocity(answerBox, {opacity: 0}, {duration: 50});

      Velocity(
        questionBox,
        {opacity: 0 },
        {delay: 0, duration: 50}
      ).then(terminalAction.animationComplete);
    }

    if (terminalAction === AnimationActions.nextQuestion) {
      Velocity(questionBox, {opacity: 0}, {duration: 50}).then(() => {
        updateTerminalAction(AnimationActions.movingRight);
      });
    }

    if (terminalAction === AnimationActions.prevQuestion) {
      Velocity(questionBox, {opacity: 0}, {duration: 50}).then(() => {
        updateTerminalAction(AnimationActions.movingLeft);
      });
    }

    if (terminalAction === AnimationActions.movingRight) {
      Velocity(questionBox, {opacity: 1}, {duration: 50}).then(
        () => {
          Velocity(
            questionBox,
            {opacity: 1},
            {duration: 50, delay: 0}
          );

          updateTerminalAction(AnimationActions.awaitAnswer);
        }
      );
    }

    if (terminalAction === AnimationActions.movingLeft) {
      Velocity(questionBox, {opacity: 1}, {duration: 50}).then(
        () => {
          Velocity(
            questionBox,
            {opacity: 1},
            {duration: 50, delay: 0}
          );

          updateTerminalAction(AnimationActions.awaitAnswer);
        }
      );
    }
  }

  render() {
    const {
      questionIndex,
      questionIds,
      questions,
      answeredQuestions
    } = this.context;

    const questionId = questionIds[questionIndex];
    const question = questions[questionId];
    const currentQuestionAnswer = answeredQuestions.get(questionId, null);

    return (
      <div id="terminal" className={`${question.answerType}`}>
        <div className="inner-term">
          <h3 ref={this.questionBox}>{question.fullText}</h3>
        </div>
        <div ref={this.answerBox} id="answers">
          <Form
            question={question}
            answerType={question.answerType}
            currentQuestionAnswer={currentQuestionAnswer}
          />
        </div>
      </div>
    );
  }
}

export default Terminal;
