import React, { Component } from 'react';
import SurveyContext from '../../SurveyContext';
import { withTranslation } from 'react-i18next';

class Bool extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  submitAnswer = e => {
    const { setStandardAnswer } = this.context;
    const { value } = e.target;

    setStandardAnswer(value);
  };

  render() {
    const { t } = this.props;
    const { answeredQuestions, questionIndex, questionIds } = this.context;
    const currentQuestionAnswer = answeredQuestions.get(
      questionIds[questionIndex],
      null
    );
    return (
      <div className="wrapper">
        <div className="form-answer">
          <button
            onClick={this.submitAnswer.bind(this)}
            type="button"
            value="true"
            className={`btn ${
              currentQuestionAnswer
                ? currentQuestionAnswer.value === true
                  ? 'btn-full'
                  : 'btn-ghost'
                : 'btn-ghost'
            }`}
          >
            {t('yes')}
          </button>
        </div>
        <div className="form-answer">
          <button
            onClick={this.submitAnswer.bind(this)}
            type="button"
            value="false"
            className={`btn ${
              currentQuestionAnswer
                ? currentQuestionAnswer.value === false
                  ? 'btn-full'
                  : 'btn-ghost'
                : 'btn-ghost'
            }`}
          >
            {t('no')}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Bool);
