import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSettingsMainNavigation } from '../../../../utils/useSettings';
import { SettingsNavigationAccordion } from '../../../../components/SettingsNavigationAccordion';
import { signOut as signOutAction } from '../../../auth/actions';
import './settings-components.scss';

export const SettingsMainNavigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const signOut = useCallback(() => {
    dispatch(signOutAction());
  }, [dispatch]);

  const { t } = useTranslation();
  const settingsMainNavigation = useSettingsMainNavigation();

  return (
    <div className="settings-main-navigation">
      <SettingsNavigationAccordion
        title={t('settings')}
        dataSource={settingsMainNavigation}
        onChange={key => history.push(`${key}/`)}
      />

      <Button type="primary" className="sign-out-btn" onClick={signOut}>
        {t('sign-out')}
      </Button>
    </div>
  );
};
