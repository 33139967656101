import React from 'react';

const CloseIcon = () => (
  <i className="icon icon-close-x">
    <svg width="11px" height="11px" viewBox="0 0 11 11" version="1.1">
      <title>1A0FA469-B771-43DE-8406-5E8D6B99D9A1</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mixer-2.0-Zestaw-skladniki-popup"
          transform="translate(-1377.000000, -53.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <g id="Group-3">
            <g id="Group" transform="translate(1364.000000, 40.000000)">
              <path
                d="M19.6562341,18.4062371 L23.7187244,22.4687274 C23.7812243,22.5520607 23.8124742,22.6458105 23.8124742,22.7499768 C23.8124742,22.854143 23.7812243,22.9374763 23.7187244,22.9999762 L22.9999762,23.7187244 C22.9374763,23.7812243 22.854143,23.8124742 22.7499768,23.8124742 C22.6458105,23.8124742 22.5520607,23.7812243 22.4687274,23.7187244 L18.4062371,19.6562341 L14.3437468,23.7187244 C14.2604135,23.7812243 14.1666637,23.8124742 14.0624975,23.8124742 C13.9583312,23.8124742 13.8749979,23.7812243 13.8124981,23.7187244 L13.0937498,22.9999762 C13.0312499,22.9374763 13,22.854143 13,22.7499768 C13,22.6458105 13.0312499,22.5520607 13.0937498,22.4687274 L17.1562401,18.4062371 L13.0937498,14.3437468 C13.0312499,14.2604135 13,14.1666637 13,14.0624975 C13,13.9583312 13.0312499,13.8749979 13.0937498,13.8124981 L13.8124981,13.0937498 C13.8749979,13.0312499 13.9583312,13 14.0624975,13 C14.1666637,13 14.2604135,13.0312499 14.3437468,13.0937498 L18.4062371,17.1562401 L22.4687274,13.0937498 C22.5520607,13.0312499 22.6458105,13 22.7499768,13 C22.854143,13 22.9374763,13.0312499 22.9999762,13.0937498 L23.7187244,13.8124981 C23.7812243,13.8749979 23.8124742,13.9583312 23.8124742,14.0624975 C23.8124742,14.1666637 23.7812243,14.2604135 23.7187244,14.3437468 L19.6562341,18.4062371 Z"
                id=""
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </i>
);

export default CloseIcon;
