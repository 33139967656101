import HardCapsule from '../../../assets/img/prescription_legend_icons/hard_capsule.svg';
import SoftCapsule from '../../../assets/img/prescription_legend_icons/soft_capsule.svg';
import Vegan from '../../../assets/img/prescription_legend_icons/vegan.svg';
import Powder from '../../../assets/img/prescription_legend_icons/powder.svg';
import SmallCapsules from '../../../assets/img/prescription_legend_icons/small_capsules.svg';
import { Map } from 'immutable';
import { useTranslation } from 'react-i18next';

const useIconsPrescriptionLegend = () => {
  const { t } = useTranslation();

  return Map({
    powder: {
      name: t('prescription-legend-name-powder'),
      icon: Powder
    },
    capsule_hard: {
      name: t('prescription-legend-name-hard-capsule'),
      icon: HardCapsule
    },
    capsule_soft: {
      name: t('prescription-legend-name-soft-capsule'),
      icon: SoftCapsule
    },
    vegan: {
      name: t('prescription-legend-name-vegan'),
      icon: Vegan
    }
  });
};

export default useIconsPrescriptionLegend;
