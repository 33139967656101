import React, { useCallback } from 'react';
import ShadowBox from '../../ShadowBox';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Calendar as AntdCalendar } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import './month-calendar.scss';

const ButtonGroup = Button.Group;

const MonthCalendar = ({ onNextClick, onPrevClick, ...props }) => {
  const prevMonth = useCallback(
    (value, onChange) => {
      const prevMonth = value.clone().month(value.month() - 1);
      onChange(prevMonth);
      onPrevClick(value, prevMonth);
    },
    [onPrevClick]
  );

  const nextMonth = useCallback(
    (value, onChange) => {
      const nextMonth = value.clone().month(value.month() + 1);
      onChange(nextMonth);
      onNextClick(value, nextMonth);
    },
    [onNextClick]
  );

  const headRenderer = ({ value, onChange }) => {
    return (
      <div className="calendar-header">
        <h2 className="vertical-center-children calendar-date">
          {value.format('MMMM YYYY')}
        </h2>
        <ButtonGroup className="calendar-navigation">
          <Button
            className="prev-month-button"
            icon={<LeftOutlined />}
            onClick={() => prevMonth(value, onChange)}
          />
          <Button
            className="next-month-button"
            icon={<RightOutlined />}
            onClick={() => nextMonth(value, onChange)}
          />
        </ButtonGroup>
      </div>
    );
  };

  const dateFullCellRenderer = value => {
    const { currentDate, additionalTileClassName } = props;
    let additionalClass = '';
    if (currentDate.isSame(value, 'day')) {
      additionalClass += 'current ';
    }
    additionalClass += additionalTileClassName(value);
    return (
      <div className="custom-cell-container" onClick={() => onDateClick(value)}>
        <div
          className={`custom-cell-calendar ${additionalClass}`}
          style={{ textAlign: 'center' }}
        >
          <div className="cell-wrapper">
            <span className="text">{value.date()}</span>
          </div>
        </div>
      </div>
    );
  };

  const { footer, disableTile, onDateClick } = props;
  return (
    <div className="month-calendar">
      <AntdCalendar
        fullscreen={false}
        headerRender={headRenderer}
        dateFullCellRender={dateFullCellRenderer}
        disabledDate={disableTile}
      />
      {footer}
    </div>
  );
};

export default MonthCalendar;

MonthCalendar.defaultProps = {
  currentDate: moment(),
  onPrevClick: (currentDate, prevMonth) => undefined,
  onNextClick: (currentDate, nextMonth) => undefined,
  onDateClick: date => undefined,
  footer: null,
  additionalTileClassName: date => '',
  disableTile: date => false
};

MonthCalendar.propTypes = {
  currentDate: PropTypes.instanceOf(moment),
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onDateClick: PropTypes.func,
  footer: PropTypes.element,
  additionalTileClassName: PropTypes.func,
  disableTile: PropTypes.func
};
