import { postUserSettings, retrieveUserSettings } from '../api/userSettings';
import { getDefaultLanguage } from '../../utils';
import { DEFAULT_SHIPPING_COUNTRY, tokenKeys } from './constants';
import { deserializeToken } from './deserializers';
import { setUserSettings } from '../actions';
import i18n from '../../i18n';
import { getLSItem, setLSItem } from './index';

export const initSessionUserSettings = async (token, dispatch) => {
  const userId = deserializeToken(token).get(tokenKeys.USER_ID);
  let userData = {};

  await retrieveUserSettings(userId)
    .then(data => {
      userData = data;

      if (Object.keys(userData).length !== 0)
        dispatch(setUserSettings(userData));
    })
    .catch(async error => {
      if (error.response.status === 404) {
        userData = {
          ui_language: getDefaultLanguage(),
          shipping_country: DEFAULT_SHIPPING_COUNTRY
        };
        await postUserSettings(userData)
          .then(data => {
            userData = data;
          })
          .catch(() => console.log('POST userSettings failed'));
      }
    });

  const language = getLSItem('language') || userData.ui_language;
  setLSItem('language', language);
  if (language !== i18n.language) await i18n.changeLanguage(language);

  return userData;
};
