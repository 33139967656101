import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import ResetPasswordRequest, {
  ResetPasswordRequestSuccess
} from './ResetPasswordRequest';
import { ResetPasswordDataProvider } from './ResetPassword';
import ResetPasswordLinkExpired from './ResetPasswordLinkExpired';

const ResetPasswordRouter = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/request`} exact component={ResetPasswordRequest} />
      <Route
        path={`${url}/request/success`}
        component={ResetPasswordRequestSuccess}
      />
      <Route
        path={`${url}/link-expired`}
        component={ResetPasswordLinkExpired}
      />
      <Route path={`${url}/`} exact component={ResetPasswordDataProvider} />
      <Route
        render={({ location }) => (
          <Redirect to={`${url}/request${location.search}`} />
        )}
      />
    </Switch>
  );
};

export default ResetPasswordRouter;
