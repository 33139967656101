import {
  deleteTakenDate as deleteTakenDateService,
  retrieveCalendarInventory as retrieveCalendarInventoryService,
  retrieveDatesTaken as retrieveDatesTakenService,
  takeSundoseOnGivenDay as takeSundoseOnGivenDayService
} from '../../dataService';
import { List } from 'immutable';
import moment from 'moment';
import { constants } from '../../utils';
import { showErrorNotification } from '../../utils/showErrorNotification';

const { CALENDAR_FORMAT, actionNamesCalendar } = constants;

const {
  SET_DATES_TAKEN,
  SET_CALENDAR_INVENTORY,
  TOGGLE_DATE_TAKEN
} = actionNamesCalendar;

export const retrieveDatesTaken = () => {
  return async dispatch => {
    const response = retrieveDatesTakenService();
    await response.then(data => {
      let datesTaken = List();
      if (data) {
        data.forEach(date => {
          datesTaken = datesTaken.push(moment(date.date_taken, CALENDAR_FORMAT));
        });
        dispatch({
          type: SET_DATES_TAKEN,
          data: datesTaken
        });
      }
    });
  };
};

export const retrieveCalendarInventory = () => {
  return async dispatch => {
    const response = retrieveCalendarInventoryService();
    await response.then(data => {
      dispatch({
        type: SET_CALENDAR_INVENTORY,
        data
      });
    });
  };
};

export const takeSundoseOnGivenDay = date => {
  return async dispatch => {
    const response = takeSundoseOnGivenDayService(date);
    await response
      .then(() => {
        dispatch({
          type: TOGGLE_DATE_TAKEN,
          data: {
            date,
            hasTaken: true
          }
        });
      })
      .catch(error => showErrorNotification(error));
  };
};

export const deleteTakenDate = date => {
  return async dispatch => {
    const response = deleteTakenDateService(date);
    await response.then(() => {
      dispatch({
        type: TOGGLE_DATE_TAKEN,
        data: {
          date,
          hasTaken: false
        }
      });
    });
  };
};
