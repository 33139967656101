import { deserializeToken } from '../deserializers';
import { tokenKeys } from '../constants';
import { getLSItem } from '../index';

const { USER_ID, SURNAME, NAME, EMAIL } = tokenKeys;

export const getDataLayerVisitorData = () => {
  const token = getLSItem('flutter.access_token');
  let visitorData;

  if (token) {
    const tokenData = deserializeToken(token);
    visitorData = {
      VisitorLoginState: 'logged-in',
      VisitorId: tokenData.get(USER_ID),
      VisitorEmail: tokenData.get(EMAIL),
      VisitorFirstName: tokenData.get(NAME),
      VisitorLastName: tokenData.get(SURNAME)
    };
  } else {
    visitorData = {
      VisitorLoginState: 'guest'
    };
  }

  return visitorData;
};
