import React from 'react';
import { Provider } from 'react-redux';

import PanelRouter from './PanelRouter';
import { createStore } from './createStore';
import { useOptimizeAfterLastRender } from './utils';
import { LoadMixerDataOnInit } from './components/LoadMixerDataOnInit';
import { SaveUrlParamsOnInit } from './components/SaveUrlParamsOnInit/SaveUrlParamsOnInit';
import './index.scss';

const store = createStore();

function PanelApp() {
  // this component renders 2 times so we use AfterLastRender hook to activate GOptimize:
  useOptimizeAfterLastRender('activatePanelApp');

  return (
    <Provider store={store}>
      <div className="panel-style">
        <PanelRouter />
      </div>
      <LoadMixerDataOnInit />
      <SaveUrlParamsOnInit />
    </Provider>
  );
}

export default PanelApp;
