import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import AuthorizationForm, {
  EmailInput,
  FORM_NAME,
  PasswordInput
} from '../../../components/AuthorizationForm';
import AlertMessage from '../../../components/AlertMessage/AlertMessage';
import { signIn as signInService } from '../../../api/auth';
import { HTTP_STATUS_CODES } from '../../../utils/constants';
import { useRedirect } from '../../../utils/redirect';
import { saveLoginData, useOptimize } from '../../../utils';
import { loginUser } from '../utlis';

const { BAD_REQUEST, FORBIDDEN } = HTTP_STATUS_CODES;

const formNames = {
  email: 'email',
  password: 'password'
};

const initValidateStatuses = {
  [formNames.email]: '',
  [formNames.password]: ''
};

const Login = () => {
  const [t] = useTranslation();
  useOptimize('activateLoginPage');
  const [redirect, redirectTo] = useRedirect();
  const [loggingIn, setLoggingIn] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [validateStatuses, setValidateStatuses] = useState(
    initValidateStatuses
  );
  const [globalError, setGlobalError] = useState(false);

  const onFormChange = useCallback(
    formName => {
      if (formName === FORM_NAME) {
        if (
          validateStatuses[formNames.email] ||
          validateStatuses[formNames.password]
        )
          setValidateStatuses(initValidateStatuses);
        setGlobalError(false);
      }
    },
    [validateStatuses]
  );

  const redirectUser = useCallback(() => {
    let path;
    if (!redirect.shouldRedirect) path = '/panel/dashboard';

    redirectTo(path);
  }, [redirect, redirectTo]);

  const signIn = useCallback(
    async values => {
      setLoggingIn(true);
      await signInService(values)
        .then(async data => {
          try {
            saveLoginData(data);
            await loginUser(data.token, data, dispatch, 'standard login');
            redirectUser();
          } catch (e) {
            setLoggingIn(false);
          }
        })
        .catch(err => {
          setLoggingIn(false);
          const { response } = err;
          if (response) {
            if (response.status === BAD_REQUEST) {
              setValidateStatuses({
                [formNames.email]: 'error',
                [formNames.password]: 'error'
              });
              setGlobalError(true);
            } else if (response.status === FORBIDDEN) {
              const { data } = response;
              history.push(
                `/panel/auth/change-password?user=${data.user}&token=${data.reset_token}`
              );
            }
          }
        });
    },
    [dispatch, history, redirectUser]
  );

  const onFormFinish = useCallback(
    async (name, { values, forms }) => {
      if (name === FORM_NAME) await signIn(values, forms);
    },
    [signIn]
  );

  return (
    <Row className="login-container">
      <Helmet>
        <title>{t('Log in to Sundose')}</title>
      </Helmet>
      <Col span={24}>
        <h1 className="login-container--title">{t('Log in to Sundose')}</h1>
        <h4 className="login-container--subtitle">
          {t('Personalised dietary supplement.')}
          <br />
          {t('Discover the world of full personalization.')}
        </h4>
        {globalError ? (
          <AlertMessage type="error" message={t('Invalid email or password')} />
        ) : null}
        <Row>
          <Col span={24}>
            <Form.Provider
              onFormFinish={onFormFinish}
              onFormChange={onFormChange}
            >
              <AuthorizationForm
                requiredMark={false}
                submitButtonText={t('Login')}
                submitting={loggingIn}
                isSubmitButtonDisabled={globalError}
                page={'login'}
              >
                <EmailInput
                  inputName={formNames.email}
                  validateStatus={
                    validateStatuses[formNames.email]
                      ? validateStatuses[formNames.email]
                      : undefined
                  }
                />
                <Form.Item
                  name={formNames.password}
                  label={
                    <div className="password-label-wrapper">
                      <div>{t('password')}</div>
                      <div className="button-container">
                        <Link
                          to="/panel/auth/reset-password/request"
                          tabIndex={4}
                        >
                          <Button type="link">{t('Forgot password?')}</Button>
                        </Link>
                      </div>
                    </div>
                  }
                  rules={[
                    { required: true, message: t('Password is required') }
                  ]}
                  validateTrigger="onBlur"
                  validateStatus={
                    validateStatuses[formNames.password]
                      ? validateStatuses[formNames.password]
                      : undefined
                  }
                >
                  <PasswordInput />
                </Form.Item>
              </AuthorizationForm>
            </Form.Provider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
