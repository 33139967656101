import React, { useCallback } from 'react';
import { bool, func, instanceOf } from 'prop-types';
import ShadowBox from '../ShadowBox';
import femaleImage from '../../assets/img/calendar-female.svg';
import { constants } from '../../utils';
import { Button } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { CALENDAR_FORMAT_DISPLAY } = constants;

const propTypes = {
  date: instanceOf(moment).isRequired,
  isTaken: bool,
  onConfirm: func.isRequired
};

const defaultProps = {
  isTaken: false
};

const ConfirmDateTaken = ({ date, isTaken, onConfirm }) => {
  const { t } = useTranslation();
  const onClick = useCallback(() => onConfirm(date, !isTaken), [
    date,
    isTaken,
    onConfirm
  ]);

  const formattedDate = date.format(CALENDAR_FORMAT_DISPLAY);
  const dayName = date.format('dddd');
  const buttonText = isTaken ? t('i-didnt-take') : t('i-confirm');
  return (
    <ShadowBox className="confirm-date-taken">
      <h3>{t('selected-day')}</h3>
      <div className="selected-date">{formattedDate}</div>
      <div className="selected-day-name">({dayName})</div>
      <div className="confirm-label">{t('confirm-taking-a-dose')}</div>
      <Button
        style={{
          marginTop: '10px',
          borderRadius: '12px',
          height: '48px',
          width: 'auto',
          maxWidth: '230px',
          background: '#061846',
          fontFamily: 'ABCSocial-Regular, sans-serif',
          fontSize: '14px',
          lineHeight: '20px',
          borderColor: 'transparent',
        }}
        size="large"
        type="primary"
        onClick={onClick}
      >
        {buttonText}&nbsp;→
      </Button>
    </ShadowBox>
  );
};

ConfirmDateTaken.propTypes = propTypes;

ConfirmDateTaken.defaultProps = defaultProps;

export default ConfirmDateTaken;
