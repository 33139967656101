import { getEnv } from '../../env';
import axios from './axios';

const baseApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');

const defaultErrorHandler = error => {
  console.trace();
  console.log(error);
};

export const getOrders = async () =>
  axios.get(`${baseApi}/orders/`).then(response => response.data);

export const getLastOrder = async () =>
  axios.get(`${baseApi}/orders/?last=true`).then(response => response.data);

export const getDietitianData = async diet_id =>
  axios
    .get(`${baseApi}/dietitian/${diet_id}/`)
    .then(response => response.data)
    .catch(error => {
      console.log(error);
    });

export const getDietitianInfo = () =>
  axios
    .get(`${baseApi}/dietitians/`)
    .then(response => response.data)
    .catch(error => {
      console.log(error);
    });

export const getCalendlyMeeting = () =>
  axios
    .get(`${baseApi}/dietitians/consultations/`)
    .then(response => response.data)
    .catch(error => {
      console.log(error);
    });

export const getOrdersForPrescription = async prescription_id =>
  axios
    .get(`${baseApi}/orders/?prescription_id=${prescription_id}`)
    .then(response => response.data)
    .catch(error => console.log(error));

export const getOrder = async id =>
  axios.get(`${baseApi}/orders/${id}/`).then(response => response.data);

export const getBillingData = async user_id =>
  axios.get(`${baseApi}/billing/${user_id}/`).then(response => response.data);

export const getShippingData = async user_id =>
  axios
    .get(`${baseApi}/shipping/${user_id}/`)
    .then(response => response.data)
    .catch(() => null);

export const getInpostShippingData = async user_id =>
  axios
    .get(`${baseApi}/shipping/inpost/${user_id}/`)
    .then(response => response.data)
    .catch(() => null);

export const getAllShippingData = user_id =>
  Promise.all([
    getShippingData(user_id),
    getInpostShippingData(user_id),
    getBillingData(user_id)
  ]).then(data => {
    const [courier, inpost, billing] = data;
    return { courier, inpost, billing };
  });

export const updateShippingData = async (user_id, data) =>
  axios.put(`${baseApi}/shipping/${user_id}/`, data);

export const updateInPostShippingData = async (user_id, data) =>
  axios.put(`${baseApi}/shipping/inpost/${user_id}/`, data);

export const updateBillingData = async (user_id, data) =>
  axios.put(`${baseApi}/billing/${user_id}/`, data);
