import { normalize, schema } from 'normalizr';
import axios from 'axios';
import { getEnv } from '../env';
import i18n from '../i18n';

import {
  authorizationInterceptor,
  authorizationInterceptorError,
  maintenanceCheckInterceptor
} from '../utils';
import { pushToDataLayer } from '../panel/utils/dataLayer';

axios.defaults.baseURL = getEnv('REACT_APP_PUBLIC_SURVEY_API_BASE_URL');
const URL = getEnv('REACT_APP_PUBLIC_API_SYSTEM_URL');

const djangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Accept-Language'] = i18n.language;
axios.interceptors.request.use(
  authorizationInterceptor,
  authorizationInterceptorError
);
axios.interceptors.request.use(maintenanceCheckInterceptor);

const question = new schema.Entity('questions');
const mySchema = {
  questions: [question]
};

i18n.on('languageChanged', lang => {
  axios.defaults.headers['Accept-Language'] = lang;
});

const getData = survey => {
  const {
    entities: { questions },
    result: { questions: questionIds, name: surveyName }
  } = normalize(survey, mySchema);

  return {
    surveyName,
    questions,
    questionIds
  };
};

const getSurvey = id =>
  axios
    .get(`/survey/${id}`)
    .then(response => response)
    .catch(error => error.response);

const generateComposition = () =>
  axios(`${URL}/generateComposition`, {
    method: 'get',
    withCredentials: true
  }).then(response => response);

const QUESTION_TO_KEY = {
  'ff01364b-7072-488a-a60b-6da6d3198fc4': 'age_survey', // dev/preprod/prod, Survey 1
  '4ad82d88-3135-4812-bc18-b0b20ca9f441': 'gender_survey', // dev/preprod/prod, Survey 1
  'aef19a76-9788-491f-801a-5003a8599469': 'age_survey', // dev, Survey 2
  '4114a79c-81b6-4b38-aabc-61321c92d1b5': 'gender_survey', // dev, Survey 2
  '010d829e-815d-49ce-b64e-38b2276162d6': 'age_survey', // prod, Survey 2
  '167926dd-a9c0-4842-a0c3-0a7faa58cdc7': 'gender_survey' // prod, Survey 2
};

const OPTION_TO_VALUE = {
  '8c7b3df2-ad73-4c21-ab8b-d29ea400727d': 'male', // dev/preprod/prod, Survey 1
  '9e8ccb45-946a-4330-8c6e-6bce17bffc14': 'female', // dev/preprod/prod, Survey 1
  'd06c134b-200b-4886-a83b-e66a1c2b300e': 'other', // dev/preprod/prod, Survey 1
  '9755c1b9-0c96-4ae3-8141-525f6e0ab60a': 'male', // dev, Survey 2
  '23041baf-a6b7-459c-9b30-36b9f3f3623b': 'female', // dev, Survey 2
  'b978e779-50b1-4d08-b4b8-66ff689ba51e': 'other', // dev, Survey 2
  '719281fd-169f-4a0c-a86f-5951daa43974': 'male', // prod, Survey 2
  '4d356b1a-a838-45ce-a8bf-f42a8a66c3af': 'female', // prod, Survey 2
  'ad8942a2-45e1-40fb-a504-2ecb011bca4b': 'other' // prod, Survey 2
};

const updateAnalyticsEvents = (questionId, answerBody) => {
  const key = QUESTION_TO_KEY[questionId];

  if (key == null)
    // we are only doing this if we want to analyze this questions
    return;

  pushToDataLayer('user_details_update', {
    [key]:
      answerBody.questionOptionAnswers &&
      answerBody.questionOptionAnswers.length > 0
        ? OPTION_TO_VALUE[answerBody.questionOptionAnswers[0].id]
        : answerBody.answer
  });
};

const editQuestionAnswer = (
  surveyAnswerId,
  questionId,
  answerId,
  answerBody
) => {
  updateAnalyticsEvents(questionId, answerBody);

  return axios
    .put(
      `/survey/answer/${surveyAnswerId}/question/${questionId}/answer/${answerId}`,
      {
        ...answerBody
      }
    )
    .then(response => response.data);
};

const deleteQuestionAnswer = (surveyAnswerId, questionId, answerId) =>
  axios
    .delete(
      `/survey/answer/${surveyAnswerId}/question/${questionId}/answer/${answerId}`
    )
    .then(answerResponse => answerResponse.data);

const addNewQuestionAnswer = (surveyAnswerId, questionId, answerBody) => {
  updateAnalyticsEvents(questionId, answerBody);

  return axios
    .post(`/survey/answer/${surveyAnswerId}/question/${questionId}/answer`, {
      ...answerBody
    })
    .then(answerResponse => answerResponse.data);
};

const markUserSkippedSurvey = userId =>
  axios
    .post(`${djangoApi}/user/settings/${userId}/skip_survey/`, {})
    .then(r => r.data);

const markUserTookPartInExp = userId =>
  axios
    .post(`${djangoApi}/user/settings/${userId}/mark_as_exp_participant/`, {})
    .then(r => r.data);

const getUserSettings = userId =>
  axios.get(`${djangoApi}/user/settings/${userId}/`).then(r => r.data);

export {
  getData,
  getSurvey,
  generateComposition,
  editQuestionAnswer,
  deleteQuestionAnswer,
  addNewQuestionAnswer,
  markUserSkippedSurvey,
  markUserTookPartInExp,
  getUserSettings
};
