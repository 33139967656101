import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as plJson from './i18n/pl_PL';
import * as enJson from './i18n/en';
import * as deJson from './i18n/de';
import * as itJson from './i18n/it_IT';
import { getDefaultLanguage, LANGUAGES } from './utils';
import { getLSItem } from './panel/utils';

const { PL, EN, DE, IT } = LANGUAGES;

const languageLocalStorage = getLSItem('language');
// this might be generalized to support arbitrary number of
// languages automatically discovered by json files inside i18n dir
const resources = {
  [PL]: {
    translation: plJson.default
  },
  [EN]: {
    translation: enJson.default
  },
  [DE]: {
    translation: deJson.default
  },
  [IT]: {
    translation: itJson.default
  }
};

// setup i18n and resources loading
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng:
      languageLocalStorage !== null
        ? languageLocalStorage
        : getDefaultLanguage(),
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    returnEmptyString: false
  });

export default i18n;
