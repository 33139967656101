import { mixerDataNames, tokenKeys } from '../constants';
import { getDataLayerVisitorData } from './getDataLayerVisitorData';
import { retrieveMixerData } from '../../api/mixer';
import { getBillingData, getOrders } from '../../api/legacy';
import { deserializeToken } from '../deserializers';
import { getLSItem } from '../index';

export const dataLayerPushLoginStatus = async ordersData => {
  const token = getLSItem('flutter.access_token');
  const deserializedToken = deserializeToken(token);
  let loginStatusData = {
    event: 'loginstatus',
    ...getDataLayerVisitorData()
  };
  if (token) {
    loginStatusData = {
      ...loginStatusData,
      UserCity: '',
      UserZipCode: '',
      UserPhone: ''
    };
    const userSettings = await getBillingData(
      deserializedToken.get(tokenKeys.USER_ID)
    );
    const userSettingsData = {
      UserCity: userSettings.city,
      UserZipCode: userSettings.postcode,
      UserPhone: userSettings.phone
    };
    let orders;
    if (ordersData) {
      orders = ordersData;
    } else {
      orders = await getOrders();
    }
    const exchangeRates = await retrieveMixerData(
      mixerDataNames.EXCHANGE_RATES
    );
    if (Array.isArray(orders))
      loginStatusData = {
        ...loginStatusData,
        UserOrdersCount: orders.length,
        UserTotalValue: orders.reduce(
          (acc, order) =>
            acc + parseFloat(order.price) / exchangeRates[order.currency],
          0
        )
      };
    loginStatusData = {
      ...loginStatusData,
      ...userSettingsData
    };
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(loginStatusData);
};
