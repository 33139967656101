import { axios } from './index';
import { getEnv } from '../../env';

const baseDjangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');
const panelMixerBase = baseDjangoApi + '/mixer';

export const retrieveMixerData = async dataName =>
  axios.get(`${panelMixerBase}/${dataName}/`).then(response => response.data);

export const getPrescription = async prescription_id =>
  axios
    .get(`${panelMixerBase}/prescriptions/${prescription_id}/`, {
      withCredentials: true
    })
    .then(response => response.data)
    .catch(() => undefined);

export const getPrescriptions = async () =>
  axios.get(`${panelMixerBase}/prescriptions/`).then(response => response.data);

export const getLastPrescription = async () =>
  axios
    .get(`${panelMixerBase}/prescriptions/last/`, { withCredentials: true })
    .then(response => response.data)
    .catch(error => {
      if (error.response && error.response.status === 404) return null;
    });

export const receiveSharedPrescription = async (id, pin) =>
  axios
    .post(
      `${panelMixerBase}/prescriptions/${id}/receive/?pin=${pin}`,
      {},
      { withCredentials: true }
    )
    .then(response => response.data)
    .catch(error => ({ errorResponse: error.response }));

export const patchPrescription = async (id, data) =>
  axios
    .patch(
      `${panelMixerBase}/prescriptions/${id}/`,
      { ...data },
      { withCredentials: true }
    )
    .then(response => response.data)
    .catch(error => ({ errorResponse: error.response }));

export const sharePrescription = async (id, email) =>
  axios
    .post(
      `${panelMixerBase}/prescriptions/${id}/send/`,
      {},
      { params: { email: email } }
    )
    .then(response => response.status === 201)
    .catch(error => ({ errorResponse: error.response }));
