import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Popover, Steps } from 'antd';

import { CalendarProvider } from '../../../CalendarContext';
import { useOptimize, useOrderStatus } from '../../../utils';

// import SpecialOffer from '../../../components/SpecialOffer';
import AwaitContact from '../../../components/AwaitContact';

import { CalendarDataProvider } from '../../../components/Calendar/CalendarDataProvider';
import CertificatesInline from '../../../components/CertificatesInline';
import { AffiliationView } from '../AffiliationView';
import './dashboard-view.scss';
import { countryNormsCodes } from '../../../utils/constants';
import BloodInfoIcon from './../../../assets/img/blood-info.svg';
import infoIcon from './../../../assets/img/info-icon.svg';
// import AddProfileIcon from './../../../assets/img/add-profile-icon.svg';
import { OrderProxyStatusDefinition } from '../../../utils/useOrderStatus';
import { ORDER_PROXY_STATUSES } from '../../../../utils';
import { getEnv } from '../../../../env';
import { RenewPaymentButton } from '../../../components/RenewPaymentButton';
import CloseIcon from '../../../assets/img/close.svg';
import { pushToDataLayer } from '../../../utils/dataLayer';
import { DietitianContainer } from '../../../components/OwnDietitian/OwnDietitian';

const PopoverContent: React.FC<{
  status: OrderProxyStatusDefinition | undefined;
  shippingType: string | undefined;
}> = ({ status, shippingType }) => {
  const [t] = useTranslation();
  const { Step } = Steps;
  const orderStatus = useOrderStatus(shippingType === 'InPost');

  const steps = orderStatus
    .toArray()
    .filter(step => step[1].timelineOrder)
    .sort((a, b) => a[1].timelineOrder - b[1].timelineOrder);

  let current = steps.findIndex(
    step => step[1].description === status?.description
  );

  current = current > 0 ? current : 0;

  return (
    <div className="status-popover-container">
      <h3>{t('order-status')}</h3>
      <Steps progressDot direction="vertical" size="small" current={current}>
        {steps.map(step => (
          <Step key={step[1].description} title={step[1].description} />
        ))}
      </Steps>
    </div>
  );
};

const Dashboard = () => {
  const [t, i18n] = useTranslation();
  useOptimize('activateDashboardPage');

  const {
    orders,
    settings: { subscriber },
    sundoseData: { awaitingDietitian }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useSelector(state => state.userDataReducer);

  const order = orders.first();

  const inpostFlag = order ? order.get('shipping_type') === 'InPost' : false;
  const orderStatus = useOrderStatus(inpostFlag);

  const status = order ? orderStatus.get(order.get('status')) : undefined;
  const isSub = order ? (!!order.get('subscription_id')) : false;

  const failed =
    order && order.get('status') === ORDER_PROXY_STATUSES.PAYMENT_FAILED;

  const deliveredOverDefinedTimeAgo =
    order && order.get('delivered_date')
      ? moment().diff(moment(order.get('delivered_date')), 'hours') >=
        getEnv('REACT_APP_HIDE_ORDER_STATUS_INFO_BOX_AFTER_AMOUNT_OF_HOURS')
      : false;

  const shouldDisplayOrderInfo = order && !deliveredOverDefinedTimeAgo;

  // const shouldDisplayOfferBox =
  //   (hasSundose === false || sinceDelivered > 15) && !subscriber;

  return (
    <>
      <CalendarProvider>
        <Helmet>
          <title>{t('dashboard')} - Sundose</title>
        </Helmet>
        <div className="dashboard-wrapper">
          <Col xs={24} md={{ span: 12 }}>
            <div className="calendar-wrapper loaded">
              <CalendarDataProvider />
            </div>
          </Col>
          <Col xs={24} md={{ span: 11, offset: 1 }}>
            {/*{i18n.language === countryNormsCodes.PL ? (*/}
            {/*  <div className="content-dashboard-wrapper loaded">*/}
            {/*    <FamilyAccountContainer />*/}
            {/*  </div>*/}
            {/*) : null}*/}
            {subscriber && i18n.language === countryNormsCodes.PL ? (
              <BloodInfoContainer />
            ) : null}
            {awaitingDietitian && (
              <div className="content-dashboard-wrapper await-contact-wrapper loaded">
                <AwaitContact />
              </div>
            )}
            {/* {shouldDisplayOfferBox && (
              <div className="content-dashboard-wrapper loaded">
                <SpecialOffer />
              </div>
            )} */}
            {shouldDisplayOrderInfo ? (
              <div className="content-dashboard-wrapper loaded">
                <div className={`status-container${failed ? ' failed' : ''}`}>
                  <div>
                    <strong>
                      {t('order')} {order.get('id')}
                    </strong>
                    <div className="status-badge-container">
                      <div className={`${status?.className} status-badge`}>
                        {status?.description}
                      </div>
                      {status?.hideIcons ? null : (
                        <Popover
                          placement="bottom"
                          content={
                            <PopoverContent
                              status={status}
                              shippingType={order.get('shipping_type')}
                            />
                          }
                        >
                          <img
                            className="info-icon"
                            src={infoIcon}
                            alt="info icon"
                          />
                        </Popover>
                      )}
                    </div>
                  </div>
                  <div
                    className={`status-icon-container${
                      failed ? ' failed' : ''
                    }`}
                  >
                    {failed && !isSub ? (
                      <RenewPaymentButton order={order.toJS()} />
                    ) : status?.hideIcons ? null : (
                      <i className={`${status?.className} status-icon`} />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {i18n.language === countryNormsCodes.PL ? (
              <div className="content-dashboard-wrapper loaded">
                <DietitianContainer />
              </div>
            ) : null}

            <div className="content-dashboard-wrapper loaded">
              <AffiliationView withLink />
            </div>
          </Col>
        </div>
        <CertificatesInline className="hide-on-desktop" />
      </CalendarProvider>
    </>
  );
};
//
// const FamilyAccountContainer = () => {
//   const [familyModalVisible, setFamilyModalVisible] = useState(false);
//
//   return (
//     <>
//       <div className="dietitian-container">
//         <div className={'dietitian-info'}>
//           <strong>Kup Sundose dla bliskiej&nbsp;osoby</strong>
//           <p className="dietitian-p">
//             Dzięki wielu profilom możesz wygodnie zarządzać kilkoma recepturami
//             na jednym koncie
//           </p>
//         </div>
//         <div
//           className={'dietitian-data'}
//           style={{ cursor: 'pointer' }}
//           onClick={() => {
//             pushToDataLayer('add_family_member_account_smoke_test');
//             setFamilyModalVisible(true);
//           }}
//         >
//           <img
//             alt="Your Dietitian"
//             src={AddProfileIcon}
//             style={{ width: '46px', height: '46px' }}
//           />
//           <p className="dietitian-p" style={{ color: '#E7792B' }}>
//             Dodaj profil
//           </p>
//         </div>
//       </div>
//       <Modal
//         width={400}
//         visible={familyModalVisible}
//         wrapClassName="subscription-modal"
//         bodyStyle={{
//           padding: 30,
//           boxShadow: '0 2px 7px 0 #00000020'
//         }}
//         maskClosable
//         centered
//         footer={null}
//         onCancel={() => setFamilyModalVisible(false)}
//         closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
//       >
//         <h4 className="modal-header-text">
//           Dodawanie kolejnych profili nie jest jeszcze dostępne
//         </h4>
//         <p
//           style={{
//             fontFamily: 'Migrena Grotesque',
//             margin: '30px 0',
//             fontSize: '18px',
//             lineHeight: '23px'
//           }}
//         >
//           Na razie sprawdzamy Twoje zainteresowanie tą opcją. Pomóż nam lepiej
//           zaprojektować dodawanie profili i zostaw swoją opinię.
//           <br /> Dziękujemy!
//         </p>
//
//         <div style={{ display: 'flex', justifyContent: 'center' }}>
//           <Button
//             type="primary"
//             shape="round"
//             style={{ width: '186px', height: '48px' }}
//             onClick={() =>
//               window.open(
//                 'https://survey.survicate.com/b86e2d87b4a97a82',
//                 '_blank'
//               )
//             }
//           >
//             Dodaj swoją opinię
//           </Button>
//         </div>
//       </Modal>
//     </>
//   );
// };

const BloodInfoContainer = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const onOpenButtonClick = () => {
    pushToDataLayer('blood_info_intent');
    setModalOpen(true);
  };

  return (
    <div className="content-dashboard-wrapper loaded">
      <div className="blood-info">
        <strong>
          Dodaj wyniki badań krwi <span>Nowość</span>
        </strong>
        <div className="blood-info-container">
          <p>
            Uwzględnij wyniki swoich badań krwi i stwórz jeszcze lepiej
            dopasowany skład
          </p>
          <img
            className="info-icon"
            src={BloodInfoIcon}
            alt="blood info icon"
          />
        </div>

        <Button type="primary" shape="round" className="act" onClick={onOpenButtonClick}>
          Dodaj wyniki →
        </Button>
      </div>

      <Modal
        width={500}
        visible={modalOpen}
        wrapClassName="subscription-modal"
        bodyStyle={{}}
        maskClosable
        centered
        footer={null}
        onCancel={() => setModalOpen(false)}
        closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
      >
        <h4 className="modal-header-text">
          Aby dodać wyniki badań - wyślij je do dietetyka i umów się na
          konsultację
        </h4>
        <ol style={{ padding: '0 15px', marginTop: '1em', textAlign: 'left' }}>
          <li style={{ margin: '10px 0' }}>
            Wyślij aktualne wyniki badań krwi na adres{' '}
            <a href="mailto:wyniki@sundose.io" style={{color: '#67728E'}}>wyniki@sundose.io →</a>. Określ w
            mailu, czy chcesz aby dietetyk odesłał Ci recepturę mailem, czy
            wolisz odbyć z nim konsultację telefoniczną.<b>*</b>
          </li>
          <li style={{ margin: '10px 0' }}>
            Dietetyk przeanalizuje Twoje wyniki i skontaktuje się z&nbsp;Tobą
            drogą mailową.
          </li>
          <li style={{ margin: '10px 0' }}>
            Jeśli wybrałeś konsultację mailową - nową recepturę opartą o Twoje
            wyniki badań, znajdziesz w panelu Sundose, a opis zmian w wiadomości
            mailowej.
          </li>
          <li style={{ margin: '10px 0' }}>
            Jeśli wybrałeś konsultację telefoniczną - dietetyk przedstawi Ci
            propozycję nowej receptury i omówicie ją podczas konsultacji.
          </li>
        </ol>
        <p style={{ marginBottom: 0 }}>
          <b>*</b> W mailu koniecznie zawrzyj klazulę:
        </p>
        <p style={{ fontSize: '12px', lineHeight: 1.6 }}>
          "Wyrażam zgodę na przetwarzanie przez Sundose Sp. z o.o.
          z&nbsp;siedzibą w Lublinie moich danych osobowych, w tym danych
          dotyczących zdrowia, przekazywanych w&nbsp;udostępnianej dokumentacji
          medycznej, w celu przeprowadzenia konsultacji dietetycznej i/lub
          personalizacji składu produktu Sundose."
        </p>
      </Modal>
    </div>
  );
};

export default Dashboard;
