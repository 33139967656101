import { useSelector } from 'react-redux';

const usePackagingPrice = countryNormsCode => {
  const baselinePrices = useSelector(
    state => state.panelMixerData.baseline_prices
  );

  return baselinePrices.get(countryNormsCode, 0);
};

export default usePackagingPrice;
