import React, { Component } from 'react';
import SurveyContext from '../../SurveyContext';
import { withTranslation } from 'react-i18next';

class FormString extends Component {
  static contextType = SurveyContext;

  constructor(props) {
    super(props);

    this.state = {
      value: this.retrieveValue()
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.submitAnswer();
    }
  };

  submitAnswer = () => {
    const { setStandardAnswer } = this.context;
    const { value } = this.state;

    if (value === '') return;

    setStandardAnswer(value);

    setTimeout(() => {
      this.setState({
        value: ''
      });
    }, 100);
  };

  retrieveValue = () => {
    const { question, currentQuestionAnswer } = this.props;
    let value = question.value ? question.value : '';
    if (currentQuestionAnswer) {
      value = currentQuestionAnswer.value;
    }
    return value;
  };

  componentDidUpdate(prevProps) {
    const { currentQuestionAnswer } = this.props;
    if (prevProps.currentQuestionAnswer !== currentQuestionAnswer) {
      this.setState({
        value: this.retrieveValue()
      });
    }
  }

  render() {
    const { value } = this.state;
    const { t } = this.props;

    return (
      <div className="wrapper">
        <div className="form-answer">
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            name="value"
            type="text"
            value={value}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            placeholder=""
            autoComplete="off"
          />
          <button
            type="button"
            className="btn-confirm"
            onClick={this.submitAnswer}
          >
            {t('confirm')} →
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FormString);
