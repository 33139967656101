export const addIcons = (data, icons, customIconKey) => {
  const iconKey = customIconKey ? customIconKey : 'icon';

  return data.map((data, key) => data.set(iconKey, icons.get(key)));
};

export const addIconsJS = (data, icons, customIconKey) => {
  const iconKey = customIconKey ? customIconKey : 'icon';

  return data.map(item => ({ ...item, [iconKey]: icons.get(item.id) }));
};
