import React, { Component } from 'react';
import SurveyContext from '../SurveyContext';
import { withTranslation } from 'react-i18next';

class Menu extends Component {
  static contextType = SurveyContext;

  render() {
    const { showMenu, closeMenu, questions, goToQuestion } = this.context;
    const { t } = this.props;

    const filteredQuestions = Object.keys(questions).filter(questionKey => {
      const question = questions[questionKey];

      return question.value ? question : null;
    });

    const questionsElements = filteredQuestions.map(questionId => {
      const question = questions[questionId];

      return (
        <li key={question.id}>
          <div className="data">
            <span>{question.fullText}</span>
            <p>{question.value}</p>
          </div>
          <button type="button" onClick={goToQuestion.bind(this, question.id)}>
            {t('edit')}
          </button>
        </li>
      );
    });

    if (showMenu)  {
      return (
        <div id="menu">
          <div id="header">
            <div className="wrapper">
              <h1>{t('my-answers')}</h1>
              <button
                type="button"
                className="menu-button"
                onClick={closeMenu.bind(this)}
              >
                <img src="../assets/images/icon_cancel.png" alt="close menu" />
              </button>
            </div>
          </div>
          <div id="questionDetails">
            <div className="wrapper">
              <h6 className="headline">{t('health-survey-sundose')}</h6>
              <ul className="question-list">{questionsElements}</ul>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default withTranslation()(Menu);
