import React from 'react';
import { useTranslation } from 'react-i18next';
import ResetPassword from '../ResetPassword/ResetPassword';
import { useQuery } from '../../../utils';

const ChangePassword = () => {
  const [t] = useTranslation();
  const { user, token } = useQuery();

  return (
    <ResetPassword
      user={user}
      token={token}
      title={t('Change your password')}
      subtitle={t('Please use this page to change your password.')}
      submitButtonText={t('Change password')}
    />
  );
};

export default ChangePassword;
