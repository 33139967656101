import { axios } from './api';
import { getEnv } from '../env';
import { setLSItem } from './utils';

const base = getEnv('REACT_APP_PUBLIC_API_SYSTEM_URL');
const baseDjangoApi = getEnv('REACT_APP_PUBLIC_DJANGO_API_BASE_URL');

const defaultErrorHandler = error => {
  console.trace();
  console.log(error);
};

function executeRequest() {
  // eslint-disable-next-line prefer-rest-params
  return axios(...arguments)
    .then(response => response.data)
    .catch(defaultErrorHandler);
}

const userSignOut = () =>
  axios(`${base}/singOutUser`, { method: 'post', withCredentials: true })
    .then(response => response.data)
    .catch(defaultErrorHandler);

const getUser = () =>
  axios(`${base}/getUser`, { method: 'get', withCredentials: true })
    .then(response => response.data)
    .catch(defaultErrorHandler);

const signInUser = data =>
  axios(`${base}/signInUser`, {
    method: 'POST',
    data: JSON.stringify(data),
    withCredentials: true
  })
    .then(response => {
      setLSItem('flutter.access_token', response.data.token);
      return response;
    })
    .catch(defaultErrorHandler);

const signUpUser = data =>
  axios(`${base}/signUpUser`, {
    method: 'POST',
    data: JSON.stringify(data)
  })
    .then(response => response)
    .catch(defaultErrorHandler);

const autoSignUpUser = data =>
  axios(`${base}/autoSignUpUser`, {
    method: 'post',
    data: JSON.stringify(data),
    withCredentials: true
  })
    .then(response => {
      if (typeof response !== 'undefined' && response.data) {
        if (response.data.status) {
          setLSItem('flutter.access_token', response.data.token);
        }
      }
      return response;
    })
    .catch(defaultErrorHandler);

const remindUserPassword = data =>
  axios(`${base}/remindUserPassword`, {
    method: 'post',
    data: JSON.stringify(data),
    withCredentials: true
  })
    .then(response => response)
    .catch(defaultErrorHandler);

const getDietitian = id =>
  axios(`${base}/getDietitian/${id}`, { method: 'get', withCredentials: true })
    .then(response => response.data)
    .catch(defaultErrorHandler);

const getCalendlyMeet = () =>
  axios(`${base}/getCalendlyMeet`, { method: 'get', withCredentials: true })
    .then(response => response.data)
    .catch(defaultErrorHandler);

const createCalendar = (sachetsAmount, firstDate) =>
  executeRequest(`${base}/createCalendar`, {
    method: 'POST',
    data: JSON.stringify({ sachetsAmount, firstDate }),
    withCredentials: true
  });

const saveCalendarDate = date =>
  executeRequest(`${base}/saveCalendarDate`, {
    method: 'POST',
    data: JSON.stringify({ date }),
    withCredentials: true
  });

const getCalendarDates = () =>
  executeRequest(`${base}/getCalendarDates`, {
    method: 'GET',
    withCredentials: true
  });

const getDietitianSecret = secretKey =>
  executeRequest(`${base}/getDietitianSecret/${secretKey}`, {
    method: 'GET',
    withCredentials: true
  });

const autoSignUpUserWithComposition = data =>
  axios(`${base}/autoSignUpUserWithComposition`, {
    method: 'post',
    data: JSON.stringify(data),
    withCredentials: true
  })
    .then(response => {
      if (typeof response !== 'undefined' && response.data) {
        if (response.data.status) {
          setLSItem('flutter.access_token', response.data.token);
        }
      }
      return response;
    })
    .catch(error => {
      alert(JSON.stringify(error));
    });

const signInUserWithComposition = data =>
  axios(`${base}/signInUserWithComposition`, {
    method: 'post',
    data: JSON.stringify(data),
    withCredentials: true
  })
    .then(response => response)
    .catch(error => {
      alert(JSON.stringify(error));
    });

const signInUserWithDietitian = data =>
  axios(`${base}/signInUserWithDietitian`, {
    method: 'post',
    data: JSON.stringify(data),
    withCredentials: true
  })
    .then(response => response)
    .catch(error => {
      alert(JSON.stringify(error));
    });

export const getVoucher = voucherId =>
  axios(`${baseDjangoApi}/vouchers/lifetime-voucher/${voucherId}/`, {
    data: {}
  })
    .then(response => response.data)
    .catch(error => {
      alert(JSON.stringify(error));
    });

export const retrieveDatesTaken = async () =>
  axios
    .get(`${baseDjangoApi}/calendar/dosages/`)
    .then(response => response.data)
    .catch(() => undefined);

export const retrieveCalendarInventory = async () =>
  axios
    .get(`${baseDjangoApi}/calendar/inventory/`)
    .then(response => response.data)
    .catch(() => undefined);

const toggleTakingSundoseURL = date => {
  const day = date.format('DD');
  const month = date.format('MM');
  const year = date.format('YYYY');
  return `${baseDjangoApi}/calendar/dosages/${year}/${month}/${day}/`;
};

export const takeSundoseOnGivenDay = async date =>
  axios.post(toggleTakingSundoseURL(date), {
    date_taken: date.format('YYYY-MM-DD')
  });

export const deleteTakenDate = async date =>
  axios.delete(toggleTakingSundoseURL(date));

export const setSachetsAmount = async sachetsAmount =>
  axios
    .put(`${baseDjangoApi}/calendar/inventory/${sachetsAmount}/`)
    .then(response => response.data);

export {
  getUser,
  signInUser,
  userSignOut,
  getDietitian,
  remindUserPassword,
  signUpUser,
  getCalendlyMeet,
  autoSignUpUser,
  createCalendar,
  saveCalendarDate,
  getCalendarDates,
  getDietitianSecret,
  autoSignUpUserWithComposition,
  signInUserWithComposition,
  signInUserWithDietitian
};
