import React from 'react';
import { func, string } from 'prop-types';

const AuthorizationViewBackgroundMobileTop = ({ onLogoClick, className }) => (
  <></>
);

AuthorizationViewBackgroundMobileTop.propTypes = {
  onLogoClick: func.isRequired,
  className: string
};

AuthorizationViewBackgroundMobileTop.defaultProps = {
  className: ''
};

export default AuthorizationViewBackgroundMobileTop;
