import React from 'react';
import { bool } from 'prop-types';

const ChevronIcon = ({ rotate }) => (
  <i className={`icon icon-chevron ${rotate ? 'rotated' : 'not-rotated'}`}>
    <svg width="10px" height="6px" viewBox="0 0 10 6" version="1.1">
      <title>C2DA236E-3266-4FFD-AF00-FF9C1549F405@3x</title>
      <desc>Created with sketchtool.</desc>
      <g
        id="Panel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Składniki-main-dietetyk"
          transform="translate(-145.000000, -1000.000000)"
          fill="currentColor"
          fillRule="nonzero"
        >
          <path
            d="M150.707107,1005.29289 L154.292893,1001.70711 C154.683418,1001.31658 154.683418,1000.68342 154.292893,1000.29289 C154.105357,1000.10536 153.851003,1000 153.585786,1000 L146.414214,1000 C145.861929,1000 145.414214,1000.44772 145.414214,1001 C145.414214,1001.26522 145.51957,1001.51957 145.707107,1001.70711 L149.292893,1005.29289 C149.683418,1005.68342 150.316582,1005.68342 150.707107,1005.29289 Z"
            id="-copy"
          ></path>
        </g>
      </g>
    </svg>
  </i>
);

ChevronIcon.propTypes = {
  rotate: bool
};

ChevronIcon.defaultProps = {
  rotate: false
};

export default ChevronIcon;
