import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';

export const FormatData = data => {
  const { t } = useTranslation();

  if (!data) return data;

  return data.map(substance => {
    const { name, amount, unit, nrv } = substance;

    const nrvFormatted =
      nrv && nrv !== 0 ? (
        <strong>
          {' '}
          ({(100 * nrv).toFixed(0)}% {t('nrv')})
        </strong>
      ) : undefined;

    return {
      ...substance,
      name,
      amount: (
        <>
          {amount.toFixed(2)} {unit} {nrvFormatted}
        </>
      )
    };
  });
};
