import { useState, useEffect } from 'react';

/**
 * Hook to activate Google Optimize by adding custom event when Component hes finished last render of it's content.
 * Google Optimize should change DOM **after** React. Otherwise React could revert changes made by GO.
 * In most cases you should use the useOptimize hook. Use this one only if Component for some reason re-render.
 * @param customEventName name of custom event used in Google Optimize to trigger an experiment. Should start with "activate..."
 */
const useOptimizeAfterLastRender = (customEventName: string): void => {
  const [finishedRendering, setFinishedRendering] = useState(false);

  useEffect(() => {
    setFinishedRendering(true);
    return () => {
      // cleanup function runs when component unmounts
      setFinishedRendering(false);
      // should we remove event from dataLayer to disable Optimize when user comes back to the same page???
    };
  }, [finishedRendering]);

  useEffect(() => {
    if (finishedRendering) {
      const renderTimer = setTimeout(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: `optimize.${customEventName}` });
        // console.log(`OPTIMIZE ${customEventName} !!!!!!!!!!!!!!!!!!!`);
      }, 100);
      return () => {
        clearTimeout(renderTimer);
      };
    }
  }, [finishedRendering, customEventName]);
};

export default useOptimizeAfterLastRender;
