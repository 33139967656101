import React, { useCallback, useState } from 'react';
import { ActionModal } from '../ActionModal';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, notification } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { func, instanceOf } from 'prop-types';
import { Map } from 'immutable';
import { sharePrescription as sharePrescriptionService } from '../../api/mixer';
import { notificationTypes } from '../../utils/constants';

const { ERROR, SUCCESS } = notificationTypes;

const SharePrescription = ({
  prescription,
  form,
  onCancelClick,
  onAcceptClick,
  ...props
}) => {
  const { getFieldDecorator } = form;
  const { t } = useTranslation();

  const id = prescription.get('id');

  const [sendingEmail, setSendingEmail] = useState(false);

  const sharePrescription = useCallback(
    async email => {
      setSendingEmail(true);
      const response = await sharePrescriptionService(id, email);
      setSendingEmail(false);
      if (!response || response.errorResponse) {
        const message = response.errorResponse.data.detail;
        notification[ERROR]({
          message: message ? message : t('something-went-wrong'),
          className: 'notification-style-error'
        });
      } else {
        notification[SUCCESS]({
          message: t('successfully-shared-prescription'),
          className: 'notification-style-success'
        });
        onAcceptClick();
      }
    },
    [id, t, onAcceptClick]
  );

  const modalAcceptClick = useCallback(() => {
    form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        sharePrescription(values['email']);
      }
    });
  }, [form, sharePrescription]);

  return (
    <ActionModal
      onAcceptClick={modalAcceptClick}
      onCancelClick={onCancelClick}
      okText={t('share')}
      confirmLoading={sendingEmail}
      {...props}
      title={<Trans i18nKey="share-prescription-title"> {{ id }}</Trans>}
    >
      <Form layout="vertical">
        <Form.Item label={t('email-address')} colon={false} required={false}>
          {getFieldDecorator('email', {
            validateTrigger: 'onBlur',
            rules: [
              {
                required: true,
                whitespace: true,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: t('incorrect-email-address')
              }
            ]
          })(<Input />)}
        </Form.Item>
      </Form>
    </ActionModal>
  );
};

SharePrescription.propTypes = {
  prescription: instanceOf(Map).isRequired,
  onCancelClick: func.isRequired
};

export default Form.create()(SharePrescription);
