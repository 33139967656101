import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {Col, Row} from 'antd';

import {fetchUserSubscriptions} from '../../../api/subscription';
import {getLastPrescription, getPrescription} from '../../../api/mixer';
import {setUserSubscription} from '../../../actions';
import {useOptimize} from '../../../utils';
import {getEnv} from '../../../../env';

import {AwaitData} from '../../../components/AwaitData';
import {InfoBoxMixer} from '../../../components/InfoBox';
import {OfferPrescription} from './OfferPrescription';
import './offer-view.scss';

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');

export const OfferView = () => {
  const {t} = useTranslation();
  useOptimize('activatePanelOfferPage');

  const [prescription, setPrescription] = useState(undefined);
  const [dataLoading, setDataLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    settings: {subscriber: isSubscriber},
    subscription: {
      last_order: lastSubscriptionOrder,
      next_order: nextSubscriptionOrder
    } = {}
  } = useSelector(state => state.userDataReducer);

  useEffect(() => {
    const fetchPrescriptionData = async () => {
      setDataLoading(true);
      const response =
        isSubscriber && lastSubscriptionOrder
          ? await getPrescription(lastSubscriptionOrder.prescription_id)
          : await getLastPrescription();
      setPrescription(response);
      setDataLoading(false);
    };
    if (isSubscriber) {
      prescription ?? (lastSubscriptionOrder && fetchPrescriptionData());
    } else {
      prescription ?? fetchPrescriptionData();
    }
  }, [prescription, isSubscriber, lastSubscriptionOrder]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      setDataLoading(true);
      const response = await fetchUserSubscriptions();
      // For now there will be always only one subscription object in the response array
      dispatch(setUserSubscription(response[0]));
      setDataLoading(false);
    };
    if (isSubscriber) {
      lastSubscriptionOrder ?? fetchSubscriptionData();
    }
  }, [isSubscriber, lastSubscriptionOrder]);

  if (typeof prescription === 'undefined' || dataLoading)
    return <AwaitData loading={true}/>;
  if (prescription === null && !dataLoading) {
    window.location.href = mixerBaseUrl;
    return null;
  }

  return (
    <div className="offer-view">
      <Helmet>
        <title>{t('offer')} - Sundose</title>
      </Helmet>
      {/* <Col xs={24} md={{ span: 11, offset: 13 }}>
        <div className="offer-for-you-header">{t('offer-sundose-for-you')}</div>
        {!prescription && (
          <div className="offer-for-you-description">
            {t('offer-no-prescription-description')}
          </div>
        )}
      </Col> */}

      <Row type="flex" justify="space-between">
        <Col xs={24} md={{span: 10}} className="offer-left-col">
          <img
            alt="Sundose Start Package"
            src="https://sundose-cdn.imgix.net/img/NPA_5040_full_2.jpg?mask=corners&auto=format"
            srcSet="https://sundose-cdn.imgix.net/img/NPA_5040_full_2.jpg?mask=corners&auto=format&dpr=1 1x,
        https://sundose-cdn.imgix.net/img/NPA_5040_full_2.jpg?mask=corners&auto=format&dpr=2 2x,
        https://sundose-cdn.imgix.net/img/NPA_5040_full_2.jpg?mask=corners&auto=format&dpr=3 3x"
          />
        </Col>

        <Col
          xs={24}
          md={{span: 13, offset: 1}}
          className="offer-card-container"
        >
          <div className="offer-for-you-header">
            {t('offer-sundose-for-you')}
          </div>
          {!prescription && (
            <div className="offer-for-you-description">
              {t('offer-no-prescription-description')}
            </div>
          )}
          {prescription ? (
            <OfferPrescription
              prescription={prescription}
              isSubscriber={isSubscriber}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              nextSubscriptionOrder={nextSubscriptionOrder}
              lastSubscriptionOrder={lastSubscriptionOrder}
            />
          ) : (
            <InfoBoxMixer/>
          )}
        </Col>
      </Row>
    </div>
  );
};
