import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { buyPrescriptionAsGift } from '../../api/auth';
import { pushCompleteRegistrationEvent } from '../../utils/analyticEventsHelpers';
import { loginUser } from './utlis';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { saveLoginData } from '../../utils';

type TRenewPaymentParams = {
  prescriptionId: string;
};

export const BuyPrescriptionAsGift: React.FC<
  RouteComponentProps<TRenewPaymentParams>
> = ({ match }) => {
  const dispatch = useDispatch();

  const { prescriptionId } = match.params;

  useEffect(() => {
    buyPrescriptionAsGift(prescriptionId).then(data => {
      const { user, prescription_id } = data;

      const redirectToCheckout = () =>
        (window.location.href = `/mixer-app/${prescription_id}/gift-checkout`);

      if (user) {
        const data = { ...user, temporary: true };
        saveLoginData(data);

        pushCompleteRegistrationEvent(user.user);

        loginUser(
          user.token,
          user,
          dispatch,
          'after temporary register form'
        ).then(redirectToCheckout);
      } else {
        redirectToCheckout();
      }
    });
  }, []);

  return <Spin />;
};
