import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';

import CloseIcon from '../../../../../../assets/img/close.svg';
import { fetchCancellationReasons } from '../../../../../../api/subscription';
import { CancellationFormValues } from '../types';

interface CancelSubscriptionModalProps {
  isOpen: boolean;
  onSubmit: (data: CancellationFormValues[]) => void;
  onCancel: () => void;
  openChangeDatesModal: () => void;
  currentDiscount: number;
  paymentDateLimit: number;
  submitButtonLoading: boolean;
}

type Reason = {
  has_comment: boolean;
  id: number;
  reason_key: string;
  reason_translated: string;
};

const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  isOpen,
  onSubmit,
  onCancel,
  openChangeDatesModal,
  currentDiscount,
  paymentDateLimit,
  submitButtonLoading
}) => {
  const { t } = useTranslation();

  const [reasons, setReasons] = useState<Reason[]>([]);
  const [selectedReasons, setSelectedReasons] = useState<Reason[]>([]);
  const [comments, setComments] = useState<string[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      fetchCancellationReasons().then(setReasons);
    }
  }, [isOpen]);

  const canBeSubmitted = selectedReasons.length !== 0;

  const handleChangeDate = () => {
    onCancel();
    openChangeDatesModal();
  };

  const submitForm = (v: any) => {
    const data: CancellationFormValues[] = Object.keys(v)
      .filter(k => v[k] && !isNaN(Number(k)))
      .map(k => {
        const reason = reasons.find(x => x.id === Number(k));
        return {
          reason_id: Number(k),
          reason_text: reason?.reason_translated || '',
          reason_key: reason?.reason_key || ''
        };
      })
      .map(k => {
        const user_comment = v[`user_comment_${k.reason_id}`];
        return user_comment ? { ...k, user_comment } : k;
      });

    if (canBeSubmitted) {
      onSubmit(data);
    }
  };

  const markReason = (reason: Reason) => {
    if (reason.has_comment) {
      const key = `user_comment_${reason.id}`;

      setComments(
        comments.includes(key)
          ? comments.filter(x => x !== key)
          : [...comments, key]
      );
    }

    if (selectedReasons.find(r => r.id === reason.id)) {
      setSelectedReasons(selectedReasons.filter(r => r.id !== reason.id));
    } else {
      setSelectedReasons([...selectedReasons, reason]);
    }
  };

  return (
    <Modal
      width={600}
      visible={isOpen}
      wrapClassName="subscription-modal"
      bodyStyle={{
        padding: 30,
        boxShadow: '0 2px 7px 0 #00000020'
      }}
      maskClosable
      centered
      footer={null}
      onCancel={onCancel}
      closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
    >
      <h2>{t('subscription-cancel-header')}</h2>
      <p style={{ marginBottom: 25 }}>
        {
          <Trans
            i18nKey="subscription-cancel-description"
            values={{
              currentDiscount: (currentDiscount * 100).toFixed(),
              paymentDateLimit
            }}
            components={{
              break: <br />,
              changeDateModal: (
                <span className="text-link" onClick={handleChangeDate} />
              )
            }}
          />
        }
      </p>

      <h3>
        {t('why-do-you-want-to-cancel')} <span className="required">*</span>
      </h3>

      <Form
        form={form}
        className="ant-form ant-form-vertical ant-form-hide-required-mark padding-10"
        onFinish={submitForm}
      >
        {reasons.map(reason => (
          <Form.Item
            key={reason.id}
            name={reason.id}
            valuePropName="checked"
            className="checkbox-item"
          >
            <Checkbox onClick={() => markReason(reason)}>
              {reason.reason_translated}
            </Checkbox>
          </Form.Item>
        ))}

        {comments.map(comment => (
          <Form.Item
            key={comment}
            name={comment}
            rules={[
              { required: true, message: t('required-field') },
              { max: 200, message: t('wrong-format') }
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        ))}

        <Form.Item>
          <Row>
            <Col xs={24} sm={12} className="col-padding">
              <Button
                className={'btn-padding'}
                type="default"
                shape="round"
                block
                size="large"
                onClick={onCancel}
              >
                {t('cancel-btn')}
              </Button>
            </Col>
            <Col xs={24} sm={12} className="col-padding">
              <Button
                loading={submitButtonLoading}
                htmlType="submit"
                disabled={!canBeSubmitted}
                type="primary"
                shape="round"
                block
                size="large"
              >
                {t('subscription-cancel-button')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CancelSubscriptionModal;
