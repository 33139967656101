import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row} from 'antd';
import './prescription-details-view.scss';
import PrescriptionDetailsHeader from './components/PrescriptionDetailsHeader';
import {getPrescription} from '../../../api/mixer';
import PrescriptionDetailsSubstances from './components/PrescriptionDetailsSubstances';
import {PrescriptionPreferencesBoxWrapper} from '../../../components/PrescriptionPreferencesBox';
import {useTranslation} from 'react-i18next';
import {AwaitData} from '../../../components/AwaitData';
import {editStatus} from '../../../utils/constants';
import {useSelector} from 'react-redux';
import warningImage from "../../../assets/img/warning.svg";

export const PrescriptionDetailsView = props => {
  const [prescription, setPrescription] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const {t} = useTranslation();

  const {subscriber} = useSelector(state => state.userDataReducer.settings);

  const fetchData = useCallback(async id => {
    setDataLoading(true);
    const response = await getPrescription(id);
    setPrescription(response);
    setDataLoading(false);
  }, []);

  useEffect(() => {
    fetchData(props.match.params.prescriptionId);
  }, [fetchData, props.match.params.prescriptionId]);

  if (!prescription) return dataLoading ? <AwaitData loading={true}/> : null;

  if (prescription.edit_status !== editStatus.COMPLETED) return null;

  const {exclusions} = prescription.json_body;

  return (
    <div className="prescription-details-view">
      <PrescriptionDetailsHeader prescription={prescription}/>

      <Col xs={{span: 22, offset: 1}} lg={{span: 18, offset: 3}}>
        <div className="prescription-info">
          <h1>{t('components')}</h1>
          <h4>{prescription.name}</h4>
          <h5>
            {t('prescription')} #{prescription.id}
          </h5>
          <h5 className="prescription-info-warning">
            {t('cannot-change-prescription-after-order')}
          </h5>
        </div>

        {subscriber ? (
          <div className="personalise-banner-container">
            <img src={warningImage} alt="warning"/>
            <span className="personalise-banner-message">{t('assign-prescription-to-subscription')}</span>
          </div>
        ) : null}


        <Row>
          <Col xs={{span: 22, offset: 1, order: 2}} lg={{span: 12, order: 1}}>
            <PrescriptionDetailsSubstances prescription={prescription}/>
          </Col>
          <Col xs={{span: 22, offset: 1, order: 1}} lg={{span: 8, order: 2}}>
            <PrescriptionPreferencesBoxWrapper
              exclusions={exclusions}
              flavour={prescription.flavour}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
};
