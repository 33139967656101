import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { confirmUnsavedChanges } from '../../utils/confirmModals';
import { bool } from 'prop-types';

class PreventLeavingPage extends React.Component {
  componentDidMount() {
    this.props.history.block(this.onLeavePage);
  }

  componentWillUnmount() {
    this.props.history.block(null);
  }

  onLeavePage = ({ pathname }) => {
    const { t, history, prevent } = this.props;

    if (!prevent) {
      history.block(null);
      return true;
    }

    confirmUnsavedChanges(pathname, history, t);
    return false;
  };

  render() {
    return null;
  }
}

PreventLeavingPage.propTypes = {
  prevent: bool.isRequired
};

export default withRouter(withTranslation()(PreventLeavingPage));
