import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { any, arrayOf, bool, oneOfType, string } from 'prop-types';

const { useForm } = Form;

export const FORM_NAME = 'authorization-form';

const AuthorizationForm = ({
  form,
  isSubmitButtonDisabled,
  submitting,
  pathsToValidate,
  submitButtonText,
  children,
  additionalButton,
  page,
  ...props
}) => {
  const [newForm] = useForm();

  if (typeof form === 'undefined') {
    form = newForm;
  }

  const [, forceUpdate] = useState();

  const checkIfAllFieldsAreTouched = useCallback(() => {
    if (Array.isArray(pathsToValidate))
      return pathsToValidate.reduce(
        (allTouched, currentPath) =>
          allTouched && form.isFieldTouched(currentPath),
        1
      );
    return form.isFieldsTouched(true);
  }, [form, pathsToValidate]);

  useEffect(() => {
    forceUpdate();
  }, []);

  const tabIndex = page === 'login' ? 3 : 5;

  return (
    <Form
      form={form}
      name={FORM_NAME}
      layout="vertical"
      className="authorization-form"
      {...props}
    >
      {children}
      <Form.Item shouldUpdate={true} className="submit-button">
        {() => (
          <>
            <Button
              type="primary"
              htmlType="submit"
              tabIndex={tabIndex}
              disabled={
                submitting ||
                !checkIfAllFieldsAreTouched() ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length ||
                isSubmitButtonDisabled
              }
              loading={submitting}
            >
              {submitButtonText}
            </Button>
            {additionalButton}
          </>
        )}
      </Form.Item>
    </Form>
  );
};

AuthorizationForm.propTypes = {
  submitButtonText: string.isRequired,
  pathsToValidate: oneOfType([arrayOf(string), bool]),
  submitting: bool,
  additionalButton: any
};

AuthorizationForm.defaultProps = {
  pathsToValidate: true,
  submitting: false,
  additionalButton: null
};

export default AuthorizationForm;
