import {useEffect} from 'react';
import {countryCurrency, dataLayerBundleLevels} from '../constants';
import {getLSItem} from '../index';

/**
 * @TODO move this global TS interface somewhere higher in code to not declare it many times?
 */
declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

export type AnalyticsEventItem = {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_variant: string;
  price: string;
  currency: string;
  quantity: number;
};

export const useDataLayerEvent = (
  name: string | undefined,
  body: Record<string, unknown>
) => {
  useEffect(() => {
    pushToDataLayer(name, body);
  }, []);
};

export function getItemsFromPrescription(
  prescription: any,
  baselinePrices: any,
  exchangeRates: any
): AnalyticsEventItem[] {
  const {
    id,
    form,
    json_body: { selected_bundle, country_norms_code }
  } = prescription;

  const currencyCode = countryCurrency[country_norms_code];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const variant = dataLayerBundleLevels[selected_bundle.level];

  const packagePrice = baselinePrices.get(country_norms_code);
  const totalPrice = prescription.price + packagePrice;

  return [
    {
      item_id: id,
      item_name: 'Sundose Personalized Supplement',
      item_brand: 'Sundose',
      item_category: form,
      item_category2: selected_bundle.id,
      item_variant: variant,
      price: (totalPrice * exchangeRates.get(currencyCode)).toFixed(2),
      currency: currencyCode,
      quantity: 1
    }
  ];
}

export function getUserId() {
  const auth = getLSItem('flutter.sundose_auth') || '{}';
  const { user } = JSON.parse(auth);
  return user || null;
}

export function pushUserDataToDataLayer(
  user_id: unknown,
  language_chosen: string,
  page_type: string | undefined
): void {
  pushToDataLayer(undefined, {
    user_id,
    language_chosen,
    page_type
  });
}

export const pushToDataLayer = (
  name: string | undefined,
  body?: Record<string, unknown>
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...(name && { event: name }),
    ...body,
    user_id: getUserId()
  });
};
