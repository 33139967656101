import React from 'react';
import { element, instanceOf } from 'prop-types';
import { Map } from 'immutable';
import { Trans, useTranslation } from 'react-i18next';
import { CollapseHeader } from '../Collapse';
import IconShoppingCart from '../../assets/img/icon-shopping-cart.svg';
import { Button } from 'antd';
import { editStatus } from '../../utils/constants';
import { getEnv } from '../../../env';
import {
  getItemsFromPrescription,
  pushToDataLayer
} from '../../utils/dataLayer';
import { useSelector } from 'react-redux';
import { LANGUAGES } from '../../../utils';

const mixerBaseUrl = getEnv('REACT_APP_PUBLIC_MIXER_BASE_URL');

const CollapsePrescriptionHeader = ({ prescription }) => {
  const { exchange_rates, baseline_prices } = useSelector(
    state => state.panelMixerData
  );

  const { paid_transactions_count } = useSelector(
    state => state.userDataReducer.settings
  );

  const prescriptionId = prescription.get('id');
  const { t } = useTranslation();

  const extraButton =
    prescription.get('edit_status') === editStatus.DRAFT ? null : (
      <Button
        className="custom-icon-button-link"
        type="primary"
        style={{ height: 'fit-content' }}
        href={`${mixerBaseUrl}/${prescriptionId}/checkout`}
        onClick={() =>
          pushToDataLayer('add_to_cart', {
            button_label: t('buy-given-prescription', { lng: LANGUAGES.EN }),
            paid_transactions_count,
            process_type:
              paid_transactions_count > 0 ? 'retention' : 'acquisition',
            formulation_category: prescription.get('form'),
            items: getItemsFromPrescription(
              prescription.toJS(),
              baseline_prices,
              exchange_rates
            )
          })
        }
      >
        <img src={IconShoppingCart} alt="shopping cart" />
        {t('buy-given-prescription')}&nbsp;→
      </Button>
    );

  return (
    <CollapseHeader
      title={<>{prescription.get('name')}</>}
      subtitle={
        <Trans i18nKey="prescription-subtitle">{{ prescriptionId }}</Trans>
      }
      extraButton={extraButton}
    />
  );
};

CollapsePrescriptionHeader.propTypes = {
  prescription: instanceOf(Map).isRequired,
  extraButton: element
};

export default CollapsePrescriptionHeader;
