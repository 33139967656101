import React from 'react';

const LinkExpiredIcon = () => {
  return (
    <svg
      width="101"
      height="110"
      viewBox="0 0 101 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="link-expired"
    >
      <path
        d="M22.1564 57.6331C41.1594 51.6935 30.5891 7.70904 56.6336 7.38347C77.8776 7.12015 109.719 49.1027 70.6891 67.1539C49.5007 76.9556 65.7483 89.8836 38.42 93.615C11.0917 97.3465 -5.9063 66.4034 22.1564 57.6331Z"
        fill="#FFD7CB"
      />
      <circle cx="18.9161" cy="25.2191" r="4.20321" fill="#FFD7CB" />
      <circle cx="70.0547" cy="79.861" r="2.1016" fill="#FFD7CB" />
      <path
        d="M33.4769 41.7817C30.8137 39.0391 29.2603 35.3824 29.2603 31.2686C29.2603 22.8125 35.9181 15.9561 44.1295 15.9561C52.3409 15.9561 58.9987 22.8125 58.9987 31.2686C58.9987 32.1828 58.9987 33.097 58.7768 34.0111"
        stroke="#2A2928"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5881 32.1736C38.6383 32.1736 39.4897 31.3221 39.4897 30.2717C39.4897 29.2212 38.6383 28.3697 37.5881 28.3697C36.5379 28.3697 35.6865 29.2212 35.6865 30.2717C35.6865 31.3221 36.5379 32.1736 37.5881 32.1736Z"
        fill="#2A2928"
      />
      <path
        d="M48.3625 29.0037C49.4127 29.0037 50.2641 28.1522 50.2641 27.1018C50.2641 26.0513 49.4127 25.1998 48.3625 25.1998C47.3123 25.1998 46.4609 26.0513 46.4609 27.1018C46.4609 28.1522 47.3123 29.0037 48.3625 29.0037Z"
        fill="#2A2928"
      />
      <path
        d="M76.1036 56.5626C76.7375 58.6759 77.16 60.1552 77.3713 60.5778C78.0052 62.9024 78.8503 64.3818 75.8923 65.4384C72.9343 66.495 41.0301 75.1596 39.1285 75.7935C37.2269 76.4275 35.1141 76.4275 34.4802 74.3142L32.5786 67.5517"
        stroke="#2A2928"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0872 52.1118L26.8747 47.6866C26.2408 44.9393 27.2972 43.6713 30.2552 42.826C33.4245 41.7693 61.7369 33.1048 64.2723 32.4708C66.5965 31.8368 68.7093 31.8368 69.5545 34.5841C69.7658 35.4294 70.2845 37.2344 71.1297 39.9817"
        stroke="#2A2928"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0422 50.8941C44.4339 50.6311 43.7275 50.9111 43.4646 51.5194C43.2016 52.1277 43.4815 52.8341 44.0899 53.097L50.2859 55.7755L47.6076 61.9711C47.3447 62.5794 47.6246 63.2857 48.233 63.5487C48.8413 63.8117 49.5476 63.5317 49.8106 62.9234L52.4889 56.7278L58.6842 59.406C59.2925 59.6689 59.9989 59.389 60.2618 58.7806C60.5248 58.1723 60.2448 57.466 59.6365 57.203L53.4412 54.5248L56.1195 48.3291C56.3825 47.7208 56.1025 47.0144 55.4942 46.7514C54.8859 46.4885 54.1795 46.7684 53.9165 47.3768L51.2382 53.5725L45.0422 50.8941Z"
        fill="#EC2A18"
      />
      <path
        d="M23.0723 56.3512C22.6497 54.4492 24.1287 52.3359 26.2416 52.1246C28.777 51.9133 32.3689 52.3359 33.8479 56.1399C36.8059 62.6911 33.4253 68.8196 29.1996 69.6649C26.0303 70.5103 23.9174 61.4231 23.0723 56.3512Z"
        stroke="#2A2928"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.3718 41.5581C76.7379 39.6561 74.6251 38.5995 72.7235 39.2334C70.188 40.2901 67.4413 42.1921 67.6526 46.2073C68.0752 53.3925 73.7799 57.1965 78.0056 56.1398C81.1749 55.5058 78.8508 46.63 77.3718 41.5581Z"
        stroke="#2A2928"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkExpiredIcon;
