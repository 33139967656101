import React, { Component, useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router';

export const mapRedirectToProps = WrappedComponent => {
  return class WithRedirect extends Component {
    render() {
      const {
        history: {
          location: { search }
        }
      } = this.props;
      const redirect = retrieveRedirectPath(search);
      return <WrappedComponent {...this.props} redirect={redirect} />;
    }
  };
};

export const useRedirect = () => {
  const history = useHistory();
  const {
    location: { search }
  } = history;

  const redirect = useMemo(() => {
    return retrieveRedirectPath(search);
  }, [search]);

  const redirectTo = useCallback(
    path => {
      let { target, externalTarget } = redirect;
      if (path !== undefined) target = path;
      if (typeof externalTarget === 'undefined' || !externalTarget)
        history.push(target);
      else window.location.href = target;
    },
    [history, redirect]
  );

  return [redirect, redirectTo];
};

export const retrieveRedirectPath = path => {
  let parsedPath = queryString.parse(path, { parseBooleans: true });
  if (parsedPath.redirect) {
    return {
      shouldRedirect: true,
      target: parsedPath.redirect,
      externalTarget:
        parsedPath.external !== null ? parsedPath.external : false,
      search: `?redirect=${parsedPath.redirect}${
        parsedPath.external ? '&external=true' : ''
      }`
    };
  }

  return {
    shouldRedirect: false,
    target: '',
    externalTarget: false,
    search: ''
  };
};
