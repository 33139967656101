import React from 'react';

const ProgressBar = ({ progress }) => {
  const progressStyle = {
    width: `${progress}%`
  };

  return (
    <div id="progress">
      <div className="line" />
      <div className="line-progress" style={progressStyle} />
    </div>
  );
};

export default ProgressBar;
