import React from 'react';
import { Input } from 'antd';
import { EyeWithEyelashes } from '../../../assets/reactIcons';
import { func, string } from 'prop-types';

const PasswordInput = ({ value, onChange, ...props }) => {
  return (
    <Input.Password
      tabIndex={2}
      value={value}
      onChange={onChange}
      className="password-input"
      {...props}
      iconRender={visible => (
        <span>
          <EyeWithEyelashes color={visible ? '#375DBE' : '#4D576F'} />
        </span>
      )}
    />
  );
};

PasswordInput.propTypes = {
  value: string,
  onChange: func
};

export default PasswordInput;
