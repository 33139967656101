import { fromJS, List, Map } from 'immutable';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { setDefaultPrescriptionName, tokenKeys } from './constants';

const { USER_ID, ROLES } = tokenKeys;

export const deserializeCalendarInventory = inventory => {
  return Map({
    ...inventory,
    datetime_updated_at: moment(inventory.datetime_updated_at)
  });
};

export const deserializeToken = token => {
  if (!token || token === 'undefined') return Map();

  const tokenData = jwt_decode(token);

  return Map({
    ...tokenData,
    [USER_ID]: parseInt(tokenData[USER_ID]),
    [ROLES]: List(tokenData[ROLES].split(','))
  });
};

export const deserializePrescriptions = prescriptions => {
  return fromJS(
    prescriptions.map(prescription => {
      if (prescription['name'].length === 0) {
        prescription['name'] = setDefaultPrescriptionName(prescription.id);
      }
      return prescription;
    })
  );
};
