import React, { useCallback, useMemo } from 'react';
import { Button, Col, Grid, Row } from 'antd';
import { Link, matchPath, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import {
  AuthorizationViewBackgroundDesktop,
  AuthorizationViewBackgroundMobileTop
} from '../../../assets/reactIcons';

const { useBreakpoint } = Grid;

const baseUrl = '/panel/auth';

export const Footer = () => {
  const [t] = useTranslation();
  const location = useLocation();

  const content = useMemo(() => {
    let data = {
      title: t("Don't have an account?"),
      buttonText: t('Register'),
      buttonLink: `${baseUrl}/register`
    };

    if (
      matchPath(location.pathname, { path: `${baseUrl}/register` }) ||
      matchPath(location.pathname, { path: `${baseUrl}/quick-register` })
    ) {
      data = {
        title: t('Do you already have an account?'),
        buttonText: t('Log in'),
        buttonLink: `${baseUrl}/login`
      };
    }

    data.buttonLink = data.buttonLink + location.search;

    return data;
  }, [location.pathname, location.search, t]);

  return (
    <div className="auth-footer">
      <div className="auth-footer--title">{content.title}</div>
      <div className="auth-footer--actions">
        <Button type="link">
          <Link to={content.buttonLink}>{content.buttonText} →</Link>
        </Button>
      </div>
    </div>
  );
};

const UserAuthorizationRoute = ({ component: Component, ...props }) => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const breakPoints = useBreakpoint();

  const pathClassName = useMemo(() => {
    return location.pathname
      .replace(url, '')
      .replace(/\//g, ' ')
      .trim()
      .replace(/ /g, '-'); // create css classes from url path
  }, [location.pathname, url]);

  const onLogoClick = useCallback(() => {
    window.location.href = '/';
  }, []);
  return (
    <div className="auth-wrapper">
      <Route {...props}>
        {!breakPoints.md && (
          <AuthorizationViewBackgroundMobileTop
            className={pathClassName}
            onLogoClick={onLogoClick}
          />
        )}

        <Row className="auth-container">
          <Col span={24}>
            <Row>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12, offset: 6 }}
                lg={{ span: 10, offset: 7 }}
                className="auth-content-container"
              >
                <Component {...props} />
                <Footer />
              </Col>
            </Row>
            {breakPoints.md && (
              <AuthorizationViewBackgroundDesktop
                className={pathClassName}
                onLogoClick={onLogoClick}
              />
            )}
          </Col>
        </Row>
      </Route>
    </div>
  );
};

export default UserAuthorizationRoute;
