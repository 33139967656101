import React from 'react';

const SendIcon = () => (
  <svg
    className="send-icon"
    width="144"
    height="158"
    viewBox="0 0 144 158"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.6279 82.2702C58.7542 73.7915 43.6653 11.0045 80.8435 10.5398C111.169 10.1639 156.622 70.0932 100.907 95.8609C70.6613 109.853 93.8544 128.307 54.8439 133.634C15.8333 138.96 -8.43109 94.7896 31.6279 82.2702Z"
      fill="currentColor"
    />
    <path
      d="M92.5006 44.9998C92.5006 44.9998 69.7712 63.4768 46.7223 81.5081C37.2619 88.9326 38.4732 91.2645 24.2266 82.7664C8.81757 73.5559 4.90786 73.6254 19.3589 67.7454C33.81 61.8654 45.2811 56.2571 67.9987 46.6004C79.5573 41.6431 96.7874 33.7815 101.349 32.8195C105.911 31.8575 107.298 31.0098 104.526 37.7045C101.741 44.4542 86.2662 84.8176 79.3073 99.9684C73.3654 112.812 75.6143 110.37 59.6633 100.755C41.0285 89.5215 40.5112 87.6853 38.998 99.6528C37.4848 111.62 38.7723 110.176 44.0786 105.526C46.9888 102.941 50.8554 99.6446 50.8554 99.6446"
      stroke="#2A2928"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="27.0011" cy="36" r="6" fill="currentColor" />
    <circle cx="100.001" cy="114" r="3" fill="currentColor" />
  </svg>
);

export default SendIcon;
