const AnimationActions = {
  idle: 'idle',
  awaitAnswer: 'await-answer',
  goToQuestion: 'go-to-question',
  nextQuestion: 'next-question',
  prevQuestion: 'previous-question',
  answered: 'answered',
  slideQuestionToLeft: 'slide-question-to-left',
  movingRight: 'moving-to-right',
  movingLeft: 'moving-to-left'
};

export default AnimationActions;
