import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

import CloseIcon from '../../../../../../assets/img/close.svg';
import { SecureForm } from '../../../../../../components/SecureForm/SecureForm';
import { CardChangedBody } from '../types';

interface ChangeCardModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (body: CardChangedBody) => void;
  onCancel: () => void;
}

const ChangeCardModal: React.FC<ChangeCardModalProps> = ({
  isOpen,
  isLoading,
  onSubmit,
  onCancel
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={600}
      visible={isOpen}
      wrapClassName="subscription-modal"
      bodyStyle={{
        padding: 30,
        boxShadow: '0 2px 7px 0 #00000020'
      }}
      maskClosable
      centered
      footer={null}
      onCancel={onCancel}
      closeIcon={<img src={CloseIcon} width="16" alt="close icon" />}
    >
      <h2>{t('change-payment-card')}</h2>

      {isOpen ? (
        <SecureForm isLoading={isLoading} onSubmit={onSubmit} changingMode />
      ) : null}
    </Modal>
  );
};

export default ChangeCardModal;
